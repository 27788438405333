import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar } from "@syncfusion/ej2-react-grids";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { useParams } from "react-router-dom";
import { addEmployeeHistory, getEmployeeHistory } from "../../../helpers/backend_helper";
import LoadingSpinner from "../../shared/Loading";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import Spinner from "../../shared/spinner";


const EmploymentHistory = (props) => {
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [empHistoryList, setEmpHistoryList] = useState();
    const [empHistory, setEmpHistory] = useState({
        employeeId: 0, companyName: "", companyAddress: "", jobTitle: "", fromDate: "", toDate: "", jobDescription: ""
    })

    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const params = useParams();

    useEffect(() => {
        setEmpHistory({ ...empHistory, 'employeeId': props.employeeId });

        getEmployeeHistory(props.employeeId).then(res => {
            console.log(res.data);
            if (res.data) {
                setEmpHistoryList(res.data)
            }
        });
    }, [submitted])

    const onSubmitEH = (event) => {
        event.preventDefault();
        // console.log(empHistory);
        const dateRange = validateDateRange(empHistory.fromDate, empHistory.toDate);
        // console.log(dateRange);

        let formErrors = {};
        if (!empHistory.jobTitle) {
            formErrors.jobTitle = 'Job Title is required';
        }
        if (!empHistory.companyName) {
            formErrors.companyName = 'Company Name is required';
        }
        if (!empHistory.companyAddress) {
            formErrors.companyAddress = 'Company Address is required';
        }
        if (!empHistory.fromDate) {
            formErrors.fromDate = 'From Date is required';
        }
        if (!empHistory.toDate) {
            formErrors.toDate = 'To Date is required';
        }
        if (!dateRange) {
            formErrors.toDate = dateRange;
        }
        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            // console.log(empHistory);
            addEmployeeHistory(empHistory).then(res => {
                if (res.status === true) {
                    setDataSuccess("added");
                    setIsLoading(false);
                    setEmpHistory({
                        companyName: "", companyAddress: "", jobTitle: "", fromDate: "", toDate: "", jobDescription: ""
                    });
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const handleChange = event => {
        setEmpHistory({ ...empHistory, [event.target.name]: event.target.value });
    };

    const toggleEmpHistory = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    return (
        <>
            <Row className="m-2">
                <Col xl="9" className="mt-2" md="9" sm="9">
                    <CardTitle tag="h4">Employment History</CardTitle>
                </Col>
                <Col xl="3" md="3" sm="4">
                    <button type="button" onClick={toggleEmpHistory} className="btn btn-success waves-effect waves-light">
                        <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                        Add
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button type="button" className="btn btn-warning waves-effect waves-light">
                        <i className="bx bx-revision font-size-16 align-middle me-2"></i>{" "}
                        Refresh
                    </button>
                </Col>
            </Row>

            <CardBody>
                {!empHistoryList ?
                    <div className='text-center mt-4'>
                        <Spinner />
                    </div>
                    :

                    <GridComponent dataSource={empHistoryList} allowPaging={true} statelessTemplates={['directiveTemplates']}
                        pageSettings={{ pageCount: 5 }}>
                        <ColumnsDirective>
                            <ColumnDirective field="jobTitle" headerText='Title' width='150'></ColumnDirective>
                            <ColumnDirective field="companyName" headerText='Company Name' width='150' />
                            <ColumnDirective field="companyAddress" headerText='Address' width='150' />
                            <ColumnDirective field="fromDate" type="datetime" format="dd/MM/yyyy" headerText='From' width='150'></ColumnDirective>
                            <ColumnDirective field="toDate" type="datetime" format="dd/MM/yyyy" headerText='To' width='150' />
                        </ColumnsDirective>
                        <Inject services={[Page, Toolbar]} />
                    </GridComponent>
                }
            </CardBody>

            <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleEmpHistory}>
                <OffcanvasHeader toggle={toggleEmpHistory}>
                    <Row>
                        <Col sm='12'>
                            <b>Employment History</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />

                <OffcanvasBody>
                    <FormGroup>
                        <Row className="mb-1">
                            <Col sm="12" md="12">
                                <Label for="bankname">Job Title</Label>
                                <Input type="text" name="jobTitle" value={empHistory.jobTitle} onChange={handleChange} placeholder="Enter Job Title"></Input>
                                <p style={{ color: "Red" }}>{errors.jobTitle && <span>{errors.jobTitle}</span>}</p>
                            </Col>
                        </Row>

                        <Row className="mb-1">
                            <Col sm="12" md="12">
                                <Label for="bankname">Organisation/Company</Label>
                                <Input type="text" name="companyName" value={empHistory.companyName} onChange={handleChange} placeholder="Enter Organisation/Company"></Input>
                                <p style={{ color: "Red" }}>{errors.companyName && <span>{errors.companyName}</span>}</p>
                            </Col>
                        </Row>

                        <Row className="mb-1">
                            <Col sm="12" md="12">
                                <Label for="bankname">Company Address</Label>
                                <Input type="text" name="companyAddress" value={empHistory.companyAddress} onChange={handleChange} placeholder="Enter Work Location"></Input>
                                <p style={{ color: "Red" }}>{errors.companyAddress && <span>{errors.companyAddress}</span>}</p>
                            </Col>
                        </Row>

                        <Row className="mb-1">
                            <Col sm="12" md="12">
                                <Label for="bankname">From Date</Label>
                                <Input type="date" name="fromDate" value={empHistory.startDate} onChange={handleChange} placeholder="Enter From Date"></Input>
                                <p style={{ color: "Red" }}>{errors.fromDate && <span>{errors.fromDate}</span>}</p>
                            </Col>
                        </Row>

                        <Row className="mb-1">
                            <Col sm="12" md="12">
                                <Label for="bankname">To Date</Label>
                                <Input type="date" name="toDate" value={empHistory.toDate} onChange={handleChange} placeholder="Enter To Date"></Input>
                                <p style={{ color: "Red" }}>{errors.toDate && <span>{errors.toDate}</span>}</p>
                            </Col>
                        </Row>

                        <Row className="mb-1">
                            <Col sm="12" md="12">
                                <Label for="bankname">Job Description</Label>
                                <Input type="textarea" name="jobDescription" value={empHistory.jobDescription} onChange={handleChange} placeholder="Enter Job Description"></Input>
                            </Col>
                        </Row>
                    </FormGroup>
                    <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
                        {dataSuccess === 'added' && <SuccessAlert />}
                        {dataSuccess === 'failed' && <FailureAlert />}
                    </div>
                    <div className="offcanvas-footer">
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            onClick={onSubmitEH} className="btn btn-sm" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;
                        <button onClick={toggleEmpHistory} className="btn btn-dark btn-sm">Close</button>
                    </div>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default EmploymentHistory;

function validateDateRange(startDate, endDate) {
    if (endDate && startDate && endDate < startDate) {
        return "To date must be after From date";
    }
    return true;
}