import { getData, postData, downloadFile } from "../../../helpers/url-request";
import {
    ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, FilterType, Inject, Sort, Edit,
    ColumnMenu, CommandColumn, Toolbar, Resize, ExcelExport, PdfExport, Group } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row, CardBody, Nav, NavItem, CardTitle, TabContent, TabPane, NavLink, Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import classnames from "classnames";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import TaskDetailView from "../../Tasks/MyTask/TaskDetailView";
import PayslipDetail from "../Payslips/payslipdetail";
import { showLoading } from 'react-global-loading';

const PayrollDetails = (payroll) => {
    let gridInstance;
    const [activeTab, setactiveTab] = useState(2);
    const [active, setActive] = useState('2')
    const [salaryslipList, setSalaryslipList] = useState();
    const [payrollDetail, setPayrollDetail] = useState();
    const [taskId, setTaskId] = useState();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [salaryDetail, setSalaryDetail] = useState();

    const loadPayslips = () => {

        getData("/payroll/payslips/" + payroll.payrollId).then(res => {
            showLoading(false);
            console.log(res);
            if (res.data) {
                setSalaryslipList(res.data);
            }
        });        
    }

    useEffect(() => {
        console.log(payroll.payrollId);

        showLoading(true);

        getData("/payroll/payrolldetail/" + payroll.payrollId).then(res => {
            console.log(res);
            if (res.data) {
                setPayrollDetail(res.data);
                setTaskId(res.data.taskId);
            }
        });

        loadPayslips();
        //setTaskId(payroll.taskId);

    }, []);

    const submitApproval = () => {
        console.log("submitApproval");
    };

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' },
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' }
    ];

    const toolbarClick = (args) => {
        console.log(args.item);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadPayslips();
                break;
        }
    }

    const viewSalarySlip = (id) => {

        showLoading(true);

        getData("/payroll/payslip/" + id).then(res => {
            console.log(res);
            if (res.data) {
                setSalaryDetail(res.data);
                setTimeout(() => {
                    showLoading(false);
                    toggle();
                }, 1000);
            } else {
                showLoading(false);
            }
        });
        
    }

    const onRowDoubleClick = (events) => {
        console.log(events.rowData);
        //setFormData({ ...formData, ["payscaleId"]: events.rowData.id });        
        //toggle();
        viewSalarySlip(events.rowData.id);
    };

    const linktemplate = props => {
        return (
            <button onClick={() => viewSalarySlip(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                View
            </button>
        )
    }

    return (        
        <div>

            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>Pay Slip Detail</ModalHeader>
                <ModalBody>
                    <PayslipDetail data={salaryDetail} />
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button color="secondary" className="btn primary" type="button" onClick={toggle}>Close</Button>
                    </div>
                </ModalFooter>
            </Modal>

            <Container fluid>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            href="#" style={{ display:'none' }}
                            className={classnames({ active: activeTab === 1 })}
                            onClick={() => {
                                setactiveTab(1);
                            }}>
                            <span>Summary</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            href="#"
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                                setactiveTab(2);
                            }}>
                            <span>Payslips</span>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            href="#"
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                                setactiveTab(3);
                            }}>
                            <span>Approval</span>                            
                        </NavLink>
                    </NavItem>

                </Nav>

                <TabContent
                    className="twitter-bs-wizard-tab-content"
                    activeTab={activeTab}>
                    <TabPane tabId={1}>
                        <Row className="m-2">
                            <Col sm='6' md='6' lg='6'>
                                <h4>Payroll Summary</h4>
                                <hr />
                                <Row className="mb-2">
                                    <Col sm='6' md='6' lg='6'>
                                        Payroll Type:- Monthly
                                    </Col>
                                    <Col sm='6' md='6' lg='6'>
                                        Net Amount
                                        ₹24,283.93
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm='6' md='6' lg='6'>
                                        Batch:- Full Time Batch
                                    </Col>
                                    <Col sm='6' md='6' lg='6'>
                                        TDS ₹0.00
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm='6' md='6' lg='6'>
                                        Employee Count:- 1
                                    </Col>
                                    <Col sm='6' md='6' lg='6'>
                                        Deductions  ₹2,144.64
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm='6' md='6' lg='6'>
                                        Total Days:- 28
                                    </Col>
                                    <Col sm='6' md='6' lg='6'>
                                        Gross ₹26,428.57
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm='6' md='6' lg='6'>
                                    </Col>
                                    <Col sm='6' md='6' lg='6'>
                                        <b>Loss Of Pay  ₹714.29</b>
                                    </Col>
                                </Row>

                            </Col>
                            {/* </Row>

                        <Row> */}

                            <Col sm='6' md='6' lg='6'>
                                <h4>Direct Deposit</h4>
                                <hr />
                                <Row className="mb-2">
                                    <Col sm='6' md='6' lg='6'>
                                        Processed On :-  Feb 18, 2023 at 09:54 AM
                                    </Col>
                                    <Col sm='6' md='6' lg='6'>
                                        Access this service for a hassle free way to deposit salary to employees' bank accounts.
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm='6' md='6' lg='6'>
                                        Processed By:- Prasad G
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <br /><br />
                        <Row>
                            <Col sm='8' md='8' lg='8'>
                                <Row>
                                    <Col sm='6' md='6' lg='6'>
                                        <h4>ESI/Prof. Tax Summary</h4>
                                        <hr />
                                        <Row className="mb-2">
                                            <Col sm='9' md='9' lg='9'>
                                                ESI Employee Contribution:-
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                ₹626.79
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm='9' md='9' lg='9'>
                                                ESI Employer Contribution:-
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                ₹626.79
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm='9' md='9' lg='9'>
                                                Total ESI:-
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                ₹771.43
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm='9' md='9' lg='9'>
                                                Total ESI Rounded:-
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                ₹772.00
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm='9' md='9' lg='9'>
                                                ESI Employees (count):-
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm='9' md='9' lg='9'>
                                                Profession Tax:-
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                ₹200.00
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm='9' md='9' lg='9'>
                                                PT Employees (count):-
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                1
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm='6' md='6' lg='6'>
                                        <h4>Loans/Advances</h4>
                                        <hr />
                                        <Row className="mb-2">
                                            <Col sm='10' md='10' lg='10'>
                                                Loans (count):-
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm='10' md='10' lg='10'>
                                                Deductions (count):-
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                1
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm='10' md='10' lg='10'>
                                                Loan Amount (Balance)
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                ₹0.00
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm='10' md='10' lg='10'>
                                                Monthly Deduction Amoun
                                            </Col>
                                            <Col sm='2' md='2' lg='2'>
                                                ₹0.00
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                            <Col sm='4' md='4' lg='4'>
                                <h4>Post Payroll Checklist</h4>
                                <hr />
                                <a href="#"><b>Release Payslips</b></a>
                                <br /><br />
                                <a href="#"><b>Pay Date & Payment Method</b></a>
                                <br /><br />
                                <a href="#"><b>EPF Transfer & Filing Check</b></a>
                                <br /><br />
                                <a href="#"><b>ESI Transfer & Filing Check</b></a>
                                <br /><br />
                                <a href="#"><b>Profession Tax Payment Tracking</b></a>
                            </Col>
                            {/* </Row>

                        <Row> */}

                            <Row className="mt-4">
                                <Col sm='4' md='4' lg='4'>
                                    <h4>TDS Summary</h4>
                                    <hr />
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Income Tax
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹0.00
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Surcharge
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹0.00
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            CESS
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹0.00
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Total TDS
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹0.00
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Total TDS Rounded
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹0.00
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Employees (count)
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹0.00
                                        </Col>
                                    </Row>
                                </Col>

                                <Col sm='4' md='4' lg='4'>
                                    <h4>PF Summary</h4>
                                    <hr />
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Employee Contribution (EE)
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹1,800.00
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Employer Contribution (ER)
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹1,950.00
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Total PF
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹3,750.00
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Total PF Rounded
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            ₹3,750.00
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='10' md='10' lg='10'>
                                            Employees (count)
                                        </Col>
                                        <Col sm='2' md='2' lg='2'>
                                            1
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Row>                     

                        </TabPane>
                        <TabPane tabId={2}>                            
                            
                                <GridComponent dataSource={salaryslipList} statelessTemplates={['directiveTemplates']} toolbar={toolbarOptions} ref={grid => gridInstance = grid}
                            allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowFiltering={true} recordDoubleClick={onRowDoubleClick}
                                showColumnMenu={true} allowPaging={true} pageSettings={{ pageCount: 5 }} filterSettings={{ type: 'Excel' }} allowResizing={true}>
                                        <ColumnsDirective>
                                            <ColumnDirective field="employeeProfile.fullName" headerText='Employee Name' width='150'></ColumnDirective>
                                            <ColumnDirective field="payrollDetail.payrollMonth" headerText='Pay Period' width='150'></ColumnDirective>
                                            <ColumnDirective field="paymentType.name" headerText='Payment Method' width='150'></ColumnDirective>
                                            <ColumnDirective field="basic" headerText='Basic (₹)' width='150' />
                                            <ColumnDirective field="allowances" headerText='Allowances (₹)' width='150' />
                                            <ColumnDirective field="gross" headerText='Gross (₹) ' width='150' />
                                            <ColumnDirective field="deductions" headerText='Deductions (₹) ' width='150' />
                                            <ColumnDirective field="netAmount" headerText='Net Amount (₹)' width='150' />
                                            <ColumnDirective headerText='View' width='150' template={linktemplate}  />
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
                                </GridComponent>
                                      
                    </TabPane>
                    <TabPane tabId={3}>
                        {taskId > 0 ? <TaskDetailView taskId={taskId} /> : ""}
                    </TabPane>
                </TabContent>
                
            </Container>
        </div>
    )
}

export default PayrollDetails;