import { Fragment, useEffect } from "react"

import { Alert, Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row, UncontrolledAlert } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject }
    from "@syncfusion/ej2-react-grids";

const SettingsPayrollBatch = () => {    
   
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: 0
    });
    const [errors, setErrors] = useState({});

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }


    const handleChange = event => {
        if (event.target.name === "status") {
            let statusSet = event.target.checked ? 1 : 0;
            setFormData({ ...formData, [event.target.name]: statusSet });
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    };


    const handleSubmit = event => {
        event.preventDefault();

        // Validate form inputs
        let formErrors = {};
        if (!formData.name) {
            formErrors.name = 'Name is required';
        }
        if (!formData.description) {
            formErrors.description = 'Description is required';
        }

        setErrors(formErrors);

        // If there are no errors, submit the form
        if (Object.keys(formErrors).length === 0) {
            //Submit the form here
        }
    };

    return (

        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Payroll Batch" enableAction={true} actionName="Add Batch" onClick={toggleCanvasEnd}></Breadcrumbs>

                    <GridComponent allowPaging={true} pageSettings={{ pageCount: 5 }}>
                        <ColumnsDirective>
                            <ColumnDirective  headerText='Name' width='150'></ColumnDirective>
                            <ColumnDirective headerText='Description' width='150' />
                            <ColumnDirective width='150' />
                            <ColumnDirective  width='150'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page]} />
                    </GridComponent>                   

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Payroll Batch</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />

                            <OffcanvasBody>                              

                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Name:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                placeholder="Enter Name"
                                                onChange={handleChange}
                                                value={formData.name}
                                            />
                                            <p style={{ color: "Red" }}>{errors.name && <span>{errors.name}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Description:
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                placeholder="Description"
                                                onChange={handleChange}
                                                value={formData.description}
                                            />
                                            <p style={{ color: "Red" }}>{errors.description && <span>{errors.description}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Status:
                                            </Label>
                                            <Input
                                                type="checkbox"
                                                name="status"
                                                onChange={handleChange}
                                                value={formData.status}
                                            />
                                            {/* <p style={{ color: "Red" }}>{errors.description && <span>{errors.description}</span>}</p> */}
                                        </Col>
                                    </Row>
                                     <Button color="primary" type="submit">Submit</Button>

                                </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>


    );
}

export default SettingsPayrollBatch;