import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Row, Modal, ModalBody, ModalHeader, ModalFooter, Label } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Resize } from "@syncfusion/ej2-react-grids";
import { useEffect, useState, useRef } from "react";
import {
    createTimeEntry, getCompanyLocations, getCompanyPayperiods, getDepartments, getEmployeeList,
    getManulAttendanceList, getVendorsPayments, getAllContractors, getUserLocations
} from "../../../helpers/backend_helper";
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const MonthlyAttendanceReport = (props) => {
    let gridInstance;
    const [locations, setLocations] = useState();
    const [payPeriods, setPayPeriods] = useState();
    const [attendance, setAttendanceDetails] = useState();
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
    const [formData, setFormData] = useState({
        "payPeriodId": 0,  "locationId": 0, "departmentId": 0,
        "contractorId": 0, "employeeId": 0
    })

    useEffect(() => {

        getCompanyPayperiods().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "payPeriodTitle": "Select Period" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setPayPeriods(emps)
            }
        });

        getUserLocations().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "locationName": "Select Location" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setLocations(emps)
            }
        });
       
    }, []);

    const handleChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        //if (event.target.name === 'hours') {
        //    setHrs(event.target.value)
        //} else if (event.target.name === 'mins') {
        //    setMin(event.target.value)
        //} else {
        //    setTimeEntry({ ...timeEntry, [event.target.name]: event.target.value });
        //}
    };

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const confirmHandler = (event) => {
        event.preventDefault();
        if (formData.locationId > 0 && formData.payPeriodId > 0) {
            showLoading(true);
            postData("/attendance/monthlyreport", formData).then(res => {
                if (res.status === true) {
                    setAttendanceDetails(res.data);
                    setIsSubmitEnabled(false);
                } else {
                    setIsSubmitEnabled(true);
                }

                showLoading(false);
            });            
        }
        else {
            alert("Please select Period and Location");
        }
    }

    const onSubmitReport = (event) => {
        showLoading(true);
        postData("/attendance/submitreport", formData).then(res => {
            console.log(res);            
            if (res.status === true) {
                alert("Report Submitted Succesfully");                
                if (props.onClose) {
                    props.onClose();
                }
            } else {
                alert(res.message);
            }
            showLoading(false);
        });
    }


    const onSubmitAllReport = (event) => {
        showLoading(true);
        postData("/attendance/submitallreport", {}).then(res => {
            console.log(res);
            if (res.status === true) {
                alert("Report Submitted Succesfully");
                if (props.onClose) {
                    props.onClose();
                }
            } else {
                alert(res.message);
            }
            showLoading(false);
        });
    }

    const onClearReport = (event) => {
        setIsSubmitEnabled(true);
        setAttendanceDetails([]);
        setFormData({
            "payPeriodId": 0, "locationId": 0, "departmentId": 0,
            "contractorId": 0, "employeeId": 0
        });
    }

    const totalDays = (data) => {
        return (
            <Label>{data.leaves + data.lop + data.presentDays}</Label>
        )
    }

    return (
        <div>            
            <CardBody>
                <form onSubmit={confirmHandler}>
                    <Row>
                        <Col className="mt-2" sm='3' md='3' lg='3'>
                            Period
                            <select className="form-select" name="payPeriodId" onChange={handleChange}>
                                {payPeriods &&
                                    payPeriods.map((payperiod) => {
                                        return <option value={payperiod.id} key={payperiod.id}>{payperiod.payPeriodTitle}</option>
                                    })}
                            </select>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='3'>
                            Location
                            <select className="form-select" name="locationId" onChange={handleChange}>
                                {locations &&
                                    locations.map((location) => {
                                        return <option value={location.id} key={location.id}>{location.locationName}</option>
                                    })}
                            </select>
                        </Col>                       
                        <Col className="mt-2" sm='3' md='3' lg='3'>
                            <br />
                            <Button color="primary" className="btn primary" type="submit">Run Report</Button>&nbsp;&nbsp;&nbsp;
                            <Button color="primary" className="btn primary" type="reset" onClick={onClearReport}>Clear</Button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
            <hr />
            <div className="d-flex">
                <GridComponent dataSource={attendance} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5, pageSizes: true }}
                    allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}>
                    <ColumnsDirective>
                        <ColumnDirective field="employee.fullName" headerText='Employee Name' width='150'></ColumnDirective>
                        <ColumnDirective field="employee.employeeType.name" headerText='Employee Type' width='100' />
                        <ColumnDirective field="employee.location.locationName" headerText='Location' width='150' />
                        <ColumnDirective field="noOfDays" headerText='Days' width='150' />
                        <ColumnDirective field="holidays" headerText='Holidays' width='150'></ColumnDirective>
                        <ColumnDirective field="workingDays" headerText='Working Days' width='150' />
                        <ColumnDirective field="leaves" headerText='Leaves' width='100' />
                        <ColumnDirective field="lop" headerText='LOP' width='100' />
                        <ColumnDirective field="presentDays" headerText='Present' width='100' />
                        <ColumnDirective field="presentDays" template={totalDays} headerText='Total' width='100' />
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Page, Sort, ColumnMenu, Resize]} />
                </GridComponent>                
            </div>
            <div className="m-2" style={{ fontWeight: "bold" }}>
                Working days column is the number of working days in the selected month. Leaves + LOP + Present, columns sum should match the Working Days column, which is same as the Total. Working Days and Total column should match.
            </div>
            <div style={{ paddingTop: '20px' }}>
                <Button color="primary" className="btn primary" disabled={isSubmitEnabled} type="submit" onClick={onSubmitReport}>Submit Report</Button>
                &nbsp;&nbsp;
                <Button color="primary" className="btn primary" type="button" onClick={onSubmitAllReport} style={{ display:'none'}}>Submit All</Button>
            </div>
        </div>
    )
}

export default MonthlyAttendanceReport;