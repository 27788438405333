//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

export const BASE_URL = "https://api.chrms.in/api/v1"
//export const BASE_URL = "http://localhost:5118/api/v1"
//export const BASE_URL = "http://192.168.161.141:5118/api/v1"
//export const BASE_URL = "http://localhost/hrmsapi/api/v1"

//Login URL
export const LOGIN_URL = "/Authenticate/webuser"
export const OTP_URL = "/Authenticate/validatewebotp"

//Attendance
export const TIME_ENTRY = "/Attendance/manualentry"
export const MANUAL_TIME_ATTENDANCE = "/Attendance/getmanualattendance"

//Expense
export const GET_EXPENSE_TYPE = '/Expense/expensetypes'

export const POST_EXPENSE_TYPE = '/Expense/createexpensetype'

export const ADD_EXPENSE = '/Expense/createexpense'

export const SEARCH_EXPENSE = '/Expense/searchexpense'

// Departments
export const GET_DEPARTMENTS = '/Company/departments'

export const POST_DEPARTMENTS = '/Company/createdepartment'

export const GET_PAYMENTTYPES = '/Master/paymenttypes'

// Consultants
export const GET_CONSULTANT_LIST = '/Consultant/getall'
export const POST_CONSULTANT = '/Consultant/createconsultant'
export const GET_CONSULTANT_PAYMENTS = '/Consultant/getallpayments'
export const ADD_CONSULTANT_PAYMENT = '/Consultant/createpayment'

// Contractors
export const GET_CONTRACTOR_PAYMENTS = '/Contractor/getallpayments'
export const ADD_CONTRACTOR_PAYMENT = '/Contractor/createpayment'

// vendors
export const GET_CONTRACTOR_LIST = '/Contractor/getall'
export const POST_CONTRACTOR = '/Contractor/createcontractor'

// Work Schedule
export const GET_WORK_SCHEDULE = '/Master/workschedules'

//Location
export const GET_LOCATIONS = '/Company/locations'
export const GET_USER_LOCATIONS = '/Company/userlocations'
export const GET_LOCATION = '/Company/location/'
export const CREATE_LOCATION = '/Company/createlocation'
export const UPDATE_LOCATION = '/Company/updatelocation'
export const GET_COMPANYPAYSCALES = '/Company/payscales'
export const ADDUPDATE_PAYSCALEDETAIL = '/Company/payscaledetail'
export const GET_COMPANYPAYSCALEDETAIL = '/Company/payscaledetail'
export const GET_COMPANYALLOWCANCES = '/Company/allowances'
export const GET_COMPANYPAYPERIODS = '/Company/payperiods'


//MASTER
export const GET_MARITAL_STATUS = '/Master/maritalstatus'
export const GET_GENDER_TYPES = '/Master/gendertypes'
export const GET_ACCOUNT_GROUPS = "/Master/accountgroups"
export const GET_EMPLOYEE_TYPE = '/Master/employeetypes'
export const GET_LEAVE_TYPES = '/Master/leavetypes'
export const GET_LOAN_TYPES = '/Master/loantypes'
export const GET_CONSULTANT_TYPES = '/Master/consultanttypes'
export const GET_CONTRACTOR_TYPES = '/Master/contractortypes'
export const GET_MASTER_DISTRICTS = '/Master/districts'
export const GET_MASTER_ALLOWANCE_TYPES = '/Master/allowancetypes'
export const GET_EDITABLE_ALLOWANCE_TYPES = '/Master/editableallowancetypes'
export const GET_MASTER_DEDUCTION_TYPES = '/Master/deductiontypes'
export const GET_EDITABLE_DEDUCTION_TYPES = '/Master/editabledeductiontypes'
export const GET_MASTER_DEDUCTION_INSURANCE_TYPES = '/Master/deductiontypes/insurance'
export const GET_SHIFT_TYPES = '/Master/shifttypes'
export const GET_TRANSFER_TYPES = '/Master/transfertypes'
export const GET_INCREMENT_TYPES = '/Master/incrementtypes'
export const GET_PROMOTION_TYPES = '/Master/promotiontypes'
export const GET_FINANCIAL_YEAR = '/Master/financialyears'
export const GET_DOCUMENT_TYPES = '/Master/documenttypes'
export const GET_ITDECLARATION_TYPES = '/payrollit/declarationtypes'

//POLICY
export const GET_LEAVE_POLICY = "/Policy/leavepolicies"
export const GET_HOLIDAY_POLICY = "/Policy/holidaypolicies"
export const ADD_HOLIDAY_DETAIL = "/Policy/createholiday"
export const GET_EXPENSE_POLICY = "/Policy/expensepolicies"

//EMPLOYEE
export const CREATE_EMPLOYEE = '/Employee/createemployee';
export const CREATE_EMPLOYEE_TYPE = '/EmployeeType/createemployeetype';
export const UPDATE_EMPLOYEE = '/Employee/updateemployee';
export const SEARCH_EMPLOYEE = '/Employee/searchemployee';
export const EMPLOYEE_BY_ID = '/Employee/profile';
export const GET_EMP_ALLOWANCES = '/Employee/allowances';
export const GET_EMP_DEDUCTIONS = '/Employee/deductions';
export const ADD_EMP_ALLOWANCES = '/Employee/addallowance';
export const ADD_EMP_DEDUCTIONS = '/Employee/adddeduction';
export const EMPLOYEE_ReportsTo = '/Employee/reportinglist';
export const EMPLOYEE_GRADES = '/EmployeeGrade/employeegrades';
export const EMPLOYEE_STATUSES = '/Master/employeestatuses';
export const EMPLOYEE_GROUPS = '/EmployeeGrade/employeegroups';
export const ADD_EMPLOYEE_GRADES = '/EmployeeGrade/createemployeegrade';


//BANK
export const GET_EMPLOYEE_BANKS = "/Employee/banks"
export const POST_EMPLOYEE_BANK = "/Employee/addbank"

//ADDRESS
export const GET_ADDRESS_TYPES = "/Master/addresstypes"
export const ADD_EMPLOYEE_ADDRESS = "/Employee/addaddress"
export const GET_EMPLOYEE_ADDRESS = "/Employee/addresses"

//EDUCATION
export const GET_EMPLOYEE_EDUCATION = "/Employee/educations"
export const ADD_EMPLOYEE_EDUCATION = "/Employee/addeducation"

//Employment History
export const GET_EMPLOYEE_HISTORY = "/Employee/employmenthistory"
export const ADD_EMPLOYEE_HISTORY = "/Employee/addemploymenthistory"

//Dependent 
export const GET_EMPLOYEE_DEPENDENT = "/Employee/dependents"
export const ADD_EMPLOYEE_DEPENDENT = "/Employee/adddependent"

//Compensation 
export const GET_EMPLOYEE_COMPENSATION = "/Employee/compenstations"
export const GET_EMPLOYEE_COMPENSATIONHISTORY = "/Employee/compenstationhistory"
export const ADD_EMPLOYEE_COMPENSATION = "/Employee/addcompensation"

//transfer
export const GET_EMPLOYEE_TRANSFER = "/Employee/transfers"
export const ADD_EMPLOYEE_TRANSFER = "/Employee/addtransfer"

//insurance
export const GET_EMPLOYEE_INSURANCE = "/Employee/insurances"

//document
export const GET_EMPLOYEE_DOCUMENT = "/Employee/documents"

//increment
export const GET_EMPLOYEE_INCREMENT = "/Employee/increments"
export const ADD_EMPLOYEE_INCREMENT = "/Employee/addincrement"

//promotion
export const GET_EMPLOYEE_PROMOTION = "/Employee/promotions"
export const ADD_EMPLOYEE_PROMOTION = "/Employee/addpromotion"

//Suspension
export const GET_EMPLOYEE_SUSPENSION = "/Employee/suspensions"
export const ADD_EMPLOYEE_SUSPENSION = "/Employee/addsuspension"

//Leave Request
export const ADD_EMPLOYEE_LEAVEREQUEST = "/Leave/requestleave"
export const SEARCH_EMPLOYEE_LEAVEREQUEST = "/Leave/searchleave"
export const SEARCH_EMPLOYEE_LEAVES = "/Leave/employeeleaves"
export const EMPLOYEE_LEAVES = "/Leave/employee"
export const EMPLOYEE_LEAVEALLOCATIONS = "/Leave/allocations"
export const EMPLOYEE_LEAVEREQUESTS = "/Leave/requests"
export const GET_LEAVE_DETAILS = "/Leave/leavedetail"
export const APPROVE_EMPLOYEE_LEAVEREQUEST = "/Leave/approveleave"
export const REJECT_EMPLOYEE_LEAVEREQUEST = "/Leave/rejectleave"
export const SEARCH_EMPLOYEE_LEAVEENCASH = "/Leave/searchleaveencash"

//workflow
export const GET_WORKFLOW_WORKFLOWS = "/Workflow/workflows"

//Roles
export const GET_ROLES = "/Application/userroles"