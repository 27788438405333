import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";

import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";

const HelpIndex = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default HelpIndex;