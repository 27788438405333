import React from 'react';
import { Fragment, useEffect } from "react"
import { useForm } from "react-hook-form"
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import LoadingSpinner from "../../shared/Loading";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { createLocation, getCompanyLocationById, getCompanyLocations, getMasterDistricts, updateLocation } from "../../../helpers/backend_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const SettingsLocation = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [districts, setDistricts] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [locationList, setLocationList] = useState();
    const [errors, setErrors] = useState({});
    const [locData, setLocationDetails] = useState({
        id: 0, locationName: "", districtId: 0, city: "", lat: "", lon: "", radius: 0, description: ""
    });
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const handleChange = (event) => {
        setLocationDetails({ ...locData, [event.target.name]: event.target.value });
    }

    const loadData = () => {
        getCompanyLocations().then((res) => {
            if (res.data) {
                setLocationList(res.data)
            }
        })
    }

    useEffect(() => {
        loadData();
    }, [submitted]);

    // console.log(locationList);

    const handleSubmit = event => {
        event.preventDefault();
        console.log(locData);
        let formErrors = {};
        if (!locData.locationName) {
            formErrors.locationName = 'Location Name is required';
        }
        // if (!locData.districtId) {
        //     formErrors.districtId = 'District is required';
        // }
        if (!locData.city) {
            formErrors.city = 'City is required';
        }
        if (!locData.lat) {
            formErrors.lat = 'Latitude is required';
        }
        if (!locData.lon) {
            formErrors.lon = 'Longitude is required';
        }
        // if (!locData.radius) {
        //     formErrors.radius = 'Radius is required';
        // }
        console.log(formErrors);
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);
            console.log(isEdit);
            if (isEdit) {
                updateLocation(locData).then(res => {
                    afterSubmit(res);
                }).catch((err) => {
                    console.log(err);
                    setDataSuccess("failed");
                    setIsLoading(false);
                    timeout();
                })
            }
            else {
                createLocation(locData).then(res => {
                    afterSubmit(res);
                }).catch((err) => {
                    console.log(err);
                    setDataSuccess("failed");
                    setIsLoading(false);
                    timeout();
                })
            }
        }
    };

    const afterSubmit = (res) => {
        console.log("after Submit");
        if (res.status === true) {
            // console.log("Inside success");
            setDataSuccess("added");
            setIsLoading(false);
            //setLocationDetails({
            //    locationName: "", districtId: 0, city: "", lat: "", lon: "", radius: 0, description: ""
            //});
        } else {
            // console.log("Inside failure");
            setDataSuccess("failed");
            setIsLoading(false);
        }
        setFormSubmit(!submitted);
        timeout();
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];


    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    function statusTemplate(props) {
        return (<div>{props.status === 1 ?
            <div className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }


    const headerLink = props => {
        return (
            <button onClick={() => viewLocation(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.locationName}
            </button>
        )
    }

    const viewLocation = (locationId) => {
        console.log(locationId);
        setIsEdit(true);
        // setLocationDetails({
        //     locationName: "aaaaa", districtId: 0, city: "", lat: "22", lon: "22", radius: 0, description: ""
        // });

        getCompanyLocationById(locationId).then((res) => {
            // console.log(res.data['locationName'], res.data['city'],
            //     res.data['lat'], res.data['lon']);
            setLocationDetails(locData => ({
                ...locData,
                id: res.data['id'], locationName: res.data['locationName'], districtId: 0, city: res.data['city'],
                lat: res.data['lat'], lon: res.data['lon'], radius: 0, description: res.data['description']
            }));
            // setTimeout(() => {
            //     console.log(locData);
            // }, 5000);
        })
        // const fields = ['locationName'];
        // fields.forEach(field => {
        //     console.log(field);
        //     console.log(locData);
        //     setValue(field, locData.locationName);
        // });
        toggleCanvasEnd();
        //history.push('/hr/employee/view/' + EmpId);
    }

    const addLocation = () => {
        setIsEdit(false);
        toggleCanvasEnd();
    }

    // setInterval(() => {
    //     console.log(locData);
    // }, 2000);


    return (
        <React.Fragment>
            <div className="m-2">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Location" enableAction={true} actionName="Add Location" onClick={addLocation} />

                    <GridComponent dataSource={locationList} allowPaging={true} pageSettings={{ pageCount: 25, pageSizes: true }} ref={grid => gridInstance = grid}
                        toolbar={toolbarOptions} allowSorting={true} showColumnMenu={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}
                        statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true} >
                        <ColumnsDirective>
                            <ColumnDirective field="locationName" headerText='Location' template={headerLink} width='150'></ColumnDirective>
                            <ColumnDirective field="city" headerText='City' width='150' />
                            <ColumnDirective field="description" headerText='Description' width='150' />
                            <ColumnDirective field="division.name" headerText='Division' width='100' />
                            <ColumnDirective field="division.description" headerText='Division Desc.' width='150' />
                            <ColumnDirective field="status" template={statusTemplate} headerText='Status' width='80' />
                            <ColumnDirective field="updatedOn" type="date" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='100' />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize]} />
                    </GridComponent>



                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop={true} className='w-35' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Location</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />
                            <OffcanvasBody>
                                <form>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Location Name
                                            </Label>
                                            <Input type='text' name="locationName" placeholder='Location Name' value={locData.locationName} onChange={handleChange} className="form-control" />
                                            <p style={{ color: "Red" }}></p>
                                        </Col>

                                        {/* <Col className="mb-1" xl="12" md="12" sm="12">
                                            <Label className="form-label" for="districtId">
                                                District
                                            </Label>
                                            <Input type="select" name="districtId"
                                                onChange={handleChange}>
                                                {districts &&
                                                    districts.map((res) => {
                                                        return <option value={res.id} key={res.id}>{res.name}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.districtId && <span>{errors.districtId}</span>}</p>
                                        </Col> */}

                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='city'>
                                                City
                                            </Label>
                                            <Input type='text' name="city" className="form-control" value={locData.city} onChange={handleChange} placeholder='City' />
                                            <p style={{ color: "Red" }}></p>
                                        </Col>

                                        <Row>
                                            <Col sm='6' className='mb-1'>
                                                <Label className='form-label' for='lat'>
                                                    Latitude
                                                </Label>
                                                <Input type='text' name="lat" className="form-control" value={locData.lat} onChange={handleChange} placeholder='Enter Latitude' />
                                                <p style={{ color: "Red" }}></p>
                                            </Col>
                                            <Col sm='6' className='mb-1'>
                                                <Label className='form-label' for='lon'>
                                                    Longitude
                                                </Label>
                                                <Input type='text' name="lon" className="form-control" value={locData.lon} onChange={handleChange} placeholder='Enter Longitude' />
                                                <p style={{ color: "Red" }}></p>
                                            </Col>
                                        </Row>

                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='lon'>
                                                Radius
                                            </Label>
                                            <Input type='text' name="radius" className="form-control" value={locData.radius} onChange={handleChange} placeholder='Enter Radius' />
                                            <p style={{ color: "Red" }}></p>
                                        </Col>

                                        <Col sm='12' className='mb-4'>
                                            <Label className='form-label' for='description'>
                                                Description
                                            </Label>
                                            <Input type='textarea' className="form-control" value={locData.description} name="description"
                                                onChange={handleChange} placeholder='Description' />
                                        </Col>

                                        <div className="text-center mt-2">
                                            {dataSuccess === 'added' && <SuccessAlert message={"Updated"} />}
                                            {dataSuccess === 'failed' && <FailureAlert />}
                                        </div>

                                        <hr />

                                        <div>
                                            {isLoading ? <LoadingSpinner /> : <Button color="primary"
                                                onClick={handleSubmit} className="btn btn-sm" disabled={isLoading}
                                                type="submit">Submit</Button>}&nbsp;
                                            <button onClick={toggleCanvasEnd} className="btn btn-dark btn-sm">Close</button>
                                        </div>

                                    </Row>
                                </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SettingsLocation;