import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";

import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getData, postData } from "../../../helpers/url-request";
import {
    ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, FilterType, Inject, Sort, Edit,
    ColumnMenu, CommandColumn, Toolbar,
    ExcelExport, PdfExport, Group, Resize
} from "@syncfusion/ej2-react-grids";
import { getEmployeeLeaveEncash } from "../../../helpers/backend_helper";
import { ToolbarOptions } from "../../../components/Common/Toolbar";
import SalaryCorrectionDetail from "./SalaryCorrectionDetail";
import { showLoading } from 'react-global-loading';
import * as url from '../../../helpers/url_helper';

const SalaryCorrectionList = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasDetailOpen, setCanvasDetailOpen] = useState(false);
    const [dataList, setDataList] = useState();
    const [leaveId, setLeaveId] = useState();

    const loadData = () => {
        showLoading(true);
        var _data = {
            forApproval: true
        }

        getData("/PayrollMisc/salarycorrections/").then(res => {
            if (res.data) {
                setDataList(res.data);
            }
            showLoading(false);
        });

    }

    useEffect(() => {

        loadData();

    }, [])

    const toggleCanvasDetailEnd = () => {
        setCanvasPlacement('end')
        setCanvasDetailOpen(!canvasDetailOpen)
    }

    const afterUserAction = () => {
        loadData();
        toggleCanvasDetailEnd();
    }

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const headerLink = props => {
        return (
            <button onClick={() => viewLeaveRequest(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.leaveId}
            </button>
        )
    }

    const viewLeaveRequest = (leaveId) => {
        setLeaveId(leaveId);
        toggleCanvasDetailEnd();
    }

    const docsLink = props => {

        const links = [];
        props.salaryCorrectionDocuments ? props.salaryCorrectionDocuments.map((doc) => {
            links.push(
                <button onClick={() => viewDocument(doc.id, doc.fileName)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                    {doc.fileName}
                </button>);
            links.push(<br></br>);
        }) : links.push(<label>None</label>)

        return (
            <div>{links}</div>
        )
    }

    const viewDocument = (docId, docName) => {
        console.log("view document: " + docId);
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        fetch(url.BASE_URL + '/payrollmisc/salarycorrectiondocument/' + docId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + userdata.token,
                'apikey': 'key123',
            },
        }).then((response) => response.blob())
            .then((blob) => {
                console.log(blob);
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download', docName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    return (
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Leave" breadcrumbItem="Salary Corrections" enableAction={true} actionName="Correction" onClick={toggleCanvasDetailEnd}></Breadcrumbs>

                    <GridComponent dataSource={dataList} allowPaging={true} toolbar={ToolbarOptions} ref={grid => gridInstance = grid}
                        statelessTemplates={['directiveTemplates']} pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }} allowResizing={true}
                        allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true}
                        toolbarClick={toolbarClick.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field="employeeProfile.fullName" headerText='Employee' width='150'></ColumnDirective>
                            <ColumnDirective field="payPeriod.payPeriodTitle" headerText='Pay Period' width='150'></ColumnDirective>                            
                            <ColumnDirective field="allowanceType.name" headerText='Type' width='150'></ColumnDirective>
                            <ColumnDirective field="percentage" headerText='Percentage' width='150' />
                            <ColumnDirective field="amount" headerText='Amount' width='150' />                            
                            <ColumnDirective field="description" headerText='description' width='150' />
                            <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='150'></ColumnDirective>
                            <ColumnDirective template={docsLink} headerText="Documents" allowTextWrap={true} width="150"></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
                    </GridComponent>

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-40' direction={canvasPlacement} isOpen={canvasDetailOpen} toggle={toggleCanvasDetailEnd}>
                            <OffcanvasHeader toggle={toggleCanvasDetailEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Salary Correction Detail</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />
                            <OffcanvasBody>
                                <SalaryCorrectionDetail onClose={afterUserAction} />
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

export default SalaryCorrectionList;