import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";

class AttendancePerformance extends Component {
    getOption = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            legend: {
                orient: "horizontal",
                right: "top",
                data: ["Intime", "Late"],
                textStyle: {
                    color: ["#858d98"],
                },
            },
            color: ['#2ab57d', '#fd625e', '#4ba6ef', '#ffbf53', '#5156be'],
            series: [
                {
                    name: "Total sales",
                    type: "pie",
                    radius: ["40%", "70%"],
                    center: ["50%", "60%"],
                    data: [
                        { value: 335, name: "Intime" },
                        { value: 310, name: "Late" }
                    ],
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                },
            ],
        }
    }
    render() {
        return (
            <React.Fragment>
                <Card className="card-h-100">
                    <ReactEcharts option={this.getOption()} />
                </Card>                
            </React.Fragment>
        )
    }
}
export default AttendancePerformance
