import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";
import classnames from "classnames";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ITSectionView from "./ITSectionView";
import ITSlabView from "./ITSlabView";
import ITSectionLimitView from "./ITSectionLimitView";
import ITDeclarationTypeView from "./ITDeclarationTypeView";

const SettingsIncomeTax = (props) => {
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')
        
    useEffect(() => {


    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Settings" breadcrumbItem="Income Tax Settings" enableAction={false}></Breadcrumbs>
                <Container fluid>
                    <CardBody>                        
                        <div id="basic-pills-wizard" className="twitter-bs-wizard">

                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>Income Tax Slabs</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>Section Limits</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            setactiveTab(3);
                                        }}>
                                        <span>Sections</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 4 })}
                                        onClick={() => {
                                            setactiveTab(4);
                                        }}>
                                        <span>Declaration Type</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <ITSlabView />
                                </TabPane>
                                <TabPane tabId={2}>
                                    <ITSectionLimitView />
                                </TabPane>
                                <TabPane tabId={3}>
                                    <ITSectionView />
                                </TabPane>
                                <TabPane tabId={4}>
                                    <ITDeclarationTypeView />
                                </TabPane>                               
                                                                
                            </TabContent>
                            
                        </div>
                    </CardBody>
                </Container >
            </div >
        </React.Fragment >
    );
};

export default SettingsIncomeTax;
