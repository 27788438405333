import React, { useState } from 'react';

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Label, Offcanvas,
    OffcanvasBody, OffcanvasHeader, Row
} from "reactstrap";

import {
    Container,
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

const EmployeeDeduction = () => {
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);

    document.title = "Employee Directory | HRMS";

    const toggleDecl = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
        setErrors({});
    }

    const toolbarTemplate = () => {
        return (
            <Row className="m-2">
                <Col xl="9" className="mt-2" md="9" sm="9">
                    <CardTitle tag="h4">Employee IT Declarations</CardTitle>
                </Col>
                <Col xl="3" md="3" sm="4">
                    <ButtonComponent onClick={toggleDecl} className='e-btn e-outline e-primary'>
                        <i className="bx bx-plus font-size-16 align-middle"></i>{" "}Add Details</ButtonComponent>
                </Col>
            </Row>
        )
    }

    const toolbarOptions = [
        { template: toolbarTemplate }
    ];

    return (
        <div className="page-content">
        <React.Fragment>
            <CardBody>
                <GridComponent allowPaging={true} pageSettings={{ pageCount: 5 }} toolbarTemplate={toolbarTemplate} toolbar={toolbarOptions} statelessTemplates={['directiveTemplates']}>
                    <ColumnsDirective>
                        <ColumnDirective headerText='FINANCIAL YEAR' width='150'></ColumnDirective>
                        <ColumnDirective headerText='EMPLOYEE NAME' width='150' />
                        <ColumnDirective headerText='DECLARATION NAME' width='150' />
                        <ColumnDirective headerText='SECTION' width='150'></ColumnDirective>
                        <ColumnDirective headerText='MAX LIMIT (₹)' width='150' />
                        <ColumnDirective headerText='AMOUNT (₹)' width='150' />
                        <ColumnDirective headerText='VERIFIED' width='150' />
                        <ColumnDirective headerText='STATUS' width='150' />
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar]} />
                </GridComponent>
            </CardBody>

            <Offcanvas backdrop="static" className='w-50' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleDecl}>
                <OffcanvasHeader toggle={toggleDecl}>
                    <Row>
                        <Col sm='12'>
                            <b>Add Declaration</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />

                <OffcanvasBody>
                    <Row className='m-2'>
                        <Col md={6}>
                            <Label for='employeeName'>Employee Name</Label>
                        </Col>
                        <Col md={6}>
                            <Label >Chethan BV</Label>
                        </Col>
                    </Row>

                    <Row className='m-2'>
                        <Col md={6}>
                            <Label for='finYear'>Financial Year</Label>
                        </Col>
                        <Col md={6}>
                            <Input className='form-select' type='select'>
                                <option>2021-2022</option>
                                <option>2022-2023</option>
                            </Input>
                        </Col>
                    </Row>

                    <Row className='m-2'>
                        <Col md={6}>
                            <Label for='decName'>Declaration Name</Label>
                        </Col>
                        <Col md={6}>
                            <Input className='form-select' type='select'>
                                <option>Select Declaration Name</option>
                            </Input>
                        </Col>
                    </Row>

                    <Row className='m-2'>
                        <Col md={6}>
                            <Label for='employeeName'>Section</Label>
                        </Col>
                        <Col md={6}>
                            <Label >-</Label>
                        </Col>
                    </Row>

                    <Row className='m-2'>
                        <Col md={6}>
                            <Label for='MaxLimit'>Max Limit</Label>
                        </Col>
                        <Col md={6}>
                            <Label >-</Label>
                        </Col>
                    </Row>

                    <Row className='m-2'>
                        <Col md={6}>
                            <Label for='amount'>Amount</Label>
                        </Col>
                        <Col md={6}>
                            <Input className='form-control' placeholder='Enter Amount' type='text'></Input>
                        </Col>
                    </Row>

                    <Row className='m-2'>
                        <Col md={6}>
                            <Label for='verified'>Verified?</Label>
                        </Col>
                        <Col md={6}>
                            <Label >YES</Label>
                        </Col>
                    </Row>

                    <Row className='m-2'>
                        <Col md={6}>
                            <Label for='notes'>Notes</Label>
                        </Col>
                        <Col md={6}>
                            <Input className='form-control' placeholder='Enter Notes' type='textarea'></Input>
                        </Col>
                    </Row>

                    <Row className='m-2'>
                        <Col md={6}>
                            <Label for='uploadFile'>Upload File</Label>
                        </Col>
                        <Col md={6}>
                            <Input className='form-control' type='file'></Input>
                        </Col>
                    </Row>

                    <div className="offcanvas-footer">
                        <Button color="primary" className="btn btn-sm"
                            type="submit">Submit</Button>&nbsp;&nbsp;
                        <button onClick={toggleDecl} className="btn btn-dark btn-sm">Close</button>
                    </div>
                </OffcanvasBody>
            </Offcanvas>


            </React.Fragment>
        </div>
    );
};

export default EmployeeDeduction;