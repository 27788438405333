import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { getCompanyPayperiods } from "../../../helpers/backend_helper";
import { getData, postData } from "../../../helpers/url-request";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { showLoading } from 'react-global-loading';

const SettingsPayPeriod = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [employeeTypeList, setEmpTypeList] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()

    const [formData, setFormData] = useState({
        id: 0, name: "", description: "", status: 1
    });

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    const loadData = () => {
        showLoading(true);
        getCompanyPayperiods().then(res => {
            showLoading(false);
            if (res.data) {
                setEmpTypeList(res.data)
            }
        });
    }

    useEffect(() => {
        loadData();
    }, [])


    const handleSubmit = event => {
        event.preventDefault();
        let formErrors = {};
        if (!formData.name) {
            formErrors.name = 'Name is required';
        }
        if (!formData.description) {
            formErrors.description = 'Description is required';
        }

        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
                        
            showLoading(true);

            if (formData.id == 0) {
                postData("/master/createallowancetype", formData).then(res => {
                    afterSubmit(res);
                }).catch((err) => {
                    console.log(err);
                    setDataSuccess("failed");
                    showLoading(false);
                    timeout();
                });
            } else {

            }
        }

    };

    const afterSubmit = (res) => {
        console.log("after Submit");
        showLoading(false);
        if (res.status === true) {
            setDataSuccess("added");
            toggleCanvasEnd();
            loadData();
        } else {
            alert(res.message);
            setDataSuccess("failed");
        }
        setFormSubmit(!submitted);
        timeout();
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const editData = (id) => {
        //showLoading(true);
        //getData("/master/allowancetype/"+id).then(res => {
        //    showLoading(false);
        //    toggleCanvasEnd();
        //    setFormData(formData => ({
        //        ...formData,
        //        id: res.data['id'], name: res.data['name'], description: res.data['description']
        //    }));
        //}).catch((err) => {
        //    console.log(err);
        //    showLoading(false);
        //});        
    }

    const headerLink = props => {
        return (<div>
            {
                props.isSystem === 1 ?
                    <div>{props.name}</div>
                    :
                    <button onClick={() => editData(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>{props.payPeriodTitle}</button>
            }</div>
        );
    }

    const statusTemplate = (props) => {

        return (<div>{props.status === 1 ?
            <div id="status" className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div id="status" className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }


    return (
        <Fragment>
            <div className="m-2">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Pay Periods" enableAction={false} actionName="Add New" onClick={toggleCanvasEnd}></Breadcrumbs>

                    <GridComponent dataSource={employeeTypeList} allowPaging={true} pageSettings={{ pageCount: 25, pageSizes: true }} allowResizing={true}
                        toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid}
                        statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true} >
                        <ColumnsDirective>
                            <ColumnDirective field="payPeriodTitle" headerText='Title' template={headerLink} width='100'></ColumnDirective>
                            <ColumnDirective field="description" headerText='Description' />
                            <ColumnDirective field="fromDate" headerText='From Date' type="date" format="dd/MM/yyyy" />
                            <ColumnDirective field="toDate" headerText='To Date' type="date" format="dd/MM/yyyy" />
                            <ColumnDirective field="status" headerText='Status' template={statusTemplate} width='80' />
                            <ColumnDirective field="updatedOn" type="date" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='100' />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize]} />
                    </GridComponent>

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-25' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Allowance Type</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />


                            <OffcanvasBody>
                                <form>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Allowance Type
                                            </Label>
                                            <Input type='text' id='input-default' name="name" value={formData.name} onChange={handleChange} placeholder='Allowance Type' />
                                            <p style={{ color: "Red" }}>{errors.name ? 'Name is required' : ''}</p>
                                        </Col>

                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Description
                                            </Label>
                                            <Input type='textarea' id='input-default' name="description" value={formData.description} onChange={handleChange} placeholder='Description' />
                                            <p style={{ color: "Red" }}>{errors.description ? 'Description is required' : ''}</p>
                                        </Col>

                                        <Col sm='12' className='mb-1'>
                                            <Input type='checkbox' id='input-default' />&nbsp;&nbsp;&nbsp;
                                            <Label className='form-label' for='input-default'>
                                                Active
                                            </Label>
                                        </Col>
                                        <hr />

                                        <div className="text-center mt-2">
                                            {dataSuccess === 'added' && <SuccessAlert message={"Updated"} />}
                                            {dataSuccess === 'failed' && <FailureAlert />}
                                        </div>

                                        <div>
                                            <Button color="primary"
                                                onClick={handleSubmit} className="btn btn-sm" disabled={isLoading}
                                                type="submit">Submit</Button>&nbsp;
                                            <button onClick={toggleCanvasEnd} className="btn btn-dark btn-sm">Close</button>
                                        </div>

                                    </Row>
                                </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div >
        </Fragment >
    )
}

export default SettingsPayPeriod;