import { Button, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { React, useState } from "react";


const AddHolidayPolicy = () => {
    
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: 0
    });
    const [errors, setErrors] = useState({});

    const handleChange = event => {
        if (event.target.name === "status") {
            let statusSet = event.target.checked ? 1 : 0;
            setFormData({ ...formData, [event.target.name]: statusSet });
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    };

    const handleSubmit = event => {
        event.preventDefault();

        let formErrors = {};
        if (!formData.name) {
            formErrors.name = 'Policy Name is required';
        }
        if (!formData.description) {
            formErrors.description = 'Description is required';
        }

        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {

        }
    };

    return (        
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col sm='12' className='mb-1'>
                        <Label className='form-label' for='input-default'>
                            Holiday Policy
                        </Label>
                        <Input type='text' id='input-default' 
                         onChange={handleChange}
                         value={formData.name} placeholder='Holiday Policy Name' />
                        <p style={{ color: "Red" }}>{errors ? errors.name : ''}</p>
                    </Col>

                    <Col sm='12' className='mb-1'>
                        <Label className='form-label' for='input-default'>
                            Description
                        </Label>
                        <Input type='textarea' id='input-default'  onChange={handleChange}
                         value={formData.description} placeholder='Description' />
                        <p style={{ color: "Red" }}>{errors ? errors.description : ''}</p>
                    </Col>

                    <Col sm='12' className='mb-1'>
                        <Input type='checkbox'  onChange={handleChange}
                            value={formData.status} id='input-default' />&nbsp;&nbsp;&nbsp;
                        <Label className='form-label' for='input-default'>
                            Active
                        </Label>
                    </Col>
                    <hr />
                    <Col sm='4'>
                        <Button block color='primary' type="submit" className='mb-1'>
                            Save
                        </Button>
                    </Col>
                    <Col sm='4' className='mr-2'>
                        <Button block outline type="button" color='secondary'>
                            Close
                        </Button>
                    </Col>

                </Row>
            </Form>       
    )
}

export default AddHolidayPolicy;