import { Fragment, useEffect } from "react"

import {
    Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Offcanvas, OffcanvasBody, OffcanvasHeader,
    Row, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Filter, ExcelExport, PdfExport, Resize }
    from "@syncfusion/ej2-react-grids";
import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import { getEmployeeLeaves, getLoggedInUser } from "../../../helpers/backend_helper";
import AddLeaves from "./AddLeaves";
import LeaveAllocation from "./LeaveAllocation";
import { showLoading } from 'react-global-loading';
import Select from "react-select"

const ManageLeaves = () => {

    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [leaveRequestList, setLeaveRequestList] = useState();
    const [employeeId, setEmployeeId] = useState();
    const [canvasOpenDetail, setCanvasOpenDetail] = useState(false);

    const loadData = () => {
        showLoading(true);
        getEmployeeLeaves().then((res) => {            

            if (res.data) {
                setLeaveRequestList(res.data);
            }

            showLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, [])

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const toggleCanvasDetail = () => {
        setCanvasOpenDetail(!canvasOpenDetail)
    }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const headerLink = props => {
        return (
            <button onClick={() => viewLeaveDetails(props.employeeId)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.employeeName}
            </button>
        )
    }

    const viewLeaveDetails = (employeeId) => {
        setEmployeeId(employeeId);
        toggleCanvasDetail();
    }

    return (
        <Fragment>
            <div className="page-content">

                <Modal isOpen={canvasOpenDetail} toggle={toggleCanvasDetail} fullscreen>
                    <ModalHeader toggle={toggleCanvasDetail}>Employee Leave Details</ModalHeader>
                    <ModalBody>
                        <LeaveAllocation employeeId={employeeId} />
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <Button color="secondary" className="btn primary" type="button" onClick={toggleCanvasDetail}>Close</Button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Container fluid>
                    <Breadcrumbs title="Leave" breadcrumbItem="Manage Leaves" enableAction={getLoggedInUser().roleId != 1 ? false : true} actionName="Update Leaves" onClick={toggleCanvasEnd} ></Breadcrumbs>

                    <GridComponent dataSource={leaveRequestList} toolbar={toolbarOptions} ref={grid => gridInstance = grid} allowResizing={true}
                        statelessTemplates={['directiveTemplates']} pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }}
                        allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} allowFiltering={true} allowPaging={true}
                        toolbarClick={toolbarClick.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field="employeeName" headerText='Employee' template={headerLink}></ColumnDirective>
                            <ColumnDirective field="employeeType" headerText='Employee Type' width='120'></ColumnDirective>
                            <ColumnDirective field="locationName" headerText='Location'></ColumnDirective>
                            <ColumnDirective field="employeeStatus" headerText='Status' width='100'></ColumnDirective>
                            <ColumnDirective field="casualLeave" headerText='CL' width='100'></ColumnDirective>
                            <ColumnDirective field="earnedLeave" headerText='EL' width='100'></ColumnDirective>
                            <ColumnDirective field="halfPayLeave" headerText='HP' width='100'></ColumnDirective>
                            <ColumnDirective field="restrictedHoliday" headerText='RH' width='100'></ColumnDirective>
                            <ColumnDirective field="total" headerText='Total' width='125' />
                            <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy" headerText='Last Updated' width='150' />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Filter, Sort, ColumnMenu, Resize, ExcelExport, PdfExport]} />
                    </GridComponent>

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-40' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Manage Leaves</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />
                            <OffcanvasBody>
                                <AddLeaves onClose={toggleCanvasEnd} />
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>
    );
};

export default ManageLeaves;