import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";

import classnames from "classnames";

const ProfessionalTax = () => {

    return (
        <React.Fragment>

            <CardBody>
                <h4>Profession Tax Slabs:</h4>
                <br />
                <Row>
                    <Col sm='4' lg='3' md='4'>
                        <h5>Profession Tax Slab for State</h5>
                    </Col>
                    <Col sm='4' lg='3' md='4'>
                        <select className="form-select">
                            <option>Karnataka</option>
                        </select>
                    </Col>

                </Row>
                <hr />
                <Row>
                    <Col sm='6' md='6' lg='6'>                        
                        <Row>
                            <Col sm='6' md='6' lg='6' className="mb-2">
                                <h6>Profession Tax Slab Rates (Rs.)</h6>
                            </Col>
                            <Col sm='6' md='6' lg='6'>
                                <h6>Tax (In Rs.)</h6>
                            </Col>
                            <br />
                            <Col sm='6' md='6' lg='6' className="mb-2">
                                ₹0.00 to ₹15,000.00
                            </Col>
                            <Col sm='6' md='6' lg='6'>
                                ₹0.00
                            </Col>
                            <Col sm='6' md='6' lg='6' className="mb-2">
                                ₹15,001.00 to Maximum
                            </Col>
                            <Col sm='6' md='6' lg='6'>
                                ₹200.00
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </CardBody>

        </React.Fragment >
    );
};

export default ProfessionalTax;
