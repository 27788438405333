import React from 'react';

import ReactApexChart from "react-apexcharts"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import CountUp from "react-countup";

// import Locations from './Locations';
import RecentLeave from './RecentLeave';
import RecentExpense from './RecentExpense';
import RecentTask from './RecentTask';
import EmployeeChart from './EmployeeChart';
import PieChart from './PieChart';
import EmployeeCount from './EmployeeCount';
import AttendanceCount from './AttendanceCount';
import GenderCount from './GenderCount';
import EmployeeTypeCount from './EmployeeTypeCount';
import EmployeeGroupCount from './EmployeeGroupCount';
import AttendancePerformance from './AttendancePerformance';
import AttendanceChart from './AttendanceChart';


const PayrollDashboard = () => {

    //meta title
    document.title = "KMVSTDCL | HRMS";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Payroll Dashboard" />
                    <Row>
                        <Col xl={3} md={6}>
                            <EmployeeCount />
                        </Col>

                        <Col xl={3} md={6}>
                            <EmployeeTypeCount />
                        </Col>

                        <Col xl={3} md={6}>
                            <EmployeeGroupCount />
                        </Col>

                        <Col xl={3} md={6}>
                            <GenderCount />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EmployeeChart />
                        </Col>
                    </Row>
                    <Row>
                        <RecentLeave />
                        <RecentTask />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default PayrollDashboard;