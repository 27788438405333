import { Fragment, useEffect } from "react"

import { Alert, Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row, UncontrolledAlert } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page }
    from "@syncfusion/ej2-react-grids";
import { getDepartments, createDepartments, getDepartmentTypes } from "../../../helpers/backend_helper";
import LoadingSpinner from "../../shared/Loading";
// import LoadingSpinner from "../../shared/Loading";
// import { ScheduleComponent, ViewsDirective, ViewDirective, Month, Resize, DragAndDrop, ResourcesDirective, ResourceDirective, Day, TimelineViews, TimelineMonth }
//     from '@syncfusion/ej2-react-schedule';
import { generateObject } from './helper';
import { updateSampleSection } from "./sample-base";
import { extend, Internationalization } from '@syncfusion/ej2-base';


import {
    ScheduleComponent, ViewsDirective, ViewDirective, TimelineMonth, getWeekNumber,
    Inject, HeaderRowDirective, HeaderRowsDirective, Resize, DragAndDrop, getWeekLastDate, TimelineViews
}
    from '@syncfusion/ej2-react-schedule';


const SettingsWorkschedule = () => {
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const [date, setDate] = useState();
    const [weekdata, setWeekdata] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: 0
    });
    const [errors, setErrors] = useState({});

    const data = extend([], [], null, true);
    const employeeData = [
        { Text: 'Alice', Id: 1, GroupId: 1, Color: '#bbdc00', Designation: 'Content writer' },
        { Text: 'Nancy', Id: 2, GroupId: 2, Color: '#9e5fff', Designation: 'Designer' },
        { Text: 'Robert', Id: 3, GroupId: 1, Color: '#bbdc00', Designation: 'Software Engineer' },
        { Text: 'Robson', Id: 4, GroupId: 2, Color: '#9e5fff', Designation: 'Support Engineer' },
        { Text: 'Laura', Id: 5, GroupId: 1, Color: '#bbdc00', Designation: 'Human Resource' },
        { Text: 'Margaret', Id: 6, GroupId: 2, Color: '#9e5fff', Designation: 'Content Analyst' }
    ];

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const handleChange = event => {
        if (event.target.name === "status") {
            let statusSet = event.target.checked ? 1 : 0;
            setFormData({ ...formData, [event.target.name]: statusSet });
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    };

    React.useEffect(() => {
        updateSampleSection();
        getWeeksInMonth();
        getNoofWeeks();
    }, []);


    const handleSubmit = event => {
        event.preventDefault();

        // Validate form inputs
        let formErrors = {};
        if (!formData.name) {
            formErrors.name = 'Name is required';
        }
        if (!formData.description) {
            formErrors.description = 'Description is required';
        }

        setErrors(formErrors);

        // If there are no errors, submit the form
        if (Object.keys(formErrors).length === 0) {

        }
    };

    const getEmployeeName = (value) => {
        return value.resourceData[value.resource.textField];
    }

    const resourceHeaderTemplate = (props) => {
        return (<div className="template-wrap"><div className="employee-category"><div className="employee-name">
            {getEmployeeName(props)}</div></div></div>);
    }

    const getWeeksInMonth = () => {
        const date = new Date();
        const days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        setYear(date.getFullYear());
        setMonth(date.getMonth());
        setDate(date.getDate());

        // console.log(year, month, date);
        return Math.ceil(days / 7);
    }

    const getNoofWeeks = () => {
        const weeks = getWeeksInMonth();
        //console.log(weeks);
        const week = [];
        for (let i = 1; i <= weeks, i++;) {
            // console.log(i - 1);
            week.push({ 'name': 'Week ' + (i - 1), 'id': i - 1 })
            if (i > weeks) {
                break;
            }
        }

        //console.log(week);

        setWeekdata(week);

        // console.log(weekdata);
    }

    const onRenderCell = (args) => {
        console.log(args);
        if (args.elementType === 'monthDay' && this.scheduleObj.currentView === 'Month') {
            let ele = document.createElement('div');
            ele.innerHTML = this.getDateHeaderText(args.date);
            (args.element).appendChild(ele.firstChild);
        }
    }

    let scheduleObj;
    let instance = new Internationalization();
    function getMonthDetails(value) {
        console.log(value);
        return instance.formatDate(value.date, { skeleton: 'yMMMM' });
    }
    function getWeekDetails(value) {
        return 'Week ' + getWeekNumber(getWeekLastDate(value.date, 0));
    }
    function monthTemplate(props) {
        //console.log(props);
        return (<span className="month">{getMonthDetails(props)}</span>);
    }
    function weekTemplate(props) {
        return (<span className="week">{getWeekDetails(props)}</span>);
    }

    return (

        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <div>
                        <span style={{ float: 'left' }}>
                            <h4>Workschedule</h4>
                        </span>

                        <span style={{ float: 'right' }}>
                            <button style={{ width: '75px' }} onClick={toggleCanvasEnd}
                                className="btn btn-primary btn-md w-500">Add</button>
                        </span>
                    </div>
                    <br />
                    <br />
                    <Card className='overflow-hidden'>

                        <div className='schedule-control-section'>
                            <div className='col-lg-12 control-section'>
                                <div className='control-wrapper'>

                                    <ScheduleComponent width='100%' height='550px' selectedDate={new Date(2023, 2, 26)}>
                                        <HeaderRowsDirective>
                                            {/* <HeaderRowDirective option='Year' /> */}
                                            <HeaderRowDirective option='Month' />
                                            <HeaderRowDirective option='Week' template={weekTemplate.bind(this)}/>
                                            <HeaderRowDirective option='Date' />
                                            <HeaderRowDirective option='Hour' />
                                        </HeaderRowsDirective>
                                        <ViewsDirective>
                                            <ViewDirective option='TimelineWeek' />
                                        </ViewsDirective>
                                        <Inject services={[TimelineViews]} />
                                    </ScheduleComponent>
                                    {/* <ScheduleComponent ref={schedule => scheduleObj = schedule} width='100%' height='650px' selectedDate={new Date(2023, 0, 1)} >
                                    <HeaderRowsDirective>
                                        <HeaderRowDirective option='Month' template={monthTemplate.bind(this)}/>
                                        <HeaderRowDirective option='Week' template={weekTemplate.bind(this)}/>
                                        <HeaderRowDirective option='Date'/>
                                    </HeaderRowsDirective>
                                    <ViewsDirective>
                                        <ViewDirective option='TimelineMonth' interval={12}/>
                                    </ViewsDirective>
                                    <Inject services={[TimelineMonth, Resize, DragAndDrop]}/>
                                </ScheduleComponent> */}
                                </div>
                            </div>
                        </div>

                        {/* <div className='schedule-control-section mt-4'>
                            <div className='col-lg-12 control-section'>
                                <div className='control-wrapper'>
                                    <ScheduleComponent cssClass='block-events' width='100%' height='650px' selectedDate={new Date(2021, 2, 25)} currentView='TimelineMonth'
                                        resourceHeaderTemplate={resourceHeaderTemplate.bind(this)} onChange={onRenderCell.bind(this)}
                                        eventSettings={{ dataSource: data }} group={{ enableCompactView: false, resources: ['Weeks'] }}>
                                        <ResourcesDirective>
                                            <ResourceDirective field='id' title='No. of Weeks' name='Weeks' allowMultiple={true} dataSource={weekdata} textField='name' idField='id'>
                                            </ResourceDirective>
                                        </ResourcesDirective>
                                        <ViewsDirective>
                                            <ViewDirective option='Day' />
                                            <ViewDirective option='TimelineDay' />
                                            <ViewDirective option='TimelineMonth' />
                                        </ViewsDirective>
                                        <Inject services={[Day, TimelineViews, TimelineMonth, Resize, DragAndDrop]} />
                                    </ScheduleComponent>
                                </div>
                            </div>
                        </div> */}

                    </Card>

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Workschedule</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />

                            <OffcanvasBody>

                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Name:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                onChange={handleChange}
                                                value={formData.name}
                                            />
                                            <p style={{ color: "Red" }}>{errors.name && <span>{errors.name}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Description:
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                placeholder="Description"
                                                onChange={handleChange}
                                                value={formData.description}
                                            />
                                            <p style={{ color: "Red" }}>{errors.description && <span>{errors.description}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Status:
                                            </Label>
                                            <Input
                                                type="checkbox"
                                                name="status"
                                                onChange={handleChange}
                                                value={formData.status}
                                            />
                                        </Col>
                                    </Row>
                                    <Button color="primary" type="submit">Submit</Button>

                                </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>


    );
}

export default SettingsWorkschedule;