import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Row, Modal, ModalHeader, ModalBody, ModalFooter, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize, Filter } from "@syncfusion/ej2-react-grids";
import { useEffect, useState, useMemo } from "react";
import {
    createTimeEntry, getCompanyLocations, getDepartments, getEmployeeList,
    getManulAttendanceList, getVendorsPayments, getAllContractors, getUserLocations
} from "../../../helpers/backend_helper";
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';
import { GoogleMap, useJsApiLoader, useLoadScript, Marker, Circle } from '@react-google-maps/api';
import { ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import { PaneDirective, PanesDirective, SplitterComponent } from '@syncfusion/ej2-react-layouts';

const AttendanceManagement = () => {
    let gridInstance;
    const [employees, setEmployees] = useState();
    const [departments, setDepartments] = useState();
    const [locations, setLocations] = useState();
    const [center, setCenter] = useState();
    const [vendors, setVendors] = useState();
    const [attendance, setAttendanceDetails] = useState();
    const [formData, setFormData] = useState({
        "fromDate": "", "toDate": "",
        "locationId": 0, "departmentId": 0,
        "contractorId": 0, "employeeId": 0
    })
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [modalDetail, setModalDetail] = useState(false);
    const toggleDetail = () => setModalDetail(!modalDetail);

    useEffect(() => {
        getEmployeeList().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "fullName": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setEmployees(emps)
            }
        });

        getDepartments().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setDepartments(emps)
            }
        });

        getUserLocations().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "locationName": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setLocations(emps)
            }
        });

        getAllContractors().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setVendors(emps)
            }
        });
    }, []);

    const handleChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        //if (event.target.name === 'hours') {
        //    setHrs(event.target.value)
        //} else if (event.target.name === 'mins') {
        //    setMin(event.target.value)
        //} else {
        //    setTimeEntry({ ...timeEntry, [event.target.name]: event.target.value });
        //}
    };

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const confirmHandler = (event) => {
        event.preventDefault();
        // var formData = {
        //     locationId: 1
        // }
        showLoading(true);
        postData("/attendance/detailreport", formData).then(res => {
            showLoading(false);
            setAttendanceDetails(res.data);
        });
    }

    const mapTemplate = (props) => {

        //var _url = "https://www.google.com/maps?q=" + props.locationLat + "," + props.locationLon + "&z=17&hl=en";
        //return (<div>{props.source === 'Mobile' ?
        //    <div><a href={_url} target="_blank" rel="noreferrer">View Location</a></div> : <div></div>}
        //</div>);

        return (<button onClick={() => viewMap(props)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
            View Location
        </button>);
    }

    const viewMap = (data) => {
        console.log("viewMap");
        setCenter({ lat: data.locationLat, lng: data.locationLon})
        toggle();
    }

    const statusTemplate = (props) => {
        return (<div>{props.attendanceStatus === "Present" ?
            <div>
                <span className="statustxt e-activecolor">Present</span>
            </div> :
            <div>
                <span className="statustxt e-inactivecolor">{props.attendanceStatus}</span>
            </div>}</div>);
    }

    //const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCLNxabfAmls1X98nko0JGmdSC6ZoVDHgY"
    });

    const headerLink = props => {
        return (
            <button onClick={() => viewDetail(props)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.employeeName}
            </button>
        )
    }

    const viewDetail = (data) => {
        console.log("viewDetail");
        toggleDetail();
        setCenter({ lat: data.locationLat, lng: data.locationLon });
        //openDetail();
    }

    const openDetail = () => {
        setCanvasOpen(!canvasOpen);
    };

    return (
        <div className="page-content">
            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>Attendance Map View</ModalHeader>
                <ModalBody style={{ padding: '0px' }}>
                    {/*<ToolbarComponent>*/}
                    {/*    <div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Cut</button> </div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Copy</button> </div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Paste</button> </div>*/}
                    {/*        <div className='e-separator'> </div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Bold</button> </div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Italic</button> </div>*/}
                    {/*    </div>*/}
                    {/*</ToolbarComponent>*/}

                    {!isLoaded ? (
                        <h1>Loading...</h1>
                    ) : (
                            <GoogleMap center={center} zoom={17} mapContainerStyle={{
                                width: '100%',
                                height: '100%'
                            }}>
                                <Marker position={center} />
                                <Circle center={center} radius={30} options={{ strokeColor: "#ff0000" }} />
                        </GoogleMap>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="btn primary" type="button" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={modalDetail} toggle={toggleDetail} fullscreen>
                <ModalHeader toggle={toggleDetail}>Attendance Detail</ModalHeader>
                <ModalBody style={{ padding: '0px' }}>
                    {/*<ToolbarComponent>*/}
                    {/*    <div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Cut</button> </div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Copy</button> </div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Paste</button> </div>*/}
                    {/*        <div className='e-separator'> </div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Bold</button> </div>*/}
                    {/*        <div><button className='e-btn e-tbar-btn'>Italic</button> </div>*/}
                    {/*    </div>*/}
                    {/*</ToolbarComponent>*/}

                    <SplitterComponent id="splitter" height="400px" width="900px">
                        <PanesDirective>
                            <PaneDirective size='200px'>
                                {!isLoaded ? (
                                    <h1>Loading...</h1>
                                ) : (
                                            <GoogleMap center={center} zoom={17} mapContainerStyle={{
                                                width: '100%',
                                                height: '100%'
                                            }}>
                                                <Marker position={center} />
                                                <Circle center={center} radius={30} options={{ strokeColor: "#ff0000" }} />
                                            </GoogleMap> 
                                    )}
                            </PaneDirective>
                            <PaneDirective size='200px'>
                                        asdadasdasd
                            </PaneDirective>
                        </PanesDirective>
                    </SplitterComponent>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="btn primary" type="button" onClick={toggleDetail}>Close</Button>
                </ModalFooter>
            </Modal>

            <Breadcrumbs title="Attendance" breadcrumbItem="Attendance Management"></Breadcrumbs>
            
            <CardBody>
                <form onSubmit={confirmHandler}>
                    <Row>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            From Date
                            <Input type="date" name="fromDate" onChange={handleChange} className="form-control"></Input>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            To Date
                            <Input type="date" name="toDate" onChange={handleChange} className="form-control"></Input>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            Location
                            <select className="form-select" name="locationId" onChange={handleChange}>
                                {locations &&
                                    locations.map((emp) => {
                                        return <option value={emp.id} key={emp.id}>{emp.locationName}</option>
                                    })}
                            </select>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='3'>
                            Employee Name
                            <select className="form-select" name="employeeId" onChange={handleChange} >
                                {employees &&
                                    employees.map((emp) => {
                                        return <option value={emp.id} key={emp.id}>{emp.fullName}</option>
                                    })}
                            </select>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='3'>
                            <br />
                            <Button color="primary" className="btn primary" type="submit">Search</Button>&nbsp;&nbsp;&nbsp;
                            <Button color="primary" className="btn primary" type="reset">Clear</Button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
            <hr />
            <GridComponent dataSource={attendance} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5, pageSizes: true }}
                statelessTemplates={['directiveTemplates']} filterSettings={{ type: 'Excel' }} allowFiltering={true}
                allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}>
                <ColumnsDirective>
                    <ColumnDirective field="employeeName" template={headerLink} headerText='Employee Name' width='150'></ColumnDirective>
                    <ColumnDirective field="employeeType" headerText='Employee Type' width='175'></ColumnDirective>
                    <ColumnDirective field="attendanceDay" headerText='Day' width='100' />
                    <ColumnDirective field="attendanceDate" headerText='Date' width='100' />
                    <ColumnDirective field="attendanceStatus" template={statusTemplate} headerText='Status' width='100' />
                    <ColumnDirective field="timeIn" headerText='Time In' width='120' />
                    <ColumnDirective field="timeOut" headerText='Time Out' width='120' />
                    <ColumnDirective field="source" headerText='Source' width='120' />
                    <ColumnDirective field="locationName" headerText='Office' width='150' />
                    <ColumnDirective field="status" headerText='Status' width='150' />
                    <ColumnDirective headerText='View Location' template={mapTemplate} width='150' />
                    <ColumnDirective field="locationLat" headerText='Lat' width='80' />
                    <ColumnDirective field="locationLon" headerText='Lon' width='80' />
                    <ColumnDirective field="locationAccuracy" headerText='Accuracy' width='80' />
                </ColumnsDirective>
                <Inject services={[Toolbar, Page, Sort, Filter, ColumnMenu, ExcelExport, PdfExport, Resize]} />
            </GridComponent>


            <Offcanvas backdrop="static" className="w-30" direction="end" isOpen={canvasOpen}
                toggle={openDetail}>
                <OffcanvasHeader toggle={openDetail}>
                    <Row>
                        <Col sm="12"><b>Attendance Details</b></Col>
                    </Row>
                </OffcanvasHeader>
                <hr />
                <OffcanvasBody>
                    Body
                </OffcanvasBody>
            </Offcanvas>
        </div>
    )
}

export default AttendanceManagement;