import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, } from "reactstrap";
import { getEmployeeTransfer, getTransferTypes, getCompanyLocations, getEmployeeList } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import Spinner from "../../shared/spinner";
import EmploymentHistory from "./EmploymentHistory";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as url from '../../../helpers/url_helper';

const TransferDetail = (props) => {
  const [canvasPlacement, setCanvasPlacement] = useState("start");
  const [isReadonly, setReadonly] = useState(false);
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [transferData, setTransferData] = useState({
      employeeId: 0, orderDate: "", effectiveDate: "", notes: "", locationId: 0, department: "", transferTypeId: 0
  });
  const [employeeList, setEmployees] = useState();
  const [locationList, setLocationList] = useState();
  const [transferTypeList, setTransferTypeList] = useState();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [submitted, setFormSubmit] = useState(false);
  const [dataSuccess, setDataSuccess] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const params = useParams();

  useEffect(() => {
      setTransferData({ ...transferData, 'employeeId': props.employeeId });

      getEmployeeList().then(res => {
           console.log(res.data);
           let employees = [{ "id": 0, "fullName": "Select Employee" }]
           res.data.forEach(element => {
               employees.push(element);
           });
           setEmployees(employees);

          if (props.employeeId && props.employeeId > 0) {
              setReadonly(true);
              transferData.employeeId = props.employeeId;
          }
      });

      getTransferTypes().then(res => {
          console.log(res.data);
          if (res.data) {
              transferData.transferTypeId = res.data[0].id;
              let transfers = [{ "id": 0, "name": "Select Transfer Type" }]
              res.data.forEach(element => {
                  transfers.push(element);
              });

              setTransferTypeList(transfers);
          }
      });

      getCompanyLocations().then(res => {
          console.log(res.data);
          if (res.data) {
              transferData.locationId = res.data[0].id;
              let locations = [{ "id": 0, "locationName": "Select Location" }]
              res.data.forEach(element => {
                  locations.push(element);
              });

              setLocationList(locations);
          }
      });

  }, [submitted]);

    const handleChange = event => {
        if (event.target.name == "transferTypeId")
            setTransferData({ ...transferData, [event.target.name]: Number(event.target.value) });
        else
            setTransferData({ ...transferData, [event.target.name]: event.target.value });

      console.log(transferData.transferTypeId);
  };


    const submitTransferDetails = (event) => {
    event.preventDefault();
      let formErrors = {};
      if (!transferData.transferTypeId) {
          formErrors.transferTypeId = 'Select the Transfer Type';
      }
      if (!transferData.orderDate) {
          formErrors.orderDate = 'Enter order date';
      }
      if (!transferData.effectiveDate) {
          formErrors.effectiveDate = 'Enter effective date';
      }

      setErrors(formErrors);
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0) {
        setIsLoading(true);

        console.log(transferData.transferTypeId);

        const formData = new FormData();

        if (transferData.transferTypeId == 1)
            transferData.department = "-";
        else
            transferData.locationId = 0;

        formData.append("department", transferData.department);
        formData.append("effectiveDate", transferData.effectiveDate);
        formData.append("orderDate", transferData.orderDate);
        formData.append("employeeId", transferData.employeeId);
        formData.append("locationId", transferData.locationId);
        formData.append("notes", transferData.notes);
        formData.append("transferTypeId", transferData.transferTypeId);

        if (selectedFiles.length > 0) {
            selectedFiles.forEach((file, index) => {
                console.log(file);
                formData.append("documents", file);
            });
        } else {
            console.log("Inside Else");
            formData.append("documents", []);
        }

        const res = addEmployeeTransfer(formData);
        console.log("res after await");
        console.log(res);
       }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

  const addEmployeeTransfer = body => {
    let userdata = localStorage.getItem("authUser");
    userdata = JSON.parse(userdata);

    var token = userdata.token;
    // const boundary = "----WebKitFormBoundary7MA4YWxkTrZu0gW";
    let requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token,
            // 'Content-Type': `multipart/form-data; boundary=${boundary}`,
            apiKey: 'key123',
        },
        body,
    };
    return fetch(url.BASE_URL + '/Employee/addtransfer', requestOptions)
        .then(response => {
            console.log({ response });
            response.json().then(res => {
                console.log(res);

                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    setTransferData({
                        orderDate: "", effectiveDate: "", notes: "", locationId: 0, department: "", transferTypeId: 0
                    });

                    if (props.onClose) {
                        props.onClose();
                    }
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();
            });
        })
        .catch(err => {
            console.log({ err });
        });
  };


  const addEducation = () => {
      if (props.onClose) {
          props.onClose();
      }
  };

    const handleAcceptedFiles = (files) => {
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    const viewDocument = (docId, docName) => {
        console.log("view document: " + docId);
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        fetch(url.BASE_URL + '/Employee/downloadtransferdocument/' + docId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + userdata.token,
                'apikey': 'key123',
            },
        }).then((response) => response.blob())
            .then((blob) => {
                console.log(blob);
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',docName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const docsLink = props => {
        console.log("Inside docsLink");
        console.log(props.employeeTransferDocuments);
        
        const links = [];
        
        props.employeeTransferDocuments ? props.employeeTransferDocuments.map((doc) => {
            links.push(
                <button onClick={() => viewDocument(doc.id, doc.fileName)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                    {doc.fileName}
                </button>);
            links.push(<br></br>);
        }) : links.push(<label>None</label>)
        
        return (
            <div>{links}</div>
        )
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

  return (
     <>
          <FormGroup>
              <Row>
                  <Col xl="12" md="12" sm="12">
                      <Label className="form-label" for="InputHelp">Employee</Label>
                      <Input type="select" value={Number(transferData.employeeId)} onChange={handleChange} name='employeeId' disabled={isReadonly}>
                          {employeeList &&
                              employeeList.map((add) => {
                                  return <option value={Number(add.id)} key={add.id}>{add.fullName}</option>
                              })}
                      </Input>
                      <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                  </Col>
              </Row>
            <Row>
              <Col xl="12" md="12" sm="12">
                              <Label className="form-label" for="InputHelp">Transfer Type</Label>
                              <Input type="select" value={Number(transferData.transferTypeId)} onChange={handleChange} name='transferTypeId'>
                        {transferTypeList &&
                            transferTypeList.map((add) => {
                                return <option value={Number(add.id)} key={add.id}>{add.name}</option>
                            })}
                              </Input>
                    <p style={{ color: "Red" }}>{errors.transferTypeId && <span>{errors.transferTypeId}</span>}</p>
              </Col>
            </Row>

                      <Row>
                          <Col xl="12" md="12" sm="12">
                              <Label className="form-label" for="InputHelp">Order Date</Label>
                              <Input type="date" className="form-control" name="orderDate"
                                  value={transferData.orderDate} onChange={handleChange} placeholder="Enter Order Date"></Input>
                              <p style={{ color: "Red" }}>{errors.orderDate && <span>{errors.orderDate}</span>}</p>
                          </Col>
              <Col xl="12" md="12" sm="12">
               <Label className="form-label" for="InputHelp">Effective From</Label>
                              <Input type="date" className="form-control" name="effectiveDate"
                                value={transferData.effectiveDate} onChange={handleChange} placeholder="Enter Effective Date"></Input>
                              <p style={{ color: "Red" }}>{errors.effectiveDate && <span>{errors.effectiveDate}</span>}</p>
              </Col>

            {transferData.transferTypeId === 1 &&
              <Col xl="12" md="12" sm="12">
                <Label className="form-label" for="InputHelp">To Location</Label>
                              <Input type="select" className="form-control" name="locationId"
                                  value={Number(transferData.locationId)} onChange={handleChange} placeholder="Enter city">
                                  {locationList &&
                                      locationList.map((add) => {
                                          return <option value={Number(add.id)} key={add.id}>{add.locationName}</option>
                                      })}
                              </Input>
                              <p style={{ color: "Red" }}>{errors.locationId && <span>{errors.locationId}</span>}</p>
              </Col>
                          }

                          {transferData.transferTypeId === 2 &&
                              <Col xl="12" md="12" sm="12">
                                  <Label className="form-label" for="InputHelp">To Department</Label>
                              <Input type="text" className="form-control" name="department"
                                  value={transferData.department} onChange={handleChange} placeholder="Enter Department"></Input>
                              <p style={{ color: "Red" }}>{errors.department && <span>{errors.department}</span>}</p>
                              </Col>
                          }

                          <Col xl="12" md="12" sm="12">
                              <Label className="form-label" for="InputHelp">Notes</Label>
                              <Input type="textarea" className="form-control" name="notes"
                                  value={transferData.notes} onChange={handleChange} placeholder="Notes"></Input>
                              <p style={{ color: "Red" }}>{errors.notes && <span>{errors.notes}</span>}</p>
                          </Col>

                          <Col sm='12' className='mb-4'>
                              <Dropzone style={{ minHeight:'100px !important' }} onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                              }}>
                                  {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                          <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                  <i className="display-4 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h4>click to upload order copies</h4>
                                          </div>
                                      </div>
                                  )}
                              </Dropzone>
                              <div className="dropzone-previews mt-3" id="file-previews">
                                  {selectedFiles.map((f, i) => {
                                      return (
                                          <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                          >
                                              <div className="p-2">
                                                  <Row className="align-items-center">
                                                      <Col className="col-auto">
                                                          <img
                                                              data-dz-thumbnail=""
                                                              height="80"
                                                              className="avatar-sm rounded bg-light"
                                                              alt={f.name}
                                                              src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUpJREFUOE+N0z1LHlEQhuHr1aDiRyEStTGK2PoTbO20sRESRAVtDWJhpYH8HwsbFQRRC02pgnaSIJgija3fMq9n4bDZDdlmd4cz9zwz85yG92cAt/iT/suvFrRjGVt4LA40MsAZBmsARYFrfMUenuJsDjjHMHoQFV/xgDv04xTTOMIMDsqAKyzhGz6mCof4jA4sYDPBXwq1ZQVDaEttPKO1oqU+/KgCXCaJE4jkG4yUABGPlr7XKfiE7gT4UAwqg0SsF8d1CmYxh58V0iN5FDGXjX8p6EwKcsZ98kHEYgYnVYDYwhcs4lfKLoYce59MLf3Gep2C8EFXmn5UzQ0XTgzzhE/CE03T5WsMBfNYwRh2MkAkh4I4s4u1KkCdlaNIAEJZfMeWKhXUAcJYU1hNgO10H/5q4QLjNZcpD4fN98stRDAA//vEtW8WewNXM0MR2YfR7AAAAABJRU5ErkJggg=="}
                                                          />
                                                      </Col>
                                                      <Col>
                                                          <Link
                                                              to="#"
                                                              className="text-muted font-weight-bold"
                                                          >
                                                              {f.name}
                                                          </Link>
                                                          <p className="mb-0">
                                                              <strong>{f.formattedSize}</strong>
                                                          </p>
                                                      </Col>
                                                  </Row>
                                              </div>
                                          </Card>
                                      );
                                  })}
                              </div>
                          </Col>
            </Row>
          </FormGroup>

          <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
            {dataSuccess === 'added' && <SuccessAlert />}
            {dataSuccess === 'failed' && <FailureAlert />}
          </div>

          <div className="offcanvas-footer">
                      {isLoading ? <LoadingSpinner /> : <Button color="primary"
                          onClick={submitTransferDetails} className="btn btn-sm" disabled={isLoading}
                          type="submit">Submit</Button>}&nbsp;
            <button onClick={addEducation} className="btn btn-dark btn-sm">
              Close
            </button>
          </div>        
    </>
  );
};

export default TransferDetail;
