import React, { useEffect } from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { Button, Card, CardHeader, CardTitle, Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Container } from "reactstrap";

import { useState } from 'react';
import LoanBody from './LoanDetail';
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Resize } from "@syncfusion/ej2-react-grids";
import { getEmployeeExpenseRequest } from '../../../helpers/backend_helper';
import { getData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const LoanList = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [loanList, setLoanList] = useState();

    const loadData = () => {
        showLoading(true);
        getData("/loan/loanlist").then(res => {
            console.log(res.data);
            if (res.data) {
                setLoanList(res.data)
            }

            showLoading(false);
        });
    }

    useEffect(() => {
        loadData();
    }, [])

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    document.title = "Expense | HRMS";
    return (
        <React.Fragment>
            <div className="page-content">
                
                    <Breadcrumbs title="Loans" breadcrumbItem="Loans" enableAction={true} actionName="Add Existing Loan" onClick={toggleCanvasEnd} />

                    <div className='mt-2'>
                       <GridComponent dataSource={loanList} allowPaging={true} allowSorting={true} showColumnMenu={true}
                        pageSettings={{ pageCount: 5, pageSizes: true }} toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)}
                        ref={grid => gridInstance = grid} statelessTemplates={['directiveTemplates']} allowExcelExport={true}
                        allowPdfExport={true} allowResizing={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='employeeProfile.fullName' headerText='Employee' ></ColumnDirective>
                                <ColumnDirective field='expenseType.name' headerText='Loan Type' width='175' ></ColumnDirective>
                                <ColumnDirective field='expenseDate' type='datetime' format='dd/MM/yyyy' headerText='Date' width='175'></ColumnDirective>
                                <ColumnDirective field='amount' headerText='Amount' width='150'></ColumnDirective>
                                <ColumnDirective field='description' headerText='Description' width='250' />
                                <ColumnDirective field='expenseStatus'  headerText='Status' width='150' />                                
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, ColumnMenu, Resize]} />
                        </GridComponent>
                    </div>
                    
                    <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen}
                        toggle={toggleCanvasEnd}>
                        <OffcanvasHeader toggle={toggleCanvasEnd}>
                            <Row>
                                <Col sm='12'>
                                    <b>Add Existing Loan</b>
                                </Col>
                            </Row>
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <LoanBody />
                        </OffcanvasBody>
                </Offcanvas>
                
            </div>
        </React.Fragment>
    );
};

export default LoanList;