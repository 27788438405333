import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Filter, Sort, ColumnMenu  } from "@syncfusion/ej2-react-grids";
import { createEmployeeType, getCompanyAllowances } from "../../../helpers/backend_helper";

const Allowances = () => {
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [allowanceList, setAllowanceList] = useState();

    useEffect(() => {
        getCompanyAllowances().then(res => {
            if (res.data) {
                setAllowanceList(res.data)
            }
        });

    }, [])

    const toggleAllowances = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const submitBankDetails = (event) => {
        event.preventDefault();

    }

    const timeout = () => {
        setTimeout(() => {
            // setDataSuccess('')
        }, 3000);
    }

    return (
        <>
            <Card>
                <GridComponent dataSource={allowanceList} allowPaging={true} pageSettings={{ pageCount: 5 }}
                    allowSorting={true} allowFiltering={true} showColumnMenu={true} statelessTemplates={['directiveTemplates']} filterSettings={{ type: 'Excel' }}>
                    <ColumnsDirective>
                        <ColumnDirective field="department.name" headerText='Department' width='250'></ColumnDirective>
                        <ColumnDirective field="employeeGroup.name" headerText='Group' width='175' />
                        <ColumnDirective field="employeeGrade.name" headerText='Designation' />
                        <ColumnDirective field="allowanceType.name" headerText='Allowance Type' width='150' ></ColumnDirective>
                        <ColumnDirective headerText='Percentage' width='150' ></ColumnDirective>
                        <ColumnDirective headerText='Amount' width='150' ></ColumnDirective>
                        <ColumnDirective field="status" headerText='Status' width='120'  />
                    </ColumnsDirective>
                    <Inject services={[Page, Filter, Sort, ColumnMenu]} />
                </GridComponent>
            </Card>

            <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleAllowances}>
                <OffcanvasHeader toggle={toggleAllowances}>
                    <Row>
                        <Col sm='12'>
                            <b>Allowances</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />

                <OffcanvasBody>

                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default Allowances;