import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Filter, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { useEffect, useState, useRef } from "react";
import {
    createTimeEntry, getCompanyLocations, getDepartments, getEmployeeList,
    getManulAttendanceList, getVendorsPayments, getAllContractors
} from "../../../helpers/backend_helper";
import { getData, postData } from "../../../helpers/url-request";
import MonthlyAttendanceReport from './monthlyattendancereport';
import MonthlyAttendanceReportView from './monthlyattendancereportview';
import { showLoading } from 'react-global-loading';

const MonthlyAttendanceList = () => {
    let gridInstance;
    const [reportId, setReportId] = useState(false);
    const [modal, setModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const toggle = () => setModal(!modal);
    const viewToggle = () => setViewModal(!viewModal);
    const [attendance, setAttendanceDetails] = useState();

    const loadData = () => {
        showLoading(true);
        getData("/attendance/monthlyreportlist").then(res => {
            setAttendanceDetails(res.data);
            showLoading(false);
        });
    }

    useEffect(() => {

        loadData();
                
    }, []);


    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.id);
        switch (args.item.id) {            
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const submitReport = (event) => {
        toggle();
    }

    const viewReport = (id) => {
        setReportId(id);
        viewToggle();
    }

    const headerLink = props => {        
        return (
            <button onClick={() => viewReport(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                AR-{props.id}
            </button>
        )
    }

    const onClose = () => {
        toggle();
        loadData();
    }

    return (
        <div className="page-content" id="pageContainer" name="pageContainer">

            <Modal isOpen={modal} toggle={toggle} fullscreen container='pageContainer'>
                <ModalHeader toggle={toggle}>Monthly Attendance Report</ModalHeader>
                <ModalBody>
                    <MonthlyAttendanceReport onClose={onClose} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="btn primary" type="button" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={viewModal} toggle={viewToggle} fullscreen container='pageContainer'>
                <ModalHeader toggle={viewToggle}>Monthly Attendance Report</ModalHeader>
                <ModalBody>
                    <MonthlyAttendanceReportView reportId={reportId} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="btn primary" type="button" onClick={viewToggle}>Close</Button>
                </ModalFooter>
            </Modal>
            
            <Container fluid>
                <Breadcrumbs title="Attendance" breadcrumbItem="Monthly Attendance List" actionName="Submit Report" enableAction={true} onClick={submitReport}></Breadcrumbs>
            </Container>
            <GridComponent dataSource={attendance} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid} allowResizing={true}
                pageSettings={{ pageCount: 5, pageSizes: true }} statelessTemplates={['directiveTemplates']} allowFiltering={true} filterSettings={{ type: 'Excel' }}
                allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)}>
                <ColumnsDirective>
                    <ColumnDirective field="id" headerText='Report Id' template={headerLink} width='150'></ColumnDirective>
                    <ColumnDirective field="payPeriod.payPeriodTitle" headerText='Period' width='150'></ColumnDirective>
                    <ColumnDirective field="location.locationName" headerText='Location' width='150' />
                    <ColumnDirective field="generatedOn" headerText='Generated On' type='date' format='dd-MM-yyyy' width='150' />
                    <ColumnDirective field="generatedBy" headerText='Generated By' width='150'></ColumnDirective>
                    <ColumnDirective field="reportStatus" headerText='Status' width='150' />
                    <ColumnDirective field="taskDetail.assignedToUser.fullName" headerText='Assigned To' width='150' />
                    <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='150' />
                </ColumnsDirective>
                <Inject services={[Toolbar, Page, Sort, Filter, ColumnMenu, ExcelExport, PdfExport, Resize]} />
            </GridComponent>

        </div>
    )
}

export default MonthlyAttendanceList;