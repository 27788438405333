import { Button, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import React, { useState, useEffect } from "react";
import { getData, postData } from "../../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const AddHoliday = (props) => {

    const [formData, setFormData] = useState({
        id: 0,
        holidayName: '',
        holidayDate: '',
        status: 1
    });
    const [errors, setErrors] = useState({});

    const handleChange = event => {
        if (event.target.name === "status") {
            let statusSet = event.target.checked ? 1 : 0;
            setFormData({ ...formData, [event.target.name]: statusSet });
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    };

    const handleSubmit = event => {
        event.preventDefault();

        let formErrors = {};
        if (!formData.holidayName) {
            formErrors.name = 'Holiday Name is required';
        }
        if (!formData.holidayDate) {
            formErrors.date = 'Holiday Date is required';
        }

        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            showLoading(true);

            if (formData.id == 0) {
                postData("/policy/createholiday", formData).then(res => {
                    showLoading(false);
                    if (res.status === true) {
                        if (props.onClose) {
                            props.onClose();
                        }
                    } else {
                        alert(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                    showLoading(false);
                });
            } else {
                showLoading(false);
            }
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col sm='12' className='mb-1'>
                        <Label className='form-label' for='input-default'>
                            Holiday
                        </Label>
                        <Input type='text' name="holidayName" onChange={handleChange}
                            value={formData.holidayName} placeholder='Holiday Name' />
                        <p style={{ color: "Red" }}>{errors ? errors.name : ''}</p>
                    </Col>

                    <Col sm='12' className='mb-1'>
                        <Label className='form-label' for='input-default'>
                            Date
                        </Label>
                        <Input type='date' name="holidayDate" onChange={handleChange}
                            value={formData.holidayDate} />
                        <p style={{ color: "Red" }}>{errors ? errors.date : ''}</p>
                    </Col>

                    <hr />
                    <Col sm='4'>
                        <Button block color='primary' type="submit" className='mb-1'>Save</Button>
                    </Col>
                    <Col sm='4' className='mr-2'>
                        <Button block outline type="button" color='secondary' onClick={props.onClose}>Close</Button>
                    </Col>

                </Row>
            </Form>
        </>
    )
}

export default AddHoliday;