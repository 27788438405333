import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Resize } from "@syncfusion/ej2-react-grids";
import LoadingSpinner from "../../shared/Loading";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { addEmployeeBank, getEmployeeBanks } from "../../../helpers/backend_helper";
import { useParams } from "react-router-dom";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import PropTypes from 'prop-types'
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const TimeAttendance = props => {
    let gridInstance;

    const [payrollData, setPayrollData] = useState({});

    useEffect(() => {
        if (props.payPeriodId > 0) {
            loadData();
        }
    }, []);

    const loadData = () => {
        if (props.payPeriodId > 0) {

            showLoading(true);

            var formData = {
                "payPeriodId": props.payPeriodId
            }
            postData("/payroll/payrollprocess/attendance", formData).then(res => {
                console.log(res.data);
                setPayrollData(res.data);

                showLoading(false);
            });
        }
    }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {        
        switch (args.item.id) {
            case 'refresh':
                loadData();
                break;
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <>            
            <CardBody>
                <GridComponent dataSource={payrollData} toolbar={toolbarOptions} allowPaging={true} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5 }}
                    allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}>
                    <ColumnsDirective>
                        <ColumnDirective field="employeeName" headerText='Employee' width='150'></ColumnDirective>
                        <ColumnDirective field="location" headerText='Location' width='150'></ColumnDirective>
                        <ColumnDirective field="department" headerText='Department' width='150'></ColumnDirective>
                        <ColumnDirective field="designation" headerText='Designation' width='150'></ColumnDirective>
                        <ColumnDirective field="totalDays" headerText='No. Days' width='150' />
                        <ColumnDirective field="holidays" headerText='Holidays' width='150' />
                        <ColumnDirective field="workingDays" headerText='Working Days' width='150'></ColumnDirective>
                        <ColumnDirective field="leaves" headerText='Leaves' width='150'></ColumnDirective>
                        <ColumnDirective field="lopDays" headerText='LOP Days' width='150' />
                        <ColumnDirective field="presentDays" headerText='Present Days' width='150' />
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Page, Resize]} />
                </GridComponent>
            </CardBody>

        </>
    )
}

TimeAttendance.propTypes = {
    data: PropTypes.args
}

export default TimeAttendance;