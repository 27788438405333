import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { getLoanTypes, createExpenseTypes } from "../../../helpers/backend_helper";

const SettingsLoanType = () => {
    let gridInstance;
    const [expenseList, setExpenseListData] = useState([]);
    const [submitted, setFormSubmit] = useState(false);
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: 0
    });
    const [errors, setErrors] = useState({});

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const loadData = () => {
        getLoanTypes()
            .then(res => {
                console.log(res);
                if (res.data) {
                    setExpenseListData(res.data)
                }
            });
    }

    useEffect(() => {
        loadData();
    }, [submitted]);

    const handleChange = event => {
        console.log(event.target.name);
        if (event.target.name === "status") {
            let statusSet = event.target.checked ? 1 : 0;
            setFormData({ ...formData, [event.target.name]: statusSet });
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }

    };

    const handleSubmit = event => {
        event.preventDefault();

        // Validate form inputs
        let formErrors = {};
        if (!formData.name) {
            formErrors.name = 'Expense Type is required';
        }
        if (!formData.description) {
            formErrors.description = 'Description is required';
        }

        setErrors(formErrors);

        // If there are no errors, submit the form
        if (Object.keys(formErrors).length === 0) {
            // console.log('Form submitted!', formData);
            //Submit the form here
            createExpenseTypes(formData).then((res) => {
                console.log(res);
                setFormSubmit(!submitted);
            }).catch((err) => {
                console.log(err);
            })

        }
    };

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const statusTemplate = (props) => {

        return (<div>{props.status === 1 ?
            <div id="status" className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div id="status" className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }


    return (

        <Fragment>
            <div className="m-2">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Loan Type" enableAction={true} actionName="Add Loan Type" onClick={toggleCanvasEnd}></Breadcrumbs>
                    
                    {!expenseList && <p>Loading Please wait.....</p>}

                    {expenseList && <GridComponent dataSource={expenseList} allowPaging={true} pageSettings={{ pageCount: 25, pageSizes: true }} allowResizing={true}
                        toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid}
                        statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true}>
                        <ColumnsDirective>
                            <ColumnDirective field="name" headerText='Loan Type' width='150'></ColumnDirective>
                            <ColumnDirective field="description" headerText='Description' width='150' />
                            <ColumnDirective field="status" headerText='Status' template={statusTemplate} width='150' />
                            <ColumnDirective field="updatedOn" type="date" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='100' />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize]} />
                    </GridComponent>}                   

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Loan Type</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />


                            <OffcanvasBody>
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Loan Type:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                placeholder="Loan Type"
                                                onChange={handleChange}
                                                value={formData.name}
                                            />
                                            <p style={{ color: "Red" }}>{errors.name && <span>{errors.name}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Description:
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                placeholder="Description"
                                                onChange={handleChange}
                                                value={formData.description}
                                            />
                                            <p style={{ color: "Red" }}>{errors.description && <span>{errors.description}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Status:
                                            </Label>
                                            <Input
                                                type="checkbox"
                                                name="status"
                                                onChange={handleChange}
                                                value={formData.status}
                                            />
                                        </Col>
                                    </Row>
                                  
                                    <Button color="primary" type="submit">Submit</Button>
                                </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>


    );
}

export default SettingsLoanType;