// import Select from "react-select/dist/declarations/src/Select";
import { Button, Col, Input, Label, Row, Card } from "reactstrap";

import Select from 'react-select'
import React, { useEffect, useState } from "react";
import { addEmployeeLeaveRequest, getEmployeeList, getLeaveTypes, getLocalStorage } from "../../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../../shared/Alert";
import LoadingSpinner from "../../../shared/Loading";
import './leave.css'
import PropTypes from 'prop-types'
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";


const AddLeaveRequest = (props) => {
    const [leaveValue, setLeaveValue] = useState('');
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [leaveTypes, setLeaveTypes] = useState();
    const [employees, setEmployees] = useState();
    const [role, setRole] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [formData, setFormData] = useState({
        employeeId: 0, leaveTypeId: 0, leavePeriod: '', fromDate: '', toDate: '', leaveDays: '', description: ''
    });
    const [errors, setErrors] = useState({});

    const confirmHandler = (event) => {
        event.preventDefault();
        let formErrors = {};
        if (!formData.leaveTypeId) {
            formErrors.leaveTypeId = 'Leave Type is required';
        }
        if (!formData.leavePeriod) {
            formErrors.leavePeriod = 'Leave Period is required';
        }
        if (formData.leavePeriod === "1") {
            if (!formData.leaveDays) {
                formErrors.leaveDays = 'Leave Days is required';
            }
        } else if (formData.leavePeriod === "3") {
            if (!formData.toDate) {
                formErrors.toDate = 'To Date is required';
            }
        }
        if (!formData.fromDate) {
            formErrors.fromDate = 'From Date is required';
        }


        // if (!formData.description) {
        //     formErrors.description = 'Description is required';
        // }

        setErrors(formErrors);

        console.log(formData);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            addEmployeeLeaveRequest(formData).then((res) => {
                // console.log(res);
                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    setFormData({
                        leavePeriod: '', fromDate: '', toDate: '', leaveDays: '', description: ''
                    });
                    setFormSubmit(!submitted);
                    props.closeAction();
                } else {
                    alert(res.message);
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }                
                timeout();
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })

        }
    }

    useEffect(() => {

        getLeaveTypes().then((res) => {            
            let leaveTypes = [{ "id": 0, "name": "Select Leave Type" }];
            res.data.forEach(el => {
                leaveTypes.push(el)
            });
            setLeaveTypes(leaveTypes);
        });

        getEmployeeList().then((res) => {
            let emps = [{ label: "Select Employee", value: 0 }];
            res.data.forEach(el => {
                emps.push({ label: el.fullName, value: el.id });
            });
            setEmployees(emps);
        });

        const storage = getLocalStorage();
        setRole(storage.roleName);

    }, [submitted]);
        
    const handleChange = (event) => {
        if (event.target) {
            if (event.target.name === "leavePeriod") {
                setLeaveValue(event.currentTarget.value);
            }
            if (formData.leavePeriod === "1") {
                formData.toDate = formData.fromDate;
            } else if (formData.leavePeriod === "2") {
                formData.leaveDays = 0;
                formData.toDate = formData.fromDate;
            } else if (formData.leavePeriod === "3") {
                formData.leaveDays = 0;
            }
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    return (
        <>
            <form onSubmit={confirmHandler}>
                <Row>                    
                    <Col className="mb-1" xl="3" md="3" sm="12">
                        Employee
                    </Col>
                    <Col className='mb-1' xl='9' md='9' sm='12'>
                        <Select type="select"
                            placeholder='Select Employee'
                            name="employeeId"
                            isMulti={false}
                            options={employees}
                            classNamePrefix="form-select"
                            isLoading={false}
                            onChange={handleChange}
                        />
                        <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                    </Col>
                    <Col className='mb-1' xl='3' md='3' sm='12'>
                        <span>Leave Type</span>
                    </Col>
                    <Col className='mb-1' xl='9' md='9' sm='12'>
                        <Input type="select" className="form-select" name="leaveTypeId"
                            onChange={handleChange} value={formData.leaveTypeId}>
                            {leaveTypes &&
                                leaveTypes.map((res) => {
                                    return <option value={res.id} key={res.id}>{res.name}</option>
                                })}</Input>
                        <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                    </Col>
                </Row>
                    <Row>
                        <Col className='mb-1' xl='3' md='3' sm='12'>
                            <span>Leave Period</span>
                        </Col>
                        <Col className='mb-1' xl='9' md='9' sm='12'>
                            <Input id='single' type='radio' name='leavePeriod' value='2'
                                onChange={handleChange} />&nbsp;
                            <Label className='form-check-label' for='single'>
                                Single
                            </Label>&nbsp;&nbsp;
                            <br /><br />
                            <Input id='multiple' type='radio' name='leavePeriod' value='3'
                                onChange={handleChange} />&nbsp;
                            <Label className='form-check-label' for='multiple'>
                                Multiple
                            </Label>
                            <p style={{ color: "Red" }}>{errors.leavePeriod && <span>{errors.leavePeriod}</span>}</p>
                        </Col>
                    </Row>

                    {leaveValue === '1' &&
                        <Row>
                            <Col className='mb-1' xl='3' md='3' sm='12'>
                                <span>Date</span>
                            </Col>
                            <Col className='mb-1' xl='9' md='9' sm='12'>
                                <Input type="date" name="fromDate" onChange={handleChange} className="form-control">
                                </Input>
                                <p style={{ color: "Red" }}>{errors.fromDate && <span>{errors.fromDate}</span>}</p>
                            </Col>

                            <Col className='mb-1' xl='3' md='3' sm='12'>
                                <span>No. of Hours</span>
                            </Col>
                            <Col className='mb-1' xl='9' md='9' sm='12'>
                                <Input placeholder='No. of Hours' onChange={handleChange} className="form-control" name="leaveDays" ></Input>
                                <p style={{ color: "Red" }}>{errors.leaveDays && <span>{errors.leaveDays}</span>}</p>
                            </Col>
                        </Row>
                    }

                    {leaveValue === '2' &&
                        <Row>
                            <Col className='mb-1' xl='3' md='3' sm='12'>
                                <span>Date</span>
                            </Col>
                            <Col className='mb-1' xl='9' md='9' sm='12'>
                                <Input type="date" name="fromDate" onChange={handleChange} className="form-control">

                                </Input>
                                <p style={{ color: "Red" }}>{errors.fromDate && <span>{errors.fromDate}</span>}</p>
                            </Col>

                        </Row>
                    }

                    {leaveValue === '3' &&
                        <Row>
                            <Col className='mb-1' xl='3' md='3' sm='12'>
                                <span>From Date</span>
                            </Col>
                            <Col className='mb-1' xl='9' md='9' sm='12'>
                                <Input type="date" name="fromDate" onChange={handleChange} className="form-control">

                                </Input>
                                <p style={{ color: "Red" }}>{errors.fromDate && <span>{errors.fromDate}</span>}</p>
                            </Col>

                            <Col className='mb-1' xl='3' md='3' sm='12'>
                                <span>To Date</span>
                            </Col>
                            <Col className='mb-1' xl='9' md='9' sm='12'>
                                <Input type="date" name="toDate" onChange={handleChange} className="form-control">

                                </Input>
                                <p style={{ color: "Red" }}>{errors.toDate && <span>{errors.toDate}</span>}</p>
                            </Col>

                        </Row>
                    }

                    <Row>
                        <Col className='mb-1' xl='3' md='3' sm='12'>
                            <span>Description</span>
                        </Col>
                        <Col className='mb-1' xl='9' md='9' sm='12'>
                            <Input type='textarea' name="description" onChange={handleChange} id='input-default' placeholder='Description' />
                        </Col>
                    </Row>

                <Row>
                    <Col sm='12' className='mb-1'>
                        <br />
                        <Dropzone onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles);
                        }}>
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone" style={{ minHeight: '130px' }}>
                                    <div className="dz-message needsclick mt-2" style={{ padding: '10px'}} {...getRootProps()} >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                            <i className="display-4 text-muted bx bx-cloud-upload" />
                                        </div>
                                        <h4>Drop files here or click to upload.</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedFiles.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}>
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUpJREFUOE+N0z1LHlEQhuHr1aDiRyEStTGK2PoTbO20sRESRAVtDWJhpYH8HwsbFQRRC02pgnaSIJgija3fMq9n4bDZDdlmd4cz9zwz85yG92cAt/iT/suvFrRjGVt4LA40MsAZBmsARYFrfMUenuJsDjjHMHoQFV/xgDv04xTTOMIMDsqAKyzhGz6mCof4jA4sYDPBXwq1ZQVDaEttPKO1oqU+/KgCXCaJE4jkG4yUABGPlr7XKfiE7gT4UAwqg0SsF8d1CmYxh58V0iN5FDGXjX8p6EwKcsZ98kHEYgYnVYDYwhcs4lfKLoYce59MLf3Gep2C8EFXmn5UzQ0XTgzzhE/CE03T5WsMBfNYwRh2MkAkh4I4s4u1KkCdlaNIAEJZfMeWKhXUAcJYU1hNgO10H/5q4QLjNZcpD4fN98stRDAA//vEtW8WewNXM0MR2YfR7AAAAABJRU5ErkJggg=="}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                );
                            })}
                        </div>
                    </Col>
                </Row>

                    <div className="text-center mt-2">
                        {dataSuccess === 'added' && <SuccessAlert />}
                        {dataSuccess === 'failed' && <FailureAlert />}
                    </div>                
                    <div className="offcanvas-footer">
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            onClick={confirmHandler} className="btn btn-sm" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;
                        <button className="btn btn-dark btn-sm" type="button" onClick={props.closeAction}>Close</button>
                    </div>                
            </form>
        </>
    )
}

AddLeaveRequest.propTypes = {
    closeAction: PropTypes.string
}

export default AddLeaveRequest;