import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";
import { showLoading } from 'react-global-loading';

import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Toolbar } from "@syncfusion/ej2-navigations";
import AllowanceType from "./AllowanceType";
import DeductionType from "./DeductionType";
import FinancialYear from "./FinancialYear";
import PayPeriod from "./PayPeriod";
import LoanType from "./LoanType";

const SettingsAccount = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Settings" breadcrumbItem="Account Settings" enableAction={false}></Breadcrumbs>
                <Container fluid>
                    <CardBody>
                        <div id="basic-pills-wizard" className="twitter-bs-wizard">

                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>Allowance Type</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>Deduction Type</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            setactiveTab(3);
                                        }}>
                                        <span>Financial Year</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 4 })}
                                        onClick={() => {
                                            setactiveTab(4);
                                        }}>
                                        <span>Pay Period</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 5 })}
                                        onClick={() => {
                                            setactiveTab(5);
                                        }}>
                                        <span>Loan Type</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <AllowanceType />
                                </TabPane>
                                <TabPane tabId={2}>
                                    <DeductionType />
                                </TabPane>
                                <TabPane tabId={3}>
                                    <FinancialYear />
                                </TabPane>
                                <TabPane tabId={4}>
                                    <PayPeriod />
                                </TabPane>
                                <TabPane tabId={5}>
                                    <LoanType />
                                </TabPane>
                            </TabContent>

                        </div>
                    </CardBody>
                </Container >
            </div >
        </React.Fragment >
    );
};

export default SettingsAccount;