import PropTypes from "prop-types"
import React, { useState } from "react"

import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label, Button } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useHistory } from "react-router-dom"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-sm.svg"
import company from "../../assets/images/company.jpg"

//Import config
import { facebook, google } from "../../config"
import CarouselPage from "../Authentication/CarouselPage"
import LoadingSpinner from "../shared/Loading";
import { postLoginRequest, setDefHeaders } from "../../helpers/backend_helper";
import { setDefaultHeaders } from "../../helpers/url-request";

const Login = props => {
  const [loading, setLoading] = useState(false);
  const [loginerror, setLoginError] = useState(false);
  const dispatch = useDispatch()
  const history = useHistory();

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "" || '',
      password: "" || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      postLoginRequest(values).then(res => {
        // console.log(res);
        if (res.status === true) {
          setDefaultHeaders(res.data);
          setDefHeaders(res.data)
          //localStorage.setItem("authUser", JSON.stringify(res.data));
            localStorage.setItem("tempUser", JSON.stringify(res.data));
          // const lc = localStorage.getItem("authUser")
          // console.log(lc);
          console.log("Inside success");
          //if (res.data.roleId == 1 || res.data.roleId == 2 || res.data.roleId == 3 || res.data.roleId == 8 || res.data.roleId == 9)
          //    history.push("/dashboard");
          //else if (res.data.roleId == 6)
          //    history.push("/districtdashboard");
          //else
          //    history.push("/dashboard");

          history.push("/twostepverification");
          setLoading(false);
        } else {
          console.log("Inside failure");
          setLoading(false);
          setLoginError(res.message)
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      })
      // setLoading(true);
      // dispatch(loginUser(values, props.history));
      // setLoading(false);
    }
  });

  document.title = "KMVSTDCL | HRMS";

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" /> <span className="logo-txt">HRMS</span>
                      </Link>
                    </div>

                    <div className="mb-4 mb-md-5 text-center">                        
                        <img src={company} alt="" height="147" />
                    </div>

                    <div className="auth-content">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">Sign in to continue to HRMS.</p>
                      </div>
                      <Form className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}>
                        {loginerror ? <Alert color="danger">{loginerror}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">Username</Label>
                          <Input name="username" className="form-control" placeholder="Enter Username"
                            type="text" onChange={validation.handleChange} onBlur={validation.handleBlur}
                            value={validation.values.username || ""} invalid={
                              validation.touched.username && validation.errors.username ? true : false
                            } />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input name="password" value={validation.values.password || ""}
                            type="password" placeholder="Enter Password" onChange={validation.handleChange}
                            onBlur={validation.handleBlur} invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            } />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" id="remember-check" />
                              <label className="form-check-label" htmlFor="remember-check" >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3 d-grid">
                              {loading ? <LoadingSpinner text="Logging in.... please wait...." />
                                : <Button color="primary" disabled={loading} type="submit"> Log In</Button>}
                            </div>
                          </div>
                        </div>
                      </Form>


                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">{new Date().getFullYear()} &#169; CodeCatalysts</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}