import React, { useState }  from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row, Button, FormFeedback, Form, Input, Alert } from 'reactstrap';

//Verification code package
import AuthCode from "react-auth-code-input";

//import images
import logo from "../../assets/images/logo-sm.svg";
import company from "../../assets/images/company.jpg"

import CarouselPage from './CarouselPage';
import { postOTPRequest, setDefHeaders } from '../../helpers/backend_helper';
import { setDefaultHeaders } from "../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const TwoStepVerfication = () => {

    const [otpError, setOTPError] = useState(false);
    const [authCode, setAuthCode] = useState();
    const [errors, setErrors] = useState({});
    const history = useHistory();

    const handleChange = (data) => {
        console.log("On change");
        console.log(data);
        setAuthCode(data);
    }

    const handleSubmit = event => {
        event.preventDefault();

        let formErrors = {};

        if (!authCode) {
            formErrors.otpcode = 'OTP is required';
        }

        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {

            const tempUser = localStorage.getItem("tempUser");
            console.log(tempUser);
            var user = JSON.parse(tempUser);

            if (!user.userId)
                history.push("/logout");

            var data = {
                'userId': user.userId,
                'otpCode': authCode
            }

            console.log(data);
            showLoading(true);

            postOTPRequest(data).then(res => {
                showLoading(false);
                console.log(res);
                if (res.status === true) {
                    setDefaultHeaders(res.data);
                    setDefHeaders(res.data);

                    localStorage.removeItem("tempUser");
                    localStorage.setItem("authUser", JSON.stringify(res.data));

                    if (res.data.roleId == 1 || res.data.roleId == 2 || res.data.roleId == 3 || res.data.roleId == 8 || res.data.roleId == 9)
                        history.push("/dashboard");
                    else if (res.data.roleId == 6)
                        history.push("/districtdashboard");
                    else
                        history.push("/dashboard");
                } else {                    
                    console.log("Inside failure");                    
                    setOTPError(res.message)
                }
            }).catch((err) => {
                showLoading(false);
                console.log(err);
            })
        }

    };

    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4} md={5} className="col-xxl-3">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">

                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt="" height="28" /> <span className="logo-txt">HRMS</span>
                                            </Link>
                                        </div>

                                        <div className="mb-4 mb-md-5 text-center">
                                            <img src={company} alt="" height="147" />
                                        </div>

                                        <div className="auth-content">
                                            <div className="text-center">

                                                <div className="avatar-lg mx-auto">
                                                    <div className="avatar-title rounded-circle bg-light">
                                                        <i className="bx bxs-lock h2 mb-0 text-primary"></i>
                                                    </div>
                                                </div>
                                                <div className="p-2 mt-4">

                                                    <h4>Verify your OTP</h4>
                                                    <p className="mb-5">Please enter the 4 digit code</p>
                                                    {otpError ? <Alert color="danger">{otpError}</Alert> : null}
                                                    <Form>
                                                        <Input type="hidden" name="otpcode" value={authCode} />
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mb-3 verification">
                                                                    <label htmlFor="digit1-input" className="visually-hidden">Dight 1</label>
                                                                    <AuthCode                                                                        
                                                                        characters={4}
                                                                        className="form-control form-control-lg text-center"
                                                                        allowedCharacters="^[0-9]"
                                                                        inputStyle={{
                                                                            width: "50px",
                                                                            height: "42px",
                                                                            padding: "8px",
                                                                            borderRadius: "8px",
                                                                            fontSize: "16px",
                                                                            textAlign: "center",
                                                                            marginRight: "15px",
                                                                            border: "1px solid #ced4da",
                                                                            textTransform: "uppercase",
                                                                        }}
                                                                        onChange={handleChange}
                                                                    />
                                                                    <p style={{ color: "Red" }}>{errors.otpcode ? 'OTP is required' : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mt-3 d-grid">
                                                            <Button color="primary" type="submit" onClick={handleSubmit}>Confirm</Button>
                                                        </div>
                                                    </Form>                                                    
                                                </div>

                                            </div>

                                            <div className="mt-5 text-center">
                                                <p className="text-muted mb-0">Didn't receive an OTP? <Link to="#"
                                                    className="text-primary fw-semibold"> Resend </Link> </p>
                                            </div>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">{new Date().getFullYear()} &#169; CodeCatalysts</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TwoStepVerfication;