import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize  }
    from "@syncfusion/ej2-react-grids";
import { AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective, Aggregate } from '@syncfusion/ej2-react-grids';
import { useHistory } from "react-router-dom";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { getData, postData, downloadFile } from "../../../helpers/url-request";
import * as urls from "../../../helpers/url_helper";
import PayslipDetail from "./payslipdetail";
import { showLoading } from 'react-global-loading';

const Payslips = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [salaryslipList, setSalaryslipList] = useState();
    const [salaryDetail, setSalaryDetail] = useState();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const history = useHistory();

    useEffect(() => {
        showLoading(true);

        getData("/payroll/payslips").then(res => {
            console.log(res);
            if (res.data) {
                setSalaryslipList(res.data);
            }
            showLoading(false);
        });

    }, []);

    const runPayroll = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
        // setErrors({});
    }

    const submitPayroll = event => {
        event.preventDefault();

        history.push("/payroll/create")
    }

    const viewPayroll = (id) => {
        history.push('/payroll/summary/view/' + id);
    }

    const linktemplate = props => {
        return (
            <button onClick={() => viewPayroll(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.payrollType}
            </button>
        )
    }

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    useEffect(() => {
        getData("/payroll/payslips").then(res => {
            console.log(res.data);            
        });
    }, []);

    const downloadSalarySlip = (id) => {
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        fetch(urls.BASE_URL + '/Payroll/downloadpayslip/' + id, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + userdata.token,
                'apikey': 'key123',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {

                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `SalarySlip.pdf`,
                );

                console.log(link);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }


    const updateSalarySlip = () => {
        if (salaryDetail) {
            showLoading(true);
            //downloadSalarySlip(salaryDetail.id);
            var _data = { "payslipId": salaryDetail.id };
            postData("/payroll/payslip/regenerate", _data).then(res => {
                showLoading(false);
                if (res.status) {
                    if (res.status == true) {
                        alert(res.data);
                    } else {
                        alert("Failed : " + res.message);
                    }                    
                }
            });
        }
    }

    const downloadSalarySlipDetail = () => {
        if (salaryDetail) {
            downloadSalarySlip(salaryDetail.id);
        }
    }

    const viewSalarySlip = (id) => {

        showLoading(true);

        getData("/payroll/payslip/" + id).then(res => {
            console.log(res);
            if (res.data) {
                setSalaryDetail(res.data);
                setTimeout(() => {
                    showLoading(false);
                    toggle();
                }, 1000);                
            } else {
                showLoading(false);
            }
        });       
    }
        
    const viewAttendance = props => {
        return (
            <label>{props.presentDays}/{props.noOfDays}</label>
        )
    }

    const viewLink = props => {
        return (
            <button onClick={() => viewSalarySlip(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>View</button>
        )
    }

    const fileLink = props => {
        return (
            <button onClick={() => downloadSalarySlip(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                Download
            </button>
        )
    }

    const footerSum = (props) => {
        return (<span>Sum: {props.Sum}</span>);
    };

    const onRowDoubleClick = (events) => {
        console.log(events.rowData);
        viewSalarySlip(events.rowData.id);
    };

    return (
        <div className="page-content">

            <Modal isOpen={modal} toggle={toggle} fullscreen>                
                <ModalBody>
                    <PayslipDetail data={salaryDetail} />
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button color="primary" className="btn primary" type="button" onClick={updateSalarySlip}>Update Payslip</Button>
                        &nbsp;&nbsp;
                        <Button color="primary" className="btn primary" type="button" onClick={downloadSalarySlipDetail}>Download Payslip</Button>
                        &nbsp;&nbsp;
                        <Button color="secondary" className="btn primary" type="button" onClick={toggle}>Close</Button>
                    </div>
                </ModalFooter>
            </Modal>

            <Container fluid>

                <Breadcrumbs title="HR Management" breadcrumbItem="Payslips" enableAction={false}></Breadcrumbs>

                <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={runPayroll}>
                    <OffcanvasHeader toggle={runPayroll}>
                        <Row>
                            <Col sm='12'>
                                <b>Run Payroll</b>
                            </Col>
                        </Row>
                    </OffcanvasHeader>
                    <hr />

                    <OffcanvasBody>
                        <FormGroup>
                            <Row className="m-2">
                                <Col sm='6'>
                                    <Label for="batch">Select Batch </Label>
                                </Col>
                                <Col sm='12'>
                                    <Input type="select" className='form-select'>
                                        <option>Full Time Batch</option>
                                        <option>Part Time Batch</option>
                                    </Input>
                                </Col>
                            </Row>

                            <Row className="m-2">
                                <Col sm='6'>
                                    <Label for="batch">Pay Period</Label>
                                </Col>
                                <Col sm='12'>
                                    <Input type="select" className='form-select'>
                                        <option>Select Pay Period</option>
                                    </Input>
                                </Col>
                            </Row>

                            <div className="offcanvas-footer">
                                <Button color="primary" onClick={submitPayroll} className="btn btn-sm"
                                    type="submit">Submit</Button>&nbsp;
                                <button onClick={runPayroll} className="btn btn-dark btn-sm">Close</button>
                            </div>

                        </FormGroup>

                    </OffcanvasBody>
                </Offcanvas>

                <CardBody>
                    <GridComponent dataSource={salaryslipList} statelessTemplates={['directiveTemplates']} recordDoubleClick={onRowDoubleClick}
                        toolbar={toolbarOptions} ref={grid => gridInstance = grid} toolbarClick={toolbarClick.bind(this)}
                        allowExcelExport={true} allowFiltering={true} filterSettings={{ type: 'Excel' }} allowResizing={true}
                        allowPaging={true} pageSettings={{ pageCount: 25, pageSizes: true }} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
                        <ColumnsDirective>                            
                            <ColumnDirective field="employeeProfile.fullName" headerText='Employee Name' width='150'></ColumnDirective>
                            <ColumnDirective field="employeeProfile.employeeGrade.name" headerText='Grade' width='150'></ColumnDirective>
                            <ColumnDirective field="employeeProfile.employeeType.name" headerText='Type' width='120'></ColumnDirective>
                            <ColumnDirective field="employeeProfile.workLocation.locationName" headerText='Location' width='150'></ColumnDirective>
                            <ColumnDirective field="payrollDetail.payrollMonth" headerText='Pay Period' width='125'></ColumnDirective>                            
                            <ColumnDirective template={viewAttendance} headerText='Attendance' width='125' />
                            <ColumnDirective field="basic" headerText='Basic' width='125' />
                            <ColumnDirective field="allowances" headerText='Allowances' width='125' />                            
                            <ColumnDirective field="gross" headerText='Gross' width='120' />
                            <ColumnDirective field="deductions" headerText='Deductions' width='125' />
                            <ColumnDirective field="netAmount" headerText='Net Salary' width='125' />
                            <ColumnDirective field="id" template={viewLink} headerText='View' width='100'/>
                            <ColumnDirective field="id" template={fileLink} headerText='Payslip' width='125' />
                        </ColumnsDirective>
                        <AggregatesDirective>
                            <AggregateDirective>
                                <AggregateColumnsDirective>
                                    <AggregateColumnDirective field='basic' type='Sum' />
                                    <AggregateColumnDirective field='da' type='Sum' />
                                </AggregateColumnsDirective>
                            </AggregateDirective>                            
                        </AggregatesDirective>
                        <Inject services={[Page, Toolbar, Filter, Sort, ColumnMenu, ExcelExport, PdfExport, Aggregate, Resize]} />
                    </GridComponent>                   
                </CardBody>
            </Container>
        </div>
    );
};

export default Payslips;
