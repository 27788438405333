import React, { useEffect, useRef, useState } from 'react';

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { enableRipple } from '@syncfusion/ej2-base';

import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Offcanvas,
    OffcanvasBody, OffcanvasHeader, Row, UncontrolledDropdown, Modal, ModalHeader, ModalBody, ModalFooter,
    Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Collapse, NavbarText
} from "reactstrap";

import {
    Container,
} from "reactstrap";

import CountUp from "react-countup";

import {
    ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, FilterType, Inject, Sort, Edit,
    ColumnMenu, CommandColumn, Toolbar,
    ExcelExport, PdfExport, Group, Resize
}
    from "@syncfusion/ej2-react-grids";
import { Link, useHistory } from 'react-router-dom';
import './index.css';
import { getEmployeeList } from '../../../helpers/backend_helper';
import LoadingSpinner from '../../shared/Loading';
import Spinner from '../../shared/spinner';

import PieChart from '../../Dashboard/PieChart';
import EmployeeCount from '../../Dashboard/EmployeeCount';
import AttendanceCount from '../../Dashboard/AttendanceCount';
import GenderCount from '../../Dashboard/GenderCount';
import AttendancePerformance from '../../Dashboard/AttendancePerformance';
import { getLoggedInUser } from "../../../helpers/backend_helper";
import EmployeeDetails from './EmployeeDetails';
import EmployeeView from './EmployeeView';
import AddEmployeeDetails from './AddEmployeeDetails';
import { showLoading } from 'react-global-loading';


enableRipple(true);
const EmployeeDirectoryList = () => {
    let gridInstance;
    const [employeeList, setEmpList] = useState();
    const [employeeId, setEmployeeId] = useState();
    const [modal, setModal] = useState(false);
    const [newmodal, setNewmodal] = useState(false);
    const toggle = () => setModal(!modal);
    const newtoggle = () => setNewmodal(!newmodal);
    const history = useHistory();
    // const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport','Add Employee'];

    const toolbarOptions = [
         'Search',
         { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
         { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
         { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' },
         { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' }
    ];

    useEffect(() => {
        showLoading(true);
        getEmployeeList().then(res => {
            if (res?.data) {
                setEmpList(res.data);
            }else{
                setEmpList([]);
            }
            showLoading(false);
        })
    }, [])

    document.title = "KMVSTDCL | HRMS";

    const loadEmployeeData = () => {
        showLoading(true);
        getEmployeeList().then(res => {          
            if (res?.data) {
                setEmpList(res.data);
            } else {
                setEmpList([]);
            }
            showLoading(false);
        })
    }

    const viewEmployee = (EmpId) => {
        if (getLoggedInUser().roleId == 1 || getLoggedInUser().roleId == 5) {
            setEmployeeId(EmpId);
            toggle();
        }        
        //history.push('/hr/employee/view/' + EmpId);
    }

    const addEmployee = () => {
        history.push('/hr/employee/add');
    }

    const headerLink = props => {
        return (
            <button onClick={() => viewEmployee(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.fullName}
            </button>
        )
    }

    function toolbarClick(args) {
        console.log(args.item);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadEmployeeData();
                break;
        }
    }

    function statusTemplate(props) {

        // console.log(props);
        // console.log(props.status);

        return (<div>{props.status === 1 ?
            <div id="status" className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div id="status" className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }

    const toggleCanvasEnd = () => {
        //history.push('/hr/employee/add');
        newtoggle();
    }

    const onRowDoubleClick = (events) => {
        console.log(events.rowData);
        viewEmployee(events.rowData.id);
    };

    const onModalClose = () => {
        newtoggle();
        loadEmployeeData();
    }

    return (
        <React.Fragment>            
            <div className="page-content" id="pageContainer" name="pageContainer">
                <Modal isOpen={modal} toggle={toggle} fullscreen container='pageContainer'>
                    <ModalHeader toggle={toggle}>Employee Details</ModalHeader>
                    <ModalBody>
                        <EmployeeView employeeId={employeeId} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="btn primary" type="button" onClick={toggle}>Close</Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={newmodal} toggle={newtoggle} fullscreen container='pageContainer'>
                    <ModalHeader toggle={newtoggle}>New Employee Details</ModalHeader>
                    <ModalBody>
                        <EmployeeDetails onClose={onModalClose} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="btn primary" type="button" onClick={newtoggle}>Close</Button>
                    </ModalFooter>
                </Modal>

                <Container fluid>
                    

                <Breadcrumbs title="Employees" breadcrumbItem="Employees" enableAction={getLoggedInUser().roleId != 1 ? false : true } actionName="Add Employee" onClick={toggleCanvasEnd} />

                    <div className='mt-2'>
                    {employeeList &&
                            <GridComponent dataSource={employeeList} height="100%" toolbar={toolbarOptions} ref={grid => gridInstance = grid}
                                allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} recordDoubleClick={onRowDoubleClick}
                                allowFiltering={true} allowPaging={true} showColumnMenu={true} statelessTemplates={['directiveTemplates']}
                                pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }} allowResizing={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='fullName' headerText='Fullname' template={headerLink} width='150'></ColumnDirective>
                                <ColumnDirective field='employeeType.name' headerText='Type' width='150' />
                                <ColumnDirective field='workTitle' headerText='Title' width='150' />
                                <ColumnDirective field='employeeGrade.name' headerText='Grade' width='150' />                                                                    
                                <ColumnDirective field='location.locationName' headerText='Posting Location' width='150' />
                                <ColumnDirective field='workLocation.locationName' headerText='Work Location' width='150' />
                                <ColumnDirective field='workDepartment.name' headerText='Department' width='150' />
                                <ColumnDirective field='phone' headerText='Phone' width='150'></ColumnDirective>
                                <ColumnDirective field='employeeStatus.name' headerText='Status' width='150' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
                        </GridComponent>}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmployeeDirectoryList;