// import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject } from "@syncfusion/ej2-react-grids";
import {
    Button, Card, CardHeader, CardTitle, Col, Container, Offcanvas,
    OffcanvasBody, OffcanvasHeader, Row
} from "reactstrap";
import { React, useState, useEffect } from "react";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { createEmployeeType, getHolidayPolicies, getLoggedInUser } from "../../../../helpers/backend_helper";
import AddHoliday from "./add-holiday";
import AddHolidayPolicy from "./add-holiday-policy";

const SettingsHolidayPolicy = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [canvasPlacement1, setCanvasPlacement1] = useState('start')
    const [canvasOpen1, setCanvasOpen1] = useState(false);

    const [holidayList, setHolidayList] = useState();

    const loadData = () => {
        getHolidayPolicies().then(res => {
            if (res.data) {
                setHolidayList(res.data[0].holidayPolicyDetails)
            }
        });
    }

    useEffect(() => {
        loadData();
    }, [])

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const toggleCanvasStart = () => {        
        setCanvasPlacement1('end')
        setCanvasOpen1(!canvasOpen1)
    }

    const CellRender = (props) => {
        console.log(props);
        return (
            <button onClick={() => toggleCanvasStart() } className="btn btn-link btn-sm waves-effect waves-light">{props.name}</button>
        );
    };

    const onClose = () => {
        toggleCanvasStart();
        loadData();
    }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const statusTemplate = (props) => {

        return (<div>{props.status === 1 ?
            <div id="status" className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div id="status" className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }

    return (
        <div id='dashboard-analytics'>
            <div className="m-2">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Holiday List" enableAction={getLoggedInUser().roleId != 1 ? false : true} actionName="Add Holiday" onClick={toggleCanvasStart}></Breadcrumbs>
                    <Row className='match-height mt-2'>
                        <Col xs='12'>
                            <Card className='overflow-hidden'>

                                <div className='react-dataTable'>
                                    <GridComponent dataSource={holidayList} allowPaging={true} pageSettings={{ pageCount: 25, pageSizes: true }} allowResizing={true}
                                        toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid} allowSorting={true}
                                        statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true}>
                                        <ColumnsDirective>
                                            <ColumnDirective field="holidayName" headerText='Name' width='150' />
                                            <ColumnDirective field="holidayDate" type="date" format="dd-MM-yyyy" headerText='Date' width='150' />
                                            <ColumnDirective field="status" headerText='Status' template={statusTemplate} width='150' />
                                            <ColumnDirective field="updatedOn" type="date" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='150' />
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize]} />
                                    </GridComponent>
                                </div>

                            </Card>
                        </Col>
                    </Row>

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement1} isOpen={canvasOpen1}
                            toggle={toggleCanvasStart}>
                            <OffcanvasHeader toggle={toggleCanvasStart}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Holiday Detail</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />

                            <OffcanvasBody>
                                <AddHoliday onClose={onClose} />
                            </OffcanvasBody>
                        </Offcanvas>

                        <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen}
                            toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Holiday Policy</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />

                            <OffcanvasBody>
                                <AddHolidayPolicy />
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </div>
    )

}

export default SettingsHolidayPolicy;