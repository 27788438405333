import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";

import './styles.css'

const ComplianceESI = () => {

    return (
        <React.Fragment>

            <CardBody>
                <h4>Employees' State Insurance(ESI):</h4>
                <br />
                <Row>
                    <Col sm='12' lg='12' md='12'>
                    Employees’ State Insurance Act, 1948 (ESI Act) is a social security legislation aimed at providing benefits to employees in case of sickness, maternity, employment injury and certain other related matters. Under this self-financing health insurance scheme, funds are primarily built out of contribution from employers and employees. 
                    ESI fund, maintained by ESIC, is applicable to employees earning ₹21,000 or less per month to 
                    provide the cash and medical benefits to them and their families. This fund is a contributory fund in which both the employer and employee contribute 3.25% and 0.75% respectively to make it a total of 4%.
                    </Col>
                </Row>
                <br />

                For ESI calculation, the salary comprises of all the monthly payable amounts such as basic pay, dearness allowance, city compensatory allowance, HRA, incentive allowance and meal allowance. The salary, however, does not include the annual bonus, retrenchment compensation, encashment of leave and gratuity.
                <br />
                <ul>
                    <li>Employee Contribution: 0.75 %</li>
                    <li>Employer Contribution: 3.25 %</li>                    
                </ul>            
                               
                
                Employees’ State Insurance Scheme will be calculated for ₹21,000 of the gross salary(basic and ESI Applicable Allowances). If Gross is above ₹21,000 ESI will be constant.


            </CardBody>

        </React.Fragment >
    );
};

export default ComplianceESI;
