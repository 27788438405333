import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort }
    from "@syncfusion/ej2-react-grids";


import SalaryPaymentDetail from "./SalaryPaymentDetail";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../../../helpers/url-request";
import { getCompanyPayperiods } from "../../../helpers/backend_helper";
import { showLoading } from 'react-global-loading';
import * as url from '../../../helpers/url_helper';

const SalaryPaymentList = () => {
  let gridInstance;
  const [canvasPlacement, setCanvasPlacement] = useState('start')
  const [canvasOpen, setCanvasOpen] = useState(false);  
  const [payrollList, setPayrollList] = useState();
  const [payrollId, setPayrollId] = useState();
  const [payPeriods, setPayPeriods] = useState();
  const [payPeriodId, setPayPeriodId] = useState();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [detailModal, setDetailModal] = useState(false);
  const detailToggle = () => setDetailModal(!detailModal);

  const history = useHistory();

  useEffect(() => {

      getPayrollSummary();

      getCompanyPayperiods().then(res => {
          if (res.data) {
              let emps = [{ "id": 0, "payPeriodTitle": "Select Period" }];
              res.data.forEach(el => {
                  emps.push(el)
              });
              setPayPeriods(emps)
          }
      });

  }, []);

    const getPayrollSummary = () => {
        showLoading(true);
        getData("/payment/salarypayments").then(res => {
            console.log(res);
            if (res.data) {
                setPayrollList(res.data);
            }

            showLoading(false);
        });
  }

  const runPayroll = () => {
    setCanvasPlacement('end')
    setCanvasOpen(!canvasOpen)
    // setErrors({});
  }

  const handleChange = event => {
    setPayPeriodId(event.target.value);
  };

  const submitPayroll = event => {
      event.preventDefault();
      //setPayPeriodId(1);
      if (payPeriodId && payPeriodId > 0) {
          setCanvasOpen(false);
          toggle();
      } else {
          alert("Please select Period to Run Payroll");
      }
  }

  const viewPayroll = (id, status) => {
      //if (status == 'Completed') {
      //    setPayrollId(id);
      //    detailToggle();
      //} else {
      //    alert("Payroll is still processing");
      //}    
  }

  const linktemplate = props => {
    return (
                <button onClick={() => viewPayroll(props.id, props.payrollStatus)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                    P-{props.id}
                </button>         
        )
  }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }        
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                getPayrollSummary();
                break;
        }
    }

    const docsLink = props => {
        console.log("Inside docsLink");
        console.log(props.salaryPaymentDocuments);

        const links = [];

        props.salaryPaymentDocuments ? props.salaryPaymentDocuments.map((doc) => {
            links.push(
                <button onClick={() => viewDocument(doc.id, doc.fileName)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                    {doc.fileName}
                </button>);
            links.push(<br></br>);
        }) : links.push(<label>None</label>)

        return (
            <div>{links}</div>
        )
    }


    const viewDocument = (docId, docName) => {
        console.log("view document: " + docId);
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        fetch(url.BASE_URL + '/payment/downloadpaymentdocument/' + docId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + userdata.token,
                'apikey': 'key123',
            },
        }).then((response) => response.blob())
            .then((blob) => {
                console.log(blob);
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download', docName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

  return (    
      <div className="page-content">

          <Modal isOpen={modal} toggle={toggle} fullscreen>
              <ModalHeader toggle={toggle}>Salary Payment Detail</ModalHeader>
              <ModalBody>
                  <SalaryPaymentDetail payPeriodId={payPeriodId} />
              </ModalBody>
              <ModalFooter>
                  <Button color="secondary" className="btn primary" type="button" onClick={toggle}>Close</Button>
              </ModalFooter>
          </Modal>

        <Container fluid>

          <Breadcrumbs title="HR Management" breadcrumbItem="Salary Payment" enableAction={true} actionName="New Payment" onClick={toggle}></Breadcrumbs>

          <CardBody>
             <GridComponent dataSource={payrollList} statelessTemplates={['directiveTemplates']}
                      toolbar={toolbarOptions} ref={grid => gridInstance = grid} toolbarClick={toolbarClick.bind(this)}
                      allowPaging={true} pageSettings={{ pageCount: 5, pageSizes: true }} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
              <ColumnsDirective>
                    <ColumnDirective field="payrollMonth" template={linktemplate} headerText='Payment Id' width='100'></ColumnDirective>
                    <ColumnDirective field="paymentDate" headerText='Payment Date' type="date" format="dd-MM-yyyy"  width='150' />
                    <ColumnDirective field="amount" headerText='Amount' width='150' />
                    <ColumnDirective field="paymentRef" headerText='Reference' width='150' />
                    <ColumnDirective field="notes" headerText='Notes' width='150' />
                    <ColumnDirective template={docsLink} headerText="Documents" allowTextWrap={true} width="150"></ColumnDirective>
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Sort, ColumnMenu]} />
            </GridComponent>
          </CardBody>
        </Container>
      </div>
  );
};

export default SalaryPaymentList;
