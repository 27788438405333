import React from 'react';

import ReactApexChart from "react-apexcharts"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import CountUp from "react-countup";

// import Locations from './Locations';
import RecentLeave from './RecentLeave';
import RecentExpense from './RecentExpense';
import RecentTask from './RecentTask';
import EmployeeChart from './EmployeeChart';
import PieChart from './PieChart';
import EmployeeCount from './EmployeeCount';
import AttendanceCount from './AttendanceCount';
import GenderCount from './GenderCount';
import EmployeeTypeCount from './EmployeeTypeCount';
import EmployeeGroupCount from './EmployeeGroupCount';
import AttendancePerformance from './AttendancePerformance';
import AttendanceChart from './AttendanceChart';
import AttendanceGrid from './AttendanceGrid';


const AttendanceDashboard = () => {

    //meta title
    document.title = "Attendance Dashboard";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Attendance Dashboard" />                    
                    <Row>
                        <Col>
                            <AttendanceChart />
                        </Col>
                    </Row>                   
                    <Row>
                        <AttendanceGrid />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default AttendanceDashboard;