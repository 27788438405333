import React, { Component, useState, useEffect } from "react"
import ReactEcharts from "echarts-for-react"
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";
import { getData } from "../../helpers/url-request"

const EmployeeTypeCount = () => {

    const [chartData, setChartData] = useState(0);

    useEffect(() => {

        getData("/Employee/typecount/0").then(response => {
            console.log(response.data);
            if (response.data) {
                setChartData(response.data);
            }
        });
    }, []);

    const getOption = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            legend: {
                orient: "horizontal",
                right: "top",
                data: ["Permanent", "Deputation", "Others"],
                textStyle: {
                    color: ["#858d98"],
                },
            },
            color: ['#2ab57d', '#ffbf53', '#5156be'],
            series: [
                {
                    name: "Employees",
                    type: "pie",
                    radius: ["40%", "70%"],
                    center: ["50%", "60%"],
                    data: [
                        { value: chartData?.Permanent, name: "Permanent" },
                        { value: chartData?.Deputation, name: "Deputation" },
                        { value: chartData?.Others, name: "Others" }
                    ],
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                },
            ],
        }
    }


    return (
        <React.Fragment>
            <Card className="card-h-100">
                <CardBody>
                    <Row className="align-items-center">                            
                        <Col xs={12}>
                            <ReactEcharts option={getOption()} />
                            Employee Type Count
                        </Col>
                    </Row>
                </CardBody>
            </Card>                
        </React.Fragment>
    )
    
}
export default EmployeeTypeCount
