import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, } from "reactstrap";
import { addEmployeeEducation, getEmployeeEducation } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import Spinner from "../../shared/spinner";

const EmployeeEducation = (props) => {
  const [canvasPlacement, setCanvasPlacement] = useState("start");
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [educationData, setEducationData] = useState({
    employeeId: 0, name: "", university: "", city: "", year: ""
  });
  const [educationList, setEducationList] = useState();

  const [submitted, setFormSubmit] = useState(false);
  const [dataSuccess, setDataSuccess] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const params = useParams();

  useEffect(() => {
      setEducationData({ ...educationData, 'employeeId': props.employeeId });

      getEmployeeEducation(props.employeeId).then(res => {
      console.log(res.data);
      if (res.data) {
        setEducationList(res.data)
      }
    });

  }, [submitted]);

  const handleChange = event => {
    setEducationData({ ...educationData, [event.target.name]: event.target.value });
  };


  const submitEducationDetails = (event) => {
    event.preventDefault();
    let formErrors = {};
    if (!educationData.name) {
      formErrors.name = 'Name is required';
    }
    if (!educationData.university) {
      formErrors.university = 'University is required';
    }
    if (!educationData.city) {
      formErrors.city = 'City is required';
    }
    if (!educationData.year) {
      formErrors.year = 'Year is required';
    }

    setErrors(formErrors);
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0) {
      setIsLoading(true);

      console.log(educationData);
      addEmployeeEducation(educationData).then(res => {
        if (res.status === true) {
          console.log("Inside success");
          setDataSuccess("added");
          setIsLoading(false);
          setEducationData({
            name: "", university: "", city: "", year: ""
          });
        } else {
          console.log("Inside failure");
          setDataSuccess("failed");
          setIsLoading(false);
        }
        setFormSubmit(!submitted);
        timeout();
      }).catch((err) => {
        console.log(err);
        setDataSuccess("failed");
        setIsLoading(false);
        timeout();
      })
    }

  }

  const timeout = () => {
    setTimeout(() => {
      setDataSuccess('')
    }, 3000);
  }

  const addEducation = () => {
    setCanvasPlacement("end");
    setCanvasOpen(!canvasOpen);
  };

  return (
    <>
        <Row className="m-2">
            <Col xl="9" className="mt-2" md="9" sm="9">
                  <CardTitle tag="h4">Education Information</CardTitle>
            </Col>
            <Col xl="3" md="3" sm="4">
                  <button type="button" onClick={addEducation} className="btn btn-success waves-effect waves-light">
                    <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                    Add
                </button>
                &nbsp;&nbsp;&nbsp;
                <button type="button" className="btn btn-warning waves-effect waves-light">
                    <i className="bx bx-revision font-size-16 align-middle me-2"></i>{" "}
                    Refresh
                </button>
            </Col>
        </Row>

      <CardBody>
              
        {!educationList ?
          <div className='text-center mt-4'>
            <Spinner />
          </div>
          :
          <GridComponent dataSource={educationList} allowPaging={true} pageSettings={{ pageCount: 5 }} statelessTemplates={['directiveTemplates']}>
            <ColumnsDirective>
              {/* <ColumnDirective headerText="Education Level" width="150"></ColumnDirective> */}
              <ColumnDirective field="name" headerText="name" width="150" />
              <ColumnDirective field="university" headerText="University/School" width="150" />
              <ColumnDirective field="city" headerText="City" width="150"></ColumnDirective>
              <ColumnDirective field="year" headerText="Year" width="150" />
            </ColumnsDirective>
            <Inject services={[Toolbar, Page]} />
          </GridComponent>
        }
      </CardBody>

      <Offcanvas backdrop="static" className="w-30" direction={canvasPlacement} isOpen={canvasOpen}
        toggle={addEducation}>
        <OffcanvasHeader toggle={addEducation}>
          <Row>
            <Col sm="12">
              <b>Education Details</b>
            </Col>
          </Row>
        </OffcanvasHeader>
        <hr />

        <OffcanvasBody>
          <FormGroup>
            <Row>
              <Col xl="12" md="12" sm="12">
                <Label className="form-label" for="InputHelp">
                  Name
                </Label>
                <Input type="text" className="form-control" name="name"
                  value={educationData.name} onChange={handleChange} placeholder="Enter Name"></Input>
                <p style={{ color: "Red" }}>{errors.name && <span>{errors.name}</span>}</p>
              </Col>
            </Row>

            <Row>
              <Col xl="12" md="12" sm="12">
                <Label className="form-label" for="InputHelp">
                  University/School
                </Label>
                <Input type="text" className="form-control" name="university"
                  value={educationData.university} onChange={handleChange} placeholder="Enter University Name"></Input>
                <p style={{ color: "Red" }}>{errors.university && <span>{errors.university}</span>}</p>
              </Col>

              <Col xl="12" md="12" sm="12">
                <Label className="form-label" for="InputHelp">
                  City
                </Label>
                <Input type="text" className="form-control" name="city"
                  value={educationData.city} onChange={handleChange} placeholder="Enter city"></Input>
                <p style={{ color: "Red" }}>{errors.city && <span>{errors.city}</span>}</p>
              </Col>

              <Col xl="12" md="12" sm="12">
                <Label className="form-label" for="InputHelp">
                  Year of Passing
                </Label>
                <Input type="text" className="form-control" name="year"
                  value={educationData.year} onChange={handleChange} placeholder="Enter Passing  Year"></Input>
                <p style={{ color: "Red" }}>{errors.year && <span>{errors.year}</span>}</p>
              </Col>
            </Row>
          </FormGroup>

          <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
            {dataSuccess === 'added' && <SuccessAlert />}
            {dataSuccess === 'failed' && <FailureAlert />}
          </div>

          <div className="offcanvas-footer">
            {isLoading ? <LoadingSpinner /> : <Button color="primary"
              onClick={submitEducationDetails} className="btn btn-sm" disabled={isLoading}
              type="submit">Submit</Button>}&nbsp;
            <button onClick={addEducation} className="btn btn-dark btn-sm">
              Close
            </button>
          </div>
        </OffcanvasBody>
      </Offcanvas>            
    </>
  );
};

export default EmployeeEducation;
