import moment from 'moment'
import * as urls from "./url_helper"

let userdata = localStorage.getItem("authUser");
userdata = JSON.parse(userdata);

const headers = new Headers();
headers.append('Content-Type', 'application/json');
if (userdata) {
    headers.append('Authorization', "Bearer " + `${userdata.token}`);
}
headers.append('ApiKey', 'key123');
const methods = ["POST", "PUT"]

export const setDefaultHeaders = (val) => {
    headers.delete("Authorization")
    if (!userdata) {
        headers.append("Authorization", "Bearer " + val.token);
    }
}

export const downloadFile = (_url, method = "GET", body = "") => {
    const url = urls.BASE_URL + _url;
    const _headers = new Headers();        
    if (userdata) {
        _headers.append('Authorization', "Bearer " + `${userdata.token}`);
    }
    _headers.append('ApiKey', 'key123');

    const params = {
        method,
        headers: _headers,
    }

    const request = new Request(url, params);
    return fetch(request).then((response) => {
        const { status, headers } = response;
        if (status === 204 || headers.get("Content-Length") === 0) {
            return {}
        }
        // response = JSON.parse(JSON.stringify(response.json()));
        return response.json();
    });

}

const request = (_url, method = "GET", body = "") => {
    const url = urls.BASE_URL + _url;

    const params = {
        method,
        headers: headers,
    }

    if (methods.includes(method)) {
        Date.prototype.toJSON = function () { return moment(this).format(); }
        params.body = typeof body != "string" ? JSON.stringify(body) : body
    }

    const request = new Request(url, params);
    return fetch(request).then((response) => {
        const { status, headers } = response;
        if (status === 204 || headers.get("Content-Length") === 0) {
            return {}
        }
        // response = JSON.parse(JSON.stringify(response.json()));
        return response.json();
    });
}

export const postRawData = (_url, method, body) => {
    const url = urls.BASE_URL + _url;

    const _headers = new Headers();
    if (userdata) {
        _headers.append('Authorization', "Bearer " + `${userdata.token}`);
    }
    _headers.append('ApiKey', 'key123');

    const params = {
        method,
        headers: _headers,
    }

    params.body = body;

    const request = new Request(url, params);
    return fetch(request).then((response) => {
        const { status, headers } = response;
        if (status === 204 || headers.get("Content-Length") === 0) {
            return {}
        }

        return response.json();
    });
}


export const getData = ((url) => request(url, "GET"));
export const postData = (url, data) => request(url, "POST", data);
export const putData = (url, data) => request(url, "PUT", data);
export const deleteData = (url) => request(url, "DELETE");

export default {
    get: getData,
    post: postData,
    put: putData,
    delete: deleteData
}

