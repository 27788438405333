import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";

import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort } from "@syncfusion/ej2-react-grids";
import { getEmployeeLeaveRequest } from "../../helpers/backend_helper";

const TaskSearch = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [leaveRequestList, setLeaveRequestList] = useState();

    useEffect(() => {
        getEmployeeLeaveRequest({}).then((res) => {
            if (res.data) {
                setLeaveRequestList(res.data);
            }
        })
    }, [])

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.text) {
            case 'PDF Export':
                gridInstance.pdfExport();
                break;
            case 'Excel Export':
                gridInstance.excelExport();
                break;
            case 'CSV Export':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Task" breadcrumbItem="Task Search" enableAction={false}></Breadcrumbs>

                    <div className='mt-2'>
                        <GridComponent dataSource={leaveRequestList} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5 }}
                            allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective field="employeeProfile.fullName" headerText='Employee' width='150'></ColumnDirective>
                                <ColumnDirective field="leaveType.name" headerText='Leave Type' width='150'></ColumnDirective>
                                <ColumnDirective field="fromDate" type="datetime" format="dd/MM/yyyy" headerText='From Date' width='150' />
                                <ColumnDirective field="toDate" type="datetime" format="dd/MM/yyyy" headerText='To Date' width='150' />
                                <ColumnDirective field="leavePeriod" headerText='Leave Period' width='150' />
                                <ColumnDirective field="leaveStatus" headerText='Leave Status' width='150' />
                                <ColumnDirective headerText='Action' width='150'></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                        </GridComponent>
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

export default TaskSearch;