// import Select from "react-select/dist/declarations/src/Select";
import { Button, Col, Input, Label, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import Select from 'react-select'
import React, { useEffect, useState } from "react";
import { addEmployeeLeaveRequest, getEmployeeList, getLeaveTypes, getLocalStorage, getLeaveDetails, getEmployeeLeaveDetails } from "../../../helpers/backend_helper";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Filter, ExcelExport, PdfExport, Resize }
    from "@syncfusion/ej2-react-grids";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from "classnames";
import TaskDetailView from "../../Tasks/MyTask/TaskDetailView";
import { ToolbarOptions } from "../../../components/Common/Toolbar";
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const RequestDetail = (props) => {
    let gridInstance;
    const [activeTab, setactiveTab] = useState(1);
    const [leaveValue, setLeaveValue] = useState('');
    const [submitted, setFormSubmit] = useState(false);
    const [leaveDetails, setLeaveDetails] = useState();
    const [leaveRequest, setLeaveRequest] = useState();
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [taskId, setTaskId] = useState();
    const [formData, setFormData] = useState({
        employeeId: 0, employeeName: '', leaveId: '', leaveTypeId: 0, leaveType: '', leavePeriod: '',
        leavePeriodType: '', fromDate: '', toDate: '', leaveDays: '', description: '', leaveStatus: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {

        getData("/loan/loanrequestdetail/"+props.loanId).then((res) => {
            console.log(res);
            if (res.status == true) {

                setFormData({
                    employeeId: res.data.employeeId,
                    employeeName: res.data.employeeProfile.fullName,
                    leaveId: res.data.leaveId,
                    leavePeriod: res.data.leavePeriod,
                    leavePeriodType: res.data.leavePeriodType.name,
                    leaveTypeId: res.data.leaveTypeId,
                    leaveType: res.data.leaveType.name,
                    fromDate: moment(res.data.fromDate).format('DD/MM/YYYY'),
                    toDate: moment(res.data.toDate).format('DD/MM/YYYY'),
                    leaveDays: res.data.leaveDays,
                    leaveHours: res.data.leaveHours,
                    description: res.data.description,
                    leaveStatus: res.data.leaveStatus
                });
                setLeaveRequest(res.data);
                setLeaveValue(res.data.leavePeriod);
                setTaskId(res.data.taskId);
            } else {
                alert(res.message);
            }
        });

        getEmployeeLeaveDetails(props.employeeId).then((res) => {
            console.log(res);
            if (res.data) {
                setLeaveDetails(res.data.leaves);
            }
        });

    }, [submitted]);

    const confirmHandler = (event) => {
        event.preventDefault();
        let formErrors = {};
        if (!formData.leaveTypeId) {
            formErrors.leaveTypeId = 'Leave Type is required';
        }
        if (!formData.leavePeriod) {
            formErrors.leavePeriod = 'Leave Period is required';
        }
        if (formData.leavePeriod === "1") {
            if (!formData.leaveDays) {
                formErrors.leaveDays = 'Leave Days is required';
            }
        } else if (formData.leavePeriod === "3") {
            if (!formData.toDate) {
                formErrors.toDate = 'To Date is required';
            }
        }
        if (!formData.fromDate) {
            formErrors.fromDate = 'From Date is required';
        }


        // if (!formData.description) {
        //     formErrors.description = 'Description is required';
        // }

        setErrors(formErrors);

        console.log(formData);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            addEmployeeLeaveRequest(formData).then((res) => {
                // console.log(res);
                if (res.status === true) {
                    console.log("Inside success");                    
                    setDataSuccess("added");
                    setIsLoading(false);
                    setFormData({
                        leavePeriod: '', fromDate: '', toDate: '', leaveDays: '', description: ''
                    });
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })

        }
    }

    const handleChange = (event) => {        
        //setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        href="#"
                        className={classnames({ active: activeTab === 1 })}
                        onClick={() => {
                            setactiveTab(1);
                        }}>
                        <span>Request Details</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        href="#"
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                            setactiveTab(2);
                        }}>
                        <span>Approval</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent
                className="twitter-bs-wizard-tab-content"
                activeTab={activeTab}>
                <TabPane tabId={1}>
                    <div style={{ paddingTop:'10px' }}>
                        <Row>
                            <Col sm='6'>
                                <form onSubmit={confirmHandler}>
                                    <Row>
                                        <Col className="mb-1" xl="4" md="4" sm="12">
                                            Employee
                                        </Col>
                                        <Col className='mb-1' xl='8' md='8' sm='12'>
                                            {formData.employeeName}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-1" xl="4" md="4" sm="12">
                                            Location
                                        </Col>
                                        <Col className='mb-1' xl='8' md='8' sm='12'>
                                            {leaveRequest?.location.locationName}
                                        </Col>                                        
                                    </Row>
                                    <Row>
                                        <Col className='mb-1' xl='4' md='4' sm='12'>
                                            <span>Leave Type</span>
                                        </Col>
                                        <Col className='mb-1' xl='8' md='8' sm='12'>
                                            {formData.leaveType}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='mb-1' xl='4' md='4' sm='12'>
                                            <span>Leave Period</span>
                                        </Col>
                                        <Col className='mb-1' xl='8' md='8' sm='12'>
                                            {formData.leavePeriodType}
                                        </Col>
                                    </Row>

                                {formData.leavePeriod == 1 &&
                                    <Row>
                                        <Col className='mb-1' xl='4' md='4' sm='12'>
                                            <span>Date</span>
                                        </Col>
                                        <Col className='mb-1' xl='8' md='8' sm='12'>
                                            {formData.fromDate}
                                        </Col>

                                        <Col className='mb-1' xl='4' md='4' sm='12'>
                                            <span>No. of Hours</span>
                                        </Col>
                                        <Col className='mb-1' xl='8' md='8' sm='12'>
                                            {formData.leaveHours}
                                        </Col>
                                    </Row>
                                }

                                {formData.leavePeriod >= 2 &&
                                    <Row>
                                        <Col className='mb-1' xl='4' md='4' sm='12'>
                                            <span>From Date</span>
                                        </Col>
                                        <Col className='mb-1' xl='8' md='8' sm='12'>
                                         { formData.fromDate }
                                        </Col>

                                        <Col className='mb-1' xl='4' md='4' sm='12'>
                                            <span>To Date</span>
                                        </Col>
                                        <Col className='mb-1' xl='8' md='8' sm='12'>
                                            {formData.toDate}
                                        </Col>
                                        <Col className='mb-1' xl='4' md='4' sm='12'>
                                            <span>No. of Days</span>
                                        </Col>
                                        <Col className='mb-1' xl='8' md='8' sm='12'>
                                            {formData.leaveDays}
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col className='mb-1' xl='4' md='4' sm='12'>
                                        <span>Description</span>
                                    </Col>
                                    <Col className='mb-1' xl='8' md='8' sm='12'>
                                        <Input type='textarea' name="description" readOnly id='input-default' placeholder='Description' value={formData.description} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-1' xl='4' md='4' sm='12'>
                                        <span>Leave Status</span>
                                    </Col>
                                    <Col className='mb-1' xl='8' md='8' sm='12'>
                                        {formData.leaveStatus}
                                    </Col>
                                </Row>
                                <div className="text-center mt-2">
                                    {dataSuccess === 'added' && <SuccessAlert />}
                                    {dataSuccess === 'failed' && <FailureAlert />}
                                </div>
                                </form>
                            </Col>
                            <Col sm='6'>
                                Available Leaves
                                <hr />
                                <GridComponent dataSource={leaveDetails} ref={grid => gridInstance = grid} allowResizing={true}
                                    statelessTemplates={['directiveTemplates']} pageSettings={{ pageCount: 25, pageSizes: true }}
                                    allowSorting={true} allowPaging={true}>
                                    <ColumnsDirective>
                                        <ColumnDirective field="key" headerText='Leave Type' width='100'></ColumnDirective>
                                        <ColumnDirective field="value" headerText='Balance' width='100'></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Page, Sort, Resize]} />
                                </GridComponent>
                            </Col>
                        </Row>
                    </div>
                </TabPane>
                <TabPane tabId={2}>
                    {taskId > 0 ? <TaskDetailView taskId={taskId} /> : ""}
                </TabPane>
        </TabContent>
        </>
    )
}

export default RequestDetail;