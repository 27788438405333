import React, { useEffect, useState } from 'react';
import {
    Card, CardBody, CardHeader, CardTitle, Col, Row, FormGroup, Label, Input, Button, FormFeedback, Form, Nav, NavItem, TabContent, TabPane, NavLink
} from 'reactstrap';
import { getEmployeeList, getTaskDetail, getTaskTypeList, getTaskActivityList, getTaskComments, getTaskDocuments, getTaskApprovers, post } from "../../../helpers/backend_helper";
import Select from "react-select"
import classnames from "classnames";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Toolbar, Page, ColumnMenu, Sort } from "@syncfusion/ej2-react-grids";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { FailureAlert, SuccessAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/Loading';
import * as url from '../../../helpers/url_helper';
import { postData } from '../../../helpers/url-request';
import PropTypes from 'prop-types'
import { showLoading } from 'react-global-loading';

const TaskDetailView = (props) => {
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')
    const [expenseTypes, setExpenseTypes] = useState();
    const [employees, setEmployees] = useState();
    const [attachments, setAttachments] = useState();
    const [taskDetail, setTaskDetail] = useState();
    const [taskComments, setTaskComments] = useState([]);
    const [taskDocuments, setTaskDocuments] = useState([]);
    const [taskActivities, setTaskActivities] = useState([]);
    const [approveDisabled, setApproveDisabled] = useState(false);

    const [submitted, setSubmitted] = useState(false);
    const [pending, setPending] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("Choose File");
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);

    const [taskData, setTaskData] = useState({ assignedTo: 0, userComment: "" });

    const loadTakDetail = () => {

        showLoading(true);

        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        getTaskActivityList(props.taskId).then((res) => {
            console.log(res);
            if (res.status === true) {
                setTaskActivities(res.data);
            }
        });

        getTaskDetail(props.taskId).then((res) => {
            console.log(res);
            if (res.status === true) {
                setTaskDetail(res.data);
                setTaskData({
                    assignedTo: res.data.assignedTo
                });

                if (res.data.taskStatusId == 1) {
                    if (res.data.roleId == userdata.roleId) {
                        setSubmitted(false);
                    } else {
                        setSubmitted(true);
                    }
                } else {
                    setSubmitted(true);
                    if (res.data.taskStatusId == 3 || res.data.taskStatusId == 9) {
                        setPending(true);
                    } else {
                        setPending(false);
                    }
                }

                if (res.data.taskStatusId == 6) {
                    setApproveDisabled(true);
                } else {
                    if (res.data.roleId == userdata.roleId) {
                        taskActivities.map((activity) => {
                            if (activity.roleId == userdata.roleId) {
                                if (activity.taskActivity != 1) {
                                    if (activity.taskStatusId != 6) {
                                        setApproveDisabled(false);
                                        return;
                                    } else {
                                        setApproveDisabled(true);
                                        return;
                                    }
                                }
                            } else {
                                setApproveDisabled(true);
                            }
                        });
                    } else {
                        setApproveDisabled(true);
                    }
                }
            }

            showLoading(false);
        });
    }

    useEffect(() => {

        console.log(props.taskId);

        loadTakDetail();

        refreshComments(props.taskId);

        refreshDocuments(props.taskId);

        getTaskTypeList().then((res) => {
            if (res.status === true) {
                let expenseTypes = [{ id: 0, name: "Select Task Type" }]
                res.data.forEach(el => {
                    expenseTypes.push(el)
                });
                setExpenseTypes(expenseTypes);
            }
        })

        getTaskApprovers(props.taskId).then((res) => {
            console.log(res);
            if (res.status === true) {
                let employeesList = [{ id: 0, fullName: "Select User", roleName: "" }]
                res.data.forEach(el => {
                    console.log(el);
                    console.log(el.role);
                    employeesList.push({
                        id: el.id, fullName: el.fullName, roleName: el.role.name
                    });
                });
                setEmployees(employeesList);
            }
        });


    }, []);

    const refreshComments = (taskId) => {
        getTaskComments(taskId).then((res) => {
            console.log(res);
            if (res.status === true) {
                setTaskComments(res.data);
                console.log(taskComments);
            }
        });
    }

    const refreshDocuments = (taskId) => {
        getTaskDocuments(taskId).then((res) => {
            console.log(res);
            if (res.status === true) {
                setTaskDocuments(res.data);
            }
        });
    }

    const handleAcceptedFiles = (files) => {
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setSelectedFiles(files);
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const handleChange = event => {
        //console.log(event.target.files[0], event.target.files[0].name);
        //setFile(event.target.files[0]);
        //setFileName(event.target.files[0].name);
        setTaskData({ ...taskData, [event.target.name]: event.target.value });
    };

    const handlePost = event => {

        if (!taskData.userComment) {
            alert("Please enter comments");
            return;
        }

        var data = {
            taskId: props.taskId,
            comments: taskData.userComment
        }

        postData('/task/taskdetail/comment', data)
            .then(response => {
                console.log({ response });
                if (response.status == true) {
                    setTaskData({ ...taskData, ["userComment"]: "" });
                    //taskData.userComment = "";
                    refreshComments(props.taskId);
                    return response;
                }
                else {
                    alert(response.message);
                }
            })
            .catch(err => {
                console.log({ err });
            });
    }

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    }

    // Form validation 
    const fileValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        //enableReinitialize: true,
        initialValues: {
            Description: '',
            Documents: ''
        },
        onChange: (values, actions) => {
            console.log("On Change");
        },
        onSubmit: async (values) => {

            const formData = new FormData();
            formData.append("taskId", props.taskId);
            formData.append("description", values.Description);

            if (selectedFiles.length > 0) {
                selectedFiles.forEach((file, index) => {
                    formData.append("documents", file);
                });
            } else {
                formData.append("documents", []);
            }

            let userdata = localStorage.getItem("authUser");
            userdata = JSON.parse(userdata);

            var token = userdata.token;
            let requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    apiKey: 'key123',
                },
                body: formData,
            };

            fetch(url.BASE_URL + '/task/addtaskdocument', requestOptions)
                .then(response => {
                    console.log({ response });
                    refreshDocuments(props.taskId);
                    toggleCanvasEnd();
                })
                .catch(err => {
                    console.log({ err });
                });
        }
    });

    const addFile = () => {
        toggleCanvasEnd();
    };

    const downloadDocument = (docId, docName) => {
        console.log("view document: " + docId);
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        fetch(url.BASE_URL + '/task/downloaddocument/' + docId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + userdata.token,
                'apikey': 'key123',
            },
        }).then((response) => response.blob())
            .then((blob) => {
                console.log(blob);
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download', docName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const fileLink = props => {
        return (
            <button onClick={() => downloadDocument(props.id, props.fileName)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                Download
            </button>
        )
    }

    const handleUpdate = event => {

        var _data = {
            taskId: props.taskId,
            assignedTo: taskData.assignedTo
        }

        postData("/task/updateassignment", _data).then(res => {
            console.log(res);
            if (res.status == true) {
                alert("Updated");
                loadTakDetail();
            } else {
                alert(res.message);
            }
        });
    }

    const handleSubmit = event => {
        var _data = {
            taskId: props.taskId
        }
        showLoading(true);
        postData("/task/submit", _data).then(res => {
            console.log(res);
            showLoading(false);
            if (res.status == true) {
                alert("Submitted");
                loadTakDetail();
            } else {
                alert(res.message);
            }
        });
    }

    const handleApprove = event => {

        var formData = {
            taskId: props.taskId
        }

        showLoading(true);
        postData("/task/approve", formData).then(res => {
            console.log(res);
            showLoading(false);
            if (res.status == true) {
                alert("Approved");
                loadTakDetail();
            } else {
                alert(res.message);
            }
        });
    }

    const handleReject = event => {

        var formData = {
            taskId: props.taskId
        }

        showLoading(true);
        postData("/task/reject", formData).then(res => {
            console.log(res);
            showLoading(false);
            if (res.status == true) {
                alert("Rejected");
                loadTakDetail();
            } else {
                alert(res.message);
            }
        });
    }

    return (
        <React.Fragment>
            <div style={{ paddingTop: '10px' }}>
                <Col xl={12}>
                    <Row>
                        <Col xl={6}>
                            {/*<Button color="primary" className="btn btn-primary" type="button" disabled={approveDisabled} onClick={handleApprove} hidden={!pending}>Send Back</Button>&nbsp;&nbsp;&nbsp;*/}
                            <Button color="primary" className="btn btn-success" type="button" disabled={approveDisabled} onClick={handleApprove} hidden={!pending}>Approve</Button>&nbsp;&nbsp;&nbsp;
                            <Button color="primary" className="btn btn-danger" type="button" disabled={approveDisabled} onClick={handleReject} hidden={!pending}>Reject</Button>
                        </Col>
                        <Col xl={6} className="float-end text-end">
                            <Button color="primary" className="btn" type="button" onClick={handleUpdate} hidden={!submitted}>Update</Button>&nbsp;&nbsp;&nbsp;
                            <Button color="primary" className="btn" type="button" onClick={handleSubmit} hidden={submitted}>Submit</Button>&nbsp;&nbsp;&nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col sm='5'>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="horizontal-firstname-input"
                                            className="col-sm-2 col-form-label">
                                            Title
                                        </Label>
                                        <Input
                                            type="textarea"
                                            className="form-control"
                                            readOnly
                                            placeholder="Title"
                                            value={taskDetail ? taskDetail.taskTitle : ""}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01">Type</Label>
                                        <Input type="text" className="form-control" readOnly value={taskDetail ? taskDetail.taskType.name : ""}></Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3" hidden={!submitted}>
                                        <Label htmlFor="AssignedTo">Submitted By</Label>
                                        <Input name="TaskTypeId" type="text" value={taskDetail ? taskDetail.taskOwnerUser.fullName : ""}
                                            className="form-control" id="validationCustom01">
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" hidden={!submitted}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01">Status</Label>
                                        <Input name="status" type="text" value={taskDetail ? taskDetail.taskStatus.name : ""}
                                            className="form-control" id="validationCustom01">
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6" hidden={!submitted}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="AssignedTo">Assigned To</Label>
                                        <Input type="select" className="form-select" name="assignedTo" id="assignedTo" value={taskData ? taskData.assignedTo : 0} onChange={handleChange}>
                                            {employees &&
                                                employees.map((emp) => {
                                                    return <option value={emp.id} key={emp.id}>{emp.fullName} : {emp.roleName}</option>
                                                })}</Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='12' md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom03">Description</Label>
                                        <Input type="textarea"
                                            placeholder='Description of the Task'
                                            className="form-control"
                                            style={{ height: "150px" }} readOnly
                                            value={taskDetail ? taskDetail.notes : ""}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="7" sm='7'>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>Discussion</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>Attachments</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            setactiveTab(3);
                                        }}>
                                        <span>Approval List</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#" style={{ display: 'none' }}
                                        className={classnames({ active: activeTab === 4 })}
                                        onClick={() => {
                                            setactiveTab(4);
                                        }}>
                                        <span>History</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Row>
                                        <Col sm='12' md='12'>
                                            <Row style={{ padding: '10px' }}>
                                                <Input
                                                    type="textarea"
                                                    name="userComment"
                                                    id="userComment"
                                                    onChange={handleChange}
                                                    value={taskData.userComment}
                                                    placeholder='Post your comments here'
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                />
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button color="primary" className="btn" type="button" onClick={handlePost}>Post Comment</Button>&nbsp;&nbsp;&nbsp;
                                                </Col>
                                            </Row>
                                            <Row style={{ padding: '10px' }}>
                                                {taskComments &&
                                                    taskComments.map((comment) => {
                                                        return (
                                                            <div key={comment.id} style={{ borderBottom: "1px solid", padding: "4px", margin: "2px" }}>
                                                                <div>{comment.userProfile.fullName} &nbsp; {comment.commentDate}</div>
                                                                <div>{comment.commentNote}</div>
                                                            </div>);
                                                    })}
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                        <Button color="primary" className="btn" type="button" onClick={addFile}>Add File</Button>
                                    </div>
                                    <GridComponent dataSource={taskDocuments} allowPaging={true} pageSettings={{ pageCount: 5 }}
                                        statelessTemplates={['directiveTemplates']} allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
                                        <ColumnsDirective>
                                            <ColumnDirective field="fileName" headerText='Filename' width='150'></ColumnDirective>
                                            <ColumnDirective field="description" headerText='Description' width='150'></ColumnDirective>
                                            <ColumnDirective field="userProfile.fullName" headerText='Uploaded By' width='150'></ColumnDirective>
                                            <ColumnDirective field="updatedOn" headerText='Uploaded On' type='date' format='dd-MM-yyyy' width='150' />
                                            <ColumnDirective template={fileLink} headerText='Download' width='150' />
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                                    </GridComponent>
                                    <div className='demo-inline-spacing'>

                                        <Offcanvas backdrop={true} className='w-35' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                                <Row><Col sm='12'><b>Add Files</b></Col></Row>
                                            </OffcanvasHeader>
                                            <hr />
                                            <OffcanvasBody>
                                                <form
                                                    onSubmit={fileValidation.handleSubmit}>
                                                    <Row>
                                                        <Col sm='12' className='mb-4'>
                                                            <Label className='form-label' for='description'>
                                                                Description
                                                            </Label>
                                                            <Input type='textarea' className="form-control" value={fileValidation.values.Description} name="Description"
                                                                onChange={fileValidation.handleChange} placeholder='Description' />
                                                        </Col>

                                                        <Col sm='12' className='mb-4'>
                                                            <Dropzone onDrop={(acceptedFiles) => {
                                                                handleAcceptedFiles(acceptedFiles);
                                                            }}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick mt-2"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} />
                                                                            <div className="mb-3">
                                                                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                                                            </div>
                                                                            <h4>Drop files here or click to upload.</h4>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                                {selectedFiles.map((f, i) => {
                                                                    return (
                                                                        <Card
                                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                            key={i + "-file"}>
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        <img
                                                                                            data-dz-thumbnail=""
                                                                                            height="80"
                                                                                            className="avatar-sm rounded bg-light"
                                                                                            alt={f.name}
                                                                                            src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUpJREFUOE+N0z1LHlEQhuHr1aDiRyEStTGK2PoTbO20sRESRAVtDWJhpYH8HwsbFQRRC02pgnaSIJgija3fMq9n4bDZDdlmd4cz9zwz85yG92cAt/iT/suvFrRjGVt4LA40MsAZBmsARYFrfMUenuJsDjjHMHoQFV/xgDv04xTTOMIMDsqAKyzhGz6mCof4jA4sYDPBXwq1ZQVDaEttPKO1oqU+/KgCXCaJE4jkG4yUABGPlr7XKfiE7gT4UAwqg0SsF8d1CmYxh58V0iN5FDGXjX8p6EwKcsZ98kHEYgYnVYDYwhcs4lfKLoYce59MLf3Gep2C8EFXmn5UzQ0XTgzzhE/CE03T5WsMBfNYwRh2MkAkh4I4s4u1KkCdlaNIAEJZfMeWKhXUAcJYU1hNgO10H/5q4QLjNZcpD4fN98stRDAA//vEtW8WewNXM0MR2YfR7AAAAABJRU5ErkJggg=="}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="text-muted font-weight-bold"
                                                                                        >
                                                                                            {f.name}
                                                                                        </Link>
                                                                                        <p className="mb-0">
                                                                                            <strong>{f.formattedSize}</strong>
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    );
                                                                })}
                                                            </div>
                                                        </Col>

                                                        <div className="text-center mt-2">
                                                            {dataSuccess === 'added' && <SuccessAlert message={"Updated"} />}
                                                            {dataSuccess === 'failed' && <FailureAlert />}
                                                        </div>

                                                        <hr />

                                                        <div>
                                                            {isLoading ? <LoadingSpinner /> : <Button color="primary"
                                                                className="btn" disabled={isLoading}
                                                                type="submit">Submit</Button>}&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <Button type="button" onClick={toggleCanvasEnd} className="btn btn-dark">Close</Button>
                                                        </div>

                                                    </Row>
                                                </form>
                                            </OffcanvasBody>
                                        </Offcanvas>
                                    </div>

                                </TabPane>
                                <TabPane tabId={3}>
                                    <GridComponent dataSource={taskActivities} allowPaging={true} pageSettings={{ pageCount: 5 }}
                                        statelessTemplates={['directiveTemplates']} allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
                                        <ColumnsDirective>
                                            <ColumnDirective field="activityDesc" headerText='Role'></ColumnDirective>
                                            <ColumnDirective field="activityStatus" headerText='Status' width='150'></ColumnDirective>
                                            <ColumnDirective field="updatedOn" headerText='Uploaded On' type='date' format='dd-MM-yyyy' width='150' />
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                                    </GridComponent>
                                </TabPane>
                                <TabPane tabId={4}>
                                    <GridComponent dataSource={attachments} allowPaging={true} pageSettings={{ pageCount: 5 }}
                                        statelessTemplates={['directiveTemplates']} allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
                                        <ColumnsDirective>
                                            <ColumnDirective field="payPeriod.payPeriodTitle" headerText='Filename' width='150'></ColumnDirective>
                                            <ColumnDirective field="payPeriod.payPeriodTitle" headerText='Description' width='150'></ColumnDirective>
                                            <ColumnDirective field="generatedBy" headerText='Uploaded By' width='150'></ColumnDirective>
                                            <ColumnDirective field="generatedOn" headerText='Uploaded On' type='date' format='dd-MM-yyyy' width='150' />
                                            <ColumnDirective field="location.locationName" headerText='Download' width='150' />
                                        </ColumnsDirective>
                                        <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                                    </GridComponent>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                    <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
                        {dataSuccess === 'added' && <SuccessAlert />}
                        {dataSuccess === 'failed' && <FailureAlert />}
                    </div>

                </Col>
            </div>
        </React.Fragment>
    );
};

TaskDetailView.propTypes = {
    closeAction: PropTypes.string
}

export default TaskDetailView;