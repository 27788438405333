import { Modal } from "reactstrap";
import React from "react";

export const HelpModal = (props) => {
    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title mt-0">Help</h5>
                <button type="button" onClick={props.onCloseClick}
                    className="close" data-dismiss="modal" aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <p>
                    Cras mattis consectetur purus sit amet fermentum. Cras
                    justo odio, dapibus ac facilisis in, egestas eget
                    quam. Morbi leo risus, porta ac consectetur ac,
                    vestibulum at eros.
                </p>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary"
                        onClick={props.onCloseClick}>
                        Close
                    </button>
                    <button type="button" className="btn btn-primary">
                        Save changes
                    </button>
                </div>
            </div>
        </>
    )
}