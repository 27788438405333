import { extend, Inject, Resize } from "@syncfusion/ej2-react-grids";
import { DragAndDrop, Month, ScheduleComponent, ViewDirective, ViewsDirective } from "@syncfusion/ej2-react-schedule";
import React, { useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { updateSampleSection } from "./sample-base";

// const data = extend([], scheduleData, null, true);

const LeaveCalendar = () => {

    useEffect(() => {
        updateSampleSection();
    }, []);

    let scheduleObj;

    const scheduleData = [{
        Id: 3,
        Subject: 'Employee A',
        StartTime: new Date(2023, 2, 4, 9, 0),
        EndTime: new Date(2023, 2, 4, 10, 0),
        IsAllDay: true
    }, {
        Id: 4,
        Subject: 'Employee B',
        StartTime: new Date(2023, 2, 13, 9, 0),
        EndTime: new Date(2023, 2, 13, 10, 0),
        IsAllDay: true
    },
    {
        Id: 5,
        Subject: 'Sick Leave',
        StartTime: new Date(2023, 2, 15, 9, 0),
        EndTime: new Date(2023, 2, 16, 10, 0),
        IsAllDay: true
    }, {
        Id: 6,
        Subject: 'PTO',
        StartTime: new Date(2023, 5, 18, 0, 0),
        EndTime: new Date(2023, 5, 18, 0, 0),
        IsAllDay: false
    }];

    function onViewChange(args) {
        scheduleObj.currentView = args.value;
        scheduleObj.dataBind();
    }
    function onEventRendered(args) {
        // applyCategoryColor(args, scheduleObj.currentView);
    }

    function onActionBegin(args) {
        console.log("Inside onActionBegin");
        console.log(args);
        if((args.requestType === 'eventCreate') || args.requestType === 'eventChange') {
            args.cancel = true;
        }
    }

    function onPopupOpen(args) {
        console.log("onPopupOpen");
        args.cancel = true;
    }

    function onCellClick(args) {
        console.log(args);        
        args.cancel = true;
    }

    return (
        <div className="page-content">            
            <Breadcrumbs title="Leave" breadcrumbItem="Leave Calendar"></Breadcrumbs>
            
            <ScheduleComponent width='100%' height='650px' actionBegin={onActionBegin} popupOpen={onPopupOpen}
                    ref={schedule => scheduleObj = schedule} selectedDate={new Date(2023, 6, 18)}
                eventSettings={{ dataSource: scheduleData }} cellClick={onCellClick}>
                    <ViewsDirective>
                        <ViewDirective option='Month' />
                    </ViewsDirective>
                    <Inject services={[Month, DragAndDrop]} />
            </ScheduleComponent>            
        </div>
    )
}

export default LeaveCalendar;