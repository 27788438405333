import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import LoadingSpinner from "../../shared/Loading";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar } from "@syncfusion/ej2-react-grids";
import { useHistory } from "react-router-dom";

const PayrollBankDeposit = () => {
    let gridInstance;
    const history = useHistory();

    const toggle = () => {
        history.push('/payroll/salarydeposits')
    }

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.text) {
            case 'PDF Export':
                gridInstance.pdfExport();
                break;
            case 'Excel Export':
                gridInstance.excelExport();
                break;
            case 'CSV Export':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <div className="page-content">

            <Breadcrumbs title="HR Management" breadcrumbItem="Bank Transfer" enableAction={true} actionName="Start Transfer" onClick={toggle}></Breadcrumbs>

            <GridComponent allowPaging={true} 
                toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5 }}
                allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} >
                <ColumnsDirective>
                    <ColumnDirective field="bankName" headerText='ID' width='150'></ColumnDirective>
                    <ColumnDirective field="bankIFSC" headerText='PAY PERIOD' width='150' />
                    <ColumnDirective field="branchName" headerText='EMPLOYEES' width='150' />
                    <ColumnDirective field="accountName" headerText='NET AMOUNT' width='150' />
                    <ColumnDirective field="accountNumber" headerText='TOTAL AMOUNT PAYABLE' width='150' />
                    <ColumnDirective field="branchName" headerText='AMOUNT PAID' width='150' />
                    <ColumnDirective field="accountNumber" headerText='STATUS' width='150' />
                    <ColumnDirective field="accountName" headerText='CREATED ON' width='150' />
                </ColumnsDirective>
                <Inject services={[Toolbar, Page]} />
            </GridComponent>
        </div>
    )
}

export default PayrollBankDeposit;