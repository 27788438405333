import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Resize } from "@syncfusion/ej2-react-grids";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanyPayperiods, getCompanyLocations, getDepartments, getEmployeeList, getAllContractors, getAllowancesTypes
} from "../../helpers/backend_helper";
import { getData, postData } from "../../helpers/url-request";
import Select from "react-select"
import { showLoading } from 'react-global-loading';

const AllowanceReport = () => {
    let gridInstance;
    const [allowances, setAllowances] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [departments, setDepartments] = useState();
    const [locations, setLocations] = useState();    
    const [payPeriods, setPayPeriods] = useState();
    const [formData, setFormData] = useState({
        "payPeriodId": 0,"allowanceId": 0,
        "locationId": 0, "departmentId": 0,
        "contractorId": 0, "employeeId": 0
    });
    const [reportData, setReportData] = useState([]);
    

    useEffect(() => {

        getCompanyPayperiods().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "payPeriodTitle": "Select Period" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setPayPeriods(emps)
            }
        });

        getAllowancesTypes().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "Select Allowance Type" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setAllowances(emps)
            }
        });

        getEmployeeList().then(res => {
            if (res.data) {
                let emps = [{ label: "ALL", value: 0 }];
                res.data.forEach(el => {
                    emps.push({ label: el.fullName, value: el.id });
                });
                setEmployees(emps)
            }
        });

        getDepartments().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setDepartments(emps)
            }
        });

        getCompanyLocations().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "locationName": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setLocations(emps)
            }
        });


    }, []);


    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const handleChange = event => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    };

    const confirmHandler = (event) => {
        event.preventDefault();
        setReportData([]);
        showLoading(true);
        postData("/payroll/salaryallowancereport", formData).then(res => {
            console.log(res.data);
            setReportData(res.data);
            showLoading(false);
        });
    }

    const resetReport = () => {
        setReportData([]);
        setFormData({
            "payPeriodId": 0, "allowanceId" : 0,
            "locationId": 0, "departmentId": 0,
            "contractorId": 0, "employeeId": 0
        });
    }

    return (
        <div className="page-content">
            <Breadcrumbs title="HR Management" breadcrumbItem="Allowance Report" enableAction={false}></Breadcrumbs>
            <CardBody>
                <Row>                    
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Select Period
                        <select className="form-select" name="payPeriodId" onChange={handleChange}>
                            {payPeriods &&
                                payPeriods.map((payperiod) => {
                                    return <option value={payperiod.id} key={payperiod.id}>{payperiod.payPeriodTitle}</option>
                                })}
                        </select>
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Select Allowance Type
                        <select className="form-select" name="allowanceId" onChange={handleChange}>
                            {allowances &&
                                allowances.map((allowance) => {
                                    return <option value={allowance.id} key={allowance.id}>{allowance.name}</option>
                                })}
                        </select>
                    </Col>                    
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Employee
                        <Select type="select"
                            placeholder='Select Employee'
                            name="employeeId"
                            isMulti={false}
                            options={employees}
                            classNamePrefix="form-select"
                            isLoading={false}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        <br />
                        <Button color="primary" className="btn primary" type="submit" onClick={confirmHandler}>View Report</Button>&nbsp;&nbsp;&nbsp;
                        <Button color="primary" className="btn primary" type="reset" onClick={resetReport}>Clear</Button>
                    </Col>
                </Row>
            </CardBody>
        <hr />

            <Row>
                <Col sm='12' md='12' lg='12'>
                    <CardBody>
                        {reportData &&
                            <GridComponent allowPaging={true} dataSource={reportData}
                                toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5, pageSizes: true }}
                                statelessTemplates={['directiveTemplates']} allowResizing={true}
                                allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field="employeeProfile.fullName" headerText='Employee' width='150'></ColumnDirective>
                                    <ColumnDirective field="employeeProfile.workTitle" headerText='Title' width='150'></ColumnDirective>
                                    <ColumnDirective field="payrollDetail.payrollMonth" headerText='Pay Month' width='150'></ColumnDirective>                                    
                                    <ColumnDirective field="allowance" headerText='Allowance' width='120' />
                                    <ColumnDirective field="amount" headerText='Amount (₹)' width='120' />
                                </ColumnsDirective>
                                <Inject services={[Toolbar, Page, Resize]} />
                            </GridComponent>
                        }
                    </CardBody>
                </Col>

            </Row>

        </div>
    )
}

export default AllowanceReport;