import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";

const PayslipSettings = () => {

    return (
        <>
            <Card>

                <CardBody>
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Include YTD Amounts
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={1} checked readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Include IT Declarations
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={1} checked readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Include Bank Information
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={1} checked readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Show Payment Method
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={1} checked readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Show Masked Numbers
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={1} checked readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Show Leave Balance
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={1} checked readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Show Additional/Reduce Income Tax
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={1} checked readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Show Loss Of Pay Days
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={1} checked readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Show Paid Days
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={1} checked readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                        </Col>
                        <Col lg='2' sm='3' md='3'>
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                </CardBody>
            </Card>
        </>
    )
}

export default PayslipSettings;