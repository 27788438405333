import React from "react";

export default function LoadingSpinner(props) {
  console.log(props);
  return (<button type="button" className="btn btn-primary btn-sm waves-effect waves-light">
    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" style={{ "color": "white" }}></i>{" "}
    {props && props ? (
      props.text
    ) : <p>Loading....</p>}
    {/* {props && <p>Loading....</p> ? props} */}
  </button>)
}