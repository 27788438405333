import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { getLeaveTypes, getLoggedInUser } from "../../../helpers/backend_helper";

const SettingsLeaveType = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [leaveType, setType] = useState('');
    const [description, setDesc] = useState();
    const [errors, setErrors] = useState({});
    const [leaveTypeList, setLeaveTypeList] = useState();

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const loadData = () => {
        getLeaveTypes().then(res => {
            if (res.data) {
                setLeaveTypeList(res.data)
            }
        });
    }

    useEffect(() => {
        loadData();
    }, [])

    const typeChange = (e) => {
        setType(e.target.value);
        if (e.target.value) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    leaveType: "",
                };
            });
        }
    }

    const changeDesc = e => {
        setDesc(e.target.value);
        if (e.target.value) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    description: "",
                };
            });
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        console.log(`Name:  ${leaveType}  `);
        //console.log(typeof(Name))
        if (!leaveType) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    leaveType: "Leave Type is Required!",
                };
            });
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    leaveType: "",
                };
            });
        }

        if (!description) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    description: "Description is Required!",
                };
            });
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    description: "",
                };
            });
        }
    };

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const statusTemplate = (props) => {

        return (<div>{props.status === 1 ?
            <div id="status" className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div id="status" className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }

    return (
        <Fragment>
            <div className="m-2">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Leave Type" enableAction={getLoggedInUser().roleId != 1 ? false : true} actionName="Add Leave Type" onClick={toggleCanvasEnd}></Breadcrumbs>
                    <Card className='overflow-hidden'>
                        <GridComponent dataSource={leaveTypeList} allowPaging={true} pageSettings={{ pageCount: 25, pageSizes: true }} allowResizing={true}
                            toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid}
                            statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true}>
                            <ColumnsDirective>
                                <ColumnDirective field="name" headerText='Leave Type' width='150'></ColumnDirective>
                                <ColumnDirective field="description" headerText='Description' width='150' />
                                <ColumnDirective field="status" headerText='Status' template={statusTemplate} width='150' />
                                <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='150'></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize]} />
                        </GridComponent>

                    </Card>

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Leave Type</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />


                            <OffcanvasBody>
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Leave Type
                                            </Label>
                                            <Input type='text' id='input-default' onChange={typeChange} placeholder='Leave Type' />
                                            <p style={{ color: "Red" }}>{errors ? errors.leaveType : ''}</p>
                                        </Col>

                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Description
                                            </Label>
                                            <Input type='textarea' id='input-default' onChange={changeDesc} placeholder='Description' />
                                            <p style={{ color: "Red" }}>{errors ? errors.description : ''}</p>
                                        </Col>

                                        <Col sm='12' className='mb-1'>
                                            <Input type='checkbox' id='input-default' />&nbsp;&nbsp;&nbsp;
                                            <Label className='form-label' for='input-default'>
                                                Active
                                            </Label>
                                        </Col>
                                        <hr />
                                        <Col sm='4'>
                                            <Button block color='primary' className='mb-1'>
                                                Save
                                            </Button>
                                        </Col>
                                        <Col sm='4' className='mr-2'>
                                            <Button block outline color='secondary' toggle={toggleCanvasEnd}>
                                                Close
                                            </Button>
                                        </Col>

                                    </Row>
                                </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

export default SettingsLeaveType;