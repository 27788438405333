import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar } from "@syncfusion/ej2-react-grids";
import { Col, Container, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap"
import React, { useState } from "react";
import { Fragment, useEffect } from "react"
import SettingsWorkflow from "./workflow";
// import ReactBpmnApp from "../BPMN/bpmn";
import BPMNWorkflow from "./BPMN/bpmn";
import { getWorkflows } from "../../../helpers/backend_helper";

const data = [
    {
        "type": "PTO",
        "date": new Date(),
        "requestedOn": new Date(),
        "status": "Approved "
    }
]


const WorkFlowList = () => {

    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [workflowList, setWorkflowList] = useState();
    const [submitted, setFormSubmit] = useState(false);
    const [url, setUrl] = useState();
    const [title, setTitle] = useState();

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const typetemplate = props => {
       
        return (
            <button className="btn btn-link btn-sm" onClick={() => viewWorkflow(props.id, props.name)}>{props.name}</button>
        )
    }

    const viewWorkflow = (workflowId, title) => {
        let diagUrl = "/bpmn/diagram" + workflowId + ".bpmn";
        setUrl(diagUrl);
        setTitle(title);
        toggleCanvasEnd();
    }

    useEffect(() => {

        getWorkflows().then((res) => {
            if (res.data) {
                setWorkflowList(res.data)
            }
        })
    }, [submitted]);

    function statusTemplate(props) {

        console.log(props);
        console.log(props.status);

        return (<div>{props.status === 1 ?
            <div id="status" className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div id="status" className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }

    return (
        <div className="page-content">
            <Container fluid>
                <GridComponent dataSource={workflowList} statelessTemplates={['directiveTemplates']} allowPaging={true} pageSettings={{ pageCount: 5 }} >
                    <Inject services={[Toolbar]} />
                    <ColumnsDirective>
                        <ColumnDirective field="name" template={typetemplate} headerText='Name' width='300'></ColumnDirective>
                        <ColumnDirective field="description" headerText='Description' />
                        <ColumnDirective field="status" template={statusTemplate} headerText='Status' width='120'></ColumnDirective>
                        <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy" headerText='Updated On' width='120' />
                    </ColumnsDirective>
                    <Inject services={[Page]} />
                </GridComponent>
            </Container>

            <Offcanvas className='w-50' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                <OffcanvasHeader toggle={toggleCanvasEnd}>
                    <Row>
                        <Col sm='12'>
                            <h3>Workflow</h3>
                        </Col>
                    </Row>
                </OffcanvasHeader>

                <OffcanvasBody>
                    <BPMNWorkflow url={url} title={title} />
                </OffcanvasBody>
            </Offcanvas>
        </div>
    )
}

export default WorkFlowList;