// import Select from "react-select/dist/declarations/src/Select";
import { Button, Col, Input, Label, Row, TabContent, TabPane, NavLink, Nav, NavItem } from "reactstrap";

import Select from 'react-select'
import React, { useEffect, useState } from "react";
import { addEmployeeLeaveRequest, getEmployeeList, getLeaveTypes, getLocalStorage, getEmployeeLeaveDetails, getEmployeeLeaveAllocations, getEmployeeLeaveRequests } from "../../../helpers/backend_helper";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Filter, ExcelExport, PdfExport, Resize }
    from "@syncfusion/ej2-react-grids";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import { getData, postData } from "../../../helpers/url-request";
import { ToolbarOptions } from "../../../components/Common/Toolbar";
import classnames from "classnames";
import { showLoading } from 'react-global-loading';

const LeaveAllocation = (props) => {
    let gridInstance;
    let allocationInstance;
    let requestInstance;
    const [leaveValue, setLeaveValue] = useState('');
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [leaveDetails, setLeaveDetails] = useState();
    const [allocationDetails, setAllocationDetails] = useState();
    const [requestDetails, setRequestDetails] = useState();
    const [leaveTypes, setLeaveTypes] = useState();
    const [employees, setEmployees] = useState();
    const [role, setRole] = useState();
    const [activeTab, setactiveTab] = useState(1);
    const [leaveRequestList, setLeaveRequestList] = useState([]);

    const [formData, setFormData] = useState({
        employeeId: 0, leaveTypeId: 0, leaveDays: '', description: ''
    });
    const [errors, setErrors] = useState({});

    const loadLeaveDetails = () => {
        getEmployeeLeaveDetails(props.employeeId).then((res) => {
            console.log(res);
            if (res.data) {
                setLeaveDetails(res.data.leaves);
            }
        });
    }

    const loadLeaveAllocations = () => {
        getEmployeeLeaveAllocations(props.employeeId).then((res) => {
            console.log(res);
            if (res.data) {
                setAllocationDetails(res.data);
            }
        });
    }

    const loadLeaveRequests = () => {
        getEmployeeLeaveRequests(props.employeeId).then((res) => {
            console.log(res);
            if (res.data) {
                setRequestDetails(res.data);
            }
        });
    }

    useEffect(() => {

        showLoading(true);

        getLeaveTypes().then((res) => {
            //console.log(res);
            formData.leaveTypeId = res.data[0].id
            setLeaveTypes(res.data);
        });

        getEmployeeList().then((res) => {
            // console.log(res);
            formData.employeeId = res.data[0].id
            setEmployees(res.data);
        });

        loadLeaveDetails();

        loadLeaveAllocations();

        loadLeaveRequests();

        const storage = getLocalStorage();
        // console.log(storage);
        setRole(storage.roleName);

        showLoading(false);

    }, [submitted]);

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadLeaveDetails();
                break;
        }
    }

    const allocationToolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                allocationInstance.pdfExport();
                break;
            case 'excel':
                allocationInstance.excelExport();
                break;
            case 'csv':
                allocationInstance.csvExport();
                break;
            case 'refresh':
                loadLeaveAllocations();
                break;
        }
    }

    const requestToolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                requestInstance.pdfExport();
                break;
            case 'excel':
                requestInstance.excelExport();
                break;
            case 'csv':
                requestInstance.csvExport();
                break;
            case 'refresh':
                loadLeaveRequests();
                break;
        }
    }

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        href="#"
                        className={classnames({ active: activeTab === 1 })}
                        onClick={() => {
                            setactiveTab(1);
                        }}>
                        <span>Available</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        href="#"
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                            setactiveTab(2);
                        }}>
                        <span>Allocations</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        href="#"
                        className={classnames({ active: activeTab === 3 })}
                        onClick={() => {
                            setactiveTab(3);
                        }}>
                        <span>Availed</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent
                className="twitter-bs-wizard-tab-content"
                activeTab={activeTab}>
                <TabPane tabId={1}>
                    <GridComponent dataSource={leaveDetails} toolbar={ToolbarOptions} ref={grid => gridInstance = grid} allowResizing={true}
                        statelessTemplates={['directiveTemplates']} pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }}
                        allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} allowFiltering={true} allowPaging={true}
                        toolbarClick={toolbarClick.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field="key" headerText='Leave Type' width='100'></ColumnDirective>
                            <ColumnDirective field="value" headerText='Balance' width='100'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Filter, Sort, ColumnMenu, Resize, ExcelExport, PdfExport]} />
                    </GridComponent>
                </TabPane>
                <TabPane tabId={2}>
                    <GridComponent dataSource={allocationDetails} toolbar={ToolbarOptions} ref={grid => allocationInstance = grid} allowResizing={true}
                        statelessTemplates={['directiveTemplates']} pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }}
                        allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} allowFiltering={true} allowPaging={true}
                        toolbarClick={allocationToolbarClick.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field="leaveType.name" headerText='Leave Type'></ColumnDirective>                            
                            <ColumnDirective field="leaveAllotted" headerText='Allotted' width='120'></ColumnDirective>
                            <ColumnDirective field="leaveAllottedOn" headerText='Allotted On' type="datetime" format="dd/MM/yyyy"></ColumnDirective>
                            <ColumnDirective field="leaveAllottedBy" headerText='Allotted By'></ColumnDirective>
                            <ColumnDirective field="carryForward" headerText='Carry Forward'></ColumnDirective>
                            <ColumnDirective field="description" headerText='Description' width='120'></ColumnDirective>
                            <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy" headerText='Last Updated' width='150' />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Filter, Sort, ColumnMenu, Resize, ExcelExport, PdfExport]} />
                    </GridComponent>
                </TabPane>
                <TabPane tabId={3}>
                    <GridComponent dataSource={requestDetails} toolbar={ToolbarOptions} ref={grid => requestInstance = grid} allowResizing={true}
                        statelessTemplates={['directiveTemplates']} pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }}
                        allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} allowFiltering={true} allowPaging={true}
                        toolbarClick={requestToolbarClick.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field="leaveId" headerText='Request Id' width='100'></ColumnDirective>
                            <ColumnDirective field="employeeProfile.fullName" headerText='Employee' width='150'></ColumnDirective>
                            <ColumnDirective field="location.locationName" headerText='Location' width='125'></ColumnDirective>
                            <ColumnDirective field="leaveType.name" headerText='Leave Type' width='150'></ColumnDirective>
                            <ColumnDirective field="leavePeriodType.name" headerText='Leave Period' width='150' />
                            <ColumnDirective field="fromDate" type="datetime" format="dd/MM/yyyy" headerText='From Date' width='125' />
                            <ColumnDirective field="toDate" type="datetime" format="dd/MM/yyyy" headerText='To Date' width='125' />
                            <ColumnDirective field="leaveStatus" headerText='Leave Status' width='125' />
                            <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='150'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Filter, Sort, ColumnMenu, Resize, ExcelExport, PdfExport]} />
                    </GridComponent>
                </TabPane>
            </TabContent>
        </>
    )
}

export default LeaveAllocation;