import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort }
    from "@syncfusion/ej2-react-grids";
import { useHistory } from "react-router-dom";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

const payslipList = [
    {
        "id": "1",
        "employeeName": "Emp Name",
        "payPeriod": "01 Feb, 2023 to 28 Feb, 2023	",
        "paymentMethod": "Bank Deposit",
        "Basic": "8,428.57",
        "Gross": "26,428.57",
        "incomeTax": "0.00",
        "surCharge": "0.00",
        "cess": "0.00",
        "netAmount": "24,283.93"
    },
    {
        "id": "2",
        "employeeName": "Emp Name",
        "payPeriod": "01 Feb, 2023 to 28 Feb, 2023	",
        "paymentMethod": "Bank Deposit",
        "Basic": "8,428.57",
        "Gross": "26,428.57",
        "incomeTax": "0.00",
        "surCharge": "0.00",
        "cess": "0.00",
        "netAmount": "24,283.93"
    },
]

const MyPayslips = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const history = useHistory();

    const runPayroll = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
        // setErrors({});
    }

    const submitPayroll = event => {
        event.preventDefault();

        history.push("/payroll/create")
    }

    const viewPayroll = (id) => {
        history.push('/payroll/summary/view/' + id);
    }

    const linktemplate = props => {
        return (
            <button onClick={() => viewPayroll(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.payrollType}
            </button>
        )
    }

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.text) {
            case 'PDF Export':
                gridInstance.pdfExport();
                break;
            case 'Excel Export':
                gridInstance.excelExport();
                break;
            case 'CSV Export':
                gridInstance.csvExport();
                break;
        }
    }

    const downloadSalarySlip = (id) => {
        //window.open("http://localhost/hrmsapi/api/v1/Payroll/downloadpayslip/"+ id);
        fetch('http://localhost/hrmsapi/api/v1/Payroll/downloadpayslip/'+ id, {
            method: 'GET',
            headers: {
                'Authorization':'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIyIiwiRnVsbE5hbWUiOiJQcmFzYWQiLCJMb2dpbk5hbWUiOiJwcmFzYWQiLCJFbXBsb3llZUlkIjoiOSIsIlJvbGVJZCI6IjEiLCJqdGkiOiI4YTIxMzA2OC05MmExLTRjZGQtYjNmMS0zOWEyM2ZhNmZkMjMiLCJyb2xlIjoiQWRtaW5pc3RyYXRvciIsIm5iZiI6MTY3NTQ4MjIzOCwiZXhwIjoxNjc4MDc0MjM1LCJpYXQiOjE2NzU0ODIyMzgsImlzcyI6Imh0dHBzOi8va212c3RkY2wua2FybmF0YWthLmdvdi5pbi8iLCJhdWQiOiJodHRwczovL2ttdnN0ZGNsLmthcm5hdGFrYS5nb3YuaW4vIn0.qufE6_j4UAzEY-3nwD8qDySkkfUiG4CzWbAh2W1-lya2CRgVVjhLQ-SAH3Uv7GZqfOtMZhU4b4pesRbpKSWf7Q',
                'apikey': 'key123',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `FileName.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const fileLink = props => {
        return (
            <button onClick={() => downloadSalarySlip(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                Salary Slip
            </button>
        )
    }

    return (
        <div className="page-content">
            <Container fluid>

                <Breadcrumbs title="HR Management" breadcrumbItem="My Payslips" enableAction={false}></Breadcrumbs>

                <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={runPayroll}>
                    <OffcanvasHeader toggle={runPayroll}>
                        <Row>
                            <Col sm='12'>
                                <b>Run Payroll</b>
                            </Col>
                        </Row>
                    </OffcanvasHeader>
                    <hr />

                    <OffcanvasBody>
                        <FormGroup>
                            <Row className="m-2">
                                <Col sm='6'>
                                    <Label for="batch">Select Batch </Label>
                                </Col>
                                <Col sm='12'>
                                    <Input type="select" className='form-select'>
                                        <option>Full Time Batch</option>
                                        <option>Part Time Batch</option>
                                    </Input>
                                </Col>
                            </Row>

                            <Row className="m-2">
                                <Col sm='6'>
                                    <Label for="batch">Pay Period</Label>
                                </Col>
                                <Col sm='12'>
                                    <Input type="select" className='form-select'>
                                        <option>Select Pay Period</option>
                                    </Input>
                                </Col>
                            </Row>

                            <div className="offcanvas-footer">
                                <Button color="primary" onClick={submitPayroll} className="btn btn-sm"
                                    type="submit">Submit</Button>&nbsp;
                                <button onClick={runPayroll} className="btn btn-dark btn-sm">Close</button>
                            </div>

                        </FormGroup>

                    </OffcanvasBody>
                </Offcanvas>
                {/* <CardHeader>
            <Row>
              <Col xl="9" md="9" sm="9">
                <CardTitle tag="h4">Payroll Details</CardTitle>
              </Col>
              <Col xl="3" md="3" sm="4">
                <button onClick={runPayroll} className="btn btn-primary btn-sm">
                  Run Payroll</button>
              </Col>
            </Row>
          </CardHeader> */}

                <CardBody>
                    <GridComponent dataSource={payslipList} statelessTemplates={['directiveTemplates']} toolbar={toolbarOptions} ref={grid => gridInstance = grid} toolbarClick={toolbarClick.bind(this)}
                        allowPaging={true} pageSettings={{ pageCount: 25 }} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
                        <ColumnsDirective>
                            <ColumnDirective field="employeeName" headerText='Employee Name' width='150'></ColumnDirective>                            
                            <ColumnDirective field="payPeriod" headerText='Pay Period' width='150'></ColumnDirective>
                            <ColumnDirective field="paymentMethod" headerText='Payment Method' width='150'></ColumnDirective>
                            <ColumnDirective field="Basic" headerText='BASIC (₹)' width='150' />
                            <ColumnDirective field="Allowances" headerText='ALLOWANCES (₹) ' width='150' />
                            <ColumnDirective field="Deductions" headerText='DEDUCTIONS (₹) ' width='150' />                            
                            <ColumnDirective field="IncomeTax" headerText='INCOME TAX (₹)' width='150' />
                            <ColumnDirective field="NetAmount" headerText='NET AMOUNT (₹)' width='150' />
                            <ColumnDirective field="id" template={fileLink} headerText='PAYSLIP' width='150' />
                        </ColumnsDirective>
                        <Inject services={[Page, Toolbar, Sort, ColumnMenu]} />
                    </GridComponent>                   
                </CardBody>
            </Container>
        </div>
    );
};

export default MyPayslips;
