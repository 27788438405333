// import Select from "react-select/dist/declarations/src/Select";
import { Button, Col, Input, Label, Row, Card } from "reactstrap";

import Select from 'react-select'
import React, { useEffect, useState } from "react";
import { addEmployeeLeaveRequest, getEmployeeList, getLeaveTypes, getLocalStorage } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import { getData, postData, postRawData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as url from '../../../helpers/url_helper';

const LeaveEncashDetail = (props) => {
    const [leaveValue, setLeaveValue] = useState('');
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [leaveTypes, setLeaveTypes] = useState();
    const [employees, setEmployees] = useState();
    const [employeeList, setEmployeeList] = useState();
    const [selectedFiles, setselectedFiles] = useState([]);
    const [role, setRole] = useState();

    const [formData, setFormData] = useState({
        employeeId: 0, leaveTypeId: 0, leaveDays: '', description: ''
    });
    const [errors, setErrors] = useState({});

    const confirmHandler = (event) => {
        event.preventDefault();
        let formErrors = {};

        if (!formData.leaveTypeId) {
            formErrors.leaveTypeId = 'Leave Type is required';
        }
       
        // if (!formData.description) {
        //     formErrors.description = 'Description is required';
        // }

        setErrors(formErrors);
        
        console.log(formData);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {

            setIsLoading(true);

            const _data = new FormData();
            _data.append("employeeId", formData.employeeId);
            _data.append("leaveTypeId", formData.leaveTypeId);
            _data.append("leaveDays", formData.leaveDays);
            _data.append("amount", formData.amount);
            _data.append("encashDate", formData.encashDate);
            _data.append("paymentDetails", formData.paymentDetails);
            _data.append("notes", formData.notes);

            if (selectedFiles.length > 0) {
                selectedFiles.forEach((file, index) => {
                    console.log(file);
                    _data.append("documents", file);
                });
            } else {
                console.log("Inside Else");
                _data.append("documents", []);
            }

            postRawData("/Leave/addleaveencash", "POST", _data).then((res) => {
                setIsLoading(false);

                if (res.status == true) {
                    setDataSuccess("added");
                    timeout();
                    if (props.onClose) {
                        props.onClose();
                    }
                } else {
                    setDataSuccess("failed");
                    alert(res.message);
                }                
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }
    }

    useEffect(() => {
        getLeaveTypes().then((res) => {
            //console.log(res);
            formData.leaveTypeId = res.data[0].id
            setLeaveTypes(res.data);
        });

        getEmployeeList().then((res) => {
            // console.log(res);
            formData.employeeId = res.data[0].id
            setEmployees(res.data);

            if (res.data) {
                let emps = [{ label: "Select Employee", value: 0 }];
                res.data.forEach(el => {
                    emps.push({ label: el.fullName, value: el.id });
                });
                setEmployeeList(emps)
            }
        });

        const storage = getLocalStorage();
        // console.log(storage);
        setRole(storage.roleName);

    }, [submitted]);

    // const onLeaveChange = e => {
    //     setLeaveValue(e.currentTarget.value);
    // }

    const handleChange = (event) => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    }

    const closeHandler = (event) => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const handleAcceptedFiles = (files) => {
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    return (
        <>
            <form>
                <Row>
                    {role != 'Administrator' ? <></> :
                        <>
                            <Col className="mb-1" xl="4" md="4" sm="12">
                                Employee
                            </Col>
                            <Col className='mb-1' xl='8' md='8' sm='12'>
                                <Select type="select"
                                    placeholder='Select Employee'
                                    name="employeeId"
                                    isMulti={false}
                                    options={employeeList}
                                    classNamePrefix="form-select"
                                    isLoading={false}
                                    onChange={handleChange}
                                />
                                <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                            </Col>
                        </>
                    }

                    <Col className='mb-1' xl='4' md='4' sm='12'>
                        <span>Leave Type</span>
                    </Col>
                    <Col className='mb-1' xl='8' md='8' sm='12'>
                        <Input type="select" className="form-select" name="leaveTypeId"
                            onChange={handleChange} value={formData.leaveTypeId}>
                            {leaveTypes &&
                                leaveTypes.map((res) => {
                                    return <option value={res.id} key={res.id}>{res.name}</option>
                                })}</Input>
                        <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                    </Col>

                    <Col className='mb-1' xl='4' md='4' sm='12'>
                        <span>Encashment Date</span>
                    </Col>
                    <Col className='mb-1' xl='8' md='8' sm='12'>
                        <Input type='date' name="encashDate" onChange={handleChange} id='input-default' placeholder='Encash Date' />
                    </Col>

                    <Col className='mb-1' xl='4' md='4' sm='12'>
                        <span>No. of Days</span>
                    </Col>
                    <Col className='mb-1' xl='8' md='8' sm='12'>
                        <Input type='number' name="leaveDays" onChange={handleChange} id='input-default' placeholder='No of Days' />
                    </Col>

                    <Col className='mb-1' xl='4' md='4' sm='12'>
                        <span>Amount</span>
                    </Col>
                    <Col className='mb-1' xl='8' md='8' sm='12'>
                        <Input type='number' name="amount" onChange={handleChange} id='input-default' placeholder='Amount' />
                    </Col>

                    <Col className='mb-1' xl='4' md='4' sm='12'>
                        <span>Payment Detail</span>
                    </Col>
                    <Col className='mb-1' xl='8' md='8' sm='12'>
                        <Input type='textarea' name="paymentDetails" onChange={handleChange} id='input-default' placeholder='Payment Details' />
                    </Col>

                    <Col className='mb-1' xl='4' md='4' sm='12'>
                        <span>Notes</span>
                    </Col>
                    <Col className='mb-1' xl='8' md='8' sm='12'>
                        <Input type='textarea' name="notes" onChange={handleChange} id='input-default' placeholder='Description' />
                    </Col>

                    <Col sm='12' className='mb-4'>
                        <Dropzone style={{ minHeight: '50px !important' }} onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles);
                        }}>
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                    <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                            <i className="display-4 text-muted bx bx-cloud-upload" />
                                        </div>
                                        <h4>click to upload</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedFiles.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                    >
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUpJREFUOE+N0z1LHlEQhuHr1aDiRyEStTGK2PoTbO20sRESRAVtDWJhpYH8HwsbFQRRC02pgnaSIJgija3fMq9n4bDZDdlmd4cz9zwz85yG92cAt/iT/suvFrRjGVt4LA40MsAZBmsARYFrfMUenuJsDjjHMHoQFV/xgDv04xTTOMIMDsqAKyzhGz6mCof4jA4sYDPBXwq1ZQVDaEttPKO1oqU+/KgCXCaJE4jkG4yUABGPlr7XKfiE7gT4UAwqg0SsF8d1CmYxh58V0iN5FDGXjX8p6EwKcsZ98kHEYgYnVYDYwhcs4lfKLoYce59MLf3Gep2C8EFXmn5UzQ0XTgzzhE/CE03T5WsMBfNYwRh2MkAkh4I4s4u1KkCdlaNIAEJZfMeWKhXUAcJYU1hNgO10H/5q4QLjNZcpD4fN98stRDAA//vEtW8WewNXM0MR2YfR7AAAAABJRU5ErkJggg=="}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                );
                            })}
                        </div>
                    </Col>

                    <div className="text-center mt-2">
                        {dataSuccess === 'added' && <SuccessAlert />}
                        {dataSuccess === 'failed' && <FailureAlert />}
                    </div>

                    <div className="offcanvas-footer">
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            onClick={confirmHandler} className="btn" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;&nbsp;&nbsp;
                        <button type="reset" className="btn btn-dark" onClick={closeHandler}>Close</button>
                    </div>
                </Row>
            </form>
        </>
    )
}

export default LeaveEncashDetail;