import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar } from "@syncfusion/ej2-react-grids";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ReportMenu = () => {
    let gridInstance;
    const history = useHistory();

    useEffect(() => {

    }, []);


    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.text) {
            case 'PDF Export':
                gridInstance.pdfExport();
                break;
            case 'Excel Export':
                gridInstance.excelExport();
                break;
            case 'CSV Export':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <div className="page-content">
            <Breadcrumbs title="HR Management" breadcrumbItem="Report Menu" enableAction={false}></Breadcrumbs>
            <Col xl={12}>
                <Row style={{ padding: '5px' }}>
                    <Col xl={6}>
                        <h4 className="card-title">Attendance Report</h4>
                        <p className="card-title-desc">Employees attendance detail report</p>
                    </Col>
                    <Col xl={3}>
                        <div className="d-flex flex-wrap gap-2">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light" onClick={() => { history.push("/reports/EmployeeAttendanceReport") }}>Goto Report</button>
                        </div>
                    </Col>
                </Row>
                <Row><hr /></Row>
                <Row style={{ padding: '5px' }}>
                    <Col xl={6}>
                        <h4 className="card-title">Leave Usage Report</h4>
                        <p className="card-title-desc">Report on leave usage by employees</p>
                    </Col>
                    <Col xl={3}>
                        <div className="d-flex flex-wrap gap-2">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light" onClick={() => { history.push("/reports/leaveusagereport") }}>Goto Report</button>
                        </div>
                    </Col>
                </Row>
                <Row><hr /></Row>
                <Row style={{ padding:'5px' }}>
                    <Col xl={6}>
                        <h4 className="card-title">Salary Summary Report</h4>
                        <p className="card-title-desc">Report to view the complete salary details</p>
                    </Col>
                    <Col xl={3}>
                        <div className="d-flex flex-wrap gap-2">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light" onClick={() => { history.push("/reports/salarysummaryreport") }}>Goto Report</button>
                        </div>
                    </Col>
                </Row>
                <Row><hr /></Row>
                <Row style={{ padding: '5px' }}>
                    <Col xl={6}>
                        <h4 className="card-title">Salary Detail Report</h4>
                        <p className="card-title-desc">Report to view the complete salary details</p>
                    </Col>
                    <Col xl={3}>
                        <div className="d-flex flex-wrap gap-2">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light" onClick={() => { history.push("/reports/salarydetailreport") }}>Goto Report</button>
                        </div>
                    </Col>
                </Row>
                <Row><hr /></Row>
                <Row style={{ padding: '5px' }}>
                    <Col xl={6}>
                        <h4 className="card-title">Salary Bank Report</h4>
                        <p className="card-title-desc">Report to view the Salary Bak Details</p>
                    </Col>
                    <Col xl={3}>
                        <div className="d-flex flex-wrap gap-2">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light" onClick={() => { history.push("/reports/salarybankreport") }}>Goto Report</button>
                        </div>
                    </Col>
                </Row>
                <Row><hr /></Row>
                <Row style={{ padding: '5px' }}>
                    <Col xl={6}>
                        <h4 className="card-title">Salary Abstract Report</h4>
                        <p className="card-title-desc">Report to view the complete salary details</p>
                    </Col>
                    <Col xl={3}>
                        <div className="d-flex flex-wrap gap-2">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light" onClick={() => { history.push("/reports/salaryabstractreport") }}>Goto Report</button>
                        </div>
                    </Col>
                </Row>
                <Row><hr /></Row>
                <Row style={{ padding: '5px' }}>
                    <Col xl={6}>
                        <h4 className="card-title">Salary Report - Employee Wise</h4>
                        <p className="card-title-desc">Report to view the complete salary details</p>
                    </Col>
                    <Col xl={3}>
                        <div className="d-flex flex-wrap gap-2">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light" onClick={() => { history.push("/reports/salaryreport") }}>Goto Report</button>
                        </div>
                    </Col>
                </Row>
                <Row><hr /></Row>
                <Row style={{ padding: '5px' }}>
                    <Col xl={6}>
                        <h4 className="card-title">Salary Allowance Report</h4>
                        <p className="card-title-desc">Report on Allowances given to Employees in Monthly Salary</p>
                    </Col>
                    <Col xl={3}>
                        <div className="d-flex flex-wrap gap-2">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light" onClick={() => { history.push("/reports/allowancereport") }}>Goto Report</button>
                        </div>
                    </Col>
                </Row>
                <Row><hr /></Row>
                <Row style={{ padding: '5px' }}>
                    <Col xl={6}>
                        <h4 className="card-title">Salary Deduction Report</h4>
                        <p className="card-title-desc">Report on Deductions given to Employees in Monthly Salary</p>
                    </Col>
                    <Col xl={3}>
                        <div className="d-flex flex-wrap gap-2">
                            <button type="button" className="btn btn-outline-primary waves-effect waves-light" onClick={() => { history.push("/reports/deductionreport") }}>Goto Report</button>
                        </div>
                    </Col>
                </Row>
                <Row><hr /></Row>                
            </Col>
        </div>
    )
}

export default ReportMenu;