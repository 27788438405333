import React, { useEffect, useState } from 'react';
import {
    Card, CardBody, CardHeader, Col, Row, FormGroup, Label, Input, Button, FormFeedback, Form
} from 'reactstrap';
import { addExpenseRequest, createExpense, getEmployeeList, getLoanTypes, modifyExpenseData } from "../../../helpers/backend_helper";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { FailureAlert, SuccessAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/Loading';
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const LoanRequestDetail = (props) => {
    const [expenseTypes, setExpenseTypes] = useState();
    const [employees, setEmployees] = useState();
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
            

    useEffect(() => {
        getLoanTypes().then((res) => {
            if (res.status === true) {
                let expenseTypes = [{ id: 0, name: "Select Loan Type" }]
                res.data.forEach(el => {
                    expenseTypes.push(el)
                });
                setExpenseTypes(expenseTypes);
            }
        })

        getEmployeeList().then((res) => {
            if (res.status === true) {
                let employeesList = [{ id: 0, fullName: "Select Employee" }]
                res.data.forEach(el => {
                    employeesList.push(el)
                });
                setEmployees(employeesList);
            }
        });
    }, []);

    const handleChange = event => {
        console.log(event.target.files[0], event.target.files[0].name);
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };
   
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            EmployeeId: '',
            LoanTypeId: '',
            LoanAmount: '',
            LoanInstallment: '',
            Description: ''
        },
        validationSchema: Yup.object({
            EmployeeId: Yup.string().required("Please Select Employee"),
            LoanTypeId: Yup.string().required("Please Select Loan Type"),
            LoanAmount: Yup.string().required("Please Enter Loan Amount"),
            LoanInstallment: Yup.string().required("Please Enter Loan Installment"),            
            Description: Yup.string().required("Please Enter Description"),
        }),
        onSubmit: async (values) => {
            console.log("values", values);

            //const formData = new FormData();
            //// formData.append("Documents", file);
            //formData.append("employeeId", values.EmployeeId);
            //formData.append("expenseTypeId", values.ExpenseTypeId);
            //formData.append("expenseDate", values.ExpenseDate);
            //formData.append("amount", values.Amount);
            //formData.append("description", values.Description);
            //// formData.append("documents", file);

            //setIsLoading(true);

            postData("/loan/requestloan", values).then((res) => {
                setIsLoading(false);

                if (res.status == true) {
                    setDataSuccess("added");
                    timeout();
                    if (props.onClose) {
                        props.onClose();
                    }
                } else {
                    setDataSuccess("failed");
                    alert(res.message);
                }
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
           
            const response = await createExpense(formData);
            console.log(response);
        }
    });

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    return (
        <React.Fragment>
            <Col xl={12}>
                <CardBody>
                    <Form className="needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <Row>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">Employee</Label>

                                    <Input name="EmployeeId" type="select"
                                        className="form-control" id="validationCustom01"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.EmployeeId || ""}
                                        invalid={
                                            validation.touched.EmployeeId && validation.errors.EmployeeId ? true : false
                                        } >
                                        {employees &&
                                            employees.map((res) => {
                                                return <option value={res.id} key={res.id}>{res.fullName}</option>
                                            })}
                                    </Input>
                                    {validation.touched.EmployeeId && validation.errors.EmployeeId ? (
                                        <FormFeedback type="invalid">{validation.errors.EmployeeId}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">Loan Type</Label>

                                    <Input name="LoanTypeId" type="select"
                                        className="form-control" id="validationCustom01"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.LoanTypeId || ""}
                                        invalid={
                                            validation.touched.LoanTypeId && validation.errors.LoanTypeId ? true : false
                                        } >
                                        {expenseTypes &&
                                            expenseTypes.map((res) => {
                                                return <option value={res.id} key={res.id}>{res.name}</option>
                                            })}
                                    </Input>
                                    {validation.touched.LoanTypeId && validation.errors.LoanTypeId ? (
                                        <FormFeedback type="invalid">{validation.errors.LoanTypeId}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>                          

                            <Col sm='12' className='mb-1'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Loan Amount</Label>
                                    <Input
                                        name="LoanAmount"
                                        type="number"
                                        placeholder='Loan Amount'
                                        className="form-control"
                                        id="validationCustom02"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.LoanAmount || ""}
                                        invalid={
                                            validation.touched.LoanAmount && validation.errors.LoanAmount ? true : false
                                        }
                                    />
                                    {validation.touched.LoanAmount && validation.errors.LoanAmount ? (
                                        <FormFeedback type="invalid">{validation.errors.LoanAmount}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm='12' className='mb-1'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Loan Installments</Label>
                                    <Input
                                        name="LoanInstallment"
                                        type="number"
                                        placeholder='Loan Installments'
                                        className="form-control"
                                        id="validationCustom02"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.LoanInstallment || ""}
                                        invalid={
                                            validation.touched.LoanInstallment && validation.errors.LoanInstallment ? true : false
                                        }
                                    />
                                    {validation.touched.LoanInstallment && validation.errors.LoanInstallment ? (
                                        <FormFeedback type="invalid">{validation.errors.LoanInstallment}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm='12' className='mb-1'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Description</Label>
                                    <Input
                                        name="Description"
                                        type="textarea"
                                        rows="4"
                                        placeholder='Descaription....'
                                        className="form-control"
                                        id="validationCustom02"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Description || ""}
                                        invalid={
                                            validation.touched.Description && validation.errors.Description ? true : false
                                        }
                                    />
                                    {validation.touched.Description && validation.errors.Description ? (
                                        <FormFeedback type="invalid">{validation.errors.Description}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                           
                        </Row>
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            className="btn btn-sm" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;
                        <Button className='btn btn-sm m-2' color="secondary" type="button" onClick={props.onClose}>Close</Button>
                        <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
                            {dataSuccess === 'added' && <SuccessAlert />}
                            {dataSuccess === 'failed' && <FailureAlert />}
                        </div>
                    </Form>
                </CardBody>
            </Col>
        </React.Fragment>
    );
};

export default LoanRequestDetail;