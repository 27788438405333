import { Fragment, useEffect } from "react"

import { Alert, Button, Card, CardHeader, CardTitle, Col, Container, FormGroup, Input, InputGroup, Label, Row, UncontrolledAlert } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { getDepartments, createDepartments, getDepartmentTypes, getApplicationRoles } from "../../../helpers/backend_helper";
import LoadingSpinner from "../../shared/Loading";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { showLoading } from 'react-global-loading';

const SettingsRoles = () => {
    let gridInstance;
    const [rolesList, setrolesListData] = useState();
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [isLoading, setIsLoading] = useState(false);
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: 0
    });
    const [errors, setErrors] = useState({});

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const loadData = () => {
        showLoading(true);
        getApplicationRoles().then(res => {
            if (res.data) {
                setrolesListData(res.data)
            }
            showLoading(false);
        });
    }

    useEffect(() => {
        console.log("inside useEffect");
        loadData();
    }, [submitted]);

    const Loading = () => {
        return (
            <h3 className="text-primary text-center">
                <i className="bx bx-loader bx-spin"></i>{" "}
                Loading....
            </h3>
        )
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const statusTemplate = (props) => {

        return (<div>{props.status === 1 ?
            <div id="status" className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div id="status" className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }

    return (

        <Fragment>
            <div className="m-2">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Roles" 
                    enableAction={false} actionName="Add Role" onClick={toggleCanvasEnd}></Breadcrumbs>
                    <Card className='overflow-hidden'>                        
                        <GridComponent dataSource={rolesList} allowPaging={true} pageSettings={{ pageCount: 25, pageSizes: true }} allowResizing={true}
                            toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid}
                            statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true}>
                            <Inject services={[Toolbar]} />
                            <ColumnsDirective>
                                <ColumnDirective field="name" headerText='Role Name' width='300'></ColumnDirective>
                                <ColumnDirective field="description" headerText='Description' />
                                <ColumnDirective field="updatedOn" type="date" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='100' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize]} />
                        </GridComponent>
                    </Card>
                </Container>

                <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                    <OffcanvasHeader toggle={toggleCanvasEnd}>
                        <Row>
                            <Col sm='12'>
                                <b>Role Details</b>
                            </Col>
                        </Row>
                    </OffcanvasHeader>
                    <hr />

                    <OffcanvasBody>
                        <FormGroup>
                            <Row className="mb-2">
                                <Col sm="12" md="12">
                                    Role Name
                                    <Input type="text"
                                        name='roleName' placeholder="Enter Role Name"></Input>
                                    {/* <p style={{ color: "Red" }}>{errors.roleName && <span>{errors.roleName}</span>}</p> */}
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col sm="12" md="12">
                                    Role Description
                                    <Input type="textarea"
                                        name="description" placeholder="Enter Role Description"></Input>
                                    {/* <p style={{ color: "Red" }}>{errors.bankIFSC && <span>{errors.bankIFSC}</span>}</p> */}
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col sm="12" md="12">
                                    Status
                                    <Input type="checkbox" className="form-check"
                                        name="description" ></Input>
                                    {/* <p style={{ color: "Red" }}>{errors.bankIFSC && <span>{errors.bankIFSC}</span>}</p> */}
                                </Col>
                            </Row>

                            <div className="text-center mt-2">
                                {dataSuccess === 'added' && <SuccessAlert />}
                                {dataSuccess === 'failed' && <FailureAlert />}
                            </div>

                            <div className="offcanvas-footer">
                                {isLoading ? <LoadingSpinner /> : <ButtonComponent color="primary"
                                    disabled={isLoading}
                                    type="submit">Submit</ButtonComponent>}&nbsp;
                                <button onClick={toggleCanvasEnd} className="btn btn-dark btn-sm">Close</button>
                            </div>
                        </FormGroup>

                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </Fragment>


    );
}

export default SettingsRoles;