import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import images
import giftBox from "../../assets/images/giftbox.png";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarList from "./SidebarList";
import { getLoggedInUser } from "../../helpers/backend_helper";

const SidebarContent = props => {
    const ref = useRef();
    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const path = useLocation();
    const activeMenu = useCallback(() => {
        const pathName = path.pathname;
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [path.pathname, activateParentDropdown]);

    useEffect(() => {
        ref.current.recalculate();

        let userData = getLoggedInUser();
        console.log("Userdata");
        console.log(userData);
        if (userData.roleId !== 1) {
            // Menu Based on role
        }

    }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
        activeMenu();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        activeMenu();
    }, [activeMenu]);

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    return (
        <React.Fragment>
            <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li>
                            <Link to="/" className="has-arrow">
                                <FeatherIcon icon="home" />
                                <span>{props.t("Dashboard")}</span>
                            </Link>
                            <ul className="sub-menu">                                
                                <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {} }>
                                    <Link to="/dashboard">{props.t("Company Dashboard")}</Link>
                                </li>
                                <li style={getLoggedInUser().roleId != 6 ? { display: 'none' } : {}}>
                                    <Link to="/districtdashboard">{props.t("District Dashboard")}</Link>
                                </li>
                                <li>
                                    <Link to="/attendancedashboard">{props.t("Attendance Dashboard")}</Link>
                                </li>
                                <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>
                                    <Link to="/payrolldashboard">{props.t("Payroll Dashboard")}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/myboard">
                                <FeatherIcon icon="book-open" />
                                <span>{props.t("My Board")}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/" className="has-arrow">
                                <FeatherIcon icon="grid" />
                                <span>{props.t("HR")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/hr/employeedirectory">{props.t("Employees")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/employeecompensation">{props.t("Compensation")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/transferlist">{props.t("Transfer")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/incrementlist">{props.t("Increment")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/promotionlist">{props.t("Promotion")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/insurancelist">{props.t("Insurance")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/itlist">{props.t("IT Declarations")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/itslablist">{props.t("IT Slabs")}</Link>
                                </li>                                
                                <li>
                                    <Link to="/hr/documentlist">{props.t("Documents")}</Link>
                                </li>                                
                                <li>
                                    <Link to="/hr/policies">{props.t("Dept. Exams")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/policies">{props.t("Noticies")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/suspensionlist">{props.t("Suspension")}</Link>
                                </li>
                                <li>
                                    <Link to="/hr/suspensionlist">{props.t("Cases")}</Link>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/hr/policies">{props.t("Policies")}</Link>*/}
                                {/*</li>*/}
                            </ul>
                        </li>

                        <li>
                            <Link to="/" className="has-arrow">
                                <FeatherIcon icon="users" />
                                <span>{props.t("Vendors")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/hr/vendors">
                                        {props.t("Vendors List")}
                                    </Link>
                                </li>
                                <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>
                                    <Link to="/vendors/payment">
                                        {props.t("Payments")}
                                    </Link>
                                </li>                               
                            </ul>
                        </li>

                        <li>
                            <Link to="/" className="has-arrow">
                                <FeatherIcon icon="users" />
                                <span>{props.t("Consultants")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/hr/consultant">
                                        {props.t("Consultants")}
                                    </Link>
                                </li>
                                <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>
                                    <Link to="/consultant/payments">
                                        {props.t("Payments")}
                                    </Link>
                                </li>                                
                            </ul>
                        </li>

                        <li style={getLoggedInUser().roleId != 1 && getLoggedInUser().roleId != 5 && getLoggedInUser().roleId != 2 && getLoggedInUser().roleId != 7 && getLoggedInUser().roleId != 8 ? { display: 'none' } : {}}>
                            <Link to="/" className="has-arrow">
                                <FeatherIcon
                                    icon="file-text"
                                />
                                <span>{props.t("Accounts")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/payroll/summary">{props.t("Monthly Payroll")}</Link>
                                </li>
                                <li>
                                    <Link to="/payroll/payslips">{props.t("Payslips")}</Link>
                                </li>
                                <li style={{ display:'none' }}>
                                    <Link to="/payroll/directdeposits">{props.t("Bank Transfer")}</Link>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link to="/payroll/forms">{props.t("Forms")}</Link>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link to="/payroll/compliance">{props.t("Compliance")}</Link>
                                </li>
                                <li>
                                    <Link to="/payroll/otallowance">{props.t("One Time Allowance")}</Link>
                                </li>
                                <li>
                                    <Link to="/payroll/otdeduction">{props.t("One Time Deduction")}</Link>
                                </li>
                                <li>
                                    <Link to="/payroll/arrearslist">{props.t("Arrears")}</Link>
                                </li>
                                <li>
                                    <Link to="/payroll/incometaxlist">{props.t("Income Tax")}</Link>
                                </li>                                
                                <li>
                                    <Link to="/payroll/salarycorrection">{props.t("Salary Correction")}</Link>
                                </li>
                                <li>
                                    <Link to="/payroll/salarypaymentlist">{props.t("Salary Payment")}</Link>
                                </li>
                                <li>
                                    <Link to="/payroll/deductionpaymentlist">{props.t("Deduction Payment")}</Link>
                                </li>
                                <li>
                                    <Link to="/leave/leaveencashment">{props.t("Leave Encashment")}</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/" className="has-arrow ">
                                <FeatherIcon icon="users" />
                                <span>{props.t("Attendance")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/attendance/management">{props.t("Attendance Management")}</Link>
                                </li>
                                <li>
                                    <Link to="/attendance/create">{props.t("Manual Time Entry")}</Link>
                                </li>                                
                                <li style={{ display: 'none' }}>
                                    <Link to="/attendance/import">{props.t("Attendance Import")}</Link>
                                </li>
                                <li>
                                    <Link to="/attendance/report">{props.t("Attendance Report")}</Link>
                                </li>
                                <li>
                                    <Link to="/attendance/detailreport">{props.t("Attendance Detail Report")}</Link>
                                </li>
                                <li>
                                    <Link to="/attendance/reportlist">{props.t("Submit Monthly Report")}</Link>
                                </li>
                                <li>
                                    <Link to="/attendance/monthlyreport">{props.t("Monthly Attendance Report")}</Link>
                                </li>
                            </ul>
                        </li>

                        {/*<li>*/}
                        {/*    <Link to="/" className="has-arrow ">*/}
                        {/*        <FeatherIcon icon="mail" />*/}
                        {/*        <span>{props.t("Task")}</span>*/}
                        {/*    </Link>*/}
                        {/*    <ul className="sub-menu">                                */}
                        {/*        <li>*/}
                        {/*            <Link to="/task/taskboard">{props.t("Task Box")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to="/task/tasksearch">{props.t("Task Search")}</Link>*/}
                        {/*        </li>                                */}
                        {/*    </ul>*/}
                        {/*</li>*/}

                        <li>
                            <Link to="/" className="has-arrow ">
                                <FeatherIcon
                                    icon="file"
                                />
                                <span>{props.t("Leave")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/leave/leave-request">{props.t("Leave Request")}</Link>
                                </li>
                                <li>
                                    <Link to="/leave/pendingleaverequest">{props.t("For Approval")}</Link>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/leave/leave-calendar">{props.t("Leave Calendar")}</Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <Link to="/leave/leaveencashment">{props.t("Leave Encashment")}</Link>*/}
                                {/*</li>*/}
                                <li>
                                    <Link to="/leave/manageleaves">{props.t("Manage Leaves")}</Link>
                                </li>
                            </ul>
                        </li>

                        <li style={{ display:'none' }}>
                            <Link to="/expense" className="has-arrow ">
                                <FeatherIcon
                                    icon="briefcase"
                                />
                                <span>{props.t("Expenses")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/expense-list">{props.t("Add Expense")}</Link>
                                </li>
                                <li>
                                    <Link to="/expense/expenserequest">{props.t("Expense Request")}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/loan" className="has-arrow ">
                                <FeatherIcon icon="briefcase" />
                                <span>{props.t("Loans")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/loan/loanlist">{props.t("Loan List")}</Link>
                                </li>
                                <li>
                                    <Link to="/loan/loanrequest">{props.t("Loan Request")}</Link>
                                </li>
                            </ul>
                        </li>

                        <li style={{ 'display': 'none' }}>
                            <Link to="/" className="has-arrow ">
                                <FeatherIcon icon="sliders" />
                                <span>{props.t("Self Service")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/hr/employee/view/2">{props.t("Profile")}</Link>
                                </li>
                                <li>
                                    <Link to="/payroll/mypayslips">{props.t("Payslips")}</Link>
                                </li>
                                <li>
                                    <Link to="/expense/myexpenserequest">{props.t("Expenses")}</Link>
                                </li>
                                <li>
                                    <Link to="">{props.t("IT Declarations")}</Link>
                                </li>
                                <li>
                                    <Link to="">{props.t("Loans")}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/reports">
                                <FeatherIcon icon="pie-chart" />
                                <span>{props.t("Reports")}</span>
                            </Link>
                        </li>
                        {/*    <ul className="sub-menu">*/}
                        {/*        <li>*/}
                        {/*            <Link to="/reports/EmployeeAttendanceReport">{props.t("Attendance Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to="/reports/bankDetailsReport">{props.t("Bank Details Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to="/reports/salaryreport">{props.t("Salary Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to="/reports/salarysummaryreport">{props.t("Salary Summary Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to="/reports/salarydetailreport">{props.t("Salary Detail Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                        {/*            <Link to="/reports/salarybankreport">{props.t("Salary Bank Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to="/reports/allowancereport">{props.t("Allowance Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to="/reports/deductionreport">{props.t("Deduction Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <Link to="/reports/leaveusagereport">{props.t("Leave Usage Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li style={{ display: 'none' }}>*/}
                        {/*            <Link to="/reports/expensereport">{props.t("Expense Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                        {/*            <Link to="/reports/VendorPaymentReport">{props.t("Vendor Payment Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                        {/*            <Link to="/reports/ConsultantPaymentReport">{props.t("Consultant Payment Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                        {/*            <Link to="/reports/TDSReport">{props.t("TDS Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                        {/*            <Link to="/reports/GSTReport">{props.t("GST Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li style={{ display: 'none' }}>*/}
                        {/*            <Link to="/reports/PFReport">{props.t("PF Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*        <li style={{ display: 'none' }}>*/}
                        {/*            <Link to="/reports/PTReport">{props.t("PT Report")}</Link>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}

                        <li>
                            <Link to="/" className="has-arrow " style={(getLoggedInUser().roleId == 1 || getLoggedInUser().roleId == 5) ? {} : { display: 'none' }}>
                                <FeatherIcon
                                    icon="settings"
                                />
                                <span>{props.t("Settings")}</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/settings/company">{props.t("Company")}</Link>
                                </li>
                                <li>
                                    <Link to="/settings/employee">{props.t("Employee")}</Link>
                                </li>
                                <li>
                                    <Link to="/settings/account">{props.t("Account")}</Link>
                                </li>
                                {/*<li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                                {/*    <Link to="/settings/location">*/}
                                {/*        {props.t("Location")}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <Link to="/settings/tasktype">{props.t("Task")}</Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <Link to="/settings/departments">{props.t("Departments")}</Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <Link to="/settings/leavetype">{props.t("Leave Type")}</Link>*/}
                                {/*</li>*/}
                                <li style={{ display: 'none' }}>
                                    <Link to="/settings/leavepolicy">
                                        {props.t("Leave Policy")}
                                    </Link>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/settings/holidaypolicy">*/}
                                {/*        {props.t("Holiday List")}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                                {/*    <Link to="/settings/employeetype">*/}
                                {/*        {props.t("Employee Type")}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li style={{ display: 'none' }}>
                                    <Link to="/settings/shifttype">
                                        {props.t("Shift Type")}
                                    </Link>
                                </li>
                                {/*<li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                                {/*    <Link to="/settings/grades">*/}
                                {/*        {props.t("Grades")}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>
                                    <Link to="/settings/payscales">
                                        {props.t("Payscale")}
                                    </Link>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link to="/settings/work-schedule">
                                        {props.t("Work Schedule")}
                                    </Link>
                                </li>
                                <li style={{ display: 'none' }}>
                                    <Link to="/settings/expensetype">
                                        {props.t("Expense Type")}
                                    </Link>
                                </li>
                                {/*<li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                                {/*    <Link to="/settings/allowancetype">*/}
                                {/*        {props.t("Allowance Type")}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                                {/*    <Link to="/settings/deductiontype">*/}
                                {/*        {props.t("Deduction Type")}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                                {/*    <Link to="/settings/financialyear">*/}
                                {/*        {props.t("Financial Year")}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                                {/*    <Link to="/settings/payperiod">*/}
                                {/*        {props.t("Pay Period")}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>
                                    <Link to="/settings/incometax">
                                        {props.t("Income Tax")}
                                    </Link>
                                </li>
                                {/*<li style={getLoggedInUser().roleId != 1 ? { display: 'none' } : {}}>*/}
                                {/*    <Link to="/settings/loantype">*/}
                                {/*        {props.t("Loan Type")}*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li style={{ display: 'none' }}>
                                    <Link to="/settings/expensepolicy">
                                        {props.t("Expense Policy")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/settings/security">
                                        {props.t("Security")}
                                    </Link>
                                </li>
                            </ul>
                        </li>

                    </ul>

                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
