import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";

const RoundingAmount = () => {

    return (
        <>
            <Card>

                <CardBody>
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            Rounding Amount Type
                        </Col>
                        <Col lg='4' sm='3' md='3'>
                            <Input type="radio" value={1} readOnly name="weeklyOff">Yes</Input>&nbsp;No Round
                            <br />
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;Actual Round
                            <br />
                            <Input type="radio" value={3} checked readOnly='true' name="weeklyOff">No</Input>&nbsp;Round Up
                            <br />
                            <Input type="radio" value={3} checked readOnly='true' name="weeklyOff">No</Input>&nbsp;Round Down

                        </Col>
                    </Row>
                    <br />

                </CardBody>
            </Card>
        </>
    )
}

export default RoundingAmount;