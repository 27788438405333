import React from 'react';

import ReactApexChart from "react-apexcharts"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import CountUp from "react-countup";

// import Locations from './Locations';
import RecentLeave from './RecentLeave';
import RecentExpense from './RecentExpense';
import RecentActivity from './RecentActivity';
import EmployeeChart from './EmployeeChart';
import PieChart from './PieChart';
import EmployeeCount from './EmployeeCount';
import AttendanceCount from './AttendanceCount';
import GenderCount from './GenderCount';
import AttendancePerformance from './AttendancePerformance';
import AttendanceChart from './AttendanceChart';
import EmployeeTypeCount from './EmployeeTypeCount';
import EmployeeGroupCount from './EmployeeGroupCount';

const options = {
    chart: {
        height: 50,
        type: "line",
        toolbar: { show: false },
    },
    colors: ["#5156be"],
    stroke: {
        curve: 'smooth',
        width: 2,
    },
    xaxis: {
        labels: {
            show: false
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            show: false
        }
    },
    yaxis: {
        labels: {
            show: false
        }
    },
    tooltip: {
        fixed: {
            enabled: false
        },
        x: {
            show: false
        },
        y: {
            title: {
                formatter: function (seriesName) {
                    return ''
                }
            }
        },
        marker: {
            show: false
        }
    }
};

const DistrictDashboard = () => {

    //meta title
    document.title = "District Dashboard";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="District Dashboard" />

                    <Row>                        
                            <Col xl={3} md={6}>
                                <EmployeeCount />
                            </Col>

                            <Col xl={3} md={6}>
                                <EmployeeTypeCount />
                            </Col>

                            <Col xl={3} md={6}>
                                <EmployeeGroupCount />
                            </Col>

                            <Col xl={3} md={6}>                                
                                <GenderCount />
                            </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AttendanceChart />
                        </Col>
                    </Row>                    
                </Container>
            </div>
        </React.Fragment>
    );
}

export default DistrictDashboard;