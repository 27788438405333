import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Filter, Resize } from "@syncfusion/ej2-react-grids";
import { useEffect, useState } from "react";
import {
    getCompanyPayperiods, getCompanyLocations, getDepartments, getEmployeeList, getAllContractors, getUserLocations, getEmployeeTypes
} from "../../../helpers/backend_helper";
import { getData, postData } from "../../../helpers/url-request";
import Select from "react-select"
import { showLoading } from 'react-global-loading';

const AttendanceMonthlyReport = () => {
    let gridInstance;
    const [employeeTypes, setEmployeeTypes] = useState();
    const [employees, setEmployees] = useState();
    const [departments, setDepartments] = useState();
    const [locations, setLocations] = useState();
    const [vendors, setVendors] = useState();
    const [payPeriods, setPayPeriods] = useState();
    const [attendance, setAttendanceDetails] = useState();
    const [formData, setFormData] = useState({
        "payPeriodId": 0,
        "locationId": 0, "departmentId": 0,
        "contractorId": 0, "employeeId": 0, "employeeTypeId": 0
    })

    useEffect(() => {

        getCompanyPayperiods().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "payPeriodTitle": "Select Period" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setPayPeriods(emps)
            }
        });

        getEmployeeList().then(res => {
            if (res.data) {
                let emps = [{ label: "ALL", value: 0 }];                                
                res.data.forEach(el => {
                    emps.push({ label: el.fullName, value: el.id });
                });
                setEmployees(emps)
            }
        });

        getDepartments().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setDepartments(emps)
            }
        });

        getUserLocations().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "locationName": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setLocations(emps)
            }
        });

        getAllContractors().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setVendors(emps)
            }
        });

        getEmployeeTypes().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setEmployeeTypes(emps)
            }
        });        

    }, []);

    const handleChange = event => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    };

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const confirmHandler = (event) => {
        event.preventDefault();
        setAttendanceDetails([]);
        showLoading(true);        
        postData("/attendance/monthlydetails", formData).then(res => {
            setAttendanceDetails(res.data);
            showLoading(false);
        });
    }

    const resetReport = () => {
        setAttendanceDetails([]);
        setFormData({"payPeriodId": 0,
            "locationId": 0, "departmentId": 0,
            "contractorId": 0, "employeeId": 0
        });
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Attendance" breadcrumbItem="Monthly Attendance Report"></Breadcrumbs>
            </Container>
            <CardBody>
                <form onSubmit={confirmHandler}>
                    <Row>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            Month
                            <select className="form-select" name="payPeriodId" onChange={handleChange}>
                                {payPeriods &&
                                    payPeriods.map((payperiod) => {
                                        return <option value={payperiod.id} key={payperiod.id}>{payperiod.payPeriodTitle}</option>
                                    })}
                            </select>
                        </Col>                       
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            Location
                            <select className="form-select" name="locationId" onChange={handleChange}>
                                {locations &&
                                    locations.map((emp) => {
                                        return <option value={emp.id} key={emp.id}>{emp.locationName}</option>
                                    })}
                            </select>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            Department
                            <select className="form-select" name="departmentId" onChange={handleChange}>
                                {departments &&
                                    departments.map((emp) => {
                                        return <option value={emp.id} key={emp.id}>{emp.name}</option>
                                    })}
                            </select>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            Employee Type
                            <select className="form-select" name="employeeTypeId" onChange={handleChange}>
                                {employeeTypes &&
                                    employeeTypes.map((emp) => {
                                        return <option value={emp.id} key={emp.id}>{emp.name}</option>
                                    })}
                            </select>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            Employee Name
                            <Select type="select"
                                placeholder='Select Employee'                                
                                name="employeeId"                                
                                isMulti={false}
                                options={employees}
                                classNamePrefix="form-select"
                                isLoading={false}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            <br />
                            <Button color="primary" className="btn primary" type="submit">Run Report</Button>&nbsp;&nbsp;&nbsp;
                            <Button color="primary" className="btn primary" type="reset" onClick={resetReport}>Clear</Button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
            <hr />
            <GridComponent dataSource={attendance} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5 }}
                allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}
                filterSettings={{ type: 'Excel' }} allowFiltering={true}>
                <ColumnsDirective>
                    <ColumnDirective field="employee.fullName" headerText='Employee Name' width='150'></ColumnDirective>
                    <ColumnDirective field="employee.employeeType.name" headerText='Type' width='150' />
                    <ColumnDirective field="location.locationName" headerText='Location' width='150' />
                    <ColumnDirective field="fromDate" headerText='From Date' type='date' format='dd-MM-yyyy' width='150' />
                    <ColumnDirective field="toDate" headerText='To Date' type='date' format='dd-MM-yyyy' width='150' />
                    <ColumnDirective field="noOfDays" headerText='Days' width='150' />
                    <ColumnDirective field="holidays" headerText='Holidays' width='150'></ColumnDirective>
                    <ColumnDirective field="workingDays" headerText='Working Days' width='150' />
                    <ColumnDirective field="leaves" headerText='Leaves' width='150' />
                    <ColumnDirective field="lop" headerText='LOP' width='150' />
                    <ColumnDirective field="presentDays" headerText='Present' width='150' />
                </ColumnsDirective>
                <Inject services={[Toolbar, Page, Sort, Filter, Resize, ExcelExport, PdfExport, ColumnMenu]} />
            </GridComponent>

        </div>
    )
}

export default AttendanceMonthlyReport;