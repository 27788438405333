import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, ColumnMenu, Sort } from "@syncfusion/ej2-react-grids";

const RecentExpense = () => {

    const dataList = [
        {
            "id": "1",
            "employeeName": "Manjunath G A",
            "expenseType": "Travel",
            "expenseDate": "22/03/2023",
            "expenseAmt": "750",
            "status": "Pending"
        },
        {
            "id": "2",
            "employeeName": "Vijayakumara V",
            "expenseType": "Travel",
            "expenseDate": "22/03/2023",
            "expenseAmt": "750",
            "status": "Pending"
        },
        {
            "id": "3",
            "employeeName": "Vijayakumara V",
            "expenseType": "Food",
            "expenseDate": "22/03/2023",
            "expenseAmt": "150",
            "status": "Pending"
        }
    ]


    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <CardTitle className="mb-0 flex-grow-1">Recent Expense Request</CardTitle>                       
                    </CardHeader>

                    <CardBody className="px-0 py-0">
                        <GridComponent dataSource={dataList} allowPaging={true} pageSettings={{ pageCount: 5 }} height="300px"
                            allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
                            <ColumnsDirective>                                
                                <ColumnDirective field="employeeName" headerText='Employee' />
                                <ColumnDirective field="expenseType" headerText='Expense Type' width='125' />
                                <ColumnDirective field="expenseDate" headerText='Date' width='125' />
                                <ColumnDirective field="expenseAmt" headerText='Amount' width='125' />
                                <ColumnDirective field="status" headerText='Status' width='125' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                        </GridComponent>
                    </CardBody>

                </Card>

            </Col>
        </React.Fragment>
    );
}

export default RecentExpense;