import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//Import Icons
import FeatherIcon from "feather-icons-react";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const Navbar = props => {

  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [contact, setcontact] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [auth, setauth] = useState(false);
  const [utility, setutility] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
          <div className="topnav">
              <div className="container-fluid" style={{ paddingBottom: '0px' }}>
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation" >
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
                <ul className="navbar-nav">

                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none">
                        <FeatherIcon icon="home" />
                        {props.t("Dashboard")} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname("dropdown-menu", { show: app })}>
                        <Link to="/dashboard" className="dropdown-item">{props.t("Company Dashboard")}</Link>
                        <Link to="/attendancedashboard" className="dropdown-item">{props.t("Attendance Dashboard")}</Link>
                        <Link to="/payrolldashboard" className="dropdown-item">{props.t("Payroll Dashboard")}</Link>                                      
                    </div>
                </li>

                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle arrow-none">
                        <FeatherIcon icon="grid" />
                        {props.t("HR Management")} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname("dropdown-menu", { show: app })}>
                        <Link to="/hr/employeedirectory" className="dropdown-item">{props.t("Employees")}</Link>
                        <Link to="/hr/employeecompensation" className="dropdown-item">{props.t("Compensation")}</Link>
                        <Link to="/hr/transferlist" className="dropdown-item">{props.t("Transfer")}</Link>
                        <Link to="/hr/incrementlist" className="dropdown-item">{props.t("Increment")}</Link>
                        <Link to="/hr/promotionlist" className="dropdown-item">{props.t("Promotion")}</Link>
                        <Link to="/hr/insurancelist" className="dropdown-item">{props.t("Insurance")}</Link>
                        <Link to="/hr/itlist" className="dropdown-item">{props.t("IT Declaration")}</Link>
                        <Link to="/hr/documentlist" className="dropdown-item">{props.t("Documents")}</Link>
                        <Link to="/hr/suspensionlist" className="dropdown-item">{props.t("Suspension")}</Link>
                        <Link to="/hr/policies" className="dropdown-item">{props.t("Policies")}</Link>
                    </div>
                </li>
                <li className="nav-item dropdown">
                                  <Link to="#" className="nav-link dropdown-toggle arrow-none">
                                    <FeatherIcon icon="users" />
                                    {props.t("Vendors")} <div className="arrow-down"></div>
                                </Link>
                              </li>
                              <li className="nav-item dropdown">
                                  <Link to="#" className="nav-link dropdown-toggle arrow-none">
                                      <FeatherIcon icon="users" />
                                      {props.t("Consultants")} <div className="arrow-down"></div>
                                  </Link>
                              </li>
                              <li className="nav-item dropdown">
                                  <Link to="#" className="nav-link dropdown-toggle arrow-none">
                                      <FeatherIcon icon="file-text" />
                                      {props.t("Payroll")} <div className="arrow-down"></div>
                                  </Link>
                              </li>
                              <li className="nav-item dropdown">
                                  <Link to="#" className="nav-link dropdown-toggle arrow-none">
                                      <FeatherIcon icon="users" />
                                      {props.t("Attendance")} <div className="arrow-down"></div>
                                  </Link>
                              </li>
                              <li className="nav-item dropdown">
                                  <Link to="#" className="nav-link dropdown-toggle arrow-none">
                                      <FeatherIcon icon="file" />
                                      {props.t("Leave")} <div className="arrow-down"></div>
                                  </Link>
                              </li>
                              <li className="nav-item dropdown">
                                  <Link to="#" className="nav-link dropdown-toggle arrow-none">
                                      <FeatherIcon icon="pie-chart" />
                                      {props.t("Reports")} <div className="arrow-down"></div>
                                  </Link>
                              </li>
                              <li className="nav-item dropdown">
                                  <Link to="#" className="nav-link dropdown-toggle arrow-none">
                                      <FeatherIcon icon="settings" />
                                      {props.t("Settings")} <div className="arrow-down"></div>
                                  </Link>
                              </li>

              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
