import ReactBpmn from "react-bpmn";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import { Card, Container } from "reactstrap";
import "./bpmn.css";

const BPMNWorkflow = props => {
    const [bpmndiagram , setBPMNDiagram] = useState();

    useEffect(()=>{
        // "/hrms/bpmn/diagram.bpmn"
        setBPMNDiagram(props.url)
    },[])   

    function onShown() {
        console.log("diagram shown");
    }

    function onLoading() {
        console.log("diagram loading");
    }

    function onError(err) {
        console.log("failed to show diagram");
    }

    return (
        <>
            <div id="pipeline_graph">
                <ReactBpmn
                    className="bpmndiagram"
                    url={bpmndiagram}
                    onShown={onShown}
                    onLoading={onLoading}
                    onError={onError}
                />
            </div>
        </>
    );
}

BPMNWorkflow.propTypes = {
    workflowId: PropTypes.string
}

export default BPMNWorkflow;
