import React, { useEffect, useState } from 'react';
import {
    Card, CardBody, CardHeader, Col, Row, FormGroup, Label, Input, Button, FormFeedback, Form
} from 'reactstrap';
import { getUsersList, getTaskTypeList } from "../../helpers/backend_helper";
import Select from "react-select"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { FailureAlert, SuccessAlert } from '../shared/Alert';
import LoadingSpinner from '../shared/Loading';
import * as url from '../../helpers/url_helper';
import PropTypes from 'prop-types'
import { showLoading } from 'react-global-loading';

const TaskDetail = (props) => {
    const [expenseTypes, setExpenseTypes] = useState();
    const [employees, setEmployees] = useState();

    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("Choose File");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [optionMulti, setoptionMulti] = useState([]);

    useEffect(() => {
        getTaskTypeList().then((res) => {
            if (res.status === true) {
                let expenseTypes = [{ id: 0, name: "Select Task Type" }]
                res.data.forEach(el => {
                    expenseTypes.push(el)
                });
                setExpenseTypes(expenseTypes);
            }
        })

        getUsersList().then((res) => {
            if (res.status === true) {
                let employeesList = [{ id: 0, fullName: "Select User" }]
                let employees = []
                res.data.forEach(el => {
                    employeesList.push(el);
                    employees.push({ label: el.fullName, value: el.userId });
                });
                setoptionMulti(employees);
                setEmployees(employeesList);
            }
        });
    }, []);

    const handleChange = event => {
        console.log(event.target.files[0], event.target.files[0].name);
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    }

    // console.log(expenseTypes[0]);

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            TaskTitle: '',
            TaskTypeId: '',
            AssignedTo: [],
            DueDate: '',
            Description: '',
            Documents: '',
        },
        onSubmit: async (values) => {
            console.log("values", values);

            console.log(values);

            const formData = new FormData();
            formData.append("taskTitle", values.TaskTitle);
            formData.append("taskTypeId", values.TaskTypeId);
            formData.append("dueDate", values.DueDate);
            formData.append("description", values.Description);            
            if (document.getElementsByName("AssignedTo").length > 0) {
                document.getElementsByName("AssignedTo").forEach((element) => {
                    if (element.value > 0) {                        
                        formData.append("assignedTo", element.value);
                    }
                });                
            } else {
                console.log("Inside Else");
                formData.append("assignedTo", []);
            }

            console.log(formData.values);

            showLoading(true);
            const response = await createTask(formData);
            showLoading(false);
            console.log(response);
            if (props.closeAction) {
                props.closeAction();
            }
        }
    });

    const createTask = async body => {
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        var token = userdata.token;        
        let requestOptions = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,                
                apiKey: 'key123',
            },
            body,
        };
        return fetch(url.BASE_URL + '/task/create', requestOptions)
            .then(response => {
                console.log({ response });
                return response;
            })
            .catch(err => {
                console.log({ err });
            });
    };

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    return (
        <React.Fragment>
            <Col xl={12}>
                <CardBody>
                    <Form className="needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <Row>

                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">Task Type</Label>

                                    <Input name="TaskTypeId" type="select"
                                        className="form-control" id="validationCustom01"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.TaskTypeId || ""}
                                        invalid={
                                            validation.touched.TaskTypeId && validation.errors.TaskTypeId ? true : false
                                        } >
                                        {expenseTypes &&
                                            expenseTypes.map((res) => {
                                                return <option value={res.id} key={res.id}>{res.name}</option>
                                            })}
                                    </Input>
                                    {validation.touched.TaskTypeId && validation.errors.TaskTypeId ? (
                                        <FormFeedback type="invalid">{validation.errors.TaskTypeId}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm='12' className='mb-1'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom03">Title</Label>
                                    <Input
                                        name="TaskTitle"
                                        type="text"
                                        placeholder='Task Title'
                                        className="form-control"
                                        id="validationCustom03"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.TaskTitle || ""}
                                        invalid={
                                            validation.touched.TaskTitle && validation.errors.TaskTitle ? true : false
                                        }
                                    />
                                    {validation.touched.TaskTitle && validation.errors.TaskTitle ? (
                                        <FormFeedback type="invalid">{validation.errors.TaskTitle}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Due Date</Label>
                                    <Input name="DueDate"
                                        type="date" className="form-control" id="validationCustom02" onChange={validation.handleChange}
                                        onBlur={validation.handleBlur} value={validation.values.DueDate || ""}
                                        invalid={
                                            validation.touched.DueDate && validation.errors.DueDate ? true : false
                                        }
                                    />
                                    {validation.touched.DueDate && validation.errors.DueDate ? (
                                        <FormFeedback type="invalid">{validation.errors.DueDate}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="AssignedTo">Assigned To</Label>

                                    <Select type="select"
                                        placeholder='Select User'
                                        name="AssignedTo"
                                        isMulti={true}
                                        options={optionMulti}
                                        classNamePrefix="select2-selection"
                                        isLoading={false}
                                        onChange={e => {
                                            var _values = [];
                                            for (var i = 0; i < e.length; i++) {                                                
                                                _values.push(e[i].value);
                                            }

                                            validation.setFieldValue("AssignedTo", _values);
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm='12' className='mb-1'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Description</Label>
                                    <Input
                                        name="Description"
                                        type="textarea"
                                        placeholder='Description....'
                                        className="form-control"
                                        id="validationCustom02"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Description || ""}
                                        invalid={
                                            validation.touched.Description && validation.errors.Description ? true : false
                                        }
                                    />
                                    {validation.touched.Description && validation.errors.Description ? (
                                        <FormFeedback type="invalid">{validation.errors.Description}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                        </Row>
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            className="btn btn-sm" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;
                        <Button className='btn btn-sm m-2' color="secondary" type="button" onClick={props.closeAction}>
                            Close
                        </Button>

                        <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
                            {dataSuccess === 'added' && <SuccessAlert />}
                            {dataSuccess === 'failed' && <FailureAlert />}
                        </div>
                    </Form>
                </CardBody>
            </Col>
        </React.Fragment>
    );
};

TaskDetail.propTypes = {
    closeAction: PropTypes.func
}

export default TaskDetail;