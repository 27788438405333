import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";

import './styles.css'

const CompliancePF = () => {

    return (
        <React.Fragment>

            <CardBody>
                <h4>Provident Fund(PF):</h4>
                <br />
                <Row>
                    <Col sm='12' lg='12' md='12'>
                        The funds thus collected are made of contributions partly from employees and
                        partly from their employers. Contributions from employees as well as employers
                        add to the EPF. However, unlike what is commonly thought to be, the entire portion of contribution from an employer doesn’t go exclusively towards the Employees Provident Fund.
                    </Col>
                </Row>
                <br /><br />
                <b>Division of funds as of November 2015</b>
                <br /><br />

                1) 12% of Basic Salary and PF applicable allowances of Employee goes directly
                towards Employees’ Provident Fund.
                <br />
                2) 13% of Basic Salary and PF applicable allowances of Employer is divided as
                follows
                <br />
                <ul>
                    <li>3.67% of contribution towards Employees’ Provident Fund</li>
                    <li>0.5% of contribution towards EPF Administration Charges</li>
                    <li>0.5% of contribution towards EDLI Administration Charges</li>
                    <li>8.33% of contribution towards Employees’ Pension Scheme</li>
                </ul>            
                               
                
                Minimum salary limits: Employees with monthly salaries less than or equal to
                INR 15,000 now have to contribute mandatorily towards EPF. PF will be calculated as
                12% of the basic and PF applicable allowances.


            </CardBody>

        </React.Fragment >
    );
};

export default CompliancePF;
