import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Resize } from "@syncfusion/ej2-react-grids";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const LeaveUsageReport = () => {
    let gridInstance;

    useEffect(() => {

    }, []);


    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.text) {
            case 'PDF Export':
                gridInstance.pdfExport();
                break;
            case 'Excel Export':
                gridInstance.excelExport();
                break;
            case 'CSV Export':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <div className="page-content">
            <Breadcrumbs title="HR Management" breadcrumbItem="Leave Usage Report" enableAction={false}></Breadcrumbs>
            <CardBody>
                <Row>
                    <Col sm='3' md='3' lg='3'>
                        Select Financial year
                        <select className="form-select">
                            <option>2022-2023</option>
                        </select>
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Select Frequency
                        <select className="form-select">
                            <option>Monthly</option>
                            <option>Quarterly</option>
                            <option>Yearly</option>
                        </select>
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Select Period
                        <select className="form-select">
                            <option>Feb, 2023</option>
                        </select>
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Select Batch
                        <select className="form-select">
                            <option>Select All</option>
                        </select>
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Select Department
                        <select className="form-select">
                            <option>All</option>
                        </select>
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Employee Name
                        <select className="form-select">
                            <option>-</option>
                        </select>
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        <br />
                        <Button color="primary" className="btn primary" type="submit">View Report</Button>&nbsp;&nbsp;&nbsp;
                        <Button color="primary" className="btn primary" type="reset">Clear</Button>
                    </Col>
                </Row>
            </CardBody>
            <hr />

            <Row>
                <Col sm='12' md='12' lg='12'>
                    <CardBody>
                        <GridComponent allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5 }}
                            allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}>
                            <ColumnsDirective>
                                <ColumnDirective headerText='Employee Name' width='150'></ColumnDirective>
                                <ColumnDirective headerText='Employee Code' width='150'></ColumnDirective>
                                <ColumnDirective headerText='Leave Date' width='150'></ColumnDirective>
                                <ColumnDirective headerText="BANK IFSC" width='150' />
                                <ColumnDirective headerText='BRANCH NAME' width='150' />
                                <ColumnDirective headerText='NAME ON ACCOUNT' width='150' />
                                <ColumnDirective headerText='ACCOUNT NUMBER ' width='150' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Resize]} />
                        </GridComponent>
                    </CardBody>
                </Col>
            </Row>
        </div>
    )
}

export default LeaveUsageReport;