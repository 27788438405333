import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, ColumnMenu, Sort } from "@syncfusion/ej2-react-grids";

//SimpleBar
import SimpleBar from "simplebar-react"


const RecentLeave = () => {

    const dataList = [
        {
            "id": "1",
            "employeeName": "Manjunath G A",
            "leaveType": "Casual",
            "leaveDate": "26/03/2023",
            "status": "Pending"
        },
        {
            "id": "2",
            "employeeName": "Vijayakumara V",
            "leaveType": "Sick",
            "leaveDate": "27/03/2023",
            "status": "Pending"
        },
    ]

    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <CardTitle className="mb-0 flex-grow-1">Recent Leave Request</CardTitle>                       
                    </CardHeader>

                    <CardBody className="px-0 py-0">
                        <GridComponent dataSource={dataList} allowPaging={true} pageSettings={{ pageCount: 5 }} height="300px"
                            allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
                            <ColumnsDirective>                                
                                <ColumnDirective field="employeeName" headerText='Employee' />
                                <ColumnDirective field="leaveType" headerText='Leave Type' width='160' />
                                <ColumnDirective field="leaveDate" headerText='Date' width='120' />
                                <ColumnDirective field="status" headerText='Status' width='120' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                        </GridComponent>
                    </CardBody>

                </Card>

            </Col>
        </React.Fragment>
    );
}

export default RecentLeave;