import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort } from "@syncfusion/ej2-react-grids";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";

const MyExpenseRequest = () => {

    const [expenseList, setExpenseList] = useState();
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);

    const dataList = [
        {
            id: 10,
            expenseType: {
                name: "asdasas"
            }
        }
    ]

    useEffect(() => {
        setExpenseList(dataList);
    }, []);


    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const viewEmployee = (EmpId) => {
        toggleCanvasEnd();
    }

    const headerLink = props => {
        console.log(props);
        return (
            <button onClick={() => viewEmployee(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.expenseType.name}
            </button>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Expense" breadcrumbItem="My Expense Requests" enableAction={false} />

                <div className='mt-2'>
                    <GridComponent dataSource={expenseList} allowPaging={true} allowSorting={true} showColumnMenu={true} 
                        statelessTemplates={['directiveTemplates']} pageSettings={{ pageCount: 5 }}>
                        <ColumnsDirective>
                            <ColumnDirective field='expenseType.name' headerText='Expense Type' template={headerLink} width='150' ></ColumnDirective>
                            <ColumnDirective field='expenseDate' type='datetime' format='dd/MM/yyyy' headerText='Expense Incurred Date' width='150'></ColumnDirective>
                            <ColumnDirective field='amount' headerText='Amount (Rs/-)' width='150'></ColumnDirective>
                            <ColumnDirective field='description' headerText='Description' width='150' />
                            <ColumnDirective field='expenseStatus' headerText='Status' width='150' />
                            <ColumnDirective headerText='Action' width='150'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                    </GridComponent>
                </div>

                <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen}
                    toggle={toggleCanvasEnd}>
                    <OffcanvasHeader toggle={toggleCanvasEnd}>
                        <Row>
                            <Col sm='12'>
                                <b>Expense Detail</b>
                            </Col>
                        </Row>
                    </OffcanvasHeader>
                    <hr />
                    <OffcanvasBody>
                        asdasdasd
                    </OffcanvasBody>
                </Offcanvas>
            </div>            
        </React.Fragment>
    );
};

export default MyExpenseRequest;