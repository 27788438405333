import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import classnames from "classnames";
import IncomeTaxSlab from "./income-tax";
import ProfessionalTax from "./professional-tax";
import CompliancePF from "./pf";
import ComplianceESI from "./ESI";


const PayrollCompliance = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab);
            }
        }
    }

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="HR Management" breadcrumbItem="Compliance" enableAction={false}></Breadcrumbs>

                    <CardBody>

                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>Income Tax Slabs</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>Profession Tax</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            setactiveTab(3);
                                        }}>
                                        <span>PF</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 4 })}
                                        onClick={() => {
                                            setactiveTab(4);
                                        }}>
                                        <span>ESI</span>
                                    </NavLink>
                                </NavItem>                               
                            </Nav>

                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <IncomeTaxSlab />
                                </TabPane>
                                <TabPane tabId={2}>
                                    <ProfessionalTax />
                                </TabPane>

                                <TabPane tabId={3}>
                                    <CompliancePF />
                                </TabPane>

                                <TabPane tabId={4}>
                                    <ComplianceESI />
                                </TabPane>

                            </TabContent>

                        </div>
                    </CardBody>
                </Container >
            </div >
        </React.Fragment >
    );
};

export default PayrollCompliance;
