import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";

const PayPeriod = () => {

    const submitBankDetails = (event) => {
        event.preventDefault();

    }

    const timeout = () => {
        setTimeout(() => {
            // setDataSuccess('')
        }, 3000);
    }

    return (
        <>
            <Card>

                <CardBody>
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='3' sm='3' md='3'>
                            Frequency
                        </Col>
                        <Col lg='3' sm='3' md='3'>
                            Monthly
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='3' sm='3' md='3'>
                            Pay Period Start Day
                        </Col>
                        <Col lg='4' sm='3' md='3'>
                            <select className="form-select" disabled>
                                <option>1st of Month</option>
                            </select>
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='3' sm='3' md='3'>
                            Starting Month/Year
                        </Col>
                        <Col lg='4' sm='3' md='3'>
                            December 2022
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='3' sm='3' md='3'>
                            Pay Days
                        </Col>
                        <Col lg='4' sm='3' md='3'>
                            <select className="form-select" disabled>
                                <option>Actual Days in Month</option>
                                <option>Working Days in Month</option>
                            </select>
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='3' sm='3' md='3'>
                            Include Weekly Offs
                        </Col>
                        <Col lg='4' sm='3' md='3'>
                            <Input type="radio" value={1} readOnly name="weeklyOff">Yes</Input>&nbsp;Yes
                            <br />
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='3' sm='3' md='3'>
                            Include Holidays
                        </Col>
                        <Col lg='4' sm='3' md='3'>
                            <Input type="radio" value={1} readOnly name="holidays">Yes</Input>&nbsp;Yes
                            <br />
                            <Input type="radio" value={2} readOnly name="holidays">No</Input>&nbsp;No
                        </Col>
                    </Row>
                    <br />
                    <div className="input-group mt-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='3' sm='3' md='3'>
                            Basic Percentage
                        </Col>
                        <Input type="text" className="form-control form-control-sm" name='basic' readOnly
                            placeholder="Enter Basic Percentage" />
                        <div className="input-group-text">% of Gross</div>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default PayPeriod;