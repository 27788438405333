import { Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, ColumnMenu, Sort  } from "@syncfusion/ej2-react-grids";

const AttendanceImport = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);

    const toggle = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <div className="page-content">            
            <Breadcrumbs title="Attendance" breadcrumbItem="Attendance Import" enableAction={true} actionName="Import Attedance" onClick={toggle}></Breadcrumbs>            
            
            <GridComponent allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5 }}
                allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} >
                <ColumnsDirective>
                    <ColumnDirective field="bankName" headerText='File Name' width='150'></ColumnDirective>
                    <ColumnDirective field="bankIFSC" headerText='Pay Period' width='150' />
                    <ColumnDirective field="branchName" headerText='Imported' width='150' />
                    <ColumnDirective field="accountName" headerText='Skipped' width='150'></ColumnDirective>
                    <ColumnDirective field="accountNumber" headerText='Total' width='150' />
                    <ColumnDirective field="accountNumber" headerText='Imported By' width='150' />
                    <ColumnDirective field="createdOn" type="datetime" format='dd/MM/yyyy' headerText='Imported On' width='150' />
                    <ColumnDirective field="accountNumber" headerText='Status' width='150' />
                    <ColumnDirective field="accountNumber" headerText='Action' width='150' />
                </ColumnsDirective>
                <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
            </GridComponent>            

            <Offcanvas backdrop="static" className='w-30' style={{width:'500px'}} direction={canvasPlacement} 
            isOpen={canvasOpen} toggle={toggle}>
                <OffcanvasHeader toggle={toggle}>
                    <Row>
                        <Col sm='12'>
                            <b>Attendance Import</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />

                <OffcanvasBody>
                    <Row className="mb-4">
                        <Col sm='6' md='6' lg='6'>
                            Attendance Pay Period
                        </Col>
                        <Col sm='6' md='6' lg='6'>
                            <select className="form-control">
                                <option>01 Feb,2023 to 28 Feb,2023</option>
                            </select>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col sm='6' md='6' lg='6'>
                            Attendance calculated using?
                        </Col>
                        <Col sm='6' md='6' lg='6'>
                            <Input type="radio" checked name="days"></Input>&nbsp;&nbsp;Days
                        </Col>
                    </Row>

                    Import
                    <Row className="mb-4">
                        <Col sm='6' md='6' lg='6'>
                            Attendance File
                        </Col>
                        <Col sm='6' md='6' lg='6'>
                            <Input type="file" name="days"></Input>
                        </Col>
                    </Row>

                    <button className="btn btn-primary btn-sm">Import Attendance</button>
                </OffcanvasBody>
            </Offcanvas>
        </div>
    )
}

export default AttendanceImport;