import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort }
    from "@syncfusion/ej2-react-grids";


import PayrollWizard from "../RunPayroll/wizard";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../../../helpers/url-request";
import { getCompanyPayperiods } from "../../../helpers/backend_helper";

const IncomeTaxList = () => {
  let gridInstance;
  const [canvasPlacement, setCanvasPlacement] = useState('start')
  const [canvasOpen, setCanvasOpen] = useState(false);  
  const [payrollList, setPayrollList] = useState();
  const [payrollId, setPayrollId] = useState();
  const [payPeriods, setPayPeriods] = useState();
  const [payPeriodId, setPayPeriodId] = useState();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [detailModal, setDetailModal] = useState(false);
  const detailToggle = () => setDetailModal(!detailModal);

  const history = useHistory();

  useEffect(() => {

      getPayrollSummary();

      getCompanyPayperiods().then(res => {
          if (res.data) {
              let emps = [{ "id": 0, "payPeriodTitle": "Select Period" }];
              res.data.forEach(el => {
                  emps.push(el)
              });
              setPayPeriods(emps)
          }
      });

  }, []);

   const getPayrollSummary = () => {
        getData("/payroll/payrollsummary").then(res => {
            console.log(res);
            if (res.data) {
                setPayrollList(res.data);
            }
        });
  }

  const runPayroll = () => {
    setCanvasPlacement('end')
    setCanvasOpen(!canvasOpen)
    // setErrors({});
    }

  const handleChange = event => {
    setPayPeriodId(event.target.value);
  };

  const submitPayroll = event => {
      event.preventDefault();
      //setPayPeriodId(1);
      if (payPeriodId && payPeriodId > 0) {          
          setCanvasOpen(false);
          toggle();
      } else {
          alert("Please select Period to Run Payroll");
      }
  }

  const viewPayroll = (id, status) => {
      if (status == 'Completed') {
          setPayrollId(id);
          detailToggle();
      } else {
          alert("Payroll is still processing");
      }    
  }

  const linktemplate = props => {
    return (
                <button onClick={() => viewPayroll(props.id, props.payrollStatus)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                    {props.payrollMonth}
                </button>         
        )
  }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }        
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                getPayrollSummary();
                break;
        }
    }

  return (    
      <div className="page-content">

          <Modal isOpen={modal} toggle={toggle} fullscreen>
              <ModalHeader toggle={toggle}>Payroll Wizard</ModalHeader>
              <ModalBody>
                  <PayrollWizard payPeriodId={payPeriodId} />
              </ModalBody>
          </Modal>

          <Modal isOpen={detailModal} toggle={detailToggle} fullscreen>
              <ModalHeader toggle={detailToggle}>Payroll Detail</ModalHeader>
              <ModalBody>
                  
              </ModalBody>
          </Modal>

        <Container fluid>

          <Breadcrumbs title="HR Management" breadcrumbItem="Income Tax" enableAction={true} actionName="New Request" onClick={runPayroll}></Breadcrumbs>

          <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={runPayroll}>
            <OffcanvasHeader toggle={runPayroll}>
              <Row>
                <Col sm='12'>
                  <b>Run Payroll</b>
                </Col>
              </Row>
            </OffcanvasHeader>
            <hr />

            <OffcanvasBody>
              <FormGroup>
                 <Row className="m-2">
                  <Col sm='6'>
                    <Label for="batch">Pay Period</Label>
                  </Col>
                              <Col sm='12'>
                                  <Input type="select" className='form-select' onChange={handleChange}>
                        {payPeriods &&
                            payPeriods.map((payperiod) => {
                                return <option value={payperiod.id} key={payperiod.id}>{payperiod.payPeriodTitle}</option>
                            })}
                    </Input>
                  </Col>
                </Row>

                <div className="offcanvas-footer">
                  <Button color="primary" onClick={submitPayroll} className="btn"
                                  type="submit">Submit</Button>&nbsp;&nbsp;&nbsp;
                  <button onClick={runPayroll} className="btn btn-dark">Close</button>
                </div>

              </FormGroup>

            </OffcanvasBody>
          </Offcanvas>
          {/* <CardHeader>
            <Row>
              <Col xl="9" md="9" sm="9">
                <CardTitle tag="h4">Payroll Details</CardTitle>
              </Col>
              <Col xl="3" md="3" sm="4">
                <button onClick={runPayroll} className="btn btn-primary btn-sm">
                  Run Payroll</button>
              </Col>
            </Row>
          </CardHeader> */}

          <CardBody>
             <GridComponent dataSource={payrollList} statelessTemplates={['directiveTemplates']}
                      toolbar={toolbarOptions} ref={grid => gridInstance = grid} toolbarClick={toolbarClick.bind(this)}
                      allowPaging={true} pageSettings={{ pageCount: 5, pageSizes: true }} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
              <ColumnsDirective>
                <ColumnDirective field="payrollMonth" template={linktemplate} headerText='Payroll Type' width='150'></ColumnDirective>
                <ColumnDirective field="runDate" headerText='Run Date' width='150' />
                <ColumnDirective field="payrollStatus" headerText='Processing' width='150' />
                <ColumnDirective field="approvalStatus" headerText='Approval' width='150' />
                <ColumnDirective field="employeeCount" headerText='Employee Count' width='150'></ColumnDirective>
                <ColumnDirective field="payPeriod" headerText='PAY PERIOD' width='150' />
                <ColumnDirective field="batchId" headerText='Batch No.' width='150' />
                <ColumnDirective field="grossAmount" headerText='GROSS (₹)' width='150' />
                <ColumnDirective field="incomeTax" headerText='INCOME TAX (₹)' width='150' />
                <ColumnDirective field="deductions" headerText='Deductions (₹)' width='150' />
                <ColumnDirective field="netAmount" headerText='NET AMOUNT (₹)' width='150' />
              </ColumnsDirective>
                      <Inject services={[Page, Toolbar, Sort, ColumnMenu]} />
            </GridComponent>
          </CardBody>
        </Container>
      </div>
  );
};

export default IncomeTaxList;
