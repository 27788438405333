import { Fragment, useEffect } from "react"

import {
    Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
    Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, TabContent, TabPane, NavLink
} from "reactstrap";

import { useState } from "react";
import React from 'react';
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Filter, Sort, ExcelExport, PdfExport, Resize }
    from "@syncfusion/ej2-react-grids";

import { searchTask } from "../../helpers/backend_helper";
import LeaveDetail from '../Leaves/LeaveRequest/LeaveDetail';
import MonthlyAttendanceReportView from '../Attendance/MonthlyReport/monthlyattendancereportview';
import PayrollDetail from "../Payroll/PayrollSummary/payroll-details";
import { showLoading } from 'react-global-loading';
import { getData } from "../../helpers/url-request";
import TaskDetail from "./TaskDetail";
import TaskDetailView from "./TaskDetailView";

const TaskBoard = () => {
    let gridInstance;
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [taskList, setTaskList] = useState();
    const [taskId, setTaskId] = useState();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [modal2, setModal2] = useState(false);
    const toggle2 = () => setModal2(!modal2);

    const [modal3, setModal3] = useState(false);
    const toggle3 = () => setModal3(!modal3);

    const [employeeId, setEmployeeId] = useState(0);
    const [leaveId, setLeaveId] = useState(0);
    const [reportId, setReportId] = useState(0);
    const [payrollId, setPayrollId] = useState(0);
    const [activeTab, setactiveTab] = useState(1);

    const loadData = () => {
        showLoading(true);
        searchTask().then((res) => {
            if (res.data) {
                setTaskList(res.data);
            }

            showLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, [])

    const toggleCanvasEnd = () => {
        setCanvasOpen(!canvasOpen)
    }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const headerLink = props => {
        return (
            <button onClick={() => viewTask(props.id, props.taskTypeId)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.taskId}
            </button>
        )
    }

    const viewTask = (id, taskTypeId) => {
        setTaskId(id);

        showLoading(true);
        toggle();
        //getData("/task/taskreference/" + id).then(res => {
        //    if (res.data) {
        //        if (taskTypeId == 1) {
        //            setLeaveId(res.data.id);
        //            setEmployeeId(res.data.employeeId);
        //            toggle();
        //        } else if (taskTypeId == 2) {
        //            setReportId(res.data.id);
        //            toggle2();
        //        } else if (taskTypeId == 3) {
        //            setPayrollId(res.data.id);
        //            toggle3();
        //        }
        //    }
        //    showLoading(false);
        //});
    }

    const OnTaskCreated = () => {
        toggleCanvasEnd();
        loadData();
    }

    return (
        <Fragment>
            <div className="page-content">

                <Modal isOpen={modal} toggle={toggle} fullscreen>
                    <ModalHeader toggle={toggle}>Task Details</ModalHeader>
                    <ModalBody>
                        <TaskDetailView taskId={taskId} closeAction={toggle} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="btn primary" type="button" onClick={toggle}>Close</Button>
                    </ModalFooter>
                </Modal>

                <Container fluid>
                    <Breadcrumbs title="Task" breadcrumbItem="Task Board" enableAction={true} actionName="New Task" onClick={toggleCanvasEnd} ></Breadcrumbs>

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === 1 })}
                                onClick={() => {
                                    setactiveTab(1);
                                }}>
                                <span>Inbox</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                    setactiveTab(2);
                                }}>
                                <span>Sent</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    
                    <div className='mt-2'>
                        <GridComponent dataSource={taskList} allowPaging={true} pageSettings={{ pageCount: 25, pageSizes: true }} allowResizing={true} height="100%"
                            toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid} filterSettings={{ type: 'Excel' }} allowSorting={true}
                            statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true} allowFiltering={true} showColumnMenu={true}>
                            <ColumnsDirective>
                                <ColumnDirective field="taskId" headerText='File No.' template={headerLink} width='150'></ColumnDirective>
                                <ColumnDirective field="taskType.name" headerText='Type' width='150'></ColumnDirective>
                                <ColumnDirective field="taskTitle" headerText='Title' width='150'></ColumnDirective>
                                <ColumnDirective field="taskStatus.name" headerText='Status' width='150' />
                                <ColumnDirective field="taskOwnerUser.fullName" headerText='Created By' width='150' />
                                <ColumnDirective field="assignedToUser.fullName" headerText='Assigned To' width='150' />
                                <ColumnDirective field="taskLocation.locationName" headerText='Location' width='150' />
                                <ColumnDirective field="assignedDate" type="datetime" format="dd/MM/yyyy" headerText='Assigned Date' width='125' />
                                <ColumnDirective field="completedOn" type="datetime" format="dd/MM/yyyy" headerText='Completion Date' width='135' />
                                <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy" headerText='Last Updated' width='135' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize, Filter]} />
                        </GridComponent>
                    </div>                                         

                    <Offcanvas fade={true} backdrop={true} className='w-40' direction="end" isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                        <OffcanvasHeader toggle={toggleCanvasEnd}>
                            <Row>
                                <Col sm='12'>
                                    <b>Task Details</b>
                                </Col>
                            </Row>
                        </OffcanvasHeader>
                        <OffcanvasBody>
                            <TaskDetail closeAction={OnTaskCreated} />
                        </OffcanvasBody>
                    </Offcanvas>

                </Container>
            </div>
        </Fragment>
    )
}

export default TaskBoard;