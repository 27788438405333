// import Select from "react-select/dist/declarations/src/Select";
import { Button, Col, Input, Label, Row, Card } from "reactstrap";

import Select from 'react-select'
import React, { useEffect, useState } from "react";
import { addEmployeeLeaveRequest, getCompanyPayperiods, getLeaveTypes, getLocalStorage, getAllowancesTypes } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import { getData, postData, postRawData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as url from '../../../helpers/url_helper';

const AllowanceDetail = (props) => {
    const [leaveValue, setLeaveValue] = useState('');
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [allowanceTypes, setAllowanceTypes] = useState();
    const [payPeriods, setPayPeriods] = useState();
    const [selectedFiles, setselectedFiles] = useState([]);
    const [role, setRole] = useState();

    const [formData, setFormData] = useState({
        employeeId: 0, leaveTypeId: 0, leaveDays: '', description: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getAllowancesTypes().then((res) => {
            //console.log(res);
            formData.leaveTypeId = res.data[0].id
            setAllowanceTypes(res.data);
        });

        getCompanyPayperiods().then((res) => {
            //console.log(res);
            formData.leaveTypeId = res.data[0].id
            setPayPeriods(res.data);
        });

        const storage = getLocalStorage();
        // console.log(storage);
        setRole(storage.roleName);

    }, [submitted]);

    const confirmHandler = (event) => {
        event.preventDefault();
        let formErrors = {};

        if (!formData.leaveTypeId) {
            formErrors.leaveTypeId = 'Leave Type is required';
        }
       
        // if (!formData.description) {
        //     formErrors.description = 'Description is required';
        // }

        setErrors(formErrors);
        
        console.log(formData);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {

            setIsLoading(true);

            postData("/PayrollMisc/addotallowance", formData).then((res) => {
                setIsLoading(false);

                if (res.status == true) {
                    setDataSuccess("added");
                    timeout();
                    if (props.onClose) {
                        props.onClose();
                    }
                } else {
                    setDataSuccess("failed");
                    alert(res.message);
                }                
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }
    }

   

    // const onLeaveChange = e => {
    //     setLeaveValue(e.currentTarget.value);
    // }

    const handleChange = (event) => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    }

    const closeHandler = (event) => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    return (
        <>
            <form>
                <Row>
                    <Col className='mb-1' xl='12' md='12' sm='12'>
                        <Label>Pay Period</Label>
                        <Input type="select" className="form-select" name="payPeriodId"
                            onChange={handleChange} value={formData.payPeriodId}>
                            {payPeriods &&
                                payPeriods.map((res) => {
                                    return <option value={res.id} key={res.id}>{res.payPeriodTitle}</option>
                                })}</Input>
                        <p style={{ color: "Red" }}>{errors.payPeriodId && <span>{errors.payPeriodId}</span>}</p>
                    </Col>

                    <Col className='mb-1' xl='12' md='12' sm='12'>
                        <Label>Allowance Type</Label>
                        <Input type="select" className="form-select" name="allowanceTypeId"
                            onChange={handleChange} value={formData.allowanceTypeId}>
                            {allowanceTypes &&
                                allowanceTypes.map((res) => {
                                    return <option value={res.id} key={res.id}>{res.name}</option>
                                })}</Input>
                        <p style={{ color: "Red" }}>{errors.allowanceTypeId && <span>{errors.allowanceTypeId}</span>}</p>
                    </Col>

                    <Col sm="12" md="12">
                        <input id="percentage" name="amountType" onChange={handleChange} type="radio" value="1" /> Percentage&nbsp;
                        <input id="fixedAmount" name="amountType" onChange={handleChange} type="radio" value="2" /> Fixed Amount
                    </Col>

                    <Col sm="12" md="12">
                        <Input type="number" onChange={handleChange}
                            name='amount' placeholder="Enter Percentage/Amount">
                        </Input>
                        <p style={{ color: "Red" }}>{errors.amount && <span>{errors.amount}</span>}</p>
                    </Col>

                    <Col className='mb-1' xl='12' md='12' sm='12'>
                        <Label>Description</Label>
                        <Input type='textarea' name="description" rows="6" onChange={handleChange} id='input-default' placeholder='Description' />
                    </Col>
                    
                    <div className="text-center mt-2">
                        {dataSuccess === 'added' && <SuccessAlert />}
                        {dataSuccess === 'failed' && <FailureAlert />}
                    </div>

                    <div className="offcanvas-footer">
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            onClick={confirmHandler} className="btn" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;&nbsp;&nbsp;
                        <button type="reset" className="btn btn-dark" onClick={closeHandler}>Close</button>
                    </div>
                </Row>
            </form>
        </>
    )
}

export default AllowanceDetail;