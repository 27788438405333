import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Container
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort, ColumnMenu, Toolbar, ExcelExport, PdfExport } from "@syncfusion/ej2-react-grids";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import { getAllContractors, getConsultantList, getConsultantPayments, getVendorsPayments, postConsultantPayment, postVendorPayment } from "../../../helpers/backend_helper";


const ConsultantPayment = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);

    const [errors, setErrors] = useState({});
    const [formSuccess, setFormSuccess] = useState();
    const [submitted, setFormSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [consultantPayData, setConsultantPayment] = useState({
        "consultantId": 0, "paymentDate": "", "invoiceNumber": "",
        "amount": 0, "gst": 0, "deductTax": 0, "tds": 0,
        "netAmount": 0, "chequeNumber": "", "transactionNumber": ""
    })
    const [consultants, setConsultantsData] = useState([]);
    const [paymentList, setPaymentList] = useState([]);

    const toggle = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    useEffect(() => {
        getConsultantList().then(res => {
            console.log(res.data);
            if (res.data) {
                let data = [{ "id": 0, "fullName": "Select Consultant" }];
                res.data.forEach(el => {
                    data.push(el);
                });
                setConsultantsData(data)
            }
        });

        getConsultantPayments().then(res => {
            console.log(res.data);
            if (res.data) {
                setPaymentList(res.data)
            }
        });

    }, [submitted])

    const handleChange = event => {
        if (event.target.name === 'deductTax') {
            let value = event.target.value === 'on' ? 1 : 0;
            setConsultantPayment({ ...consultantPayData, "deductTax": value });
        } else {
            setConsultantPayment({ ...consultantPayData, [event.target.name]: event.target.value });
        }

    };

    const handleSubmit = event => {
        event.preventDefault();

        let formErrors = {};
        if (!consultantPayData.consultantId) {
            formErrors.consultantId = 'Consultant is required';
        }
        if (!consultantPayData.paymentDate) {
            formErrors.paymentDate = 'Payment Date is required';
        }
        if (!consultantPayData.invoiceNumber) {
            formErrors.invoiceNumber = 'Invoice Number is required';
        }
        if (!consultantPayData.amount) {
            formErrors.amount = 'Amount is required';
        }
        if (!consultantPayData.gst) {
            formErrors.gst = 'GST is required';
        }
        if (!consultantPayData.tds) {
            formErrors.tds = 'TDS is required';
        }
        if (!consultantPayData.netAmount) {
            formErrors.netAmount = 'Net Amount is required';
        }
        if (!consultantPayData.chequeNumber) {
            formErrors.chequeNumber = 'Cheque Number is required';
        }
        if (!consultantPayData.transactionNumber) {
            formErrors.transactionNumber = 'TransactionNumber is required';
        }

        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);
            // console.log(formData);
            postConsultantPayment(consultantPayData).then((res) => {
                // console.log(res);
                if (res.data) {
                    setFormSuccess('added');
                    setIsLoading(false);
                    setFormSubmit(true)
                    // props.onStateChange(res.status);
                    resetForm();
                } else {
                    setFormSuccess('failed');
                    setIsLoading(false);
                }
                timeout();
            }).catch((err) => {
                // console.log(err);
                setFormSuccess('failed');
                setIsLoading(false);
                timeout();
            })
        }
    }

    const resetForm = () => {
        setConsultantPayment({
            "consultantId": 0, "paymentDate": "", "invoiceNumber": "",
            "amount": 0, "gst": 0, "deductTax": 0, "tds": 0,
            "netAmount": 0, "chequeNumber": "", "transactionNumber": ""
        })
    }

    const timeout = () => {
        setTimeout(() => {
            setFormSuccess('')
        }, 3000);
    }

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.text) {
            case 'PDF Export':
                gridInstance.pdfExport();
                break;
            case 'Excel Export':
                gridInstance.excelExport();
                break;
            case 'CSV Export':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <div className="page-content">

            <Breadcrumbs title="HR Management" breadcrumbItem="Consultant Payment" enableAction={true} actionName="Make Payment" onClick={toggle}></Breadcrumbs>

            <GridComponent dataSource={paymentList} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5 }}
                allowExcelExport={true} allowPdfExport={true} allowSorting={true} showColumnMenu={true} toolbarClick={toolbarClick.bind(this)} >
                <ColumnsDirective>
                    <ColumnDirective field="invoiceNumber" headerText='Invoice Number' width='150' />
                    <ColumnDirective field="amount" headerText='Amount' width='150' />
                    <ColumnDirective field="paymentDate" headerText='paymentDate' width='150' />
                    <ColumnDirective field="transactionNumber" headerText='transactionNumber' width='150' />
                    <ColumnDirective field="createdOn" headerText='Created On' width='150'></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
            </GridComponent>

            <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggle}>
                <OffcanvasHeader toggle={toggle}>
                    <h5 className="font-size-14">
                        <i className="mdi mdi-arrow-right text-primary me-1"></i>{" "}
                        Consultant Payment
                    </h5>
                </OffcanvasHeader>
                <hr />
                <OffcanvasBody>

                    <Form>
                        <Row className="mb-2">
                            <Col sm={12}>
                                <Label className="col-form-label"                                            >
                                    Select Consultant
                                </Label>
                                <Input type="select" name="consultantId"
                                    onChange={handleChange} value={consultantPayData.consultantId} className="form-control-md">
                                    {consultants.map(item => {
                                        return (<option key={item.id} value={item.id}>{item.fullName}</option>);
                                    })}
                                </Input>
                                {errors.consultantId && <span style={{ color: "Red" }}>{errors.contractorId}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={12}>
                                <Label className="col-form-label">
                                    Payment Date
                                </Label>
                                <Input type="date" name="paymentDate"
                                    onChange={handleChange} className="form-control-md">
                                </Input>
                                {errors.paymentDate && <span style={{ color: "Red" }}>{errors.paymentDate}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={12}>
                                <Label className="col-form-label">
                                    Invoice Number
                                </Label>
                                <Input type="text" name="invoiceNumber"
                                    onChange={handleChange} className="form-control-md" placeholder="Enter Invoice Number">
                                </Input>
                                {errors.invoiceNumber && <span style={{ color: "Red" }}>{errors.invoiceNumber}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={12}>
                                <Label className="col-form-label">
                                    Payment Amount
                                </Label>
                                <Input type="text" name="amount"
                                    onChange={handleChange} className="form-control-md" placeholder="Enter Payment Amount">
                                </Input>
                                {errors.amount && <span style={{ color: "Red" }}>{errors.amount}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={12}>
                                <Label className="col-form-label" >
                                    GST
                                </Label>
                                <Input type="text" name="gst" className="form-control-md"
                                    onChange={handleChange} placeholder="Enter GST Amount">
                                </Input>
                                {errors.gst && <span style={{ color: "Red" }}>{errors.gst}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={12}>
                                <Label className="col-form-label">
                                    TDS
                                </Label>
                                <Input type="text" name="tds" className="form-control-md"
                                    onChange={handleChange} placeholder="Enter TDS Amount">
                                </Input>
                                {errors.tds && <span style={{ color: "Red" }}>{errors.tds}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm={12}>
                                <Label className="col-form-label" >
                                    Net Amount
                                </Label>
                                <Input type="text" name="netAmount" className="form-control-md"
                                    onChange={handleChange} placeholder="Enter Net Amount">
                                </Input>
                                {errors.netAmount && <span style={{ color: "Red" }}>{errors.netAmount}</span>}
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm={12}>
                                <Label className="col-form-label">
                                    Cheque Number
                                </Label>
                                <Input type="text" name="chequeNumber" className="form-control-md"
                                    onChange={handleChange} placeholder="Enter Cheque Number">
                                </Input>
                                {errors.chequeNumber && <span style={{ color: "Red" }}>{errors.chequeNumber}</span>}
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm={12}>
                                <Label className="col-form-label">
                                    Transaction Number
                                </Label>
                                <Input type="text" name="transactionNumber" className="form-control-md"
                                    onChange={handleChange} placeholder="Enter Transaction Number">
                                </Input>
                                {errors.transactionNumber && <span style={{ color: "Red" }}>{errors.transactionNumber}</span>}
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col sm={12}>
                                <div className="form-check form-switch form-switch-md mb-3" dir="ltr"                                >
                                    <input type="checkbox" name="deductTax" className="form-check-input"
                                        onChange={handleChange} id="customSwitchsizemd" />
                                    <label className="form-check-label"
                                        htmlFor="customSwitchsizemd">
                                        Enable Tax
                                    </label>
                                </div>
                            </Col>
                        </Row>

                        <div className="text-center">
                            {formSuccess === 'added' && <SuccessAlert />}
                            {formSuccess === 'failed' && <FailureAlert />}
                        </div>
                        {/* {isLoading ? <LoadingSpinner /> : <Button color="primary" onClick={handleSubmit} disabled={isLoading} type="submit">Submit</Button>} */}

                        <Row className="offcanvas-footer mt-2">
                            <Col sm={9}>
                                <div>
                                    {isLoading ? <LoadingSpinner /> :
                                        <button type="submit" disabled={isLoading} onClick={handleSubmit} className="btn btn-sm btn-primary w-sm">
                                            Submit
                                        </button>}
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </OffcanvasBody>
            </Offcanvas>
        </div>
    )
}

export default ConsultantPayment;