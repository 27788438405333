import { Fragment } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject }
    from "@syncfusion/ej2-react-grids";

const SettingsShiftType = () => {
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [shiftType, setType] = useState('');
    const [description, setDesc] = useState();
    const [errors, setErrors] = useState({});

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const typeChange = (e) => {
        setType(e.target.value);
        if (e.target.value) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    shiftType: "",
                };
            });
        }
    }

    const changeDesc = e => {
        setDesc(e.target.value);
        if (e.target.value) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    description: "",
                };
            });
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        console.log(`Name:  ${shiftType}  `);
        //console.log(typeof(Name))
        if (!shiftType) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    shiftType: "Shift Type is Required!",
                };
            });
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    shiftType: "",
                };
            });
        }

        if (!description) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    description: "Description is Required!",
                };
            });
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    description: "",
                };
            });
        }
    };

    return (
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Shift Type" enableAction={true} actionName="Add Shift Type" onClick={toggleCanvasEnd}></Breadcrumbs>
                                            
                    <GridComponent allowPaging={true} pageSettings={{ pageCount: 5 }}>
                        <ColumnsDirective>
                            <ColumnDirective headerText='Shift Type' width='150'></ColumnDirective>
                            <ColumnDirective headerText='Description' width='150' />
                            <ColumnDirective headerText='Status' width='150' />
                            <ColumnDirective headerText='Action' width='150'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page]} />
                    </GridComponent>                   

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-25' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Shift Type</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />


                            <OffcanvasBody>
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Shift Type
                                            </Label>
                                            <Input type='text' id='input-default' onChange={typeChange} placeholder='Shift Type' />
                                            <p style={{ color: "Red" }}>{errors ? errors.shiftType : ''}</p>
                                        </Col>

                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Description
                                            </Label>
                                            <Input type='textarea' id='input-default' onChange={changeDesc} placeholder='Description' />
                                            <p style={{ color: "Red" }}>{errors ? errors.description : ''}</p>
                                        </Col>

                                        <Col sm='12' className='mb-1'>
                                            <Input type='checkbox' id='input-default' />&nbsp;&nbsp;&nbsp;
                                            <Label className='form-label' for='input-default'>
                                                Active
                                            </Label>
                                        </Col>
                                        <hr />
                                        <Col sm='4'>
                                            <Button block color='primary' className='mb-1'>
                                                Save
                                            </Button>
                                        </Col>
                                        <Col sm='4' className='mr-2'>
                                            <Button block outline color='secondary' toggle={toggleCanvasEnd}>
                                                Close
                                            </Button>
                                        </Col>

                                    </Row>
                                </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

export default SettingsShiftType;