import React, { Fragment, useEffect, useState } from "react"
import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Resize, Filter, ExcelExport, PdfExport } from "@syncfusion/ej2-react-grids";
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const SettingsDAScale = () => {
    let gridInstance;
    const [gradeList, setGradeData] = useState([]);
    const [submitted, setFormSubmit] = useState(false);
    const [groups, setGroups] = useState();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [payscales, setPayscales] = useState();
    const [payscaleDetails, setPayscaleDetails] = useState();
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: 0,
        payscaleId: 0,
        payscale: '',
        description: '',
        fromDate: '',
        toDate: ''
    });
    const [errors, setErrors] = useState({});

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const loadData = () => {

        showLoading(true);

        getData("/company/dapayscales").then(res => {
            console.log(res);
            if (res.data) {
                setPayscales(res.data)
            }
            showLoading(false);
        });

    }

    useEffect(() => {
        console.log("inside useEffect");

        loadData();
    }, []);

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    return (

        <Fragment>
            <div className="m-2">
                <GridComponent dataSource={payscales} allowPaging={true} pageSettings={{ pageCount: 5, pageSizes: true }} toolbar={toolbarOptions}
                    toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid} allowSorting={true} allowResizing={true}
                    allowFiltering={true} showColumnMenu={true} statelessTemplates={['directiveTemplates']} filterSettings={{ type: 'Excel' }}
                    allowExcelExport={true} allowPdfExport={true}>
                    <ColumnsDirective>                            
                        <ColumnDirective field="department.name" headerText='Department' width='150'></ColumnDirective>                            
                        <ColumnDirective field="fromDate" type="datetime" format="dd/MM/yyyy" headerText='From Date' width='150' />
                        <ColumnDirective field="toDate" type="datetime" format="dd/MM/yyyy" headerText='To Date' width='150' />
                        <ColumnDirective field="percentage" headerText='Percentage' width='150' />
                        <ColumnDirective field='updatedOn' type='datetime' format='dd/MM/yyyy' headerText='Last Updated' width='150' />
                    </ColumnsDirective>
                    <Inject services={[Page, Filter, Toolbar, Sort, ColumnMenu, Resize, ExcelExport, PdfExport]} />
                </GridComponent>
            </div>
        </Fragment>


    );
}

export default SettingsDAScale;