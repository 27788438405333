import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";

import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import { showLoading } from 'react-global-loading';

import { getAllContractors, getEmployeeById, getEmployeeID, getEmployeeWorkGrades, updateEmployeeDetails, getEmployeeReportingList } from "../../../helpers/backend_helper";
import {
    getEmployeeTypes, getDepartments, getWorkSchedules,
    getCompanyLocations, getMaritalStatus, getGenderTypes, getLeavePolicies,
    getHolidayPolicies, getExpensePolicies
} from "../../../helpers/backend_helper";
import BankDetails from "./BankDetails";
import EmployeeEducation from "./Education";
import EmploymentHistory from "./EmploymentHistory";
import EmployeeCompensation from "./Compensation";
import EmployeeAddressDetails from "./AdressDetails";
import EmployeeDependent from "./Dependent";
import EmployeeTransfer from "./TransferView";
import EmployeeIncrement from "./IncrementView";
import EmployeePromotion from "./PromotionView";
import EmployeeInsurance from "./InsuranceView";
import EmployeeHistory from "./HistoryDetail";
import EmployeeDocument from "./DocumentView";
import EmployeeSuspension from "./SuspensionView";

import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import Sidebar from "../../../components/VerticalLayout/Sidebar";
import SimpleBar from "simplebar-react";
//Import Icons
import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";
import moment from "moment";

const EmployeeDetailsView = (props) => {
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')
    const [isEdit, setIsEdit] = useState(false)
    const [employeetypes, setEmpTypes] = useState();
    const [departments, setDepartments] = useState();
    const [workschedules, setWorkSchedule] = useState();
    const [maritalstatus, setMaritalStatus] = useState();
    const [locations, setLocations] = useState();
    const [gendertypes, setGenderTypes] = useState();
    const [leavepolicies, setLeavePolicy] = useState();
    const [holidaypolicies, setHolidayPolicy] = useState();
    const [expensepolicies, setExpensePolicy] = useState();
    const [contractors, setContractors] = useState();
    const [grades, setGrades] = useState();
    const [reportsTo, setReportsTo] = useState();

    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);

    const [empData, setEmployeeData] = useState({
        fullName: '', employeeCode: '', genderTypeId: 0, dateOfBirth: '', maritalStatus: 0,
        fatherName: '', motherName: '', phone: '', emailId: '', joiningDate: '', nextIncrementDate:'', locationId: '',
        employeeTypeId: 0, contactorId: 0, departmentId: 0, reportsTo: 0, workTitle: '',
        workDesc: '', workShift: 0, workSchedule: 0, attendanceType: 0, bloodGroup: '',
        expensePolicyId: 0, leavePolicyId: 0, holidayPolicyId: 0,
        aadharNumber: "", pan: "", pfAccountNo: "", pfuan: "", employeeGradeId: 0,
        esiAccountNo: "", payrollEnabled: 0, employeeStatus: 0
    });

    const params = useParams();
    const history = useHistory();

    const fetchData = async () => {
        var empId = props.employeeId;
        const response = await getEmployeeID(empId);
        const resData = response;
        // console.table(resData);

        var joinDate = moment(resData.data.joiningDate).format('YYYY-MM-DD');
        var dobDate = moment(resData.data.dateOfBirth).format('YYYY-MM-DD');
        var incrementDate = moment(resData.data.nextIncrementDate).format('YYYY-MM-DD');

        setEmployeeData(resData.data);

        setEmployeeData(prevState => ({
            ...prevState,
            joiningDate: joinDate
        }))

        setEmployeeData(prevState => ({
            ...prevState,
            dateOfBirth: dobDate
        }))

        setEmployeeData(prevState => ({
            ...prevState,
            nextIncrementDate: incrementDate
        }))

        // if (!resData.contactorId) {
        //     console.log("Inside ResData",resData);
        //     setEmployeeData(prevState => ({
        //         ...prevState,
        //         contactorId: 0
        //     }))
        // }

        if (!resData.attendanceType) {
            setEmployeeData(prevState => ({
                ...prevState,
                attendanceType: 0
            }))
        }

        console.log(empData);
    }

    useEffect(() => {

        showLoading(true);

        fetchData();

        getEmployeeTypes().then(res => {
            if (res.data) {
                let empType = [{ "id": 0, "name": "Select Employee Type" }];
                res.data.forEach(el => {
                    empType.push(el)
                });
                setEmpTypes(empType)
            }
        });

        getEmployeeWorkGrades().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Grade" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                // employeeData.locationId = resData[0].id
                setGrades(resData)
            }
        });

        getDepartments().then(res => {
            if (res.data) {
                let depType = [{ "id": 0, "name": "Select Department Type" }];
                res.data.forEach(el => {
                    depType.push(el)
                });
                setDepartments(depType)
            }
        });

        getWorkSchedules().then(res => {
            if (res.data) {
                let workSch = [{ "id": 0, "name": "Select Work Schedule" }];
                res.data.forEach(el => {
                    workSch.push(el)
                });
                setWorkSchedule(workSch)
            }
        });

        getCompanyLocations().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "locationName": "Select Office Location" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                setLocations(resData)
            }
        });

        getMaritalStatus().then(res => {
            if (res.data) {
                // console.log(res.data);
                let resData = [{ "id": 0, "name": "Select Marital Status" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                setMaritalStatus(resData)
            }
        });

        getGenderTypes().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Gender Type" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                setGenderTypes(resData)
            }
        });

        getLeavePolicies().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Leave Policy" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                setLeavePolicy(resData)
            }
        });

        getHolidayPolicies().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Holiday Policy" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                setHolidayPolicy(resData)
            }
        });

        getExpensePolicies().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Expense Policy" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                setExpensePolicy(resData)
            }
        });

        getAllContractors().then(res => {
            if (res.data) {
                let empType = [{ "id": 0, "name": "Select Contractor" }];
                res.data.forEach(el => {
                    empType.push(el)
                });
                // empData.contactorId = empType[0].id
                setContractors(empType)
            }
        });

        getEmployeeReportingList().then(res => {
            if (res.data) {
                let empType = [{ "id": 0, "fullName": "Select Reporting" }];
                res.data.forEach(el => {
                    empType.push(el)
                });
                setReportsTo(empType)
            }
        });

        //setTimeout(showLoading(false), 5000);

        setTimeout(() => {
            showLoading(false);
        }, 3000);

    }, [])

    // console.log(empData);

    const refreshData = () => {
        showLoading(true);
        fetchData();
        setTimeout(() => {
            showLoading(false);
        }, 3000);
    }

    const handleChange = event => {
        // console.log(event.target.name, event.target.value);
        setEmployeeData({ ...empData, [event.target.name]: event.target.value });
    };

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab);
            }
        }
    }

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const onEditView = () => {
        setIsEdit(!isEdit);
    }

    const onBackClick = () => {
        history.goBack(-1);
    }

    const toolbarBtn = () => {
        return (
            <div className='text-end'>
                {/* {!isEdit && <Button color='info' onClick={onBackClick} className='btn btn-dark btn-sm w-150'>
                    <i className="bx bx-left-arrow-alt font-size-16 align-middle"></i>{" "}
                    <b>Back</b></Button>}&nbsp;&nbsp; */}

                {!isEdit && <Button color='info' onClick={onEditView} className='btn btn-info btn-sm w-150'>
                    <i className="bx bx-edit font-size-16 align-middle"></i>{" "}
                    <b>Edit</b></Button>}

                {isEdit && <Button color='info' onClick={onEditView} className='btn btn-dark btn-sm'>
                    Cancel</Button>}&nbsp;&nbsp;

                {isEdit && <Button color='info' className='btn btn-info btn-sm'>
                    Submit</Button>}
            </div>
        )
    }
    const ele = '<div className = "e-tool-name"><h5>General Information</h5></div>';

    const onEditClick = (args) => {
        onEditView();
    }

    const onSubmitClick = () => {
        console.log(empData);
        showLoading(true);

        if (empData.payrollEnabled == "on" || empData.payrollEnabled == 1)
            empData.payrollEnabled = 1;
        else
            empData.payrollEnabled = 0;

        updateEmployeeDetails(empData).then((res) => {

            showLoading(false);

            if (res.status === true) {
                setDataSuccess("added");
                //setIsLoading(false);
                setIsEdit(!isEdit);
                refreshData();
                //setEmployeeData({
                //    ...empData,
                //    empData
                //});
            } else {                
                setDataSuccess("failed");
                setIsLoading(false);
                alert(res.message);
                showLoading(false);
            }            
            setFormSubmit(!submitted);
            timeout();
        }).catch((err) => {
            console.log(err);
            setDataSuccess("failed");
            setIsLoading(false);
            timeout();
            showLoading(false);
        })
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    return (
        <React.Fragment>
            <div>
                <Container fluid>
                    <CardBody>                        
                        <div id="basic-pills-wizard" className="twitter-bs-wizard">

                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>General Information</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>Bank & Others</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            setactiveTab(3);
                                        }}>
                                        <span>Employment and Education</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 4 })}
                                        onClick={() => {
                                            setactiveTab(4);
                                        }}>
                                        <span>Compensation</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 6 })}
                                        onClick={() => {
                                            setactiveTab(6);
                                        }}>
                                        <span>Transfer</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 7 })}
                                        onClick={() => {
                                            setactiveTab(7);
                                        }}>
                                        <span>Increment</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 8 })}
                                        onClick={() => {
                                            setactiveTab(8);
                                        }}>
                                        <span>Promotion</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 9 })}
                                        onClick={() => {
                                            setactiveTab(9);
                                        }}>
                                        <span>Insurance</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 10 })}
                                        onClick={() => {
                                            setactiveTab(10);
                                        }}>
                                        <span>Documents</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 11 })}
                                        onClick={() => {
                                            setactiveTab(11);
                                        }}>
                                        <span>Suspension</span>
                                    </NavLink>
                                </NavItem>

                            </Nav>

                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>

                                    <Row className="m-2">
                                        <Col xl="9" className="mt-2" md="9" sm="9">
                                            <CardTitle tag="h4">&nbsp;</CardTitle>
                                        </Col>
                                        <Col xl="3" md="3" sm="4">
                                            {!isEdit && <button type="button" onClick={onEditClick} className="btn btn-success waves-effect waves-light">
                                                <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                                                Edit
                                            </button>}
                                            {isEdit && <button type="button" onClick={onSubmitClick} className="btn btn-success waves-effect waves-light">
                                                <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                                                Update
                                            </button>}                                            
                                            &nbsp;&nbsp;&nbsp;
                                            {!isEdit && <button type="button" onClick={refreshData} className="btn btn-warning waves-effect waves-light">
                                                <i className="bx bx-revision font-size-16 align-middle me-2"></i>{" "}
                                                Refresh
                                            </button>}
                                            {isEdit && <button type="button" onClick={onEditClick} className="btn btn-warning waves-effect waves-light">
                                                <i className="bx bx-undo font-size-16 align-middle me-2"></i>{" "}
                                                Cancel
                                            </button>}
                                        </Col>
                                    </Row>
                                    <br />
                                    <div className="text-center mt-2">
                                        {dataSuccess === 'added' && <SuccessAlert />}
                                        {dataSuccess === 'failed' && <FailureAlert />}
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Full Name :
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.fullName}</Label> :
                                                            <Input type="text" name="fullName" className="form-control"
                                                                onChange={handleChange} value={empData.fullName} placeholder="Enter First Name"></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Phone : 
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.phone}</Label> :
                                                            <Input type="text" className="form-control" value={empData.phone}
                                                                onChange={handleChange} placeholder="Enter Phone Number"></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Email : 
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.emailId}</Label> :
                                                            <Input type="email" name="emailId" className="form-control" value={empData.emailId}
                                                                onChange={handleChange} placeholder="Enter Email"></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Gender
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.genderTypeId ? empData.genderType.name : '-'}</Label> :
                                                            <Input type="select" placeholder="Gender" name="genderTypeId"
                                                                onChange={handleChange} defaultValue={empData.genderTypeId}>
                                                                {gendertypes &&
                                                                    gendertypes.map((gender) => {
                                                                        return <option value={gender.id} key={gender.id}>{gender.name}</option>
                                                                    })}
                                                            </Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Date Of Birth
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{new Date(empData.dateOfBirth).toLocaleDateString('en-GB')}</Label> :
                                                            <Input type="date" placeholder="Employee Status" name="dateOfBirth"
                                                                onChange={handleChange} value={empData.dateOfBirth}></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Blood Group
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>-</Label> :
                                                            <Input type="text" placeholder="Enter Blood Group" name="bloodGroup"
                                                                onChange={handleChange} value={empData.bloodGroup}></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Marital Status
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.maritalStatus ? empData.maritalStatusType.name : '-'}</Label> :
                                                            <Input type="select" placeholder="Marital Status" name="maritalStatus"
                                                                onChange={handleChange} defaultValue={empData.maritalStatus}>
                                                                {maritalstatus &&
                                                                    maritalstatus.map((mar) => {
                                                                        return <option value={mar.id} key={mar.id}>{mar.name}</option>
                                                                    })}
                                                            </Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Father Name
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.fatherName}</Label> :
                                                            <Input type="text" className="form-control" name="fatherName"
                                                                onChange={handleChange} value={empData.fatherName} placeholder="Father Name"></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Mother Name
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.motherName}</Label> :
                                                            <Input type="text" className="form-control" name="motherName"
                                                                onChange={handleChange} value={empData.motherName} placeholder="Mother Name"></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        Aadhar ID
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.aadharNumber}</Label> :
                                                            <Input type="text" onChange={handleChange} name="aadharNumber"
                                                                value={empData.aadharNumber} placeholder="Aadhar ID"></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        PAN
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.pan}</Label> :
                                                            <Input type="text" onChange={handleChange} name="pan"
                                                                value={empData.pan} placeholder="PAN"></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        PF A/c No
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.pfAccountNo}</Label> :
                                                            <Input type="text" placeholder="PF A/c No" name="pfAccountNo"
                                                                onChange={handleChange} value={empData.pfAccountNo}></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        PF UAN
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.pfuan}</Label> :
                                                            <Input type="text" placeholder="PF UAN" name="pfuan"
                                                                onChange={handleChange} value={empData.pfuan}></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="mb-2">
                                                <Row>
                                                    <Col md={4}>
                                                        ESI A/c No.
                                                    </Col>
                                                    <Col md={8}>
                                                        {!isEdit ? <Label className='form-label'>{empData.esiAccountNo}</Label> :
                                                            <Input type="text" placeholder="ESI A/c No." name="esiAccountNo"
                                                                onChange={handleChange} value={empData.esiAccountNo}></Input>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                    </div>
                                    <hr />
                                    <CardHeader className="mb-4">
                                        <CardTitle><h5>Work Information</h5></CardTitle>
                                    </CardHeader>

                                    <CardBody>

                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Employee Code
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData?.employeeCode}</Label> :
                                                                <Input type="text" className="form-control" name="employeeCode" onChange={handleChange}
                                                                    value={empData.employeeCode} placeholder="Enter Employee Id"></Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Employee Status
                                                        </Col>
                                                        <Col md={8}>
                                                            <Label className='form-label'>{empData?.employeeStatus?.name}</Label>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Date Of Joining
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{new Date(empData.joiningDate).toLocaleDateString('en-GB')}</Label> :
                                                                <Input type="date" value={empData.joiningDate} name="joiningDate"
                                                                    onChange={handleChange} className="form-control" ></Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Employee Type
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData?.employeeTypeId ? empData.employeeType.name : '-'}</Label> :
                                                                <Input type="select" defaultValue={empData.employeeTypeId}
                                                                    className="form-select" name="employeeTypeId" onChange={handleChange}>
                                                                    {employeetypes &&
                                                                        employeetypes.map((emp) => {
                                                                            return <option value={emp.id} key={emp.id}>{emp.name}</option>
                                                                        })}</Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        
                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Department
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData.departmentId ? empData.workDepartment.name : '-'}</Label> :
                                                                <Input type="select" className="form-select" name="departmentId" defaultValue={empData.departmentId} onChange={handleChange}>
                                                                    {departments &&
                                                                        departments.map((dep) => {
                                                                            return <option value={dep.id} key={dep.id}>{dep.name}</option>
                                                                        })}</Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Contractor
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData?.contactorId ? empData?.contactorProfile?.name : '-'}</Label> :
                                                                <Input type="select" defaultValue={empData.contactorId} value={empData.contactorId}
                                                                    onChange={handleChange} name="contactorId" className="form-select">
                                                                    {contractors &&
                                                                        contractors.map((emp) => {
                                                                            return <option value={emp.id} key={emp.id}>{emp.name}</option>
                                                                        })}</Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                       
                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Office Location
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData.locationId ? empData.workLocation.locationName : '-'}</Label> :
                                                                <Input type="select" className="form-select" onChange={handleChange}
                                                                    defaultValue={empData.locationId} name="locationId">
                                                                    {locations &&
                                                                        locations.map((loc) => {
                                                                            return <option value={loc.id} key={loc.id}>{loc.locationName}</option>
                                                                        })}</Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Next Increment Date
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{new Date(empData.nextIncrementDate).toLocaleDateString('en-GB')}</Label> :
                                                                <Input type="date" value={empData.nextIncrementDate} name="nextIncrementDate"
                                                                    onChange={handleChange} className="form-control" ></Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                        </div>
                                    </CardBody>
                                    <hr />
                                    <CardHeader className="mb-4">
                                        <CardTitle><h5>Job Information</h5></CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                    <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Reports To
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData?.reportsToProfile?.fullName}</Label> :
                                                                <Input type="select" placeholder="Reports To" name="reportsTo"
                                                                    onChange={handleChange} value={empData.reportsTo}>
                                                                    {reportsTo &&
                                                                        reportsTo.map((empUser) => {
                                                                            return <option value={empUser.id} key={empUser.id}>{empUser.fullName}</option>
                                                                        })}</Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Work Title
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData.workTitle}</Label> :
                                                                <Input type="text" placeholder="Work Title" name="workTitle"
                                                                    onChange={handleChange} value={empData.workTitle}></Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                             <div className="col-lg-4">
                                                    <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="m">
                                                                <Label className='form-label' for='empStatus'>
                                                                    Employee Grade
                                                                </Label>
                                                            </div>
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>
                                                                {empData.employeeGradeId ? empData.employeeGrade.name : '-'}</Label> :
                                                                <Input type="select" name="employeeGradeId"
                                                                onChange={handleChange} value={empData.employeeGradeId}>
                                                                {grades &&
                                                                    grades.map((res) => {
                                                                        return <option value={res.id} key={res.id}>{res.name}</option>
                                                                    })}
                                                            </Input>}
                                                            {/* <p style={{ color: "Red" }}>{errors.employeeGradeId && <span>{errors.employeeGradeId}</span>}</p> */}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Work Shift
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData?.workShiftType?.name}</Label> :
                                                                <Input type="select" placeholder="Marital Status" name="workShift"
                                                                    onChange={handleChange} defaultValue={empData.workShift}>
                                                                    <option value={1}>General Shift</option>
                                                                </Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Work Schedule Policy
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData?.workScheduleType?.name}</Label> :
                                                                <Input type="select" id="basicInput"
                                                                    name="workSchedule"
                                                                    onChange={handleChange} defaultValue={empData.workSchedule}>
                                                                    {workschedules &&
                                                                        workschedules.map((work) => {
                                                                            return <option value={work.id} key={work.id}>{work.name}</option>
                                                                        })}
                                                                </Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Attendance Type
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData?.attendanceType == 1 ? 'Biometric' : 'Mobile'}</Label> :
                                                                <Input type="select" id="basicInput"
                                                                    name="attendanceType"
                                                                    onChange={handleChange} defaultValue={empData?.attendanceType}>
                                                                    <option value={0}>Select Attendance Type</option>
                                                                    <option value={1}>Biometric</option>
                                                                    <option value={2}>Mobile</option>
                                                                    <option value={3}>Biometric/Mobile</option>
                                                                </Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Expense Policy
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData.expensePolicyId ? empData.expensePolicy.name : '-'}</Label> :
                                                                <Input type="select" id="basicInput"
                                                                    name="expensePolicyId" onChange={handleChange} defaultValue={empData.expensePolicyId}>
                                                                    {expensepolicies &&
                                                                        expensepolicies.map((res) => {
                                                                            return <option selected={empData.expensePolicyId} value={res.id} key={res.id}>{res.name}</option>
                                                                        })}
                                                                </Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Leave Policy
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData.leavePolicyId ? empData.leavePolicy.name : '-'}</Label> :
                                                                <Input type="select" id="basicInput" name="leavePolicyId"
                                                                    onChange={handleChange} defaultValue={empData.leavePolicyId}>
                                                                    {leavepolicies &&
                                                                        leavepolicies.map((res) => {
                                                                            return <option selected={empData.leavePolicyId} value={res.id} key={res.id}>{res.name}</option>
                                                                        })}
                                                                </Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Holiday Policy
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData.holidayPolicyId ? empData.holidayPolicy.name : '-'}</Label> :
                                                                <Input type="select" id="basicInput" name="holidayPolicyId"
                                                                    onChange={handleChange} defaultValue={empData.holidayPolicyId}>
                                                                    {holidaypolicies &&
                                                                        holidaypolicies.map((res) => {
                                                                            return <option value={res.id} key={res.id}>{res.name}</option>
                                                                        })}
                                                                </Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={4}>
                                                            Enable Payroll
                                                        </Col>
                                                        <Col md={8}>
                                                            {!isEdit ? <Label className='form-label'>{empData.payrollEnabled == '0' ? 'No' : 'Yes'}
                                                            </Label> :
                                                                <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                                                                    <input type="checkbox" name="payrollEnabled" defaultChecked={empData.payrollEnabled} className="form-check-input"
                                                                        onChange={handleChange} id="customSwitchsizemd" />
                                                                </div>}
                                                        </Col>
                                                     </Row>
                                                </div>
                                            </div>

                                        </div>

                                        <Row>
                                            <div className="col-lg-12">
                                                <div className="mb-2">
                                                    <Row>
                                                        <Col md={12}>
                                                            Work Description
                                                        </Col>
                                                        <Col md={12}>
                                                            {!isEdit ? <Label className='form-label'>{empData.workDesc}</Label> :
                                                                <Input type="textarea" name="workDesc"
                                                                    onChange={handleChange} value={empData.workDesc} placeholder="Work Description"></Input>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Row>

                                    </CardBody>

                                </TabPane>
                                <TabPane tabId={2}>
                                    <div>
                                        <Row className="m-2">
                                            Employee : {empData.fullName}
                                        </Row>
                                        <BankDetails employeeId={props.employeeId} employeeData={empData} />

                                        <br />

                                        <EmployeeAddressDetails employeeId={props.employeeId} employeeData={empData}  />

                                        <br />

                                        <EmployeeDependent employeeId={props.employeeId} employeeData={empData}  />

                                    </div>
                                </TabPane>

                                <TabPane tabId={3}>
                                    <div>
                                        <Row className="m-2">
                                            Employee : {empData.fullName}
                                        </Row>
                                        <EmployeeEducation employeeId={props.employeeId} employeeData={empData}  />
                                        <br />
                                        <EmploymentHistory employeeId={props.employeeId} employeeData={empData}  />
                                    </div>
                                </TabPane>

                                <TabPane tabId={4}>
                                    <div>
                                        <EmployeeCompensation employeeId={props.employeeId} employeeData={empData} />
                                    </div>
                                </TabPane>

                                <TabPane tabId={6}>
                                    <div>
                                        <EmployeeTransfer employeeId={props.employeeId} employeeData={empData} />
                                    </div>
                                </TabPane>

                                <TabPane tabId={7}>
                                    <div>
                                        <EmployeeIncrement employeeId={props.employeeId} employeeData={empData} />
                                    </div>
                                </TabPane>

                                <TabPane tabId={8}>
                                    <div>
                                        <EmployeePromotion employeeId={props.employeeId} employeeData={empData} />
                                    </div>
                                </TabPane>

                                <TabPane tabId={9}>
                                    <div>
                                        <EmployeeInsurance employeeId={props.employeeId} employeeData={empData} />
                                    </div>
                                </TabPane>

                                <TabPane tabId={10}>
                                    <div>
                                        <EmployeeDocument employeeId={props.employeeId} employeeData={empData} />
                                    </div>
                                </TabPane>

                                <TabPane tabId={11}>
                                    <div>
                                        <EmployeeSuspension employeeId={props.employeeId} employeeData={empData} />
                                    </div>
                                </TabPane>

                                <TabPane tabId={12}>
                                    <div>
                                        <EmployeeHistory employeeId={props.employeeId} employeeData={empData} />
                                    </div>
                                </TabPane>
                                                                
                            </TabContent>
                            
                        </div>
                    </CardBody>
                </Container >
            </div >
        </React.Fragment >
    );
};

EmployeeDetailsView.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default EmployeeDetailsView;
