import React, { Component, useState, useEffect } from "react"
import ReactEcharts from "echarts-for-react"
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";
import { getData } from "../../helpers/url-request"
import { getEmployeeCount } from "../../helpers/backend_helper";

const EmployeeCount = () => {

    const [chartData, setChartData] = useState(0);

    const option = {
        tooltip: {
            formatter: "{a} <br/>{b} : {c} ({d}%)",
        },        
        series: [
            {
                name: "Employees",
                type: "gauge",
                progress: {
                    show: true
                },
                min: 0,
                max: 400,
                splitNumber: 10,
                detail: {
                    valueAnimation: true,
                    formatter: '{value}'
                },
                data: [
                    { value: chartData, name: "Employees" }
                ],
                axisLine: {
                    lineStyle: {
                        width: 6,
                        color: [
                            [1, '#00C853']
                        ],
                    },
                },
                axisTick: {
                    length: 12,
                    lineStyle: {
                        color: "auto",
                        width: 2
                    }
                },
                splitLine: {
                    length: 20,
                    lineStyle: {
                        color: "auto",
                        width: 5
                    }
                }
            },
        ]
    }

    useEffect(() => {
        
        getData("/Employee/totalcount/0").then(response => {
            console.log(response.data);
            if (response.data) {                
                setChartData(response.data.Total);
            }
         });
    }, []);

    return (
        <React.Fragment>
            <Card>
                <CardBody style={{ padding : "0px !important" }}>
                    <Row className="align-items-center">                       
                        <Col xs={12}>                            
                            <ReactEcharts option={option} />
                            Employee Count
                        </Col>
                    </Row>
                </CardBody>
            </Card>            
        </React.Fragment>
    )
}

export default EmployeeCount