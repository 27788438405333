import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";
import EmployeeDashboard from "../pages/Dashboard/EmployeeDashboard";
import AttendanceDashboard from "../pages/Dashboard/AttendanceDashboard";
import DistrictDashboard from "../pages/Dashboard/DistrictDashboard";
import PayrollDashboard from "../pages/Dashboard/PayrollDashboard";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import userProfile from "../pages/Authentication/user-profile";
import TwoStepVerfication from "../pages/Authentication/TwoStepVerfication";

import MyTask from "../pages/Tasks/MyTask/TaskList";
import TaskSearch from "../pages/Tasks/TaskSearch";
import TaskBoard from "../pages/Tasks/TaskBoard";

import AddExpense from "../pages/Expenses/AddExpense/AddExpense";
import ExpenseRequest from "../pages/Expenses/ExpenseRequest/expenserequest";
import MyExpenseRequest from "../pages/Expenses/ExpenseRequest/myexpenserequest";

import LoanList from "../pages/Loans/LoanList/LoanList";
import LoanRequestList from "../pages/Loans/LoanRequest/LoanRequestList";

import EmployeeDirectoryList from "../pages/HRManagement/Employee-Directory/EmployeeList";
import AddEmployeeDetails from "../pages/HRManagement/Employee-Directory/AddEmployeeDetails";
import SettingsLocation from "../pages/Settings/Company/Location";
import SettingsDepartment from "../pages/Settings/Company/Department";
import SettingsLeaveType from "../pages/Settings/Company/LeaveType";
import SettingsHolidayPolicy from "../pages/Settings/Company/HolidayPolicy/holiday-policy";
import SettingsEmployeeType from "../pages/Settings/Employee/EmployeeType";
import SettingsAllowanceType from "../pages/Settings/Account/AllowanceType";
import SettingsDeductionType from "../pages/Settings/Account/DeductionType";
import SettingsShiftType from "../pages/Settings/ShiftType/Shift-Type";
import SettingsRoles from "../pages/Settings/Security/Roles";
import SettingsUsers from "../pages/Settings/Security/Users";
import SettingsCompany from "../pages/Settings/Company/Company";
import SettingsEmployee from "../pages/Settings/Employee/Employee";
import SettingsSecurity from "../pages/Settings/Security/Security";
import SettingsAccount from "../pages/Settings/Account/Account";
import SettingsPayscale from "../pages/Settings/Payscale/Payscale";
import SettingsHRACCAScale from "../pages/Settings/Payscale/HRACCAScale";
import SettingsFinancialYear from "../pages/Settings/Account/FinancialYear";
import SettingsPayPeriod from "../pages/Settings/Account/PayPeriod";
import SettingsIncomeTax from "../pages/Settings/IncomeTax/IncomeTax";

import LeaveRequest from "../pages/Leaves/LeaveRequest/Leave-Request";
import PendingLeaveRequest from "../pages/Leaves/PendingRequest/PendingLeaveRequest";
import SettingsLeavePolicy from "../pages/Settings/LeavePolicy/leavepolicy";
import ManageLeaves from "../pages/Leaves/ManageLeave/ManageLeaves";
import LeaveEncashment from "../pages/Leaves/Encashment/LeaveEncashment";

import DocumentList from "../pages/HRManagement/Employee-Directory/DocumentList";
import TransferList from "../pages/HRManagement/Employee-Directory/TransferList";
import ITDeclarationList from "../pages/HRManagement/Employee-Directory/ITDeclarationList";
import ITSlabList from "../pages/HRManagement/Employee-Directory/ITSlabList";
import IncrementList from "../pages/HRManagement/Employee-Directory/IncrementList";
import PromotionList from "../pages/HRManagement/Employee-Directory/PromotionList";
import InsuranceList from "../pages/HRManagement/Employee-Directory/InsuranceList";
import SuspensionList from "../pages/HRManagement/Employee-Directory/SuspensionList";
import AddEmployeeCompensation from "../pages/HRManagement/Employee-Directory/Compensation";
import EmployeeCompensation from "../pages/HRManagement/Employee-Directory/CompensationList";
import SettingsExpenseType from "../pages/Settings/ExpenseType/expensetype";
import SettingsExpensePolicy from "../pages/Settings/expensepolicy/expensepolicy";
import SettingsLoanType from "../pages/Settings/Account/LoanType";
import EmployeeDeduction from "../pages/HRManagement/Deductions/AddDeductions";
import HRConsultant from "../pages/HRManagement/consultant/consultant-list";
import HROrganizationalChart from "../pages/HRManagement/Org-Chart/Org-Chart";
import HRContractor from "../pages/HRManagement/Vendors/vendors";
import SettinsWorkflow from "../pages/Settings/workflow/workflow";
import SettingsWorkScedule from "../pages/Settings/WorkSchedule/work-schedule";
import EmployeeDetailsView from "../pages/HRManagement/Employee-Directory/EmployeeView";
import SettingsPayrollBatch from "../pages/Settings/PayrollBatch/payroll-batch";
import TaskType from "../pages/Settings/TaskType/TaskType";

import PayrollSummary from "../pages/Payroll/PayrollSummary/summary";
import PayrollSettings from "../pages/Payroll/Settings/payroll-settings";
import PayrollCompliance from "../pages/Payroll/Compliance/compliance";
import RunPayrollWizard from "../pages/Payroll/RunPayroll/wizard";
import Payslips from "../pages/Payroll/Payslips/payslips";
import MyPayslips from "../pages/Payroll/Payslips/mypayslips";
import Forms from "../pages/Payroll/Forms/forms";
import ArrearsList from "../pages/Payroll/Arrears/ArrearsList";
import IncomeTaxList from "../pages/Payroll/IncomeTax/IncomeTaxList";
import SalaryPaymentList from "../pages/Payroll/Payment/SalaryPaymentList";
import DeductionPaymentList from "../pages/Payroll/Payment/DeductionPaymentList";
import PayrollAllowanceList from "../pages/Payroll/Allowance/AllowanceList";
import PayrollDeductionList from "../pages/Payroll/Deduction/DeductionList";
import SalaryCorrectionList from "../pages/Payroll/SalaryCorrection/SalaryCorrectionList";

import LeaveCalendar from "../pages/Leaves/LeaveCalendar/LeaveCalendar";
import ManualTimeEntry from "../pages/Attendance/manual-time-entry/manual-time-entry";
import AttendanceImport from "../pages/Attendance/AttendanceImport/attendance-import";
import AttendanceReport from "../pages/Attendance/AttendanceReport/attendance-report";
import AttendanceDetailReport from "../pages/Attendance/AttendanceReport/attendance-detailreport";
import MonthlyAttendanceReport from "../pages/Attendance/AttendanceReport/attendance-monthlyreport";
import MonthlyAttendanceList from "../pages/Attendance/MonthlyReport/monthlyattendancelist";
import AttendanceManagement from "../pages/Attendance/AttendanceManagement/attendancemanagement";

import PayrollBankDeposit from "../pages/Payroll/BankDeposit/BankDeposit";
import SalaryDeposit from "../pages/Payroll/BankDeposit/SalaryDeposit";
import WorkFlowList from "../pages/Settings/workflow/workflow-list";
import PayrollDetails from "../pages/Payroll/PayrollSummary/payroll-details";
import ConsultantSalaryDeposit from "../pages/Consultants/DirectDeposit/SalaryDeposit";
import ConsultantDirectDeposit from "../pages/Consultants/DirectDeposit/DirectDeposit";
import VendorsDirectDeposit from "../pages/Vendors/DirectDeposit/DirectDeposit";
import VendorSalaryDeposit from "../pages/Vendors/DirectDeposit/SalaryDeposit";
import ConsultantPayment from "../pages/Consultants/Payments/payment";
import VendorPayment from "../pages/Vendors/Payments/payment";

import BankDetailsReport from "../pages/Reports/BankDetailsReport";
import SalaryReport from "../pages/Reports/SalaryReport";
import SalaryDetailReport from "../pages/Reports/SalaryDetailReport";
import SalaryAbstractReport from "../pages/Reports/SalaryAbstractReport";
import SalarySummaryReport from "../pages/Reports/SalarySummaryReport";
import SalaryBankReport from "../pages/Reports/SalaryBankReport";
import AllowanceReport from "../pages/Reports/AllowanceReport";
import DeductionReport from "../pages/Reports/DeductionReport";
import LeaveUsageReport from "../pages/Reports/LeaveUsageReport";
import ExpenseReport from "../pages/Reports/ExpenseReport";
import EmployeeAttendanceReport from "../pages/Attendance/AttendanceReport/attendance-report";
import VendorPaymentReport from "../pages/Reports/VendorPaymentReport";
import ConsultantPaymentReport from "../pages/Reports/ConsultantPaymentReport";
import TDSReport from "../pages/Reports/TDSReport";
import GSTReport from "../pages/Reports/GSTReport";
import PFReport from "../pages/Reports/PFReport";
import PTReport from "../pages/Reports/PTReport";
import ReportMenu from "../pages/Reports/ReportMenu";

import { HRPolicies } from "../pages/HRManagement/Policies/PolicyList";
import { SancationList } from "../pages/HRManagement/Sancation/SancationList";
// import BPMNApp from "../pages/Settings/expense-policcy/expensepolicy";

import HelpIndex from "../pages/Help/help";
import SettingsGrades from "../pages/Settings/Employee/EmployeeGrades";


const userRoutes = [
  //dashboard
  { path: "/dashboard", component: Dashboard },
  { path: "/attendancedashboard", component: AttendanceDashboard },
  { path: "/mydashboard", component: EmployeeDashboard },
  { path: "/districtdashboard", component: DistrictDashboard },
  { path: "/payrolldashboard", component: PayrollDashboard },


  //Consultants
  { path: '/consultant/deposits', component: ConsultantDirectDeposit },
  { path: '/consultant/salary', component: ConsultantSalaryDeposit },
  { path: '/consultant/payments', component: ConsultantPayment },

  //Vendors
  { path: '/vendors/deposits', component: VendorsDirectDeposit },
  { path: '/vendors/salary', component: VendorSalaryDeposit },
  { path: '/vendors/payment', component: VendorPayment },

  //payroll
  { path: "/payroll/summary", component: PayrollSummary },
  { path: "/payroll/settings", component: PayrollSettings },
  { path: "/payroll/compliance", component: PayrollCompliance },
  { path: "/payroll/payslips", component: Payslips },
  { path: "/payroll/mypayslips", component: MyPayslips },
  { path: "/payroll/arrearslist", component: ArrearsList },
  { path: "/payroll/incometaxlist", component: IncomeTaxList },
  { path: "/payroll/otallowance", component: PayrollAllowanceList },
  { path: "/payroll/otdeduction", component: PayrollDeductionList },
  { path: "/payroll/salarycorrection", component: SalaryCorrectionList },
  { path: "/payroll/salarypaymentlist", component: SalaryPaymentList },
  { path: "/payroll/deductionpaymentlist", component: DeductionPaymentList },
  { path: "/payroll/forms", component: Forms },
  { path: "/payroll/wizard", component: RunPayrollWizard },
  { path: '/payroll/directdeposits', component: PayrollBankDeposit },
  { path: '/payroll/salarydeposits', component: SalaryDeposit },
  {
    path: "/payroll/summary/view/:payrollId",
    component: PayrollDetails,
  },


  //HR Management
  {
    path: "/hr/employeedirectory",
    component: EmployeeDirectoryList,
  },
  {
    path: "/hr/employeecompensation",
    component: EmployeeCompensation,
  },
  {
     path: "/hr/transferlist",
     component: TransferList,
    }, {
      path: "/hr/incrementlist",
      component: IncrementList,
    }, {
      path: "/hr/promotionlist",
      component: PromotionList,
    }, {
        path: "/hr/insurancelist",
        component: InsuranceList,
    }, {
      path: "/hr/itlist",
      component: ITDeclarationList,
    }, {
      path: "/hr/itslablist",
      component: ITSlabList,
    }, {
      path: "/hr/suspensionlist",
      component: SuspensionList,
    },
    {
        path: "/hr/documentlist",
        component: DocumentList,
    },
  {
    path: "/hr/org-chart",
    component: HROrganizationalChart,
  },
  {
    path: "/hr/employee/add",
    component: AddEmployeeDetails,
  },
  {
    path: "/hr/employee/view/:empid",
    component: EmployeeDetailsView,
  },
  {
    path: "/hr/employee-compensation/add",
    component: AddEmployeeCompensation,
  },
  {
    path: "/hr/employee-deduction/add",
    component: EmployeeDeduction,
  },
  {
    path: "/hr/consultant",
    component: HRConsultant,
  },
  {
    path: "/hr/vendors",
    component: HRContractor,
  },
  {
    path: "/hr/policies",
    component: HRPolicies,
  },
  {
      path: "/hr/sancations",
      component: SancationList,
  },
  

  //Settings    
  {
      path: "/settings/company",
      component: SettingsCompany,
    },
    {
        path: "/settings/employee",
        component: SettingsEmployee,
    },
  {
    path: "/settings/location",
    component: SettingsLocation,
  },
  {
    path: "/settings/tasktype",
    component: TaskType,
  },
  {
    path: "/settings/departments",
    component: SettingsDepartment,
  },
  {
    path: "/settings/leavetype",
    component: SettingsLeaveType,
  },
  {
      path: "/settings/leavepolicy",
      component: SettingsLeavePolicy,
  },
  {
    path: "/settings/holidaypolicy",
    component: SettingsHolidayPolicy,
  },
  {
    path: "/settings/employeetype",
    component: SettingsEmployeeType,
  },
  {
    path: "/settings/account",
    component: SettingsAccount,
  },
  {
     path: "/settings/allowancetype",
     component: SettingsAllowanceType,
  },
  {
     path: "/settings/deductiontype",
     component: SettingsDeductionType,
  },
  {
    path: "/settings/shifttype",
    component: SettingsShiftType,
  },
  {
    path: "/settings/expensetype",
    component: SettingsExpenseType,
  },
  {
    path: "/settings/expensepolicy",
    component: SettingsExpensePolicy,
  },
  {
    path: "/settings/loantype",
    component: SettingsLoanType,
  },
  {
    path: "/settings/workflow",
    component: WorkFlowList,
  },
  {
    path: "/settings/work-schedule",
    component: SettingsWorkScedule,
  },
  {
    path: "/settings/payroll-batch",
    component: SettingsPayrollBatch,
  },
  {
    path: "/settings/roles",
    component: SettingsRoles,
    },
    {
        path: "/settings/security",
        component: SettingsSecurity,
    },
  {
    path: "/settings/users",
    component: SettingsUsers,
  },
  {
    path: "/settings/grades",
    component: SettingsGrades,
    },
    {
        path: "/settings/payscales",
        component: SettingsPayscale,
    },
   {
        path: "/settings/financialyear",
        component: SettingsFinancialYear,
    },
    {
        path: "/settings/payperiod",
        component: SettingsPayPeriod,
    },
    {
        path: "/settings/incometax",
        component: SettingsIncomeTax,
    },
    
  
  // {
  //   path: '/settings/work-flow', component: BPMNApp
  // },

  //Task
   {
       path: '/task/mytask',
       component: MyTask
    },
    {
        path: '/myboard',
        component: MyTask
    },
   {
       path: '/task/TaskSearch',
       component: TaskSearch
   },    
   {
       path: '/task/Taskboard',
       component: TaskBoard
   },
    
  //Attendance
  {
    path: '/attendance/create',
    component: ManualTimeEntry
  },
  {
    path: '/attendance/import',
    component: AttendanceImport
  },
  {
    path: '/attendance/report',
    component: AttendanceReport
  },
  {
    path: '/attendance/detailreport',
    component: AttendanceDetailReport
  },
  {
    path: '/attendance/monthlyreport',
    component: MonthlyAttendanceReport
  },
  {
    path: '/attendance/reportlist',
    component: MonthlyAttendanceList
  },
  {
    path: '/attendance/management',
    component: AttendanceManagement
  },   

  //Leave
  {
    path: "/leave/leave-request",
    component: LeaveRequest,
  },
  {
    path: "/leave/pendingleaverequest",
    component: PendingLeaveRequest,
  },
  {
      path: '/leave/leave-calendar',
      component: LeaveCalendar
  },
  {
      path: '/leave/manageleaves',
      component: ManageLeaves
  },
  {
      path: '/leave/leaveencashment',
      component: LeaveEncashment
  },    

  //expenses
    { path: "/expense-list", component: AddExpense },
    { path: "/expense/expenserequest", component: ExpenseRequest },
    { path: "/expense/myexpenserequest", component: MyExpenseRequest },

  //loans
    { path: "/loan/loanlist", component: LoanList },
    { path: "/loan/loanrequest", component: LoanRequestList },

 // reports 
    { path: "/reports/bankDetailsReport", component: BankDetailsReport },
    { path: "/reports/salaryreport", component: SalaryReport },
    { path: "/reports/salarysummaryreport", component: SalarySummaryReport },
    { path: "/reports/salarydetailreport", component: SalaryDetailReport },
    { path: "/reports/salaryabstractreport", component: SalaryAbstractReport },
    { path: "/reports/salarybankreport", component: SalaryBankReport },
    { path: "/reports/allowancereport", component: AllowanceReport },
    { path: "/reports/deductionreport", component: DeductionReport },
    { path: "/reports/leaveusagereport", component: LeaveUsageReport },
    { path: "/reports/expensereport", component: ExpenseReport },
    { path: "/reports/EmployeeAttendanceReport", component: EmployeeAttendanceReport },
    { path: "/reports/VendorPaymentReport", component: VendorPaymentReport },
    { path: "/reports/ConsultantPaymentReport", component: ConsultantPaymentReport },
    { path: "/reports/TDSReport", component: TDSReport },
    { path: "/reports/GSTReport", component: GSTReport },
    { path: "/reports/PFReport", component: PFReport },
    { path: "/reports/PTReport", component: PTReport },
    { path: "/reports", component: ReportMenu },
    

  //profile
  { path: "/profile", component: userProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  { path: "/help", component: HelpIndex }

];

const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/twostepverification", component: TwoStepVerfication },
];

export { userRoutes, authRoutes };
