import React, { useState } from 'react';

import {
    Button, Col, Input, Label, Row
} from "reactstrap";

export const AddPolicy = () => {
    return (
        <>
            <Row className='m-2'>
                <Col md={4}>
                    Policy Name
                </Col>
                <Col md={8}>
                    <Input className='form-control' type='text' placeholder='Enter Policy Name' />
                </Col>
            </Row>

            <Row className='m-2'>
                <Col md={4}>
                    Policy Description
                </Col>
                <Col md={8}>
                    <Input className='form-control' type='textarea' placeholder='Please enter Policy Description'>
                    </Input>
                </Col>
            </Row>

            <Row className='m-2'>
                <Col md={4}>
                    Policy File
                </Col>
                <Col md={8}>
                    <Input className='form-control' type='file'></Input>
                </Col>
            </Row>

            <Row className='m-2'>
                <Col md={4}>
                    Employee can view
                </Col>
                <Col md={2}>
                    <div className="form-check mb-3">
                        <input className="form-check-input" type="radio"
                            name="formRadios" id="formRadios1" defaultChecked />
                        <label className="form-check-label" htmlFor="formRadios1">
                            Yes
                        </label>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                            name="formRadios" id="formRadios2" />
                        <label className="form-check-label" htmlFor="formRadios2">
                            No
                        </label>
                    </div>
                </Col>
            </Row>

            <Row className='m-2'>
                <Col md={4}>
                    Employee can Download
                </Col>
                <Col md={2}>
                    <div className="form-check mb-3">
                        <input className="form-check-input" type="radio"
                            name="formRadios" id="formRadios1" defaultChecked />
                        <label className="form-check-label" htmlFor="formRadios1">
                            Yes
                        </label>
                    </div>
                </Col>
                <Col md={2}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                            name="formRadios" id="formRadios2" />
                        <label className="form-check-label" htmlFor="formRadios2">
                            No
                        </label>
                    </div>
                </Col>
            </Row>

            <div className="offcanvas-footer">
                <Button color="primary" className="btn btn-sm"
                    type="submit">Submit</Button>&nbsp;&nbsp;
                <button className="btn btn-dark btn-sm">Close</button>
            </div>
        </>
    )
}