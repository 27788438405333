import React from 'react';

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
    Button, Card, CardHeader, CardTitle, Col, Row
} from "reactstrap";
import {
    Container,
} from "reactstrap";

import { Link } from 'react-router-dom';
import EmployeeDetails from './EmployeeDetails';

const AddEmployeeDetails = () => {


    document.title = "Employee Directory | HRMS";
    return (
        <React.Fragment>            
            <Container fluid>                    
                <Breadcrumbs title="HR Management" breadcrumbItem="Employee Details" />
                <div className="row">
                    <div className="col-lg-12"><EmployeeDetails /></div>
                </div>
            </Container>            
        </React.Fragment>
    );
};

export default AddEmployeeDetails;