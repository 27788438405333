import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

import store from "./store";

import { registerLicense } from '@syncfusion/ej2-base';

registerLicense("Mgo+DSMBaFt/QHRqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSHxUdkNiW3pZdnNRQw==;Mgo+DSMBPh8sVXJ0S0J+XE9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0dkWXlfcnFSQGlYUQ==;ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRhXn9YcXZQQWddVUE=;MTAxNTIyNUAzMjMwMmUzNDJlMzBkYXVZejJxNVhTaGVSS3JGc3BKN0lYak5MYi9uSDYxMmlZTkNuZE45M2lrPQ==;MTAxNTIyNkAzMjMwMmUzNDJlMzBOeldGREtNMEtqV2ZGWDdTOXQrbzh6a09wVXZEdmVRd1RJUmw5VUdPc05jPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFxKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViW3hednVXQ2dUUU1z;MTAxNTIyOEAzMjMwMmUzNDJlMzBWcVFhU2dLQXhUMUJtNldTMHBTVVM5dFVPbm54clVmYWJjYmgrblNjUjVVPQ==;MTAxNTIyOUAzMjMwMmUzNDJlMzBVMXk5Nmpra2VjZlhaQ3hJUnM2QmxNbTRHclVuMk43VjdsTXpncGFOUHhZPQ==;Mgo+DSMBMAY9C3t2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRhXn9YcXZQQWldUUQ=;MTAxNTIzMUAzMjMwMmUzNDJlMzBRV2xpeHhiZHlTZ1ZUdEVNa05wV21VcHBLQnQrRDNzdlBTclVLQXN3amQ0PQ==;MTAxNTIzMkAzMjMwMmUzNDJlMzBQMzEwZ1RkK1c3N2JZOFozT3dqenN6bVVLMUwwRDYyWnRyNkFqWEtqT0xvPQ==;MTAxNTIzM0AzMjMwMmUzNDJlMzBWcVFhU2dLQXhUMUJtNldTMHBTVVM5dFVPbm54clVmYWJjYmgrblNjUjVVPQ==")

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
     <React.Fragment>
        <BrowserRouter basename="http://localhost/hrms">
            <App />            
        </BrowserRouter>
     </React.Fragment>
  </Provider>
);
