import React, { useEffect, useState } from "react";
import {
    CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col,
    Row, Container, Button, Offcanvas, OffcanvasBody, OffcanvasHeader, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Resize } from "@syncfusion/ej2-react-grids";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';
import LoanRequestDetail from "./LoanRequestDetail";
import RequestDetail from "./RequestDetail";

const LoanRequestList = () => {
    let gridInstance;
    const [loanRequestList, setLoanRequestList] = useState();
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [canvasDetailOpen, setCanvasDetailOpen] = useState(false);

    const [loanId, setLoanId] = useState();
    const [employeeId, setEmployeeId] = useState();

    const loadData = () => {
        showLoading(true);

        postData("/loan/searchloanrequest", {}).then(res => {
            if (res.data) {
                setLoanRequestList(res.data);
            }
            showLoading(false);
        }).catch((err) => {
            console.log(err);
            showLoading(false);
        });
    }

    useEffect(() => {
                
        loadData();

    }, [])

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const viewRequestDetail = (requestId) => {
        setLoanId(toggleDetailEnd);
        toggleDetailEnd();
    }

    const headerLink = props => {
        console.log(props);
        return (
            <button onClick={() => viewRequestDetail(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                LN-{props.id}
            </button>
        )
    }

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const onDetailClose = () => {
        toggleCanvasEnd();
    }

    const toggleDetailEnd = () => {        
        setCanvasDetailOpen(!canvasDetailOpen)
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Modal isOpen={canvasDetailOpen} toggle={toggleDetailEnd} fullscreen>
                    <ModalHeader toggle={toggleDetailEnd}>Loan Request Details</ModalHeader>
                    <ModalBody>
                        <RequestDetail loanId={loanId} employeeId={employeeId} closeAction={toggleDetailEnd} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="btn primary" type="button" onClick={toggleDetailEnd}>Close</Button>
                    </ModalFooter>
                </Modal>

                <Breadcrumbs title="Loan" breadcrumbItem="Loan Requests" enableAction={true} actionName="New Request" onClick={toggleCanvasEnd} />

                <div className='mt-2'>
                    <GridComponent dataSource={loanRequestList} allowPaging={true} allowSorting={true} showColumnMenu={true}
                        pageSettings={{ pageCount: 5, pageSizes: true }} toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)}
                        ref={grid => gridInstance = grid} statelessTemplates={['directiveTemplates']} allowExcelExport={true}
                        allowPdfExport={true} allowResizing={true}>
                        <ColumnsDirective>
                            <ColumnDirective field='id' headerText='Request No.' template={headerLink} width='100'></ColumnDirective>
                            <ColumnDirective field='employeeProfile.fullName' headerText='Employee'></ColumnDirective>
                            <ColumnDirective field='loanType.name' headerText='Loan Type' width='150'></ColumnDirective>
                            <ColumnDirective field='appliedOn' type='datetime' format='dd/MM/yyyy' headerText='Date' width='150'></ColumnDirective>
                            <ColumnDirective field='loanAmount' headerText='Loan Amount' width='150'></ColumnDirective>
                            <ColumnDirective field='installments' headerText='Installments' width='150'></ColumnDirective>
                            <ColumnDirective field='description' headerText='Description' width='150' />
                            <ColumnDirective field='loanStatus' headerText='Status' width='150' />
                            <ColumnDirective field='updatedOn' type='datetime' format='dd/MM/yyyy' headerText='Last Updated' width='150' />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Sort, ColumnMenu, Resize]} />
                    </GridComponent>
                </div>

                <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen}
                    toggle={toggleCanvasEnd}>
                    <OffcanvasHeader toggle={toggleCanvasEnd}>
                        <Row>
                            <Col sm='12'>
                                <b>Loan Request</b>
                            </Col>
                        </Row>
                    </OffcanvasHeader>
                    <hr />
                    <OffcanvasBody>
                        <LoanRequestDetail onClose={onDetailClose} />
                    </OffcanvasBody>
                </Offcanvas>
            </div>            
        </React.Fragment>
    );
};

export default LoanRequestList;