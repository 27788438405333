import React, { useEffect, useState } from "react";
import {
    CardBody, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader,
    CardTitle, Col, Row, FormGroup, Form, Button
} from "reactstrap";

import { Link } from "react-router-dom";
import {
    getEmployeeTypes, fetchJson, getDepartments, getWorkSchedules, getCompanyLocations, getMaritalStatus,
    getGenderTypes, getLeavePolicies, getHolidayPolicies, getExpensePolicies, createEmployee, getShiftTypes, getEmployeeList,
    getEmployeeReportingList, getAllContractors, getEmployeeWorkGrades, getEmployeeStatuses
} from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";

const EmployeeDetails = (props) => {
    const [activeTab, setactiveTab] = useState(1);
    const [employeetypes, setEmpTypes] = useState();
    const [departments, setDepartments] = useState();
    const [workschedules, setWorkSchedule] = useState();
    const [maritalstatus, setMaritalStatus] = useState();
    const [locations, setLocations] = useState();
    const [grades, setGrades] = useState();
    const [gendertypes, setGenderTypes] = useState();
    const [employeeStatuses, setEmployeeStatuses] = useState();
    const [leavepolicies, setLeavePolicy] = useState();
    const [holidaypolicies, setHolidayPolicy] = useState();
    const [expensepolicies, setExpensePolicy] = useState();
    const [shiftTypes, setShiftTypes] = useState();
    const [emplList, setEmployeeReportingList] = useState();
    const [contractors, setContractors] = useState();

    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [employeeData, setEmployeeData] = useState({
        fullName: '', employeeCode: '', genderTypeId: 0, dateOfBirth: '', maritalStatus: 0,
        fatherName: '', motherName: '', phone: '', emailId: '', joiningDate: '', locationId: '',
        employeeTypeId: 0, contactorId: 0, departmentId: 0, reportsTo: 0, workTitle: '',
        workDesc: '', workShift: 0, workSchedule: 0, attendanceType: 0, bloodGroup: '',
        expensePolicyId: 0, leavePolicyId: 0, holidayPolicyId: 0, employeeGradeId: 0,
        aadharNumber: "", pan: "", pfAccountNo: "", pfuan: "",
        esiAccountNo: "", payrollEnabled: 0, employeeStatus: 0, loginEnabled: 0
    });

    const handleSubmit = event => {
        event.preventDefault();
        console.log(employeeData);
        let formErrors = {};
        if (!employeeData.fullName) {
            formErrors.fullName = 'Full Name is required';
        }
        if (!employeeData.employeeCode) {
            formErrors.employeeCode = 'Employee Code is required';
        }
        if (employeeData.genderTypeId === 0) {
            formErrors.genderTypeId = 'Gender is required';
        }
        if (employeeData.dateOfBirth === 0) {
            formErrors.dateOfBirth = 'Date of Birth is required';
        }
        if (employeeData.maritalStatus === 0) {
            formErrors.maritalStatus = 'Marital Status is required';
        }
        //if (!employeeData.fatherName) {
        //    formErrors.fatherName = 'Father Name is required';
        //}
        //if (!employeeData.motherName) {
        //    formErrors.motherName = 'Mother Name is required';
        //}
        if (!employeeData.phone) {
            formErrors.phone = 'Phone Number is required';
        }
        //if (!employeeData.emailId) {
        //    formErrors.emailId = 'Email Id is required';
        //}
        if (!employeeData.joiningDate) {
            formErrors.joiningDate = 'JoiningDate is required';
        }
        if (employeeData.locationId === 0) {
            formErrors.locationId = 'Office Location is required';
        }
        if (employeeData.employeeTypeId === 0) {
            formErrors.employeeTypeId = 'Employee Type is required';
        }
        //if (!employeeData.employeeGradeId) {
        //    formErrors.employeeGradeId = 'Employee Grade is required';
        //}
        // if (employeeData.contactorId === 0) {
        //     formErrors.contactorId = 'Contractor is required';
        // }
        if (employeeData.departmentId === 0) {
            formErrors.departmentId = 'Department is required';
        }
        // if (employeeData.reportsTo === 0) {
        //     formErrors.reportsTo = 'ReportsTo is required';
        // } 
        if (!employeeData.workTitle) {
            formErrors.workTitle = 'WorkTitle is required';
        } if (employeeData.workShift === 0) {
            formErrors.workShift = 'WorkShift is required';
        }
        if (employeeData.workSchedule === 0) {
            formErrors.workSchedule = 'WorkSchedule is required';
        }
        if (employeeData.employeeTypeId == 2 && employeeData.contactorId === 0) {
            formErrors.contactorId = 'Contractor is required';
        }
        if (employeeData.attendanceType === 0) {
            formErrors.attendanceType = 'AttendanceType is required';
        }
        if (employeeData.expensePolicyId === 0) {
            formErrors.expensePolicyId = 'Expense Policy is required';
        }
        if (employeeData.leavePolicyId === 0) {
            formErrors.leavePolicyId = 'LeavePolicy is required';
        }
        if (employeeData.holidayPolicyId === 0) {
            formErrors.holidayPolicyId = 'HolidayPolicy is required';
        }

        setErrors(formErrors);
        console.log(formErrors);
        if (Object.keys(formErrors).length != 0) {
            return;
        }

        // console.log(employeeData);
        createEmployee(employeeData).then((res) => {
            // console.log(res);
            if (res.status === true) {
                console.log("Inside success");
                setDataSuccess("added");
                setIsLoading(false);
                setEmployeeData({
                    ...employeeData,
                    employeeData
                });
                alert("Employee added successfully");
                props.onClose();
            } else {
                console.log("Inside failure");
                setDataSuccess("failed");
                setIsLoading(false);
            }
            setFormSubmit(!submitted);
            timeout();
        }).catch((err) => {
            console.log(err);
            setDataSuccess("failed");
            setIsLoading(false);
            timeout();
        })
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const handleChange = event => {
        if (event.target.name === "status" || event.target.name === "loginEnabled" || event.target.name === "payrollEnabled") {
            let statusSet = event.target.checked ? 1 : 0;
            setEmployeeData({ ...employeeData, [event.target.name]: statusSet });
        } else {
            setEmployeeData({ ...employeeData, [event.target.name]: event.target.value });
        }
    };

    useEffect(() => {
        console.log("inside useEffect");

        getEmployeeStatuses().then(res => {
            if (res.data) {
                let empType = [{ "id": 0, "name": "Select Status" }];
                res.data.forEach(el => {
                    empType.push(el)
                });
                employeeData.employeeStatus = empType[0].id
                setEmployeeStatuses(empType)
            }
        });

        getEmployeeTypes().then(res => {
            if (res.data) {
                //  console.log(res.data);

                let empType = [{ "id": 0, "name": "Select Employee Type" }];
                res.data.forEach(el => {
                    empType.push(el)
                });
                employeeData.employeeTypeId = empType[0].id
                setEmpTypes(empType)
            }
        });

        getAllContractors().then(res => {
            if (res.data) {
                let empType = [{ "id": 0, "name": "Select Contractor" }];
                res.data.forEach(el => {
                    empType.push(el)
                });
                employeeData.contactorId = empType[0].id
                setContractors(empType)
            }
        });

        getEmployeeReportingList().then(res => {
            if (res.data) {
                console.log(res.data);
                let emps = [{ "id": 0, "fullName": "Select Employee" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                employeeData.reportsTo = emps[0].id
                setEmployeeReportingList(emps)
            }
        });

        getDepartments().then(res => {
            if (res.data) {

                let depType = [{ "id": 0, "name": "Select Department Type" }];
                res.data.forEach(el => {
                    depType.push(el)
                });
                console.log(depType);
                employeeData.departmentId = depType[0].id
                setDepartments(depType)
            }
        });

        getWorkSchedules().then(res => {
            if (res.data) {

                let workSch = [{ "id": 0, "name": "Select Work Schedule" }];
                res.data.forEach(el => {
                    workSch.push(el)
                });
                employeeData.workSchedule = workSch[0].id
                setWorkSchedule(workSch)
            }
        });

        getCompanyLocations().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "locationName": "Select Work Location" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                employeeData.locationId = resData[0].id
                setLocations(resData)
            }
        });

        getEmployeeWorkGrades().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Grade" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                // employeeData.locationId = resData[0].id
                setGrades(resData)
            }
        });

        getMaritalStatus().then(res => {
            if (res.data) {

                let resData = [{ "id": 0, "name": "Select Marital Status" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                employeeData.maritalStatus = resData[0].id
                setMaritalStatus(resData)
            }
        });

        getGenderTypes().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Gender Type" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                employeeData.genderTypeId = resData[0].id
                setGenderTypes(resData)
            }
        });

        getLeavePolicies().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Leave Policy" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                employeeData.leavePolicyId = resData[0].id
                setLeavePolicy(resData)
            }
        });

        getHolidayPolicies().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Holiday Policy" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                employeeData.holidayPolicyId = resData[0].id
                setHolidayPolicy(resData)
            }
        });

        getExpensePolicies().then(res => {
            if (res.data) {
                let resData = [{ "id": 0, "name": "Select Expense Policy" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                employeeData.expensePolicyId = resData[0].id
                setExpensePolicy(resData)
            }
        });

        getShiftTypes().then(res => {
            if (res.data) {
                console.log(res.data);
                let resData = [{ "id": 0, "name": "Select Shift Type" }];
                res.data.forEach(el => {
                    resData.push(el)
                });
                employeeData.workShift = resData[0].id
                setShiftTypes(resData)
            }
        });
        // console.log(departments);
    }, []);

    return (
        <React.Fragment>

            <CardBody>
                <div id="basic-pills-wizard" className="twitter-bs-wizard">

                    <TabContent
                        className="twitter-bs-wizard-tab-content"
                        activeTab={activeTab}>
                        <TabPane tabId={1}>
                            <div className="text-start mb-4">
                                <h5>General Information</h5>
                                {/* <p className="card-title-desc">Fill all information below</p> */}
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='firstname'>
                                                Full Name
                                            </Label>
                                            <Input placeholder='Enter Full Name' name="fullName"
                                                onChange={handleChange} value={employeeData.fullName} />
                                            <p style={{ color: "Red" }}>{errors.fullName && <span>{errors.fullName}</span>}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='firstname'>
                                                Phone
                                            </Label>
                                            <Input placeholder='Enter Phone Number' name="phone"
                                                onChange={handleChange} value={employeeData.phone} />
                                            <p style={{ color: "Red" }}>{errors.phone && <span>{errors.phone}</span>}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='firstname'>
                                                Email
                                            </Label>
                                            <Input type="email" placeholder='Enter Email' name="emailId"
                                                onChange={handleChange} value={employeeData.emailId} />
                                            <p style={{ color: "Red" }}>{errors.emailId && <span>{errors.emailId}</span>}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                   

                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className="form-label" for="InputHelp">
                                                Marital Status
                                            </Label>
                                            <Input type="select" placeholder="Marital Status" name="maritalStatus"
                                                onChange={handleChange} value={employeeData.maritalStatus}>
                                                {maritalstatus &&
                                                    maritalstatus.map((mar) => {
                                                        return <option value={mar.id} key={mar.id}>{mar.name}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.maritalStatus && <span>{errors.maritalStatus}</span>}</p>
                                        </div>
                                    </div>

                                    <Col className="mb-1" xl="4" md="4" sm="4">
                                        <Label className="form-label" for="InputHelp">
                                            Gender
                                        </Label>
                                        <Input type="select" placeholder="Gender" name="genderTypeId"
                                            onChange={handleChange} value={employeeData.genderTypeId}>
                                            {gendertypes &&
                                                gendertypes.map((gender) => {
                                                    return <option value={gender.id} key={gender.id}>{gender.name}</option>
                                                })}
                                        </Input>
                                        <p style={{ color: "Red" }}>{errors.genderTypeId && <span>{errors.genderTypeId}</span>}</p>
                                    </Col>

                                    <Col className="mb-1" xl="4" md="4" sm="4">
                                        <Label className="form-label" for="basicInput">
                                            Date Of Birth
                                        </Label>
                                        <Input type="date" placeholder="Date of Birth" name="dateOfBirth"
                                            onChange={handleChange} value={employeeData.dateOfBirth}></Input>
                                        <p style={{ color: "Red" }}>{errors.dateOfBirth && <span>{errors.dateOfBirth}</span>}</p>
                                    </Col>

                                </div>
                                <div className="row">
                                    
                                        <Col className="mb-1" xl="4" md="4" sm="4">
                                            <Label className="form-label" for="bloodGroup">Blood Group</Label>
                                            <Input type="text" placeholder="Enter Blood Group" name="bloodGroup"
                                                onChange={handleChange} value={employeeData.bloodGroup}></Input>
                                        </Col>

                                        <Col className="mb-1" xl="4" md="4" sm="4">
                                        <Label className="form-label" for="aadharNumber">Aadhar ID</Label>
                                            <Input type="text" onChange={handleChange} name="aadharNumber"
                                                value={employeeData.aadharNumber} placeholder="Aadhar ID"></Input>
                                        </Col>

                                        <Col className="mb-1" xl="4" md="4" sm="4">
                                            <Label className="form-label" for="InputHelp">
                                                PAN
                                            </Label>
                                            <Input type="text" onChange={handleChange} name="pan"
                                                value={employeeData.pan} placeholder="PAN"></Input>
                                    </Col>
                                </div>
                                <div className="row">                                    
                                    <Col className="mb-1" xl="4" md="4" sm="12">
                                        <Label className="form-label" for="InputHelp">
                                            PF A/c No
                                        </Label>
                                        <Input type="text" placeholder="PF A/c No" name="pfAccountNo"
                                            onChange={handleChange} value={employeeData.pfAccountNo}></Input>
                                    </Col>

                                    <Col className="mb-1" xl="4" md="4" sm="12">
                                        <Label className="form-label" for="InputHelp">
                                            PF UAN
                                        </Label>
                                        <Input type="text" placeholder="PF UAN" name="pfuan"
                                            onChange={handleChange} value={employeeData.pfuan}></Input>
                                    </Col>

                                    <Col className="mb-1" xl="4" md="4" sm="12">
                                        <Label className="form-label" for="InputHelp">
                                            ESI A/c No.
                                        </Label>
                                        <Input type="text" placeholder="ESI A/c No." name="esiAccountNo"
                                            onChange={handleChange} value={employeeData.esiAccountNo}></Input>
                                    </Col>
                                </div>
                                <hr />
                                <div className="text-start mb-4">
                                    <h5>Work Information</h5>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='empId'>
                                                Employee Id
                                            </Label>
                                            <Input placeholder='Enter Employee Id' name="employeeCode"
                                                onChange={handleChange} value={employeeData.employeeCode} />
                                            <p style={{ color: "Red" }}>{errors.employeeCode && <span>{errors.employeeCode}</span>}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='empStatus'>
                                                Employee Status
                                            </Label>
                                            <Input type="select" placeholder='Employee Status' name="employeeStatus"
                                                onChange={handleChange} value={employeeData.employeeStatus}>
                                                {employeeStatuses &&
                                                    employeeStatuses.map((empStatus) => {
                                                        return <option value={empStatus.id} key={empStatus.id}>{empStatus.name}</option>
                                                    })}
                                            </Input>
                                            {/* <p style={{ color: "Red" }}>{errors.employeeStatus && <span>{errors.employeeStatus}</span>}</p> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='doj'>
                                                Date Of Joining
                                            </Label>
                                            <Input name="joiningDate" type="date"
                                                onChange={handleChange} value={employeeData.joiningDate} />
                                        </div>
                                        <p style={{ color: "Red" }}>{errors.joiningDate && <span>{errors.joiningDate}</span>}</p>
                                    </div>
                                </div>

                                <div className="row">                                    
                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='empStatus'>
                                                Employee Type
                                            </Label>
                                            <Input type="select" placeholder='Employee Type' name="employeeTypeId"
                                                onChange={handleChange} value={employeeData.employeeTypeId}>
                                                {employeetypes &&
                                                    employeetypes.map((emp) => {
                                                        return <option value={emp.id} key={emp.id}>{emp.name}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.employeeTypeId && <span>{errors.employeeTypeId}</span>}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='empStatus'>
                                                Contractor
                                            </Label>
                                            <Input type="select" defaultValue={employeeData.contactorId}
                                                onChange={handleChange} name="contactorId">
                                                {contractors &&
                                                    contractors.map((emp) => {
                                                        return <option value={emp.id} key={emp.id}>{emp.name}</option>
                                                    })}</Input>
                                            <p style={{ color: "Red" }}>{errors.contactorId && <span>{errors.contactorId}</span>}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='empStatus'>
                                                Department
                                            </Label>
                                            <Input type="select" name="departmentId"
                                                onChange={handleChange} value={employeeData.departmentId}>
                                                {departments &&
                                                    departments.map((dep) => {
                                                        return <option value={dep.id} key={dep.id}>{dep.name}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.departmentId && <span>{errors.departmentId}</span>}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='empStatus'>
                                                Office Location
                                            </Label>
                                            <Input type="select" name="locationId"
                                                onChange={handleChange} value={employeeData.locationId}>
                                                {locations &&
                                                    locations.map((loc) => {
                                                        return <option value={loc.id} key={loc.id}>{loc.locationName}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.locationId && <span>{errors.locationId}</span>}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="m">
                                            <Label className='form-label' for='doj'>
                                                Next Increment Date
                                            </Label>
                                            <Input name="incrementDate" type="date"
                                                onChange={handleChange} value={employeeData.incrementDate} />
                                        </div>
                                        <p style={{ color: "Red" }}>{errors.incrementDate && <span>{errors.incrementDate}</span>}</p>
                                    </div>

                                    <div className="col-lg-4">
                                        <Label className="form-label" for="InputHelp">
                                            Reports To
                                        </Label>
                                        <Input type="select" placeholder="Reports To" name="reportsTo"
                                            onChange={handleChange} value={employeeData.reportsTo}>
                                            {emplList &&
                                                emplList.map((emp) => {
                                                    return <option value={emp.id} key={emp.id}>{emp.fullName}</option>
                                                })}
                                        </Input>
                                        {/* <p style={{ color: "Red" }}>{errors.reportsTo && <span>{errors.reportsTo}</span>}</p> */}
                                    </div>

                                    <div className="col-lg-4">
                                        <Label className="form-label" for="basicInput">
                                            Work Title
                                        </Label>
                                        <Input type="text" placeholder="Work Title" name="workTitle"
                                            onChange={handleChange} value={employeeData.workTitle}></Input>
                                        <p style={{ color: "Red" }}>{errors.workTitle && <span>{errors.workTitle}</span>}</p>
                                    </div>
                                </div>

                                <div className="row">                                        

                                        <div className="col-lg-4">
                                            <div className="m">
                                                <Label className='form-label' for='empStatus'>
                                                    Employee Grade
                                                </Label>
                                                <Input type="select" name="employeeGradeId"
                                                    onChange={handleChange} value={employeeData.employeeGradeId}>
                                                    {grades &&
                                                        grades.map((res) => {
                                                            return <option value={res.id} key={res.id}>{res.name}</option>
                                                        })}
                                                </Input>
                                                <p style={{ color: "Red" }}>{errors.employeeGradeId && <span>{errors.employeeGradeId}</span>}</p>
                                            </div>
                                        </div>


                                        <Col className="mb-1" xl="4" md="4" sm="12">
                                            <Label className="form-label" for="InputHelp">
                                                Work Shift
                                            </Label>
                                            <Input type="select" placeholder="Marital Status" name="workShift"
                                                onChange={handleChange} value={employeeData.workShift}>
                                                {shiftTypes &&
                                                    shiftTypes.map((shift) => {
                                                        return <option value={shift.id} key={shift.id}>{shift.name}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.workShift && <span>{errors.workShift}</span>}</p>
                                        </Col>

                                        <Col className="mb-1" xl="4" md="4" sm="12">
                                            <Label className="form-label" for="basicInput">
                                                Work Schedule Policy
                                            </Label>
                                            <Input type="select" id="basicInput"
                                                name="workSchedule"
                                                onChange={handleChange} value={employeeData.workSchedule}>
                                                {workschedules &&
                                                    workschedules.map((work) => {
                                                        return <option value={work.id} key={work.id}>{work.name}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.workSchedule && <span>{errors.workSchedule}</span>}</p>
                                    </Col>
                                </div>
                                <div className="row">
                                        <Col className="mb-1" xl="4" md="4" sm="12">
                                            <Label className="form-label" for="basicInput">
                                                Attendance Type
                                            </Label>
                                            <Input type="select" id="basicInput"
                                                name="attendanceType"
                                                onChange={handleChange} value={employeeData.attendanceType}>
                                                <option value={0}>Select Attendance Type</option>
                                                <option value={1}>Biometric</option>
                                                <option value={2}>Mobile</option>
                                                <option value={3}>Biometric/Mobile</option>
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.attendanceType && <span>{errors.attendanceType}</span>}</p>
                                        </Col>

                                        <Col className="mb-1" xl="4" md="4" sm="12">
                                            <Label className="form-label" for="basicInput">
                                                Expense Policy
                                            </Label>
                                            <Input type="select" id="basicInput"
                                                name="expensePolicyId" onChange={handleChange} value={employeeData.expensePolicyId}>
                                                {expensepolicies &&
                                                    expensepolicies.map((res) => {
                                                        return <option value={res.id} key={res.id}>{res.name}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.expensePolicyId && <span>{errors.expensePolicyId}</span>}</p>
                                        </Col>

                                        <Col className="mb-1" xl="4" md="4" sm="12">
                                            <Label className="form-label" for="basicInput">
                                                Leave Policy
                                            </Label>
                                            <Input type="select" id="basicInput" name="leavePolicyId"
                                                onChange={handleChange} value={employeeData.leavePolicyId}>
                                                {leavepolicies &&
                                                    leavepolicies.map((res) => {
                                                        return <option value={res.id} key={res.id}>{res.name}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.leavePolicyId && <span>{errors.leavePolicyId}</span>}</p>
                                    </Col>
                                </div>
                                 <div className="row">
                                        <Col className="mb-1" xl="4" md="4" sm="12">
                                            <Label className="form-label" for="basicInput">
                                                Holiday Policy
                                            </Label>
                                            <Input type="select" id="basicInput" name="holidayPolicyId"
                                                onChange={handleChange} value={employeeData.holidayPolicyId}>
                                                {holidaypolicies &&
                                                    holidaypolicies.map((res) => {
                                                        return <option value={res.id} key={res.id}>{res.name}</option>
                                                    })}
                                            </Input>
                                            <p style={{ color: "Red" }}>{errors.holidayPolicyId && <span>{errors.holidayPolicyId}</span>}</p>
                                        </Col>

                                        <Col className="mt-2" xl="4" md="4" sm="12">
                                            <Label className="form-label" for="basicInput">
                                                Enable Payroll
                                            </Label>
                                            <div className="form-check form-switch form-switch-md m" dir="ltr">
                                                <input type="checkbox" className="form-check-input" name="payrollEnabled" onChange={handleChange} defaultChecked={employeeData.payrollEnabled} />
                                            </div>
                                        </Col>

                                        <Col className="mt-2" xl="4" md="4" sm="6">
                                            <Label className="form-label" for="basicInput">
                                                Enable Login
                                            </Label>
                                            <div className="form-check form-switch form-switch-md m" dir="ltr">
                                                <input type="checkbox" className="form-check-input" name="loginEnabled" onChange={handleChange} defaultChecked={employeeData.loginEnabled}  />
                                            </div>
                                        </Col>                                       
                                </div>
                                <div className="row">
                                    <Col className="mb-1" xl="12" md="12" sm="12">
                                        <Label className="form-label" for="InputHelp">
                                            Work Description
                                        </Label>
                                        <Input type="textarea" name="workDesc"
                                            onChange={handleChange} value={employeeData.workDesc} placeholder="Work Description"></Input>
                                    </Col>
                                </div>
                            </form>
                        </TabPane>
                        {/* <TabPane tabId={2}>
                            <EmployeePersonalInfo />
                        </TabPane>

                        <TabPane tabId={3}>
                            <div>
                                <EmployeeEducation />
                            </div>
                        </TabPane>

                        <TabPane tabId={4}>
                            <div>
                                <AddEmployeeCompensation />
                            </div>
                        </TabPane> */}
                    </TabContent>
                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                        {/* <li className={activeTab === 1 ? "previous disabled" : "previous"}>
                            <Link
                                to="#"
                                className={activeTab === 1 ? "btn btn-primary disabled" : "btn btn-primary"}
                                onClick={() => {
                                    toggleTab(activeTab - 1);
                                }}
                            >
                                <i className="bx bx-chevron-left me-1"></i> Previous
                            </Link>
                        </li> */}

                        <div className="text-end">
                            {isLoading ? <LoadingSpinner /> : <Button color="primary" onClick={handleSubmit} disabled={isLoading}
                                type="submit">Submit</Button>}&nbsp;&nbsp;&nbsp;
                            <button className="btn btn-dark" onClick={props.onClose} >Close</button>
                        </div>

                        {/* <li className={activeTab === 3 ? "next disabled" : "next"}>
                            <Link to="#" type="submit" className="btn btn-primary"
                                onClick={handleSubmit}
                            // onClick={() => {
                            //     toggleTab(activeTab + 1);
                            // }} 
                            >
                                Save <i className="bx bx-chevron-right ms-1"></i>
                            </Link>
                        </li> */}
                    </ul>

                </div>

                <div className="text-center mt-2">
                    {dataSuccess === 'added' && <SuccessAlert />}
                    {dataSuccess === 'failed' && <FailureAlert />}
                </div>
            </CardBody>
        </React.Fragment >
    );
};

export default EmployeeDetails;
