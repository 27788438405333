import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page } from "@syncfusion/ej2-react-grids";


const Deductions = () => {
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);

    const toggleDeductions = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const submitBankDetails = (event) => {
        event.preventDefault();

    }

    const timeout = () => {
        setTimeout(() => {
            // setDataSuccess('')
        }, 3000);
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <Row>
                        <Col xl="9" md="9" sm="9">
                            <CardTitle tag="h4">Deductions</CardTitle>
                        </Col>
                        <Col xl="3" md="3" sm="4">
                            <button onClick={toggleDeductions} className="btn btn-primary btn-sm">
                                <i className="bx bx-plus font-size-16 align-middle"></i>{" "}Add Details</button>
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody>
                    <GridComponent allowPaging={true} pageSettings={{ pageCount: 5 }}>
                        <ColumnsDirective>
                            <ColumnDirective headerText='Display Name' width='250'></ColumnDirective>
                            <ColumnDirective headerText='Deduction Code'  />
                            <ColumnDirective headerText='Type' />
                            <ColumnDirective headerText='Deduction On' ></ColumnDirective>                           
                            <ColumnDirective headerText='AMOUNT'  />
                            <ColumnDirective headerText='ACTION' />
                        </ColumnsDirective>
                        <Inject services={[Page]} />
                    </GridComponent>
                </CardBody>
            </Card>

            <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleDeductions}>
                <OffcanvasHeader toggle={toggleDeductions}>
                    <Row>
                        <Col sm='12'>
                            <b>Deductions</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />

                <OffcanvasBody>

                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default Deductions;