import React, { Fragment, useEffect, useState } from "react"
import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Resize, Filter, ExcelExport, PdfExport } from "@syncfusion/ej2-react-grids";
import { getEmployeeGrades, getEmployeeGroups, getCompanyPayscales, addupdatePayscaleDetail, getCompanyPayscaleDetails } from "../../../helpers/backend_helper";
import { showLoading } from 'react-global-loading';

const SettingsHRACCAScale = () => {
    let gridInstance;
    const [gradeList, setGradeData] = useState([]);
    const [submitted, setFormSubmit] = useState(false);
    const [groups, setGroups] = useState();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [payscales, setPayscales] = useState();
    const [payscaleDetails, setPayscaleDetails] = useState();
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: 0,
        payscaleId: 0,
        payscale: '',
        description: '',
        fromDate: '',
        toDate: ''
    });
    const [errors, setErrors] = useState({});

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const loadData = () => {

        showLoading(true);

        getCompanyPayscales().then(res => {
            console.log(res);
            if (res.data) {
                setPayscales(res.data)
            }
            showLoading(false);
        });
    }

    useEffect(() => {
        console.log("inside useEffect");

        showLoading(true);

        getEmployeeGroups().then(res => {
            console.log(res);
            if (res.data) {
                setGroups(res.data)
            }
        });

        getEmployeeGrades()
            .then(res => {
                console.log(res);
                if (res.data) {
                    setGradeData(res.data)
                }
            });

        loadData();

    }, [submitted]);

    const handleChange = event => {
        console.log(event.target.name);
        if (event.target.name === "status") {
            let statusSet = event.target.checked ? 1 : 0;
            setFormData({ ...formData, [event.target.name]: statusSet });
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }

    };

    const handleSubmit = event => {
        event.preventDefault();

        // Validate form inputs
        let formErrors = {};
       
        if (!formData.payscale) {
            formErrors.payscale = 'Payscale is required';
        }

        setErrors(formErrors);

        // If there are no errors, submit the form
        if (Object.keys(formErrors).length === 0) {
            // console.log('Form submitted!', formData);
            //Submit the form here
            addupdatePayscaleDetail(formData).then((res) => {
                console.log(res);
                setFormSubmit(!submitted);
                refreshPayscaleDetails(formData.payscaleId);
            }).catch((err) => {
                console.log(err);
            })

        }
    };

    const onRowSelected = (events) => {
        console.log("onRowSelected");
    };

    const refreshPayscaleDetails = (id) => {
        getCompanyPayscaleDetails(id).then(res => {
            console.log(res);
            if (res.data) {
                setPayscaleDetails(res.data)
            }
        });
    }

    const onRowDoubleClick = (events) => {
        console.log(events.rowData);
        setFormData({ ...formData, ["payscaleId"]: events.rowData.id });        
        toggle();

        refreshPayscaleDetails(events.rowData.id);
    };

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    return (

        <Fragment>
            <div className="m-2">
                <GridComponent dataSource={payscales} allowPaging={true} pageSettings={{ pageCount: 5, pageSizes: true }} toolbar={toolbarOptions}
                    toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid} allowSorting={true} allowResizing={true}
                    allowFiltering={true} showColumnMenu={true} statelessTemplates={['directiveTemplates']} filterSettings={{ type: 'Excel' }}
                    recordDoubleClick={onRowDoubleClick} allowExcelExport={true} allowPdfExport={true}>
                    <ColumnsDirective>                            
                        <ColumnDirective field="department.name" headerText='Department' width='150'></ColumnDirective>
                        <ColumnDirective field="employeeGroup.name" headerText='Group' width='100'></ColumnDirective>
                        <ColumnDirective field="employeeGrade.name" headerText='Designation' width='150'></ColumnDirective>
                        <ColumnDirective field="fromDate" type="datetime" format="dd/MM/yyyy" headerText='From Date' width='100' />
                        <ColumnDirective field="toDate" type="datetime" format="dd/MM/yyyy" headerText='To Date' width='100' />
                        <ColumnDirective field="payscaleRange" headerText='Pay Scale' width='250' />
                        <ColumnDirective field='updatedOn' type='datetime' format='dd/MM/yyyy' headerText='Last Updated' width='150' />
                    </ColumnsDirective>
                    <Inject services={[Page, Filter, Toolbar, Sort, ColumnMenu, Resize, ExcelExport, PdfExport]} />
                </GridComponent>

                <Modal isOpen={modal} toggle={toggle} fullscreen>
                    <ModalHeader toggle={toggle}>Payscale Details</ModalHeader>
                    <ModalBody>
                        <form onSubmit={handleSubmit} style={{display:'none'}}>
                            <Row>
                                <Col sm='3' className='mb-1'>
                                    <Label className='form-label'>
                                        Department :
                                    </Label>
                                    <Label className='form-label'>
                                        Social Welfare Department
                                    </Label>
                                </Col>
                                <Col sm='3'>
                                    <Label className='form-label'>
                                        Group :
                                    </Label>
                                    <Label className='form-label'>
                                        Group A
                                    </Label>
                                </Col>
                                <Col sm='3'>
                                    <Label className='form-label'>
                                        Designation :
                                    </Label>
                                    <Label className='form-label'>
                                        General Manager
                                    </Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm='12' className='mb-1'>
                                    <Label className='form-label' for='input-default'>
                                        Payscale:
                                    </Label>
                                    <Input
                                        type="text"
                                        name="payscale"
                                        placeholder="Payscale"
                                        onChange={handleChange}
                                        value={formData.payscale}
                                    />
                                    <p style={{ color: "Red" }}>{errors.payscale && <span>{errors.payscale}</span>}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm='3' className='mb-1'>
                                    <Label className='form-label' for='input-default'>
                                        From Date :
                                    </Label>
                                    <Input type="date" name="fromDate" onChange={handleChange} className="form-control">
                                    </Input>
                                    <p style={{ color: "Red" }}>{errors.fromDate && <span>{errors.fromDate}</span>}</p>
                                </Col>
                                <Col sm='3' className='mb-1'>
                                    <Label className='form-label' for='input-default'>
                                        To Date :
                                    </Label>
                                    <Input type="date" name="toDate" onChange={handleChange} className="form-control">
                                    </Input>
                                    <p style={{ color: "Red" }}>{errors.toDate && <span>{errors.toDate}</span>}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm='12' className='mb-1'>
                                    <Label className='form-label' for='input-default'>
                                        Description:
                                    </Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        placeholder="Description"
                                        onChange={handleChange}
                                        value={formData.description}
                                    />
                                    <p style={{ color: "Red" }}>{errors.description && <span>{errors.description}</span>}</p>
                                </Col>
                            </Row>
                            <Button color="primary" type="submit">Submit</Button>
                        </form>

                        <GridComponent dataSource={payscaleDetails} allowPaging={true} pageSettings={{ pageCount: 5 }}
                            allowFiltering={true} showColumnMenu={true} statelessTemplates={['directiveTemplates']} filterSettings={{ type: 'Excel' }}>
                            <ColumnsDirective>
                                <ColumnDirective field="allowanceType.name" headerText='Allowance' width='250'></ColumnDirective>
                                <ColumnDirective field="division.name" headerText='Division' width='100'></ColumnDirective>
                                <ColumnDirective field="percentage" headerText='Percentage' width='100'></ColumnDirective>
                                <ColumnDirective field="amount" headerText='Amount' width='100'></ColumnDirective>
                                <ColumnDirective field="description" headerText='Description' width='250' />
                            </ColumnsDirective>
                            <Inject services={[Page, Filter, Sort, ColumnMenu]} />
                        </GridComponent>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </Fragment>


    );
}

export default SettingsHRACCAScale;