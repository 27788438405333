import { Fragment, useEffect } from "react"

import { Alert, Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row, UncontrolledAlert } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize }
    from "@syncfusion/ej2-react-grids";

import { getDepartments, createDepartments, getLoggedInUser } from "../../../helpers/backend_helper";
import LoadingSpinner from "../../shared/Loading";
// import LoadingSpinner from "../../shared/Loading";

const SettingsDepartment = () => {
    let gridInstance;
    const [departmentList, setDepartmentListData] = useState();
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [isLoading, setIsLoading] = useState(false);
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: 0
    });
    const [errors, setErrors] = useState({});

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const loadData = () => {
        getDepartments()
            .then(res => {
                console.log(res);
                if (res.status === true) {
                    setDepartmentListData(res.data);
                }
            }).catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        console.log("inside useEffect");
        
        loadData();

    }, [submitted]);

    const handleChange = event => {
        if (event.target.name === "status") {
            let statusSet = event.target.checked ? 1 : 0;
            setFormData({ ...formData, [event.target.name]: statusSet });
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    };

    const SuccessAlert = () => {
        return (<UncontrolledAlert color="success" className="alert-label-icon label-arrow">
            <i className="mdi mdi-check-all label-icon"></i><strong>Success</strong> - Added Successfully!!!
        </UncontrolledAlert>)
    }

    const FailureAlert = () => {
        return (<UncontrolledAlert color="danger" className="alert-label-icon label-arrow">
            <i className="mdi mdi-check-all label-icon"></i><strong>Failed</strong> - Please Check !!!
        </UncontrolledAlert>)
    }

    const Loading = () => {
        return (
            <h3 className="text-primary text-center">
                <i className="bx bx-loader bx-spin"></i>{" "}
                Loading....
            </h3>
        )
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const handleSubmit = event => {
        event.preventDefault();


        // simulate an API call

        // Validate form inputs
        let formErrors = {};
        if (!formData.name) {
            formErrors.name = 'Department Type is required';
        }
        if (!formData.description) {
            formErrors.description = 'Description is required';
        }

        setErrors(formErrors);

        // If there are no errors, submit the form
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);
            // console.log('Form submitted!', formData);
            //Submit the form here
            createDepartments(formData).then((res) => {
                // console.log(res);
                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    setFormData({
                        ...formData,
                        name: '',
                        description: '',
                        status: false
                    });
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })

        }
        // setIsLoading(false);
    };

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];


    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    function statusTemplate(props) {

        return (<div>{props.status === 1 ?
            <div className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }

    return (

        <Fragment>
            <div className="m-2">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Department(s)" enableAction={getLoggedInUser().roleId != 1 ? false : true} actionName="Add Department" onClick={toggleCanvasEnd}></Breadcrumbs>
                    <Card className='overflow-hidden'>                        

                        {departmentList && <GridComponent dataSource={departmentList} allowPaging={true} ref={dataGrid => gridInstance = dataGrid}
                            toolbar={toolbarOptions} allowSorting={true} showColumnMenu={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}
                            statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true}
                            pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }}>
                            <ColumnsDirective>
                                <ColumnDirective field="name" headerText='Department Name' width='150'></ColumnDirective>
                                <ColumnDirective field="description" headerText='Description' width='150' />
                                <ColumnDirective field="status" template={statusTemplate} headerText='Status' width='100' />
                                <ColumnDirective field="updatedOn" type='date' format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='100'></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
                        </GridComponent>}


                    </Card>

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop={true} className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Department Type</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />

                            <OffcanvasBody>
                                {dataSuccess === 'added' && <SuccessAlert />}
                                {dataSuccess === 'failed' && <FailureAlert />}

                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Department Type:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                placeholder="Department Type"
                                                onChange={handleChange}
                                                value={formData.name}
                                            />
                                            <p style={{ color: "Red" }}>{errors.name && <span>{errors.name}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>Description:</Label>
                                            <Input type="textarea" name="description" placeholder="Description" onChange={handleChange} value={formData.description} />
                                            <p style={{ color: "Red" }}>{errors.description && <span>{errors.description}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>Status:</Label>
                                            <Input type="checkbox" name="status" onChange={handleChange} value={formData.status} />                                            
                                        </Col>
                                    </Row>
                                    {isLoading ? <LoadingSpinner /> : <Button color="primary" disabled={isLoading} type="submit">Submit</Button>}
                                </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>


    );
}

export default SettingsDepartment;