import React from 'react';

import ReactApexChart from "react-apexcharts"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import CountUp from "react-countup";

// import Locations from './Locations';
import RecentLeave from './RecentLeave';
import RecentExpense from './RecentExpense';
import EmployeeChart from './EmployeeChart';
import PieChart from './PieChart';
import EmployeeCount from './EmployeeCount';
import AttendanceCount from './AttendanceCount';
import GenderCount from './GenderCount';
import AttendancePerformance from './AttendancePerformance';
import AttendanceChart from './AttendanceChart';

const options = {
    chart: {
        height: 50,
        type: "line",
        toolbar: { show: false },
    },
    colors: ["#5156be"],
    stroke: {
        curve: 'smooth',
        width: 2,
    },
    xaxis: {
        labels: {
            show: false
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            show: false
        }
    },
    yaxis: {
        labels: {
            show: false
        }
    },
    tooltip: {
        fixed: {
            enabled: false
        },
        x: {
            show: false
        },
        y: {
            title: {
                formatter: function (seriesName) {
                    return ''
                }
            }
        },
        marker: {
            show: false
        }
    }
};

const EmployeeDashboard = () => {

    //meta title
    document.title = "Dashboard";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="My Dashboard" />

                    <Row>
                            <Col xl={3} md={6}>
                                <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={6}>
                                                <span className="text-muted mb-3 lh-1 d-block text-truncate">Attendance Count</span>
                                                <h4 className="mb-3">
                                                    <span className="counter-value">
                                                        <CountUp
                                                            start={0}
                                                            end={43}
                                                            duration={0}
                                                        />
                                                    </span>
                                                </h4>
                                            </Col>
                                            <Col xs={6}>
                                                <AttendanceCount />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl={3} md={6}>
                                <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={5}>
                                                <span className="text-muted mb-3 lh-1 d-block text-truncate">Leaves</span>
                                                <h4 className="mb-3">                                                    
                                                    <span className="counter-value">
                                                        <CountUp start={0} end={63} duration={0} />
                                                    </span>
                                                </h4>
                                            </Col>
                                            <Col xs={7}>
                                                <EmployeeCount />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            

                            <Col xl={3} md={6}>
                                <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={6}>
                                                <span className="text-muted mb-3 lh-1 d-block text-truncate">Gender Ratio</span>
                                                <h4 className="mb-3">
                                                    <span className="counter-value">
                                                        
                                                    </span>
                                                </h4>
                                            </Col>
                                            <Col xs={6}>
                                                <GenderCount />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl={3} md={6}>
                                <Card className="card-h-100">
                                    <CardBody>
                                        <Row className="align-items-center">
                                            <Col xs={6}>
                                                <span className="text-muted mb-3 lh-1 d-block text-truncate">Attendance Performance</span>
                                                <h4 className="mb-3">
                                                    <span className="counter-value">
                                                        <CountUp
                                                            start={0}
                                                            end={36}
                                                            duration={0}
                                                        />
                                                    </span>
                                                </h4>
                                            </Col>
                                            <Col xs={6}>
                                                <AttendancePerformance />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EmployeeChart />
                        </Col>
                        <Col>
                            <AttendanceChart />
                        </Col>
                    </Row>
                    <Row>
                        <RecentLeave />
                        <RecentExpense />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default EmployeeDashboard;