import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";
import { showLoading } from 'react-global-loading';

import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Toolbar } from "@syncfusion/ej2-navigations";
import Users from "./Users";
import Roles from "./Roles";

const SettingsSecurity = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Settings" breadcrumbItem="Security Settings" enableAction={false}></Breadcrumbs>
                <Container fluid>
                    <CardBody>
                        <div id="basic-pills-wizard" className="twitter-bs-wizard">

                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>Users</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>Roles</span>
                                    </NavLink>
                                </NavItem>

                                {/*<NavItem>*/}
                                {/*    <NavLink*/}
                                {/*        href="#"*/}
                                {/*        className={classnames({ active: activeTab === 3 })}*/}
                                {/*        onClick={() => {*/}
                                {/*            setactiveTab(3);*/}
                                {/*        }}>*/}
                                {/*        <span>Holidays</span>*/}
                                {/*    </NavLink>*/}
                                {/*</NavItem>*/}

                                {/*<NavItem>*/}
                                {/*    <NavLink*/}
                                {/*        href="#"*/}
                                {/*        className={classnames({ active: activeTab === 4 })}*/}
                                {/*        onClick={() => {*/}
                                {/*            setactiveTab(4);*/}
                                {/*        }}>*/}
                                {/*        <span>Leave Type</span>*/}
                                {/*    </NavLink>*/}
                                {/*</NavItem>*/}
                            </Nav>

                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Users />
                                </TabPane>
                                <TabPane tabId={2}>
                                    <Roles />
                                </TabPane>
                                <TabPane tabId={3}>
                                    asdasasd
                                </TabPane>
                                <TabPane tabId={4}>
                                    asdasasd
                                </TabPane>

                            </TabContent>

                        </div>
                    </CardBody>
                </Container >
            </div >
        </React.Fragment >
    );
};

export default SettingsSecurity;