import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar } from "@syncfusion/ej2-react-grids";
import LoadingSpinner from "../../shared/Loading";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { addEmployeeBank, getEmployeeBanks } from "../../../helpers/backend_helper";
import { useParams } from "react-router-dom";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Spinner from "../../shared/spinner";

const BankDetails = (props) => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [bankData, setBankDetails] = useState({
        employeeId: 0, bankName: "", branchName: "", accountNumber: "", accountName: "", bankIFSC: ""
    });

    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [bankList, setBankList] = useState();

    const params = useParams();

    const addBank = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
        setErrors({});
    }

    useEffect(() => {
        setBankDetails({ ...bankData, 'employeeId': props.employeeId });

        getEmployeeBanks(props.employeeId).then(res => {
            console.log(res.data);
            if (res.data) {
                setBankList(res.data)
            }
        });
    }, [submitted]);

    const handleChange = event => {
        setBankDetails({ ...bankData, [event.target.name]: event.target.value });
    };

    const submitBankDetails = (event) => {
        event.preventDefault();
        console.log("Clicked");
        let formErrors = {};
        if (!bankData.bankName) {
            formErrors.bankName = 'Bank Name is required';
        }
        if (!bankData.branchName) {
            formErrors.branchName = 'Branch Name is required';
        }
        if (!bankData.accountNumber) {
            formErrors.accountNumber = 'Account Number is required';
        }
        if (!bankData.accountName) {
            formErrors.accountName = 'Account Name is required';
        }
        if (!bankData.bankIFSC) {
            formErrors.bankIFSC = 'Bank IFSC is required';
        }

        setErrors(formErrors);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            console.log(bankData);
            addEmployeeBank(bankData).then(res => {
                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    setBankDetails({
                        ...bankData,
                        bankData
                    });
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const toolbarTemplate = () => {
        return (
            <Row className="m-2">
                <Col xl="9" className="mt-2" md="9" sm="9">
                    <CardTitle tag="h4">Bank Information</CardTitle>
                </Col>
                <Col xl="3" md="3" sm="4">
                    <ButtonComponent onClick={addBank} className='e-btn e-outline e-primary'>
                        <i className="bx bx-plus font-size-16 align-middle"></i>{" "}Add Details</ButtonComponent>
                </Col>
            </Row>
        )
    }

    const toolbarOptions = [
        { template: toolbarTemplate }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.text) {
            case 'PDF Export':
                gridInstance.pdfExport();
                break;
            case 'Excel Export':
                gridInstance.excelExport();
                break;
            case 'CSV Export':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <>
            <Row className="m-2">
                <Col xl="9" className="mt-2" md="9" sm="9">
                    <CardTitle tag="h4">Bank Details</CardTitle>
                </Col>
                <Col xl="3" md="3" sm="4">                                        
                    <button type="button" onClick={addBank} className="btn btn-success waves-effect waves-light">
                        <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                        Add
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button type="button" onClick={addBank} className="btn btn-warning waves-effect waves-light">
                        <i className="bx bx-revision font-size-16 align-middle me-2"></i>{" "}
                        Refresh
                    </button>
                </Col>
            </Row>

            <CardBody>
                {!bankList ?
                    <div className='text-center mt-4'>
                        <Spinner />
                    </div>
                    :
                    <GridComponent dataSource={bankList} allowPaging={true} 
                        ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5 }} statelessTemplates={['directiveTemplates']}
                        allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} >
                        <ColumnsDirective>
                            <ColumnDirective field="bankName" headerText='Bank Name' width='150'></ColumnDirective>
                            <ColumnDirective field="bankIFSC" headerText='Bank IFSC' width='150' />
                            <ColumnDirective field="branchName" headerText='Branch Name' width='150' />
                            <ColumnDirective field="accountName" headerText='Name on Account' width='150'></ColumnDirective>
                            <ColumnDirective field="accountNumber" headerText='Account Number' width='150' />
                            <ColumnDirective field="createdOn" type="datetime" format='dd/MM/yyyy' headerText='Created On' width='150' />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page]} />
                    </GridComponent>}
            </CardBody>

            <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={addBank}>
                <OffcanvasHeader toggle={addBank}>
                    <Row>
                        <Col sm='12'>
                            <b>Bank Details</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />

                <OffcanvasBody>
                    <FormGroup onSubmit={submitBankDetails}>
                        <Row className="mb-2">
                            <Col sm="12" md="12">
                                <Label for="bankname">Bank Name</Label>
                                <Input type="text" value={bankData.bankName} onChange={handleChange}
                                    name='bankName' placeholder="Enter Bank Name"></Input>
                                <p style={{ color: "Red" }}>{errors.bankName && <span>{errors.bankName}</span>}</p>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm="12" md="12">
                                <Label for="bankname">Bank IFSC</Label>
                                <Input type="text" value={bankData.bankIFSC} onChange={handleChange}
                                    name="bankIFSC" placeholder="Enter Bank IFSC"></Input>
                                <p style={{ color: "Red" }}>{errors.bankIFSC && <span>{errors.bankIFSC}</span>}</p>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm="12" md="12">
                                <Label for="branchName">Branch Name</Label>
                                <Input type="text" value={bankData.branchName} onChange={handleChange}
                                    name="branchName" placeholder="Enter Branch Name"></Input>
                                <p style={{ color: "Red" }}>{errors.branchName && <span>{errors.branchName}</span>}</p>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm="12" md="12">
                                <Label for="bankname">Name on Account</Label>
                                <Input type="text" value={bankData.accountName} onChange={handleChange}
                                    name="accountName" placeholder="Enter Account Name"></Input>
                                <p style={{ color: "Red" }}>{errors.accountName && <span>{errors.accountName}</span>}</p>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col sm="12" md="12">
                                <Label for="accountNumber">Account Number</Label>
                                <Input type="text" value={bankData.accountNumber} onChange={handleChange}
                                    name="accountNumber" placeholder="Enter Account Number"></Input>
                                <p style={{ color: "Red" }}>{errors.accountNumber && <span>{errors.accountNumber}</span>}</p>
                            </Col>
                        </Row>

                        <div className="text-center mt-2">
                            {dataSuccess === 'added' && <SuccessAlert />}
                            {dataSuccess === 'failed' && <FailureAlert />}
                        </div>

                        <div className="offcanvas-footer">
                            {isLoading ? <LoadingSpinner /> : <Button color="primary"
                                onClick={submitBankDetails} className="btn btn-sm" disabled={isLoading}
                                type="submit">Submit</Button>}&nbsp;
                            <button onClick={addBank} className="btn btn-dark btn-sm">Close</button>
                        </div>
                    </FormGroup>

                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default BankDetails;