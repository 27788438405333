import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import {
    ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, FilterType, Inject, Sort, Edit,
    ColumnMenu, CommandColumn, Toolbar,
    ExcelExport, PdfExport, Group, Resize
} from "@syncfusion/ej2-react-grids";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanyPayperiods, getUserLocations, getDepartments, getEmployeeList, getAllContractors
} from "../../helpers/backend_helper";
import { getData, postData } from "../../helpers/url-request";
import Select from "react-select"
import { showLoading } from 'react-global-loading';

const SalarySummaryReport = () => {
    let gridInstance;
    const [cssname, setCssname] = useState("page-content");
    const [employees, setEmployees] = useState();
    const [departments, setDepartments] = useState();
    const [locations, setLocations] = useState();    
    const [payPeriods, setPayPeriods] = useState();
    const [formData, setFormData] = useState({
        "payPeriodId": 0,
        "locationId": 0, "departmentId": 0,
        "contractorId": 0, "employeeId": 0
    });
    const [reportData, setReportData] = useState([]);
    

    useEffect(() => {

        getCompanyPayperiods().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "payPeriodTitle": "Select Period" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setPayPeriods(emps)
            }
        });

        getEmployeeList().then(res => {
            if (res.data) {
                let emps = [{ label: "ALL", value: 0 }];
                res.data.forEach(el => {
                    emps.push({ label: el.fullName, value: el.id });
                });
                setEmployees(emps)
            }
        });

        getDepartments().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setDepartments(emps)
            }
        });

        getUserLocations().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "locationName": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setLocations(emps)
            }
        });


    }, []);


    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];


    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const handleChange = event => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    };

    const confirmHandler = (event) => {
        event.preventDefault();
        setReportData([]);
        console.log(gridInstance.columns);

        if (!formData.payPeriodId) {
            alert("Please select the pay period");
            return;
        }

        for (var i = gridInstance.columns.length; i >= 0; i--) {
            gridInstance.columns.pop();            
        }
        gridInstance.refreshColumns();
        var _this = gridInstance;        
        showLoading(true);
        postData("/payroll/salarydetailreport", formData).then(res => {
            console.log(res.data);

            res.data.columns.map((columnName) => {
                var newColumn = { field: columnName.key, headerText: columnName.value, width: 120 };
                _this.columns.push(newColumn);
            });
            _this.refreshColumns();

            console.log(res.data.records);
            setReportData(res.data.records);
            _this.dataSource = res.data.records;
            _this.refresh();

            showLoading(false);
        });
    }

    const resetReport = () => {
        setReportData([]);
        setFormData({
            "payPeriodId": 0,
            "locationId": 0, "departmentId": 0,
            "contractorId": 0, "employeeId": 0
        });
    }

    return (
        <div className={cssname}>
            <Breadcrumbs title="HR Management" breadcrumbItem="Salary Summary Report" enableAction={false}></Breadcrumbs>
            <CardBody>
                <Row>                    
                    <Col sm='3' md='3' lg='3'>                        
                        <select className="form-select" name="payPeriodId" onChange={handleChange}>
                            {payPeriods &&
                                payPeriods.map((payperiod) => {
                                    return <option value={payperiod.id} key={payperiod.id}>{payperiod.payPeriodTitle}</option>
                                })}
                        </select>
                    </Col>
                    <Col sm='3' md='3' lg='3'>                        
                        <Button color="primary" className="btn primary" type="submit" onClick={confirmHandler}>View Summary</Button>&nbsp;&nbsp;&nbsp;
                        <Button color="primary" className="btn primary" type="reset" onClick={resetReport}>Clear</Button>
                    </Col>
                </Row>
            </CardBody>
        <hr />

            <Row>
                <Col sm='12' md='12' lg='12'>
                    <CardBody>                        
                        <GridComponent allowPaging={true} dataSource={reportData}
                            toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5, pageSizes: true }}
                            statelessTemplates={['directiveTemplates']} allowResizing={true} filterSettings={{ type: 'Excel' }} allowFiltering={true}
                            allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective field="employeeProfile.fullName" headerText='Employee' width='150'></ColumnDirective>
                                <ColumnDirective field="payrollDetail.payrollMonth" headerText='Pay Month' width='150'></ColumnDirective>                                    
                                <ColumnDirective field="basic" headerText='Basic (₹)' width='120' />
                                <ColumnDirective field="gross" headerText='Gross (₹)' width='120' />
                                <ColumnDirective field="deductions" headerText='Deductions' width='120' />
                                <ColumnDirective field="netAmount" headerText='Net Amount' width='120' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
                        </GridComponent>                        
                    </CardBody>
                </Col>

            </Row>

        </div>
    )
}

export default SalarySummaryReport;