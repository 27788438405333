import { UncontrolledAlert } from "reactstrap";
import React from "react";

const SuccessAlert = (props) => {
    return (<UncontrolledAlert color="success" className="alert-label-icon label-arrow">
        <i className="mdi mdi-check-all label-icon"></i><strong>Success</strong> - {props.message ? props.message : "Added"} Successfully!!!
    </UncontrolledAlert>)
}

const FailureAlert = (props) => {
    return (<UncontrolledAlert color="danger" className="alert-label-icon label-arrow">
        <i className="bx bx-block label-icon"></i><strong>Failed</strong> - {props.message ? props.message : "Please Check!!!"}
    </UncontrolledAlert>)
}

export { SuccessAlert, FailureAlert }