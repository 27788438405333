import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Filter, Sort, ColumnMenu, Resize } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getEmployeeSuspension, getIncrementTypes, getCompanyLocations } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import Spinner from "../../shared/spinner";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as url from '../../../helpers/url_helper';
import { showLoading } from 'react-global-loading';
import classnames from "classnames";

const PayslipDetail = (props) => {
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')
    const [canvasPlacement, setCanvasPlacement] = useState("start");
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [salaryDetail, setSalaryDetail] = useState();
    const [salaryEarningDetails, setSalaryEarningDetails] = useState();
    const [salaryDeductionDetails, setSalaryDeductionDetails] = useState();
    const [transferTypeList, setTransferTypeList] = useState();
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const params = useParams();

    useEffect(() => {
        console.log(props.data);

        setSalaryDetail(props.data);

        var earningDetails = [];
        props.data.salaryEarnings.map((earning) => {
            earning.salaryEarningDetails.map((detail) => {
                earningDetails.push(detail);
            })
        });
        setSalaryEarningDetails(earningDetails);

        var deductionDetails = [];
        props.data.salaryDeductions.map((deduction) => {
            deduction.salaryDeductionDetails.map((detail) => {
                deductionDetails.push(detail);
            })
        });
        setSalaryDeductionDetails(deductionDetails);

    }, [submitted]);

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        href="#"
                        className={classnames({ active: activeTab === 1 })}
                        onClick={() => {
                            setactiveTab(1);
                        }}>
                        <span>Payslip</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        href="#"
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                            setactiveTab(2);
                        }}>
                        <span>Details</span>
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent
                className="twitter-bs-wizard-tab-content"
                activeTab={activeTab}>
                <TabPane tabId={1}>
                    <Row className="m-2">
                        <Col sm='6' md='6' lg='6'>
                            <Row className="mb-2">
                                <Col sm='6' md='6' lg='6'>
                                    Sri/Smt : {salaryDetail?.employeeProfile.fullName}
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    Gender : {salaryDetail?.employeeProfile.genderType.name}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='6' md='6' lg='6'>
                                    Date of Birth : {new Date(salaryDetail?.employeeProfile.dateOfBirth).toLocaleDateString('en-GB')}
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    Designation : {salaryDetail?.employeeProfile.workTitle}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='6' md='6' lg='6'>
                                    Group : {salaryDetail?.employeeProfile.employeeGrade.name}
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='12' md='12' lg='12'>
                                    Pay Scale : {salaryDetail?.employeeProfile.employeeGrade.payscale}
                                </Col>
                            </Row>
                        </Col>
                        {/* </Row>

                                <Row> */}

                        <Col sm='6' md='6' lg='6'>
                            <Row className="mb-2">
                                <Col sm='6' md='6' lg='6'>
                                    Days Worked : {salaryDetail?.noOfDays}
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    PAN Number : {salaryDetail?.employeeProfile.pan}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='6' md='6' lg='6'>
                                    Joining Date : {new Date(salaryDetail?.employeeProfile.joiningDate).toLocaleDateString('en-GB')}
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    Next Increment Date : {new Date(salaryDetail?.employeeProfile.nextIncrementDate).toLocaleDateString('en-GB')}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm='6' md='6' lg='6'>
                                    Month : {salaryDetail?.payrollDetail.payrollMonth}
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    From : {new Date(salaryDetail?.payrollDetail.fromDate).toLocaleDateString('en-GB')} - {new Date(salaryDetail?.payrollDetail.toDate).toLocaleDateString('en-GB')}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-2">
                        <Col sm='6' md='6' lg='6'>
                            Basic : {salaryDetail?.basic}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm='6' md='6' lg='6'>
                            <h4>Allowances</h4>
                            <hr />
                            {salaryDetail?.salaryEarnings && salaryDetail?.salaryEarnings.map((earning) => {
                                return <Row className="mb-2" key={earning.id}>
                                        <Col sm='6' md='6' lg='6'>
                                            {earning.allowance} : 
                                        </Col>
                                        <Col sm='6' md='6' lg='6'>
                                            {earning.amount}
                                        </Col>
                                    </Row>
                                })
                            }
                        </Col>
                        <Col sm='6' md='6' lg='6'>
                            <h4>Deductions</h4>
                            <hr />
                            {salaryDetail?.salaryDeductions && salaryDetail?.salaryDeductions.map((deduction) => {
                                return <Row className="mb-2" key={deduction.id}>
                                        <Col sm='6' md='6' lg='6'>
                                            {deduction.deduction} :
                                        </Col>
                                        <Col sm='6' md='6' lg='6'>
                                            {deduction.amount}
                                        </Col>
                                    </Row>
                                })
                            }
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm='6' md='6' lg='6'>
                            <hr />
                            Gross Salary(Excl. Internal Rec:) : {salaryDetail?.gross}
                        </Col>
                        <Col sm='6' md='6' lg='6'>
                            <hr />
                            <Row className="mb-2">
                                <Col>
                                    Sum of Deductions & Recoveries :
                                </Col>
                                <Col>
                                    {salaryDetail?.deductions}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    Net Salary Payable :
                                </Col>
                                <Col>
                                    {salaryDetail?.netAmount}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm='6' md='6' lg='6'>
                            <hr />
                            <Row className="mb-2">
                                <Col sm='6' md='6' lg='6'>
                                    Bank Name : {salaryDetail?.bankDetails?.bankName}
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    Bank A/C Number : {salaryDetail?.bankDetails?.accountNumber}
                                </Col>
                            </Row>
                            <Row className="mb-2">                                
                                <Col sm='6' md='6' lg='6'>
                                    Branch Name : {salaryDetail?.bankDetails?.branchName}
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    IFSC Code : {salaryDetail?.bankDetails?.bankIFSC}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm='6' md='6' lg='6'>
                            <hr />
                            Net Salary Payable (In Words) : {salaryDetail?.salaryPayableWords}
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={2}>
                    <Row className="m-2">
                        <Col sm='6' md='6' lg='6'>
                            <CardBody>
                                <GridComponent dataSource={salaryEarningDetails} statelessTemplates={['directiveTemplates']}                                 
                                    allowFiltering={true} filterSettings={{ type: 'Excel' }} allowResizing={true}
                                    allowPaging={true} pageSettings={{ pageCount: 25 }} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
                                    <ColumnsDirective>
                                        <ColumnDirective field="allowance" headerText='Allowance' width='100'></ColumnDirective>
                                        <ColumnDirective field="allowanceDesc" headerText='Details' width='150'></ColumnDirective>
                                        <ColumnDirective field="amount" headerText='Amount' width='100' />
                                    </ColumnsDirective>                                
                                    <Inject services={[Page, Filter, Sort, ColumnMenu, Resize]} />
                                </GridComponent>
                            </CardBody>
                        </Col>
                        <Col sm='6' md='6' lg='6'>
                            <CardBody>
                                <GridComponent dataSource={salaryDeductionDetails} statelessTemplates={['directiveTemplates']}
                                    allowFiltering={true} filterSettings={{ type: 'Excel' }} allowResizing={true}
                                    allowPaging={true} pageSettings={{ pageCount: 25 }} showColumnMenu={true} allowPdfExport={true} allowSorting={true}>
                                    <ColumnsDirective>
                                        <ColumnDirective field="deduction" headerText='Deduction' width='100'></ColumnDirective>
                                        <ColumnDirective field="deductionDesc" headerText='Details' width='150'></ColumnDirective>
                                        <ColumnDirective field="amount" headerText='Amount' width='100' />
                                    </ColumnsDirective>
                                    <Inject services={[Page, Filter, Sort, ColumnMenu, Resize]} />
                                </GridComponent>
                            </CardBody>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </>
    );
};

export default PayslipDetail;
