import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import LoadingSpinner from "../../shared/Loading";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Filter, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { createTimeEntry, getEmployeeList, getManulAttendanceList } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { showLoading } from 'react-global-loading';

const ManualTimeEntry = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState();
    const [hours, setHrs] = useState();
    const [errors, setErrors] = useState({});
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [mins, setMin] = useState();
    const [timeEntryList, setTimeEntryList] = useState();
    const [timeEntry, setTimeEntry] = useState({
        "employeeId": 0,
        "attendanceDate": "",
        "timeIn": "",
        "timeOut": "",
        "breakTime": 0,
        "remarks": "",
        "isApproved": 0
    });

    const toggle = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const handleChange = event => {
        if (event.target.name == "timeIn" || event.target.name == "timeOut") {
            console.log(event.target.value);
            console.log(new Date(event.target.value));
            setTimeEntry({ ...timeEntry, [event.target.name]: new Date(event.target.value) });
        }
        else
            setTimeEntry({ ...timeEntry, [event.target.name]: event.target.value });
    };

    const loadData = () => {
        showLoading(true);
        getManulAttendanceList().then((res) => {
            showLoading(false);
            setTimeEntryList(res.data);
        })
    }

    useEffect(() => {
        getEmployeeList().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "fullName": "Select Employee" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setEmployees(emps)
            }
        });

        loadData();

    }, []);

    const submitTimeEntry = (e) => {
        e.preventDefault();
       
        let formErrors = {};
        if (timeEntry.employeeId == "0") {
            formErrors.employeeId = 'Employee is required';
        }
        if (!timeEntry.attendanceDate) {
            formErrors.attendanceDate = 'Date is required';
        }
        if (!timeEntry.timeIn) {
            formErrors.timeIn = 'Time In is required';
        }
        if (!timeEntry.timeOut) {
            formErrors.timeOut = 'Time out is required';
        }

        setErrors(formErrors);

        if (Object.keys(formErrors).length != 0) {
            return;
        }

        timeEntry.breakTime = 0;

        createTimeEntry(timeEntry).then((res) => {
            // console.log(res);
            if (res.status === true) {
                console.log("Inside success");                
                setDataSuccess("added");
                setIsLoading(false);
                toggle();
                loadData();
            } else {
                console.log("Inside failure");
                setDataSuccess("failed");
                setIsLoading(false);
            }
            setFormSubmit(!submitted);
            timeout();
        }).catch((err) => {
            console.log(err);
            setDataSuccess("failed");
            setIsLoading(false);
            timeout();
        })
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' },
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    return (
        <div className="page-content">

            <Breadcrumbs title="Attendance" breadcrumbItem="Time Entry" enableAction={true} actionName="New Entry" onClick={toggle}></Breadcrumbs>

            <GridComponent dataSource={timeEntryList} ref={grid => gridInstance = grid} allowPaging={true} allowFiltering={true} showColumnMenu={true}
                allowResizing={true} toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} statelessTemplates={['directiveTemplates']} allowExcelExport={true}
                allowPdfExport={true} pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }}>
                <ColumnsDirective>
                    <ColumnDirective field="employee.fullName" headerText='Employee' width='180' />
                    <ColumnDirective field="attendanceDate" type="date" format="dd-MM-yyyy" headerText='Date' width='110'></ColumnDirective>
                    <ColumnDirective field="attendanceDay" headerText='Day' width='110' />
                    <ColumnDirective field="timeIn" type="date" format="h:mm a" headerText='Time In' width='110' />
                    <ColumnDirective field="timeOut" type="date" format="h:mm a" headerText='Time Out' width='110' />
                    <ColumnDirective field="remarks" headerText='Reason' width='180' />
                    <ColumnDirective field="updatedOn" type="date" format="dd-MM-yyyy h:mm a" headerText="Last Updated" width="150" />
                </ColumnsDirective>
                <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
            </GridComponent>

            <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggle}>
                <OffcanvasHeader toggle={toggle}>
                    <Row>
                        <Col sm='12'>
                            <b>Create Manual Entry</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />

                <OffcanvasBody>
                    <FormGroup>
                        <div>
                            <Row>
                                <Col sm='6' md='6' lg='4'>
                                    Select Employee
                                </Col>
                                <Col sm='6' md='6' lg='8'>
                                    <select className="form-select" name="employeeId" onChange={handleChange} value={timeEntry.employeeId}  >
                                        {employees &&
                                            employees.map((emp) => {
                                                return <option value={emp.id} key={emp.id}>{emp.fullName}</option>
                                            })}
                                    </select>
                                    <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                                </Col>
                            </Row>

                            <Row>

                                <Col sm='6' md='6' lg='4'>
                                    Attendance Date
                                </Col>
                                <Col sm='6' md='6' lg='8'>
                                    <Input type="date" name="attendanceDate" onChange={handleChange} value={timeEntry.attendanceDate} className="form-control"></Input>
                                    <p style={{ color: "Red" }}>{errors.attendanceDate && <span>{errors.attendanceDate}</span>}</p>
                                </Col>
                            </Row>

                            <Row>

                                <Col sm='6' md='6' lg='6'>
                                    Time IN
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    <TimePickerComponent id="timeIn" name="timeIn" onChange={handleChange} format={{ skeleton: 'hh:mm:ss' }}></TimePickerComponent>
                                    <p style={{ color: "Red" }}>{errors.timeIn && <span>{errors.timeIn}</span>}</p>
                                </Col>
                            </Row>

                            <Row>

                                <Col sm='6' md='6' lg='6'>
                                    Time OUT
                                </Col>
                                <Col sm='6' md='6' lg='6'>
                                    <TimePickerComponent id="timeOut" name="timeOut" onChange={handleChange}></TimePickerComponent>
                                    <p style={{ color: "Red" }}>{errors.timeOut && <span>{errors.timeOut}</span>}</p>
                                </Col>
                            </Row>

                            {/*<Row>*/}

                            {/*    <Col sm='6' md='6' lg='6'>*/}
                            {/*        Break Time*/}
                            {/*    </Col>*/}
                            {/*    <Col sm='6' md='6' lg='6'>*/}
                            {/*        <div className="input-group mt-2">*/}
                            {/*            <Input type="text" name="hours" className="form-control"*/}
                            {/*                placeholder="Hours" onChange={handleChange} value={hours} />*/}
                            {/*            <div className="input-group-text">:</div>*/}
                            {/*            <Input type="text" name="mins" className="form-control" onChange={handleChange} value={mins}*/}
                            {/*                placeholder="Minutes" />*/}
                            {/*        </div>*/}
                            {/*    </Col>*/}
                            {/*    <p style={{ color: "Red" }}>{errors.breakTime && <span>{errors.breakTime}</span>}</p>*/}
                            {/*</Row>*/}

                            <Row>
                                <Col sm='4' md='4' lg='12'>
                                    Reason for Manual Entry
                                </Col>
                            </Row>
                            <Row>                            
                                <Col sm='8' md='8' lg='12'>
                                    <Input type="textarea" name="remarks" rows="5" onChange={handleChange} value={timeEntry.remarks} className="form-control"></Input>
                                </Col>
                            </Row>

                        </div>

                        <div className="text-center mt-2">
                            {dataSuccess === 'added' && <SuccessAlert />}
                            {dataSuccess === 'failed' && <FailureAlert />}
                        </div>

                        <div className="offcanvas-footer">
                            {isLoading ? <LoadingSpinner /> : <Button color="primary"
                                className="btn" onClick={submitTimeEntry} disabled={isLoading}
                                type="submit">Submit</Button>}&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={toggle} className="btn btn-dark">Close</button>
                        </div>
                    </FormGroup>

                </OffcanvasBody>
            </Offcanvas>
        </div>
    )
}

export default ManualTimeEntry;