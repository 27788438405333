// ** Reactstrap Imports
import { Row, Col, CardHeader, CardTitle, CardBody, Label, Form, Input, Card, Container, CardFooter, Button, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import classnames from "classnames";
// import { Radio } from 'react-feather'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from 'react-router-dom';
import { getEmployeeCompensations, getEmployeeCompensationHistory, getLoggedInUser } from '../../../helpers/backend_helper';
import { FailureAlert, SuccessAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/Loading';
import { ItemDirective, ItemsDirective, ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { showLoading } from 'react-global-loading';

import {
    ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, FilterType, Inject, Sort, Edit,
    ColumnMenu, CommandColumn, Toolbar,
    ExcelExport, PdfExport, Group, Resize
} from "@syncfusion/ej2-react-grids";

import Compensation from './Compensation';

const CompensationList = (props) => {
    let gridInstance;
    const [employeeId, setEmployeeId] = useState();
    const [empData, setEmployeeData] = useState();
    const [compensationList, setCompensationList] = useState([]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const loadData = async () => {
        showLoading(true);
        const response = await getEmployeeCompensations();
        const resData = await response;        
        if (resData.data) {
            setCompensationList(resData.data);
            showLoading(false);
        }
    }

    useEffect(() => {

        loadData();

    }, []);    

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' },
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' }
    ];

    const toolbarClick = (args) => {
        console.log(args.item);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const viewEmployee = (props) => {
        if (getLoggedInUser().roleId == 1) {
            setEmployeeId(props.employeeId);
            setEmployeeData(props.employeeProfile)
            toggle();
        }
    }

    const headerLink = props => {
        return (
            <button onClick={() => viewEmployee(props)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.employeeProfile.fullName}
            </button>
        )
    }

    const onRowDoubleClick = (events) => {
        viewEmployee(events.rowData);
    };

    return (
        <>
            <div className="page-content">

                <Modal isOpen={modal} toggle={toggle} fullscreen>
                    <ModalHeader toggle={toggle}>Employee Details</ModalHeader>
                    <ModalBody>
                        <Compensation employeeId={employeeId} employeeData={empData} />
                    </ModalBody>
                    <ModalFooter>
                        <div>                            
                            <Button color="secondary" className="btn primary" type="button" onClick={toggle}>Close</Button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Container fluid>

                    <Breadcrumbs title="Employees" breadcrumbItem="Employees Compensation" enableAction={false} />

                    <div className='mt-2'>
                        {compensationList &&
                            <GridComponent dataSource={compensationList} height="100%" toolbar={toolbarOptions} ref={grid => gridInstance = grid}
                            allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}
                                allowFiltering={true} allowPaging={true} showColumnMenu={true} statelessTemplates={['directiveTemplates']}
                                recordDoubleClick={onRowDoubleClick} pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }}>
                                <ColumnsDirective>
                                    <ColumnDirective field='employeeProfile.fullName' headerText='Fullname' template={headerLink} width='150'></ColumnDirective>
                                    <ColumnDirective field='employeeProfile.workTitle' headerText='Title' width='150' />
                                    <ColumnDirective field='employeeProfile.workLocation.locationName' headerText='Location' width='150' />
                                    <ColumnDirective field='basicPay' headerText='Basic Pay' width='100' />
                                    <ColumnDirective field='da' headerText='DA' width='80' />
                                    <ColumnDirective field='hra' headerText='HRA' width='80' />
                                    <ColumnDirective field='cca' headerText='CCA' width='80' />
                                    <ColumnDirective field='employeeProfile.employeeStatus.name' headerText='Status' width='80' />
                                </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
                            </GridComponent>}
                    </div>
                </Container>
            </div>
        </>

    )
}

export default CompensationList
