// ** Reactstrap Imports
import { Row, Col, CardHeader, CardTitle, CardBody, Label, Form, Input, Card, Container, CardFooter, Button, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap'
import classnames from "classnames";
// import { Radio } from 'react-feather'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from 'react-router-dom';
import { addEmpAllowance, addEmpDeduction, addEmployeeCompensation, getEditableAllowancesTypes, getEditableDeductionTypes, getEmpAllowances, getEmpDeductions, getEmployeeCompensation, getEmployeeCompensationHistory } from '../../../helpers/backend_helper';
import { FailureAlert, SuccessAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/Loading';
import { ItemDirective, ItemsDirective, ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import {
    ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, FilterType, Inject, Sort, Edit,
    ColumnMenu, CommandColumn, Toolbar,
    ExcelExport, PdfExport, Group, Resize
} from "@syncfusion/ej2-react-grids";
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const Compensation = (props) => {
    let allowanceGridInstance;
    let deductionGridInstance;
    let compHistoryGridInstance;

    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')

    const [canvasCPlacement, setCCanvasPlacement] = useState("start");
    const [canvasCOpen, setCCanvasOpen] = useState(false);

    const [canvasAPlacement, setACanvasPlacement] = useState("start");
    const [canvasAOpen, setACanvasOpen] = useState(false);

    const [canvasDPlacement, setDCanvasPlacement] = useState("start");
    const [canvasDOpen, setDCanvasOpen] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [compensationHistoryData, setCompensationHistoryData] = useState({});

    const [compensationData, setCompensationData] = useState({
        da: "", basicPay: "", hra: "", cca: "", notes: "", paymentMethod: "", paymentTypeId: "", paymentType: null, effectiveFrom: "", payscale:"", notes:""
    });
    const [allowanceData, setAllowanceData] = useState({
        employeeId: "", allowanceTypeId: "", amount: "", effectiveDate: "", notes: "", amountType: 1, paymentCycleId: 0
    });
    const [deductionData, setDeductionData] = useState({
        employeeId: "", deductionTypeId: "", employeeAmount: "", employerAmount: "", effectiveDate: "", notes: "", amountType: 1, paymentCycleId: 1
    });

    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [allerrors, setAllErrors] = useState({});
    const [dederrors, setDedErrors] = useState({});
    const [allowances, setAllowances] = useState();
    const [allowancesHistory, setAllowancesHistory] = useState();
    const [deductions, setDeducions] = useState();
    const [deductionsHistory, setDeducionsHistory] = useState();
    const [allowanceTypes, setAllowanceTypes] = useState();
    const [deductionTypes, setDeducionTypes] = useState();

    const params = useParams();
    const history = useHistory();

    const fetchData = async () => {
        const response = await getEmployeeCompensation(props.employeeId);
        const resData = await response;
        console.log("Compensation..............");
        console.log(resData);
        if (resData.data) {
            setCompensationData(resData.data);
        } else {
            compensationData.employeeId = props.employeeId;
        }
    }

    useEffect(() => {
        setAllowanceData({ ...allowanceData, 'employeeId': props.employeeId });
        setDeductionData({ ...deductionData, 'employeeId': props.employeeId });

        fetchData();

        getEmployeeCompensationHistory(props.employeeId).then((res) => {
            if (res.data) {
                setCompensationHistoryData(res.data);
            }
        });

        getEditableAllowancesTypes(props.employeeId).then((res) => {
            if (res.data) {
                let allowanceType = [{ "id": 0, "name": "Select Allowance Type" }];
                res.data.forEach(el => {
                    allowanceType.push(el)
                });
                setAllowanceTypes(allowanceType);
            }
        })

        getEditableDeductionTypes(props.employeeId).then((res) => {
            if (res.data) {
                let dedType = [{ "id": 0, "name": "Select deduction Type" }];
                res.data.forEach(el => {
                    dedType.push(el)
                });
                setDeducionTypes(dedType);
            }
        })

        getEmpAllowances(props.employeeId).then((res) => {
            if (res.data) {
                setAllowances(res.data.employeeAllownaces);
                setAllowancesHistory(res.data.employeeAllowanceHistories);
            }
        })

        loadEmployeeDeductions();

    }, [submitted]);

    // console.log(compensationData);

    const loadEmployeeDeductions = () => {
        showLoading(true);

        getEmpDeductions(props.employeeId).then((res) => {
            if (res.data) {
                setDeducions(res.data.employeeDeductions);
                setDeducionsHistory(res.data.employeeDeductionHistories);
            }
            showLoading(false);
        });
    }

    const openAllowance = () => {
        setACanvasPlacement('end')
        setACanvasOpen(!canvasAOpen)
        setErrors({});
    }

    const openDeduction = () => {
        setDCanvasPlacement('end')
        setDCanvasOpen(!canvasDOpen)
        setErrors({});
    }

    const openCompensation = () => {
        setCCanvasPlacement('end')
        setCCanvasOpen(!canvasCOpen)
        setErrors({});
    }

    // console.log(compensationData);

    const handleChange = event => {
        // console.log(event.target.value, event.target.name);
        setCompensationData({ ...compensationData, [event.target.name]: event.target.value });        
    };

    const compensationChange = event => {
        setCompensationData({ ...compensationData, [event.target.name]: event.target.value });
    }

    const allowanceChange = event => {
        setAllowanceData({ ...allowanceData, [event.target.name]: event.target.value });
    }

    const deductionChange = event => {
        setDeductionData({ ...deductionData, [event.target.name]: event.target.value });
    }

    const onSubmitDeduction = event => {
        event.preventDefault();
        let formErrors = {};
        if (!deductionData.deductionTypeId) {
            formErrors.deductionTypeId = 'Deduction  Type is required';
        }
        if (!deductionData.employeeAmount) {
            formErrors.employeeAmount = 'Percentage/Amount is required';
        }

        if (!deductionData.employerAmount) {
            formErrors.employerAmount = 'Percentage/Amount is required';
        }

        setDedErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            console.log(deductionData);

            addEmpDeduction(deductionData).then(res => {
                if (res.status === true) {
                    console.log("Inside success");                    
                    setIsLoading(false);
                    openDeduction();
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                    timeout();
                }
                setFormSubmit(!submitted);                
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }
    }

    const onSubmitAllowance = event => {
        event.preventDefault();

        let formErrors = {};
        if (!allowanceData.allowanceTypeId) {
            formErrors.allowanceTypeId = 'Allowance  Type is required';
        }
        if (!allowanceData.amount) {
            formErrors.amount = 'Amount is required';
        }

        setAllErrors(formErrors)

        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            addEmpAllowance(allowanceData).then(res => {
                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    openAllowance();
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();                
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }
    }

    const onSubmitCompensation = event => {
        event.preventDefault();

        let formErrors = {};
        if (!compensationData.basicPay) {
            formErrors.grossPay = 'Basic Pay is required';
        }
        if (!compensationData.da) {
            formErrors.basicPay = 'DA is required';
        }
        if (compensationData.paymentType === 0) {
            formErrors.paymentType = 'Payment Method is required';
        }

        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            console.log(compensationData);

            var _data = {};
            _data.employeeId = compensationData.employeeId;
            _data.basicPay = compensationData.basicPay;            
            _data.da = compensationData.da;
            _data.hra = compensationData.hra;
            _data.cca = compensationData.cca;
            _data.effectiveFrom = compensationData.effectiveFrom;
            _data.id = compensationData.id;
            _data.notes = compensationData.notes;
            _data.paymentTypeId = compensationData.paymentTypeId;
            _data.payscale = compensationData.payscale;


            addEmployeeCompensation(compensationData).then(res => {
                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    // setCompensationData({
                    //     grossPay: "", basicPay: "", variablePay: "", gratuity: "", totalCTC: "", paymentMethod: 0
                    // });
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                    timeout();
                }
                setFormSubmit(!submitted);                
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const onUpdateClick = () => {
        openCompensation();
    }

    const onSubmitClick = () =>{
        
    }

    const compHistoryToolbarOptions = [
        'Search',        
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' }
    ];

    const compHistoryToolbarClick = (args) => {
        switch (args.item.id) {            
            case 'refresh':
                //loadEmployeeData();
                break;
        }
    }

    const allowanceToolbarOptions = [
        'Search',
        { text: 'Add', prefixIcon: 'e-add', id: 'add' },        
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Delete', prefixIcon: 'e-trash', id: 'delete' },
    ];

    const allowanceToolbarClick = (args) => {
        switch (args.item.id) {
            case 'add':
                openAllowance();
                break;
            case 'delete':
                alert("delete");
                break;
            case 'refresh':
                //loadEmployeeData();
                break;
        }
    }


    const deductionToolbarOptions = [
        'Search',
        { text: 'Add', prefixIcon: 'e-add', id: 'add' },
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Delete', prefixIcon: 'e-trash', id: 'delete' },
    ];

    const deductionToolbarClick = (args) => {
        switch (args.item.id) {
            case 'add':
                openDeduction();
                break;
            case 'delete':
                deleteDeductions();
                break;
            case 'refresh':
                loadEmployeeDeductions();
                break;
        }
    }

    const deleteDeductions = () => {
        var selRows = deductionGridInstance.getSelectedRecords();
        if (selRows.length > 0) {

            showLoading(true);

            var _data = [];
            selRows.map((_row, i) => {
                _data.push(_row.id);
            });

            postData("/employee/deletededuction", _data).then(res => {
                showLoading(false);
                console.log(res);
                if (res.data) {
                    if (res.status == true) {
                        alert("Deleted");
                        loadEmployeeDeductions();
                    } else {
                        alert(res.message);
                    }
                }
            }).catch(error => {
                alert(error);
                showLoading(false);
            });

        } else {
            alert("Please select the Details to delete");
        }
    }
    

    return (
        <>
            <Offcanvas backdrop="static" className='w-30' direction={canvasCPlacement} isOpen={canvasCOpen} toggle={openCompensation}>
                <OffcanvasHeader toggle={openCompensation}>
                    <Row>
                        <Col sm='12'>
                            <b>Compensation</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />
                <OffcanvasBody>
                    <FormGroup>
                        <Row>
                            <Col sm="12" md="12">
                                <Label for="effectiveDate">Basic Pay</Label>
                                <Input type="number" onChange={handleChange}
                                    name='basicPay' placeholder="Basic Pay" value={compensationData.basicPay}>
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.amount && <span>{allerrors.amount}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="effectiveDate">DA</Label>
                                <Input type="number" onChange={handleChange}
                                    name='da' placeholder="DA" value={compensationData.da}>
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.amount && <span>{allerrors.amount}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="effectiveDate">HRA</Label>
                                <Input type="number" onChange={handleChange}
                                    name='hra' placeholder="HRA" value={compensationData.hra}>
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.amount && <span>{allerrors.amount}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="effectiveDate">CCA</Label>
                                <Input type="number" onChange={handleChange}
                                    name='cca' placeholder="CCA" value={compensationData.cca}>
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.cca && <span>{allerrors.cca}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="effectiveDate">Payscale</Label>
                                <Input type="textarea" onChange={handleChange}
                                    name='payscale' placeholder="Payscale" value={compensationData.payscale}>
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.payscale && <span>{allerrors.payscale}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="effectiveDate">Effective Date</Label>
                                <Input type="date" onChange={handleChange}
                                    name='effectiveFrom' placeholder="Effective from date">
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.amount && <span>{allerrors.amount}</span>}</p>
                            </Col>

                            <Col sm="12" md="12" className="mb-2">
                                <Label for="deduction">Select Payment Method</Label>
                                <Input type="select" onChange={handleChange}
                                    name='paymentTypeId' placeholder="Select Payment Method">
                                    <option value='0'>Select Payment Method</option>
                                    <option value='1'>Cash</option>
                                    <option value='2'>Cheque</option>
                                    <option value='3'>Direct Transfer</option>
                                    <option value='4'>Direct Deposit</option>
                                </Input>
                                <p style={{ color: "Red" }}>{dederrors.paymentCycleId && <span>{dederrors.paymentCycleId}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="effectiveDate">Notes</Label>
                                <Input type="textarea" onChange={handleChange}
                                    name='notes' placeholder="Notes" value={compensationData.notes}>
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.payscale && <span>{allerrors.payscale}</span>}</p>
                            </Col>
                        </Row>
                        <div className="text-center mt-2">
                            {dataSuccess === 'added' && <SuccessAlert />}
                            {dataSuccess === 'failed' && <FailureAlert />}
                        </div>

                        <div className="offcanvas-footer">
                            {isLoading ? <LoadingSpinner /> : <Button color="primary" className="btn"
                                type="submit" onClick={onSubmitCompensation}>Submit</Button>}&nbsp;&nbsp;
                            <button onClick={openCompensation} className="btn btn-dark">Close</button>
                        </div>
                    </FormGroup>
                </OffcanvasBody>
            </Offcanvas>

            <Offcanvas backdrop="static" className='w-30' direction={canvasAPlacement} isOpen={canvasAOpen} toggle={openAllowance}>
                <OffcanvasHeader toggle={openAllowance}>
                    <Row>
                        <Col sm='12'>
                            <b>Allowances</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />
                <OffcanvasBody>
                    <FormGroup>
                        <Row>
                            <Col sm="12" md="12" className="mb-2">
                                <Label for="allowance">Select Allowance</Label>
                                <Input type="select" onChange={allowanceChange}
                                    name='allowanceTypeId' placeholder="Select Allowance">
                                    {allowanceTypes &&
                                        allowanceTypes.map((res) => {
                                            return <option value={res.id} key={res.id}>{res.name}</option>
                                        })}
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.allowanceTypeId && <span>{allerrors.allowanceTypeId}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <input id="percentage" name="amountType" onChange={allowanceChange} type="radio" value="1" /> Percentage&nbsp;
                                <input id="fixedAmount" name="amountType" onChange={allowanceChange} type="radio" value="2" /> Fixed Amount
                            </Col>

                            <Col sm="12" md="12">                                
                                <Input type="number" onChange={allowanceChange}
                                    name='amount' placeholder="Enter Percentage/Amount">
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.amount && <span>{allerrors.amount}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="effectiveDate">Effective From</Label>
                                <Input type="date" onChange={allowanceChange}
                                    name='effectiveDate' placeholder="Effective from date">
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.amount && <span>{allerrors.amount}</span>}</p>
                            </Col>

                            <Col sm="12" md="12" className="mb-2">
                                <Label for="deduction">Select Payment Cycle</Label>
                                <Input type="select" onChange={allowanceChange}
                                    name='paymentCycleId' placeholder="Select Payment Cycle">
                                    <option value='0'>Select Payment Cycle</option>
                                    <option value='1'>Monthly</option>
                                    <option value='2'>Quaterly</option>
                                </Input>
                                <p style={{ color: "Red" }}>{dederrors.paymentCycleId && <span>{dederrors.paymentCycleId}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="notes">Notes</Label>
                                <Input type="textarea" onChange={allowanceChange}
                                    name='notes' placeholder="Notes">
                                </Input>
                                <p style={{ color: "Red" }}>{allerrors.notes && <span>{allerrors.notes}</span>}</p>
                            </Col>
                        </Row>
                        <div className="text-center mt-2">
                            {dataSuccess === 'added' && <SuccessAlert />}
                            {dataSuccess === 'failed' && <FailureAlert />}
                        </div>

                        <div className="offcanvas-footer">
                            {isLoading ? <LoadingSpinner /> : <Button color="primary" className="btn"
                                type="submit" onClick={onSubmitAllowance}>Submit</Button>}&nbsp;&nbsp;
                            <button onClick={openAllowance} className="btn btn-dark">Close</button>
                        </div>
                    </FormGroup>
                </OffcanvasBody>
            </Offcanvas>

            <Offcanvas backdrop="static" className='w-30' direction={canvasDPlacement}
                isOpen={canvasDOpen} toggle={openDeduction}>
                <OffcanvasHeader toggle={openDeduction}>
                    <Row>
                        <Col sm='12'>
                            <b>Add Deductions</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />
                <OffcanvasBody>
                    <FormGroup>
                        <Row>
                            <Col sm="12" md="12" className="mb-2">
                                <Label for="deduction">Select Deduction</Label>
                                <Input type="select" onChange={deductionChange}
                                    name='deductionTypeId' placeholder="Select Deduction">
                                    {deductionTypes &&
                                        deductionTypes.map((res) => {
                                            return <option value={res.id} key={res.id}>{res.name}</option>
                                        })}
                                </Input>
                                <p style={{ color: "Red" }}>{dederrors.deductionTypeId && <span>{dederrors.deductionTypeId}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <input id="percentage" name="amountType" onChange={deductionChange} type="radio" value="1" /> Percentage&nbsp;
                                <input id="fixedAmount" name="amountType" onChange={deductionChange} type="radio" value="2" /> Fixed Amount
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="amount">Employee Contribution</Label>
                                <Input type="number" onChange={deductionChange}
                                    name='employeeAmount' placeholder="Enter Percentage/Amount">
                                </Input>
                                <p style={{ color: "Red" }}>{dederrors.employeeAmount && <span>{dederrors.employeeAmount}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="amount">Employer Contribution</Label>
                                <Input type="number" onChange={deductionChange}
                                    name='employerAmount' placeholder="Enter Percentage/Amount">
                                </Input>
                                <p style={{ color: "Red" }}>{dederrors.employerAmount && <span>{dederrors.employerAmount}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="effectiveDate">Effective From</Label>
                                <Input type="date" onChange={deductionChange}
                                    name='effectiveDate' placeholder="Effective from date">
                                </Input>
                                <p style={{ color: "Red" }}>{dederrors.effectiveDate && <span>{dederrors.effectiveDate}</span>}</p>
                            </Col>

                            <Col sm="12" md="12">
                                <Label for="notes">Notes</Label>
                                <Input type="textarea" onChange={deductionChange}
                                    name='notes' placeholder="Notes">
                                </Input>
                                <p style={{ color: "Red" }}>{dederrors.notes && <span>{dederrors.notes}</span>}</p>
                            </Col>
                        </Row>

                        <div className="text-center mt-2">
                            {dataSuccess === 'added' && <SuccessAlert />}
                            {dataSuccess === 'failed' && <FailureAlert />}
                        </div>

                        <div className="offcanvas-footer">
                            {isLoading ? <LoadingSpinner /> : <Button color="primary" className="btn"
                                type="submit" onClick={onSubmitDeduction}>Submit</Button>}&nbsp;&nbsp;
                            <button onClick={openDeduction} className="btn btn-dark">Close</button>
                        </div>
                    </FormGroup>
                </OffcanvasBody>
            </Offcanvas>

            <Row className="m-2">
                <Col xl="9" className="mt-2" md="9" sm="9">
                    <CardTitle tag="h4">Compensation Information : {props.employeeData?.fullName}</CardTitle>
                </Col>
                <Col xl="3" md="3" sm="4">
                    <button type="button" onClick={onUpdateClick} className="btn btn-info waves-effect waves-light">
                        <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                        Update Compensation
                    </button>
                    &nbsp;&nbsp;&nbsp;                    
                    <button type="button" className="btn btn-warning waves-effect waves-light">
                        <i className="bx bx-revision font-size-16 align-middle me-2"></i>{" "}
                        Refresh
                    </button>
                </Col>
            </Row>

            <Form>

                <CardBody>
                    <div className="text-center mt-2" style={{ marginBottom: '1rem' }}>
                        {dataSuccess === 'added' && <SuccessAlert />}
                        {dataSuccess === 'failed' && <FailureAlert />}
                    </div>

                    <Row>
                        <Col className='mb-1' xl='3' md='3' sm='12'>
                            Basic Pay (in Rs/-)
                            {!compensationData ? <p>-</p> :
                                <div className="input-group mt-2">                                    
                                    <Input type="text" className="form-control" name='basicPay' readOnly={true}
                                        placeholder="Basic Pay" value={compensationData.basicPay} />                                    
                                </div>}
                        </Col>

                        <Col className='mb-1' xl='3' md='3' sm='12'>
                            DA (in %)
                            {!compensationData ? <p>-</p> :
                                <div className="input-group mt-2">
                                    <Input type="text" className="form-control" name='basicPay' readOnly={true}
                                        placeholder="DA" value={compensationData.da} />
                                </div>}
                        </Col>

                        <Col className='mb-1' xl='3' md='3' sm='12'>
                            HRA (in %)
                            {!compensationData ? <p>-</p> :
                                <div className="input-group mt-2">
                                    <Input type="text" className="form-control" name='basicPay' readOnly={true}
                                        placeholder="HRA" value={compensationData.hra} />
                                </div>}
                        </Col>

                        <Col className='mb-1' xl='3' md='3' sm='12'>
                            CCA (in Rs/-)
                            {!compensationData ? <p>-</p> :
                                <div className="input-group mt-2">
                                    <Input type="text" className="form-control" name='basicPay' readOnly={true}
                                        placeholder="CCA" value={compensationData.cca}  />
                                </div>}
                        </Col>

                    </Row>
                    <Row>
                        <Col className='mb-1' xl='6' md='6' sm='12'>
                            Payscale
                            {!compensationData ? <p>-</p> :
                                <div className="input-group mt-2">
                                    <Input type="text" className="form-control" name='payscale' readOnly={true}
                                        placeholder="pay scale" value={compensationData.payscale} />
                                </div>}
                        </Col>

                        <Col className='mb-1' xl='3' md='3' sm='12'>
                            Payment Method
                            {!compensationData ? <p>-</p> :
                                <div className="input-group mt-2">
                                    <Input id='paymentType' type='text' value={compensationData.paymentType?.name} readOnly={true}
                                        name='paymentMethod' placeholder="Payment Method">
                                    </Input>
                                </div>
                                }
                        </Col>
                    </Row>
                    <Row>
                        <br />
                    </Row>
                </CardBody>

                <CardBody>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">

                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    href="#" className={classnames({ active: activeTab === 1 })}
                                    onClick={() => {
                                        setactiveTab(1);
                                    }}>
                                    <span>Compensation History</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#" className={classnames({ active: activeTab === 2 })}
                                    onClick={() => {
                                        setactiveTab(2);
                                    }}>
                                    <span>Allowances</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#" className={classnames({ active: activeTab === 3 })}
                                    onClick={() => {
                                        setactiveTab(3);
                                    }}>
                                    <span>Deductions</span>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent
                            className="twitter-bs-wizard-tab-content"
                            activeTab={activeTab}>
                            <TabPane tabId={1}>

                                <Row>
                                    <Col className='mb-1' xl='12' md='12' sm='12'>

                                        {!compensationHistoryData && <p>No History found!!</p>}

                                        <GridComponent dataSource={compensationHistoryData} allowSorting={true} allowResizing={true} statelessTemplates={['directiveTemplates']}
                                            toolbar={compHistoryToolbarOptions} toolbarClick={compHistoryToolbarClick.bind(this)} ref={grid => compHistoryGridInstance = grid}                                        >
                                            <ColumnsDirective>
                                                <ColumnDirective field='action' headerText='Action' width='150'></ColumnDirective>
                                                <ColumnDirective field='basicPay' headerText='Basic Pay' width='150'></ColumnDirective>
                                                <ColumnDirective field='da' headerText='DA' width='150' />
                                                <ColumnDirective field='hra' headerText='HRA' width='150' />
                                                <ColumnDirective field='cca' headerText='CCA' width='150' />
                                                <ColumnDirective field='effectiveFrom' headerText='Effective From' type='date' format='dd-MM-yyyy' width='150' />
                                                <ColumnDirective field='notes' headerText='Notes' width='150' />
                                            </ColumnsDirective>
                                            <Inject services={[Sort, Resize]} />
                                        </GridComponent>

                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={2}>

                                {!allowances && <p>No Allowances found!!</p>}

                                <GridComponent dataSource={allowances} allowSorting={true} allowResizing={true} statelessTemplates={['directiveTemplates']}
                                    toolbar={allowanceToolbarOptions} toolbarClick={allowanceToolbarClick.bind(this)} ref={grid => allowanceGridInstance = grid}>
                                    <ColumnsDirective>
                                        <ColumnDirective type="checkbox" width='50' />
                                        <ColumnDirective field='allowanceType.name' headerText='Allowance Type' width='150'></ColumnDirective>
                                        <ColumnDirective field='percentage' headerText='Percentage' width='150' />
                                        <ColumnDirective field='amount' headerText='Amount' width='150' />
                                        <ColumnDirective field='effectiveDate' headerText='Effective From' type='date' format='dd-MM-yyyy' width='150' />
                                        <ColumnDirective field='notes' headerText='Notes' width='150' />
                                    </ColumnsDirective>
                                    <Inject services={[Sort, Resize]} />
                                </GridComponent>

                                <hr></hr>
                                <Row>
                                    <Col className='mb-1' xl='12' md='12' sm='12'>
                                        <CardHeader>
                                            <Row><CardTitle tag='h4'>Allowance History</CardTitle></Row>
                                        </CardHeader>

                                        {!allowancesHistory && <p>No History found!!</p>}

                                        <GridComponent dataSource={allowancesHistory} allowSorting={true} allowResizing={true} statelessTemplates={['directiveTemplates']}>
                                            <ColumnsDirective>
                                                <ColumnDirective field='action' headerText='Action' width='150'></ColumnDirective>
                                                <ColumnDirective field='allowanceType.name' headerText='Allowance Type' width='150'></ColumnDirective>
                                                <ColumnDirective field='percentage' headerText='Percentage' width='150' />
                                                <ColumnDirective field='amount' headerText='Amount' width='150' />
                                                <ColumnDirective field='effectiveDate' headerText='Effective From' type='date' format='dd-MM-yyyy' width='150' />
                                                <ColumnDirective field='notes' headerText='Notes' width='150' />
                                            </ColumnsDirective>
                                            <Inject services={[Sort, Resize]} />
                                        </GridComponent>

                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={3}>

                                {!deductions && <p>No Deductions found!!</p>}

                                <GridComponent dataSource={deductions} allowSorting={true} allowResizing={true} statelessTemplates={['directiveTemplates']}
                                    toolbar={deductionToolbarOptions} toolbarClick={deductionToolbarClick.bind(this)} ref={grid => deductionGridInstance = grid}>
                                    <ColumnsDirective>
                                        <ColumnDirective type="checkbox" width='50' />
                                        <ColumnDirective field='deductionType.name' headerText='Deduction Type' width='150'></ColumnDirective>
                                        <ColumnDirective field='percentage' headerText='Percentage' width='100' />
                                        <ColumnDirective field='amount' headerText='Amount' width='100' />
                                        <ColumnDirective field='employerPercentage' headerText='Emp. Percentage' width='150' />
                                        <ColumnDirective field='employerAmount' headerText='Emp. Amount' width='150' />
                                        <ColumnDirective field='effectiveDate' headerText='Effective From' type='date' format='dd-MM-yyyy' width='150' />
                                        <ColumnDirective field='notes' headerText='Notes' width='150' />
                                    </ColumnsDirective>
                                    <Inject services={[Sort, Resize]} />
                                </GridComponent>

                                <hr></hr>
                                <Row>
                                    <Col className='mb-1' xl='12' md='12' sm='12'>
                                        <CardHeader>
                                            <Row><CardTitle tag='h4'>Deduction History</CardTitle></Row>
                                        </CardHeader>

                                        {!deductionsHistory && <p>No History found!!</p>}

                                        <GridComponent dataSource={deductionsHistory} allowSorting={true} allowResizing={true} statelessTemplates={['directiveTemplates']}>
                                            <ColumnsDirective>
                                                <ColumnDirective field='action' headerText='Action' width='150'></ColumnDirective>
                                                <ColumnDirective field='deductionType.name' headerText='Deduction Type' width='150'></ColumnDirective>
                                                <ColumnDirective field='percentage' headerText='Percentage' width='100' />
                                                <ColumnDirective field='amount' headerText='Amount' width='100' />
                                                <ColumnDirective field='employerPercentage' headerText='Emp. Percentage' width='150' />
                                                <ColumnDirective field='employerAmount' headerText='Emp. Amount' width='150' />
                                                <ColumnDirective field='effectiveDate' headerText='Effective From' type='date' format='dd-MM-yyyy' width='150' />
                                                <ColumnDirective field='notes' headerText='Notes' width='150' />
                                            </ColumnsDirective>
                                            <Inject services={[Sort, Resize]} />
                                        </GridComponent>

                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                </CardBody>

            </Form>
        </>

    )
}

export default Compensation
