// ** Reactstrap Imports
import { Row, Col, CardHeader, CardTitle, CardBody, Label, Form, Input, Card, Container, CardFooter, Button, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import classnames from "classnames";
// import { Radio } from 'react-feather'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from 'react-router-dom';
import { getEmployeeDocumentList } from '../../../helpers/backend_helper';
import { FailureAlert, SuccessAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/Loading';
import { ItemDirective, ItemsDirective, ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { showLoading } from 'react-global-loading';
import * as url from '../../../helpers/url_helper';

import {
    ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, FilterType, Inject, Sort, Edit,
    ColumnMenu, CommandColumn, Toolbar,
    ExcelExport, PdfExport, Group, Resize
} from "@syncfusion/ej2-react-grids";

import DocumentDetail from './DocumentDetail';

const DocumentList = (props) => {
    let gridInstance;
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [employeeId, setEmployeeId] = useState();
    const [compensationList, setCompensationList] = useState([]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const loadData = async () => {
        showLoading(true);
        const response = await getEmployeeDocumentList();
        const resData = await response;
        console.log(resData);
        if (resData.data) {
            setCompensationList(resData.data);
            showLoading(false);
        }
    }

    useEffect(() => {

        loadData();

    }, []);    

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' },
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' }
    ];

    const toolbarClick = (args) => {
        console.log(args.item);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const viewEmployee = (EmpId) => {        
        setEmployeeId(EmpId);
        toggle();
    }

    const headerLink = props => {
        return (
            <button onClick={() => viewEmployee(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.employeeProfile.fullName}
            </button>
        )
    }

    const onRowDoubleClick = (events) => {
        console.log(events.rowData);
        viewEmployee(events.rowData.id);
    };

    const viewDocument = (docId, docName) => {
        console.log("view document: " + docId);
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        fetch(url.BASE_URL + '/Employee/downloaddocument/' + docId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + userdata.token,
                'apikey': 'key123',
            },
        }).then((response) => response.blob())
            .then((blob) => {
                console.log(blob);
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download', docName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const docsLink = props => {
        console.log("Inside docsLink");
        console.log(props.employeeTransferDocuments);

        const links = [];
        links.push(
            <button onClick={() => viewDocument(props.id, props.fileName)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.fileName}
            </button>);
        return (
            <div>{links}</div>
        )
    }

    const addNewEntry = () => {
        setCanvasOpen(!canvasOpen);
    };

    const onClose = () => {
        setCanvasOpen(!canvasOpen);
        loadData();
    }

    return (
        <>
            <div className="page-content">

                <Container fluid>

                    <Breadcrumbs title="Employees" breadcrumbItem="Employees Document" enableAction={true} onClick={addNewEntry} actionName="New Entry" />

                    <div className='mt-2'>
                        {compensationList &&
                            <GridComponent dataSource={compensationList} height="100%" toolbar={toolbarOptions} ref={grid => gridInstance = grid}
                            allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}
                                allowFiltering={true} allowPaging={true} showColumnMenu={true} statelessTemplates={['directiveTemplates']}
                                recordDoubleClick={onRowDoubleClick} pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }}>
                                <ColumnsDirective>
                                    <ColumnDirective field='employeeProfile.fullName' headerText='Fullname' template={headerLink} width='150'></ColumnDirective>
                                    <ColumnDirective field='employeeProfile.workTitle' headerText='Title' width='150' />
                                    <ColumnDirective field='employeeProfile.workLocation.locationName' headerText='Location' width='150' />
                                    <ColumnDirective field='employeeProfile.employeeStatus.name' headerText='Status' width='80' />
                                    <ColumnDirective field="name" headerText="Name" width="150" />
                                    <ColumnDirective field="description" type="Description" width="150" />
                                    <ColumnDirective template={docsLink} headerText="Documents" allowTextWrap={true} width="150"></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
                            </GridComponent>}
                    </div>
                </Container>


                <Offcanvas backdrop="static" className="w-30" direction="end" isOpen={canvasOpen}
                    toggle={addNewEntry}>
                    <OffcanvasHeader toggle={addNewEntry}>
                        <Row>
                            <Col sm="12"><b>Document Details</b></Col>
                        </Row>
                    </OffcanvasHeader>
                    <hr />
                    <OffcanvasBody>
                        <DocumentDetail employeeId={props.employeeId} onClose={onClose} />
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </>

    )
}

export default DocumentList
