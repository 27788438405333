import React, { Component, useState, useEffect, useRef } from "react"
import ReactEcharts from "echarts-for-react"
import { getData } from "../../helpers/url-request"
import ProfileSaga from "../../store/auth/profile/saga";
import { showLoading } from 'react-global-loading';

const AttendanceChart = () => {

    const [categoryData, setCategoryData] = useState([]);
    const [presentData, setPresentData] = useState([]);
    const [leaveData, setLeaveData] = useState([]);
    const [absentData, setAbsentData] = useState([]);

    useEffect(() => {
        showLoading(true);
        getData("/attendance/dashboard").then(response => {
            console.log(response.data);
            if (response.data) {
                var _catData = [];
                var _presentData = [];
                var _leaveData = [];
                var _absentData = [];
                response.data.map((data) => {
                    _catData.push(data.attendanceDate);
                    _presentData.push(data.presentCount);
                    _leaveData.push(data.leaveCount);
                    _absentData.push(data.absentCount);
                });
                setCategoryData(_catData);
                setPresentData(_presentData);
                setLeaveData(_leaveData);
                setAbsentData(_absentData);
            }
            showLoading(false);
        });
    }, []);

    const getOption = () => {
        return {
            grid: {
                zlevel: 0,
                x: 80,
                x2: 50,
                y: 30,
                y2: 30,
                borderWidth: 0,
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(0,0,0,0)',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                orient: 'center',
                left: 0,
                top: 20,
                feature: {
                    magicType: { type: ['line', 'bar'], title: { line: "For line chart", bar: "For bar chart" } },
                    restore: { title: "restore" },
                    saveAsImage: { title: "Download Image" }
                }
            },
            color: ['#2ab57d', '#4ba6ef', '#fd625e', '#ffbf53', '#5156be'],
            legend: {
                data: ['Present', 'Leave', 'Absent'],
                textStyle: { color: '#858d98' }
            },
            xAxis: [
                {
                    type: 'category',
                    data: categoryData,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#858d98'
                        },
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',                    
                    axisLine: {
                        lineStyle: {
                            color: '#858d98'
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: "rgba(133, 141, 152, 0.1)"
                        }
                    }
                },               
            ],
            series: [
                {
                    name: 'Present',
                    type: 'bar',
                    data: presentData
                },
                {
                    name: 'Leave',
                    type: 'bar',
                    data: leaveData
                },
                {
                    name: 'Absent',
                    type: 'bar',
                    data: absentData
                }                
            ]
        }
    }

    return (        
        <React.Fragment>
            Attendance Chart
            <ReactEcharts style={{ height: "350px" }} option={getOption()} />
        </React.Fragment>
    )
}
export default AttendanceChart
