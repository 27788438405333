import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";

import classnames from "classnames";

const IncomeTaxSlab = () => {

    return (
        <React.Fragment>

            <CardBody>
                <Row>
                    <Col sm='4' lg='4' md='4'>
                        <h5>Income Tax Slab Rates for </h5>
                    </Col>
                    <Col sm='4' lg='4' md='4'>
                        <select className="form-select">
                            <option>FY 2022-23 (AY 2023-24)</option>
                            <option>FY 2021-22 (AY 2022-23)</option>
                            <option>FY 2020-21 (AY 2022-23)</option>
                            <option>FY 2019-20 (AY 2021-22)</option>
                        </select>
                    </Col>

                </Row>
                <br />
                <Row>
                    <Col sm='6' md='6' lg='6'>
                        <b>For Every Taxpayer:</b>
                        <hr />
                        <Row>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                <h6>Income Tax Slab Rates (Rs.)</h6>
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                <h6>Tax (In %)</h6>
                            </Col>
                            <br />
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                ₹0 - ₹2,50,000
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                No tax rates applicable
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                ₹2,50,001 - ₹5,00,000
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                5% of total income exceeding ₹2,50,000
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                ₹5,00,001 - ₹7,50,000
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                ₹12,500 + 10% of total income exceeding ₹5,00,000
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                ₹7,50,001 - ₹10,00,000
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                ₹37,500 + 15% of total income exceeding ₹7,50,000
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                ₹10,00,001 - ₹12,50,000
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                ₹75,000 + 20% of total income exceeding ₹10,00,000
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                ₹12,50,001 - ₹15,00,000
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                ₹1,25,000 + 25% of total income exceeding ₹12,50,000
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                Above ₹15,00,000
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                ₹1,87,500 + 30% of total income exceeding ₹15,00,000
                            </Col>
                            <br /><br /><br /><br />
                            <Col sm='12' md='12' lg='12' className="mb-4">
                                Note:
                                <ul>
                                    <li>Tax slabs are same for all the ages.</li>
                                    <li>For general taxpayers rebate Rs 12,500/- will be applied
                                        if the annual taxable income of the employee is equal or less than 5 lakhs.</li>
                                    <li>You can opt for the 2019 tax slabs or 2020 tax slabs for this fiscal year.
                                        To change that go to view page of the selected employee.</li>
                                    <li>Some of the savings will not be considered for this Fiscal year. To know more <a> Click Here</a></li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>



                    <Col sm='6' md='6' lg='6'>
                        <b>Surcharge:</b>
                        <hr />
                        <Row>
                            <Col sm='12' md='12' lg='12' className="mb-4">
                                Surcharge is an additional charge payable on income tax. It is an added tax on the taxpayers having a higher income inflow during a particular financial year.
                                Following are the thresholds and the rates of surcharge applicable in case of various assesses.
                            </Col>

                            <Col sm='5' md='5' lg='5' className="mb-2">
                                <h6>Total Taxable Income</h6>
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                <h6>Rate of Surcharge applicable (In %)</h6>
                            </Col>
                            <br />
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                Upto ₹50 Lakhs
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                Nil
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                Above ₹50 Lakhs to ₹1 Crore
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                10%
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                Above ₹1 Crore to ₹2 Crores
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                15%
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                Above ₹2 Crores to ₹5 Crores
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                25%
                            </Col>
                            <Col sm='5' md='5' lg='5' className="mb-2">
                                More than ₹5 Crores
                            </Col>
                            <Col sm='7' md='7' lg='7'>
                                37%
                            </Col>
                            <br /><br /><br />
                            Note:
                            <Col sm='12'>
                                The surcharge shall be subject to marginal relief (where income exceeds fifty lakh rupees, the total amount payable as income-tax and surcharge shall not exceed total amount payable as income-tax on total income of fifty lakh rupees by
                                 above the amount of income that exceeds fifty lakh rupees). Click here to know more
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>

        </React.Fragment >
    );
};

export default IncomeTaxSlab;
