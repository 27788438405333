import React, { Component, useState, useEffect, useRef } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";
import ReactEcharts from "echarts-for-react"
import { getData } from "../../helpers/url-request"

const AttendanceCount = () => {

    const [chartData, setChartData] = useState([]);
    const [countData, setCountData] = useState(1);
    let child1 = useRef();
    const option = {        
        toolbox: {
            show: false,
        },
        tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
            orient: "horizontal",
            right: "top",
            data: ["Present", "Absent"],
            textStyle: {
                color: ["#858d98"],
            },
        },
        color: ['#2ab57d', '#fd625e', '#4ba6ef', '#ffbf53', '#5156be'],
        series: [
            {
                name: "Total sales",
                type: "pie",
                radius: ["40%", "70%"],
                center: ["50%", "60%"],
                data: chartData,
                label: {
                    show: false,
                },
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],        
    }

    useEffect(() => {
        getData("/Employee/count/0").then(response => {
            console.log(response.data);
            var _data = [];
            _data.push({ value: 122, name: "Present" });
            _data.push({ value: 23, name: "Absent" });
            setChartData(_data);
            setCountData(222);
        });
    }, []);
    
    return (
        <React.Fragment>
            <Card className="card-h-100">
                <CardBody style={{ padding: '5px' }}>                    
                    <ReactEcharts option={option} />                       
                </CardBody>
            </Card>
        </React.Fragment>
    )
    
}
export default AttendanceCount
