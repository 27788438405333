import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, } from "reactstrap";
import { getEmployeeSuspension, getIncrementTypes, getCompanyLocations } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import Spinner from "../../shared/spinner";
import SuspensionDetail from "./SuspensionDetail";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as url from '../../../helpers/url_helper';

const SuspensionView = (props) => {
  const [canvasPlacement, setCanvasPlacement] = useState("start");
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [transferData, setTransferData] = useState({
      employeeId: 0, orderDate: "", effectiveDate: "", notes: "", reason: ""
  });
  const [educationList, setEducationList] = useState();  
  const [transferTypeList, setTransferTypeList] = useState();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [submitted, setFormSubmit] = useState(false);
  const [dataSuccess, setDataSuccess] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const params = useParams();

    const loadData = () => {
        getEmployeeSuspension(props.employeeId).then(res => {
            console.log(res.data);
            if (res.data) {
                setEducationList(res.data)
            }
        });
    }

  useEffect(() => {
      setTransferData({ ...transferData, 'employeeId': props.employeeId });

      loadData();

  }, [submitted]);

  const handleChange = event => {        
      setTransferData({ ...transferData, [event.target.name]: event.target.value });      
  };


    const submitTransferDetails = (event) => {
    event.preventDefault();
      let formErrors = {};
      if (!transferData.reason) {
          formErrors.reason = 'Enter the reason';
      }
      if (!transferData.orderDate) {
          formErrors.orderDate = 'Enter order date';
      }
      if (!transferData.effectiveDate) {
          formErrors.effectiveDate = 'Enter effective date';
      }

      setErrors(formErrors);
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0) {
        setIsLoading(true);

        console.log(transferData.transferTypeId);

        const formData = new FormData();

        formData.append("reason", transferData.reason);
        formData.append("effectiveDate", transferData.effectiveDate);
        formData.append("orderDate", transferData.orderDate);
        formData.append("employeeId", transferData.employeeId);        
        formData.append("notes", transferData.notes);

        if (selectedFiles.length > 0) {
            selectedFiles.forEach((file, index) => {
                console.log(file);
                formData.append("documents", file);
            });
        } else {
            console.log("Inside Else");
            formData.append("documents", []);
        }

        const res = addEmployeeTransfer(formData);
        console.log("res after await");
        console.log(res);
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

  const addEmployeeTransfer = body => {
    let userdata = localStorage.getItem("authUser");
    userdata = JSON.parse(userdata);

    var token = userdata.token;
    // const boundary = "----WebKitFormBoundary7MA4YWxkTrZu0gW";
    let requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token,
            // 'Content-Type': `multipart/form-data; boundary=${boundary}`,
            apiKey: 'key123',
        },
        body,
    };
    return fetch(url.BASE_URL + '/Employee/addsuspension', requestOptions)
        .then(response => {
            console.log({ response });
            response.json().then(res => {
                console.log(res);

                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    setTransferData({
                        employeeId: 0, orderDate: "", effectiveDate: "", notes: "", reason: ""
                    });

                    console.log("Setting canvas to false");
                    setCanvasOpen(false);

                    getEmployeeSuspension(props.employeeId).then(resp => {
                        console.log(resp.data);
                        if (resp.data) {
                            setEducationList(resp.data)
                        }
                    });

                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();
            });
        })
        .catch(err => {
            console.log({ err });
        });
  };


  const addEducation = () => {
    setCanvasPlacement("end");
    setCanvasOpen(!canvasOpen);
  };

    const onClose = () => {
        addEducation();
        loadData();
    }

    const handleAcceptedFiles = (files) => {
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    const viewDocument = (docId, docName) => {
        console.log("view document: " + docId);
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        fetch(url.BASE_URL + '/Employee/downloadsuspensiondocument/' + docId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + userdata.token,
                'apikey': 'key123',
            },
        }).then((response) => response.blob())
            .then((blob) => {
                console.log(blob);
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',docName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const docsLink = props => {
        console.log("Inside docsLink");
        console.log(props.employeeIncrementDocuments);
        
        const links = [];
        
        props.employeeSuspensionDocuments ? props.employeeSuspensionDocuments.map((doc) => {
            links.push(
                <button onClick={() => viewDocument(doc.id, doc.fileName)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                    {doc.fileName}
                </button>);
            links.push(<br></br>);
        }) : links.push(<label>None</label>)
        
        return (
            <div>{links}</div>
        )
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

  return (
    <>

          <Row className="m-2">
              <Col xl="9" className="mt-2" md="9" sm="9">
                  <CardTitle tag="h4">Suspension : {props.employeeData?.fullName}</CardTitle>
              </Col>
              <Col xl="3" md="3" sm="4">                  
                  <button type="button" onClick={addEducation} className="btn btn-success waves-effect waves-light">
                      <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                      Add Order
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button type="button" onClick={loadData} className="btn btn-warning waves-effect waves-light">
                      <i className="bx bx-revision font-size-16 align-middle me-2"></i>{" "}
                      Refresh
                  </button>
              </Col>
          </Row>
      

        {!educationList ?
          <div className='text-center mt-4'>
            <Spinner />
          </div>
          :
          <GridComponent dataSource={educationList} allowPaging={true} allowTextWrap={true} pageSettings={{ pageCount: 5 }} statelessTemplates={['directiveTemplates']}>
            <ColumnsDirective>                                              
                <ColumnDirective field="orderDate" type="date" format="dd-MM-yyyy" headerText="Order Date" width="150" />
                <ColumnDirective field="effectiveDate" type="date" format="dd-MM-yyyy" headerText="Effective Date" width="150" />
                <ColumnDirective field="reason" headerText="Reason" width="150" />
                <ColumnDirective field="notes" headerText="Notes" width="150"></ColumnDirective>
                <ColumnDirective template={docsLink} headerText="Documents" allowTextWrap={true} width="150"></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Toolbar, Page]} />
          </GridComponent>
        }
      

      <Offcanvas backdrop="static" className="w-30" direction={canvasPlacement} isOpen={canvasOpen}
        toggle={addEducation}>
        <OffcanvasHeader toggle={addEducation}>
          <Row>
            <Col sm="12">
              <b>Suspension Details</b>
            </Col>
          </Row>
        </OffcanvasHeader>
        <hr />
        <OffcanvasBody>
                  <SuspensionDetail employeeId={props.employeeId} onClose={onClose} />
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default SuspensionView;
