import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, } from "reactstrap";
import { getEmployeeTransfer, getTransferTypes, getFinancialYears, getEmployeeList, getITDeclarationTypes } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import Spinner from "../../shared/spinner";
import EmploymentHistory from "./EmploymentHistory";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as url from '../../../helpers/url_helper';
import { getData, postData } from "../../../helpers/url-request";
import { post } from "../../../helpers/api_helper";

const ITSlabDetail = (props) => {
  const [canvasPlacement, setCanvasPlacement] = useState("start");
  const [isReadonly, setReadonly] = useState(false);
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [formData, setFormData] = useState({
      employeeId: 0, orderDate: "", effectiveDate: "", notes: "", locationId: 0, department: "", transferTypeId: 0
  });
  const [employeeList, setEmployees] = useState();
  const [locationList, setLocationList] = useState();
  const [transferTypeList, setTransferTypeList] = useState();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [submitted, setFormSubmit] = useState(false);
  const [dataSuccess, setDataSuccess] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const params = useParams();

  useEffect(() => {
      setFormData({ ...formData, 'employeeId': props.employeeId });

      getEmployeeList().then(res => {
           console.log(res.data);
           let employees = [{ "id": 0, "fullName": "Select Employee" }]
           res.data.forEach(element => {
               employees.push(element);
           });
           setEmployees(employees);

          if (props.employeeId && props.employeeId > 0) {
              setReadonly(true);
              formData.employeeId = props.employeeId;
          }
      });

      getData("/payrollit/slabs").then(res => {
          console.log(res.data);
          if (res.data) {
              formData.transferTypeId = res.data[0].id;
              let transfers = [{ "id": 0, "name": "Select IT Slab" }]
              res.data.forEach(element => {
                  transfers.push(element);
              });

              setTransferTypeList(transfers);
          }
      });

      getFinancialYears().then(res => {
          console.log(res.data);
          if (res.data) {
              formData.locationId = res.data[0].id;
              let locations = [{ "id": 0, "name": "Select Financial Year" }]
              res.data.forEach(element => {
                  locations.push(element);
              });

              setLocationList(locations);
          }
      });

  }, [submitted]);

    const handleChange = event => {
        if (event.target.name == "transferTypeId")
            setFormData({ ...formData, [event.target.name]: Number(event.target.value) });
        else
            setFormData({ ...formData, [event.target.name]: event.target.value });

        console.log(formData.transferTypeId);
  };


    const submitTransferDetails = (event) => {
        event.preventDefault();
        let formErrors = {};
        if (!transferData.transferTypeId) {
            formErrors.transferTypeId = 'Select the Transfer Type';
        }
        if (!transferData.orderDate) {
            formErrors.orderDate = 'Enter order date';
        }
        if (!transferData.effectiveDate) {
            formErrors.effectiveDate = 'Enter effective date';
        }

        setErrors(formErrors);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(false);
            postData("/attendance/detailreport", formData).then(res => {

            });
        }
    }

  const addEducation = () => {
      if (props.onClose) {
          props.onClose();
      }
  };
    
  return (
     <>
          <FormGroup>
              <Row>
                  <Col xl="12" md="12" sm="12">
                      <Label className="form-label" for="InputHelp">Employee</Label>
                      <Input type="select" value={Number(formData.employeeId)} onChange={handleChange} name='employeeId' disabled={isReadonly}>
                          {employeeList &&
                              employeeList.map((add) => {
                                  return <option value={Number(add.id)} key={add.id}>{add.fullName}</option>
                              })}
                      </Input>
                      <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                  </Col>
              </Row>
              <Row>
                  <Col xl="12" md="12" sm="12">
                      <Label className="form-label" for="InputHelp">Financial Year</Label>
                      <Input type="select" className="form-control" name="locationId"
                          value={Number(formData.locationId)} onChange={handleChange} placeholder="Enter city">
                          {locationList &&
                              locationList.map((add) => {
                                  return <option value={Number(add.id)} key={add.id}>{add.name}</option>
                              })}
                      </Input>
                      <p style={{ color: "Red" }}>{errors.locationId && <span>{errors.locationId}</span>}</p>
                  </Col>
                  <Col xl="12" md="12" sm="12">
                                  <Label className="form-label" for="InputHelp">IT Declaration Type</Label>
                      <Input type="select" value={Number(formData.transferTypeId)} onChange={handleChange} name='transferTypeId'>
                            {transferTypeList &&
                                transferTypeList.map((add) => {
                                    return <option value={Number(add.id)} key={add.id}>{add.name}</option>
                                })}
                                  </Input>
                        <p style={{ color: "Red" }}>{errors.transferTypeId && <span>{errors.transferTypeId}</span>}</p>
                  </Col>
                </Row>

                <Row>                                                    
                    <Col xl="12" md="12" sm="12">
                      <Label className="form-label" for="InputHelp">Notes</Label>
                      <Input type="textarea" className="form-control" name="notes" style={{ height:"200px" }}
                          value={formData.notes} onChange={handleChange} placeholder="Notes"></Input>
                        <p style={{ color: "Red" }}>{errors.notes && <span>{errors.notes}</span>}</p>
                    </Col>
                </Row>
          </FormGroup>

          <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
            {dataSuccess === 'added' && <SuccessAlert />}
            {dataSuccess === 'failed' && <FailureAlert />}
          </div>

          <div className="offcanvas-footer">
                      {isLoading ? <LoadingSpinner /> : <Button color="primary"
                          onClick={submitTransferDetails} className="btn btn-sm" disabled={isLoading}
                          type="submit">Submit</Button>}&nbsp;
            <button onClick={addEducation} className="btn btn-dark btn-sm">
              Close
            </button>
          </div>        
    </>
  );
};

export default ITSlabDetail;
