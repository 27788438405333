import React, { Component, useState, useEffect } from "react"
import ReactEcharts from "echarts-for-react"
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";
import { getData } from "../../helpers/url-request"

const EmployeeGroupCount = () => {

    const [chartData, setChartData] = useState(0);

    useEffect(() => {

        getData("/Employee/groupcount/0").then(response => {
            console.log(response.data);
            if (response.data) {
                setChartData(response.data);
            }
        });
    }, []);

    const getOption = () => {
        return {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            legend: {},
            yAxis: [
                {
                    type: 'category',
                    data: [""],
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#858d98'
                        },
                        label: {
                            show: false,
                        },
                    },                    
                }
            ],
            xAxis: [{
                type: 'value'
            }],
            color: ['#2ab57d', '#ffbf53', '#5156be', '#fd625e'],
            series: [
                {
                    name: "Group A",
                    type: "bar",
                    data: [chartData?.GroupA],
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                },
                {
                    name: "Group B",
                    type: "bar",
                    data: [chartData?.GroupB],
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                }, {
                    name: "Group C",
                    type: "bar",
                    data: [chartData?.GroupC],
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                }, {
                    name: "Group D",
                    type: "bar",
                    data: [chartData?.GroupD],
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                },
            ],
        }
    }
    
    return (
        <React.Fragment>
            <Card>
                <CardBody style={{ padding: "0px !important" }}>                                                       
                    <ReactEcharts option={getOption()} />
                    Employee Group/Grade                           
                </CardBody>
            </Card>                
        </React.Fragment>
    )    
}
export default EmployeeGroupCount
