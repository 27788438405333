import axios from "axios";
import { useEffect, useState } from "react";
import { getMasterEmployeeTypes } from "./api_helper";
import { getData, postData } from "./url-request";
import * as url from "./url_helper";

let userdata = localStorage.getItem("authUser");
userdata = JSON.parse(userdata);
// console.log("Backend Helper " + JSON.stringify(userdata));
const headers = new Headers();
headers.append("Content-Type", "application/json");
// if (userdata) {
//   headers.append("Authorization", "Bearer " + userdata.token);
// }
headers.append("ApiKey", "key123");

export const setDefHeaders = (val) => {
  headers.delete("Authorization")
  headers.append("Authorization", "Bearer " + val.token);
}

if (userdata) {
  const token = userdata.token;
  const tokenExpirationDate = userdata.tokenExpirationTime;
  if (token && new Date(tokenExpirationDate) < new Date()) {
    // console.log(new Date(tokenExpirationDate));
  }
}

const getHeaders = () => {
  console.log("Inside getHeaders");
  let userdata = localStorage.getItem("authUser");
  userdata = JSON.parse(userdata);  
  const _headers = new Headers();
  _headers.append("Content-Type", "application/json");
  if (userdata) {
    _headers.append("Authorization", "Bearer " + userdata.token);
  }
  _headers.append("ApiKey", "key123");
  return _headers;
}

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const getLocalStorage = () => {
  let userdata = localStorage.getItem("authUser");
  userdata = JSON.parse(userdata);
  return userdata;
}

// get Employee Type Method
//const getEmployeeTypes = async () => await getMasterEmployeeTypes(url.BASE_URL + url.GET_EMPLOYEE_TYPE);

//EMPLOYEE API 

const getEmployeeTypes = async () => {
  const response = await getData(url.GET_EMPLOYEE_TYPE, {
    headers: headers,
  });
  return response;
};

export const createEmployeeType = async (data) => {
  const response = await postData(url.CREATE_EMPLOYEE_TYPE, data);
  return response;
};

const setDefaultHeaders = () => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'ApiKey': 'key123'
    // Add more default headers if needed
  };

  return defaultHeaders;

}

export const getEmployeeCount = async () => {
    console.log("getEmployeeCount");
    return await getData("/Employee/count/0");
};

const createEmployee = async (employeeData) => {
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(employeeData),
  };

  const response = await postData(
    url.CREATE_EMPLOYEE,
    employeeData
  );
  const data = await response;
  return data;
};

// Update Employee API
export const updateEmployeeDetails = async (updateEmpData) => {
  return await postData(url.UPDATE_EMPLOYEE, updateEmpData);
}

const getEmployeeList = async () => {
  return await postData(url.SEARCH_EMPLOYEE, '{}').catch((err) => {
    console.log(err);
  });
};

const getEmployeeById = async (empid) => {
  return await getData(url.EMPLOYEE_BY_ID + '/' + empid);
};

const getEmployeeID = async (empid) => {
  const response = await getData(url.EMPLOYEE_BY_ID + '/' + empid,);
  return response;
};

export const getEmployeeReportingList = async () => {
  const response = await getData(url.EMPLOYEE_ReportsTo, {
    headers: headers,
  });
  return response;
};

export const getEmployeeWorkGrades = async () => {
  const response = await getData(url.EMPLOYEE_GRADES, {
    headers: headers,
  });
  return response;
};

export const getEmployeeStatuses = async () => {
    const response = await getData(url.EMPLOYEE_STATUSES, {
        headers: headers,
    });
    return response;
};


export const getEmployeeGroups = async () => {
    const response = await getData(url.EMPLOYEE_GROUPS, {
        headers: headers,
    });
    return response;
};

export const getEmployeeGrades = async () => {
  const response = await getData(url.EMPLOYEE_GRADES, {
    headers: headers,
  });
  return response;
};

export const createEmployeeGrades = async (gradeData) => {
  return await postData(url.ADD_EMPLOYEE_GRADES, gradeData);
};

export const addupdatePayscaleDetail = async (payscaleDetail) => {
    return await postData(url.ADDUPDATE_PAYSCALEDETAIL, payscaleDetail);
};

const getExpenseTypes = async () => {
  const response = await getData(url.GET_EXPENSE_TYPE, {
    headers: headers,
  });
  return response;
};

const createExpenseTypes = async (expenseData) => {
  console.log("Inside Expense Post: " + JSON.stringify(expenseData));

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(expenseData),
  };

  const response = await postData(
    url.POST_EXPENSE_TYPE,
    expenseData
  );
  const data = await response;
  console.log(data);
  return data;

  // const response = await fetch(url.BASE_URL + url.POST_EXPENSE_TYPE, { headers: headers })
  // return response;
};

const getEmpAllowances = async (empId) => {
  return await getData(url.GET_EMP_ALLOWANCES + "/" + empId);
  // const response = await fetch(url.BASE_URL + url.GET_EMP_ALLOWANCES + "/" + empId, {
  //   headers: headers,
  // });
  // return response;
};

const getEmpDeductions = async (empId) => {
  return await getData(url.GET_EMP_DEDUCTIONS + "/" + empId);
  // const response = await fetch(url.BASE_URL + url.GET_EMP_DEDUCTIONS + "/" + empId, {
  //   headers: headers,
  // });
  // return response;
};

const addEmpAllowance = async (allowanceData) => {
  return await postData(url.ADD_EMP_ALLOWANCES, allowanceData);
}

const addEmpDeduction = async (deductData) => {
  return await postData(url.ADD_EMP_DEDUCTIONS, deductData);
}


// Work schedule API 
const getWorkSchedules = async () => {
  const response = await getData(url.GET_WORK_SCHEDULE, {
    headers: headers,
  });
  return response;
};

// Location API Request

const getCompanyLocations = async () => {
    var _headers = getHeaders();
    console.log(_headers);
    const response = await getData(url.GET_LOCATIONS, {
        headers: getHeaders(),
    });
    return response;
};

const getUserLocations = async () => {
    var _headers = getHeaders();
    console.log(_headers);
    const response = await getData(url.GET_USER_LOCATIONS, {
        headers: getHeaders(),
    });
    return response;
};


const getCompanyPayperiods = async () => {
    var _headers = getHeaders();
    const response = await getData(url.GET_COMPANYPAYPERIODS, {
        headers: getHeaders(),
    });
    return response;
}

const getCompanyPayscales = async () => {
    var _headers = getHeaders();
    const response = await getData(url.GET_COMPANYPAYSCALES, {
        headers: getHeaders(),
    });
    return response;
};


const getCompanyPayscaleDetails = async (id) => {
    var _headers = getHeaders();
    const response = await getData(url.GET_COMPANYPAYSCALEDETAIL + "/" + id, {
        headers: getHeaders(),
    });
    return response;
};

export const getCompanyLocationById = async (Id) => {
  const response = await getData(url.GET_LOCATION + Id, {
    headers: headers,
  });
  return response;
};

const createLocation = async (locationData) => {
  return await postData(url.CREATE_LOCATION, locationData);
}

export const updateLocation = async (locationData) => {
  return await postData(url.UPDATE_LOCATION, locationData);
}


// Department API Request

const getDepartments = async () => {
  const response = await getData(url.GET_DEPARTMENTS, {
    headers: headers,
  });
  return response;
};

const getDepartmentTypes = async () => {
  return await getData(url.GET_DEPARTMENTS);
};

const createDepartments = async (departmentData) => {
  console.log(
    "Inside createDepartments Post: " + JSON.stringify(departmentData)
  );

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(departmentData),
  };

  const response = await postData(
    url.POST_DEPARTMENTS,
    departmentData
  );
  const data = await response;
  console.log(data);
  return data;
};

//Consultants

const getConsultantList = async () => {
  return await getData(url.GET_CONSULTANT_LIST);
};
const CreateConsultants = async (consultantData) => {
  console.log(
    "Inside CreateConsultants Post: " + JSON.stringify(consultantData)
  );

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(consultantData),
  };

  const response = await postData(
    url.POST_CONSULTANT,
    consultantData
  );
  const data = await response;
  console.log(data);
  return data;

};

export const getConsultantPayments = async () => {
  return await getData(url.GET_CONSULTANT_PAYMENTS);
}

export const postConsultantPayment = async (paymentData) => {
  return await postData(url.ADD_CONSULTANT_PAYMENT, paymentData);
}

//Vendors 

const getAllContractors = async () => {
  return await getData(url.GET_CONTRACTOR_LIST);
};

const CreateVendors = async (vendorData) => {
  // console.log(
  //   "Inside CreateVendors Post: " + JSON.stringify(vendorData)
  // );

  return await postData(url.POST_CONTRACTOR, vendorData)
}

export const getVendorsPayments = async () => {
  return await getData(url.GET_CONTRACTOR_PAYMENTS);
}

export const postVendorPayment = async (paymentData) => {
  return await postData(url.ADD_CONTRACTOR_PAYMENT, paymentData);
}

//Master API Requests

const getMaritalStatus = async () => {
  return await getData(url.GET_MARITAL_STATUS);
};

const getGenderTypes = async () => {
  return await getData(url.GET_GENDER_TYPES);
};

const getMasterDistricts = async () => {
  return await getData(url.GET_MASTER_DISTRICTS);
};

const getLeaveTypes = async () => {
  return await getData(url.GET_LEAVE_TYPES);
}

const getConsultantTypes = async () => {
  return await getData(url.GET_CONSULTANT_TYPES);
}

const getContractorsTypes = async () => {
  return await getData(url.GET_CONTRACTOR_TYPES);
}

export const getDocumentTypes = async () => {
    return await getData(url.GET_DOCUMENT_TYPES);
}

export const getFinancialYears = async () => {
    return await getData(url.GET_FINANCIAL_YEAR);
}

const getAllowancesTypes = async () => {
  return await getData(url.GET_MASTER_ALLOWANCE_TYPES);
}

const getEditableAllowancesTypes = async () => {
    return await getData(url.GET_EDITABLE_ALLOWANCE_TYPES);
}

const getCompanyAllowances = async () => {
    return await getData(url.GET_COMPANYALLOWCANCES);
}

const getDeductionTypes = async () => {
  return await getData(url.GET_MASTER_DEDUCTION_TYPES);
}

const getEditableDeductionTypes = async () => {
    return await getData(url.GET_EDITABLE_DEDUCTION_TYPES);
}

const getInsuranceDeductionTypes = async () => {
    return await getData(url.GET_MASTER_DEDUCTION_INSURANCE_TYPES);
}

export const getITDeclarationTypes = async () => {
    return await getData(url.GET_ITDECLARATION_TYPES);
}

// Policy API Request
const getLeavePolicies = async () => {
  return await getData(url.GET_LEAVE_POLICY);
};

const getHolidayPolicies = async () => {
  return await getData(url.GET_HOLIDAY_POLICY);
};

const getExpensePolicies = async () => {
  return await getData(url.GET_EXPENSE_POLICY);
};

export const getShiftTypes = async () => {
  return await getData(url.GET_SHIFT_TYPES);
};

export const getTransferTypes = async () => {
    return await getData(url.GET_TRANSFER_TYPES);
};

export const getIncrementTypes = async () => {
    return await getData(url.GET_INCREMENT_TYPES);
};

export const getPromotionTypes = async () => {
    return await getData(url.GET_PROMOTION_TYPES);
};

const getAccountGroups = async (empId) => {
    return await getData(url.GET_ACCOUNT_GROUPS);
}


// Bank API
const getEmployeeBanks = async (empId) => {
  return await getData(url.GET_EMPLOYEE_BANKS + "/" + empId);
};

const addEmployeeBank = async (bankData) => {
  return await postData(url.POST_EMPLOYEE_BANK, bankData)
}

//Address API
const getEmployeeAddress = async (empId) => {
  return await getData(url.GET_EMPLOYEE_ADDRESS + "/" + empId);
}

const getAddressTypes = async (empId) => {
  return await getData(url.GET_ADDRESS_TYPES);
}

const addEmployeeAddress = async (address) => {
  return await postData(url.ADD_EMPLOYEE_ADDRESS, address)
}

//Education API
const getEmployeeEducation = async (empId) => {
  return await getData(url.GET_EMPLOYEE_EDUCATION + "/" + empId);
}

const addEmployeeEducation = async (education) => {
  return await postData(url.ADD_EMPLOYEE_EDUCATION, education)
}

//Transfer API
export const getEmployeeTransferList = async () => {
    return await getData(url.GET_EMPLOYEE_TRANSFER);
}

const getEmployeeTransfer = async (empId) => {
    return await getData(url.GET_EMPLOYEE_TRANSFER + "/" + empId);
}

const addEmployeeTransfer = async (transfer) => {
    return await postData(url.ADD_EMPLOYEE_TRANSFER, transfer)
}

//Inusrance API
export const getEmployeeInsuranceList = async (empId) => {
    return await getData(url.GET_EMPLOYEE_INSURANCE);
}

const getEmployeeInsurances = async (empId) => {
    return await getData(url.GET_EMPLOYEE_INSURANCE + "/" + empId);
}

//documents API
export const getEmployeeDocumentList = async (empId) => {
    return await getData(url.GET_EMPLOYEE_DOCUMENT);
}

const getEmployeeDocuments = async (empId) => {
    return await getData(url.GET_EMPLOYEE_DOCUMENT + "/" + empId);
}


//Increment API
export const getEmployeeIncrementList = async () => {
    return await getData(url.GET_EMPLOYEE_INCREMENT);
}

const getEmployeeIncrement = async (empId) => {
    return await getData(url.GET_EMPLOYEE_INCREMENT + "/" + empId);
}

const addEmployeeIncrement = async (increment) => {
    return await postData(url.ADD_EMPLOYEE_INCREMENT, increment)
}

//Promotion API
export const getEmployeePromotionList = async () => {
    return await getData(url.GET_EMPLOYEE_PROMOTION);
}

const getEmployeePromotion = async (empId) => {
    return await getData(url.GET_EMPLOYEE_PROMOTION + "/" + empId);
}

const addEmployeePromotion = async (promotion) => {
    return await postData(url.ADD_EMPLOYEE_PROMOTION, promotion)
}

//Suspension API
export const getEmployeeSuspensionList = async (empId) => {
    return await getData(url.GET_EMPLOYEE_SUSPENSION);
}

const getEmployeeSuspension = async (empId) => {
    return await getData(url.GET_EMPLOYEE_SUSPENSION + "/" + empId);
}

const addEmployeeSuspension = async (suspension) => {
    return await postData(url.ADD_EMPLOYEE_SUSPENSION, suspension)
}

//Employement History API
const getEmployeeHistory = async (empId) => {
  return await getData(url.GET_EMPLOYEE_HISTORY + "/" + empId);
}
const addEmployeeHistory = async (empHistory) => {
  return await postData(url.ADD_EMPLOYEE_HISTORY, empHistory)
}

//Employement Dependent API
const getEmployeeDependents = async (empId) => {
  return await getData(url.GET_EMPLOYEE_DEPENDENT + "/" + empId);
}
const addEmployeeDependent = async (empDependent) => {
  return await postData(url.ADD_EMPLOYEE_DEPENDENT, empDependent)
}

//Employement Compensation API
// const getEmployeeCompensation = async (empId) => {
//   return await getData(url.GET_EMPLOYEE_COMPENSATION + "/" + empId);
// }

const getEmployeeCompensation = async (empid) => {
  const response = await getData(url.GET_EMPLOYEE_COMPENSATION + '/' + empid, {
    headers: headers,
  });
  return response;
};

const getEmployeeCompensations = async () => {
    const response = await getData(url.GET_EMPLOYEE_COMPENSATION, {
        headers: headers,
    });
    return response;
};

const getEmployeeCompensationHistory = async (empid) => {
    const response = await getData(url.GET_EMPLOYEE_COMPENSATIONHISTORY + '/' + empid, {
        headers: headers,
    });
    return response;
};

const addEmployeeCompensation = async (empCompensation) => {
  return await postData(url.ADD_EMPLOYEE_COMPENSATION, empCompensation)
}

//Employee Leave Request
const addEmployeeLeaveRequest = async (leaveRequest) => {
  return await postData(url.ADD_EMPLOYEE_LEAVEREQUEST, leaveRequest)
}

const getEmployeeLeaveRequest = async (searchRequest) => {
  return await postData(url.SEARCH_EMPLOYEE_LEAVEREQUEST, searchRequest)
}

const getLeaveDetails = async (leaveId) => {
    return await getData(url.GET_LEAVE_DETAILS + "/" + leaveId);
}

const getEmployeeLeaves = async () => {
  return await getData(url.SEARCH_EMPLOYEE_LEAVES)
}

export const getEmployeeLeaveDetails = async (employeeId) => {
    return await getData(url.EMPLOYEE_LEAVES + "/" + employeeId)
}

export const getEmployeeLeaveAllocations = async (employeeId) => {
    return await getData(url.EMPLOYEE_LEAVEALLOCATIONS + "/" + employeeId)
}

export const getEmployeeLeaveRequests = async (employeeId) => {
    return await getData(url.EMPLOYEE_LEAVEREQUESTS + "/" + employeeId)
}

const approveEmployeeLeaveRequest = async (leaveRequest) => {
    return await postData(url.APPROVE_EMPLOYEE_LEAVEREQUEST, leaveRequest)
}

const rejectEmployeeLeaveRequest = async (leaveRequest) => {
    return await postData(url.REJECT_EMPLOYEE_LEAVEREQUEST, leaveRequest)
}

export const getEmployeeLeaveEncash = async (searchRequest) => {
    return await postData(url.SEARCH_EMPLOYEE_LEAVEENCASH, searchRequest)
}

//Expense 

const getEmployeeExpenseRequest = async () => {
  return await postData(url.SEARCH_EXPENSE, {})
}

const addExpenseRequest = async (expenseData) => {
  // console.log(
  //   "Inside addExpenseRequest Post: " + JSON.stringify(expenseData)
  // );

  const headers = new Headers();
  const boundary = "---011000010111000001101001";
  headers.append("Content-Type", `multipart/form-data; boundary=${boundary}`);
  if (userdata) {
    headers.append("Authorization", "Bearer " + userdata.token);
  }
  headers.append("ApiKey", "key123");

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: expenseData,
  };

  const response = await fetch(
    url.BASE_URL + url.ADD_EXPENSE,
    requestOptions
  );
  const data = await response;
  console.log(data);
  return data;
  // return await postData(url.ADD_EXPENSE, expenseData)
}

export const createExpense = async body => {
  var token = userdata.token;
  // const boundary = "----WebKitFormBoundary7MA4YWxkTrZu0gW";
  let requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      // 'Content-Type': `multipart/form-data; boundary=${boundary}`,
      apiKey: 'key123',
    },
    body,
  };
  return fetch(url.BASE_URL + url.ADD_EXPENSE, requestOptions)
    .then(response => {
      console.log({ response });
      return response;
    })
    .catch(err => {
      console.log({ err });
    });
};

//Login request
const postLoginRequest = async (data) => {
  return await postData(url.LOGIN_URL, data)
};

export const postOTPRequest = async (data) => {
    return await postData(url.OTP_URL, data)
};

//workflow
const getWorkflows = async () => {
  const response = await getData(url.GET_WORKFLOW_WORKFLOWS, {
    headers: headers,
  });
  return response;
};

// Time Entry
export const createTimeEntry = async (timeEntry) => {
  return await postData(url.TIME_ENTRY, timeEntry);
}

export const getManulAttendanceList = async () => {
  return await getData(url.MANUAL_TIME_ATTENDANCE);
};

//users
const getUsersList = async () => {
  return await getData("/User/users");
};

export const addUserDetail = async (userdata) => {
    return await postData("/User/adduser", userdata);
}

export const updateUserDetail = async(userdata) =>{
  return await postData("/User/updateuser",userdata);
}

export const updateUserCredential = async (data) => {
    return await postData("/User/updatecredentials", data);
}

export const getuserById = async (id) => {
  console.log(id);
  return await getData("/User/userdetail/" + id);
};

const getTaskTypeList = async () => {
  return await getData("/task/tasktypes");
};

const searchTask = async (searchData) => {
    return await postData("/task/search", {})
}

const getTaskDetail = async (taskId) => {
    return await getData("/task/taskdetail/" + taskId)
}

const getTaskComments = async (taskId) => {
    return await getData("/task/taskdetail/comments/" + taskId)
}

const getTaskDocuments = async (taskId) => {
    return await getData("/task/taskdetail/documents/" + taskId)
}

const getTaskApprovers = async (taskId) => {
    return await getData("/task/approvelist/" + taskId)
}

const getTaskActivityList = async (taskId) => {
    return await getData("/task/activitylist/" + taskId)
}

//Application 
export const getApplicationRoles = async () => {
  return await getData(url.GET_ROLES)
}


const getLoanTypes = async () => {
    const response = await getData(url.GET_LOAN_TYPES, {
        headers: headers,
    });
    return response;
};


export {
    postLoginRequest, getLoanTypes,
  getLoggedInUser, isUserAuthenticated, getLocalStorage,
  getEmployeeTypes, getExpenseTypes, createExpenseTypes,
  getDepartments, createDepartments, getDepartmentTypes,
  CreateVendors, getAllContractors, getConsultantTypes,
  getConsultantList, CreateConsultants, getWorkSchedules, getCompanyLocations,
  createLocation, getMasterDistricts, getContractorsTypes,
  getMaritalStatus, getGenderTypes, getLeaveTypes,
  getLeavePolicies, getHolidayPolicies, getExpensePolicies,
  createEmployee, getEmployeeList, getEmployeeById, getEmployeeID,
  getEmpAllowances, getEmpDeductions, addEmpAllowance, addEmpDeduction,
  getEmployeeBanks, addEmployeeBank, getAllowancesTypes, getDeductionTypes,
  getAddressTypes, getEmployeeAddress, addEmployeeAddress,
  getEmployeeEducation, addEmployeeEducation,
  getEmployeeHistory, addEmployeeHistory,
  getEmployeeDependents, addEmployeeDependent,
  getEmployeeCompensation, addEmployeeCompensation,
    addEmployeeLeaveRequest, getEmployeeLeaveRequest, addExpenseRequest, getEmployeeExpenseRequest,
    getWorkflows, getEmployeeLeaves, getUsersList, getTaskTypeList, searchTask, getLeaveDetails, approveEmployeeLeaveRequest,
    getCompanyPayscales, rejectEmployeeLeaveRequest, getUserLocations, getCompanyPayscaleDetails, getCompanyAllowances, getEmployeeTransfer,
    addEmployeeTransfer, getEmployeeIncrement, addEmployeeIncrement, getEmployeePromotion, addEmployeePromotion, getEditableAllowancesTypes, getCompanyPayperiods,
    getTaskDetail, getTaskComments, getTaskDocuments, getTaskApprovers, getEmployeeDocuments, getInsuranceDeductionTypes, getEmployeeInsurances, getEditableDeductionTypes,
    getTaskActivityList, getEmployeeCompensationHistory, getEmployeeSuspension, addEmployeeSuspension, getEmployeeCompensations, getAccountGroups
};
