import { Fragment, useEffect, useRef } from "react"

import { Alert, Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row, UncontrolledAlert } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody, TabContent, TabPane, Nav, NavItem, NavLink, FormGroup } from 'reactstrap'
import { useState } from "react";
import React from 'react';
import classnames from "classnames";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Filter, Sort, ExcelExport, PdfExport, Resize }
    from "@syncfusion/ej2-react-grids";

import { getApplicationRoles, getEmployeeList, getuserById, getUsersList, updateUserDetail, addUserDetail, getLoggedInUser, getCompanyLocations, updateUserCredential } from "../../../helpers/backend_helper";
import LoadingSpinner from "../../shared/Loading";
import Spinner from "../../shared/spinner";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { showLoading } from 'react-global-loading';

const SettingsUsers = () => {
    let gridInstance;
    const [isReadOnly, setReadOnly] = useState(false);
    const [activeTab, setactiveTab] = useState(1);
    const [userList, setuserListData] = useState();
    const [employeeList, setEmployeeList] = useState([]);
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [isLoading, setIsLoading] = useState(false);
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [roles, setRoles] = useState();
    const [locations, setLocations] = useState();
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: 0
    });
    const [errors, setErrors] = useState({});

    const [editcanvasPlacement, setEditCanvasPlacement] = useState('start')
    const [editcanvasOpen, setEditCanvasOpen] = useState(false);
    const [userData, setUserData] = useState({
        userId: 0, fullName: '', loginName: '', roleId: '', disableUser: 0, blockUser: 0, changePassword: 0, locationId: '',
        employeeId: '', newPassword: '', confPassword: ''
    })

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end');
        if (!canvasOpen) {
            setUserData({
                userId: 0, fullName: '', loginName: '', roleId: '', disableUser: 0, blockUser: 0, changePassword: 0, locationId: '',
                employeeId: '', newPassword: '', confPassword: ''
            });
        }
        setCanvasOpen(!canvasOpen);
    }

    const editCanvasEnd = () => {
        //setEditCanvasPlacement('end')
        setEditCanvasOpen(!editcanvasOpen)
    }

    useEffect(() => {
        console.log("inside useEffect");

        showLoading(true);

        getEmployeeList().then(res => {
            console.log(res);
            if (res.status === true) {
                setEmployeeList(res.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        getCompanyLocations().then(res => {
            console.log(res);
            if (res.status === true) {
                setLocations(res.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        getApplicationRoles().then(res => {
            console.log(res);
            if (res.status === true) {
                setRoles(res.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        fetchUsers();        

    }, []);

    const fetchUsers = () => {
        getUsersList().then(res => {
            console.log(res);
            if (res.status === true) {
                setuserListData(res.data);
            }
            showLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' },
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' }
    ];

    const handleChange = event => {

        if (event.target.name === "status") {
            let statusSet = event.target.checked ? 1 : 0;
            setUserData({ ...userData, [event.target.name]: statusSet });
        }
        else if (event.target.name === 'roleId') {
            setUserData({ ...userData, 'roleId': Number(event.target.value) });           
        }
        else if (event.target.name === 'disableUser') {
            if(event.target.checked)
                setUserData({ ...userData, 'disableUser': 1 });
            else
                setUserData({ ...userData, 'disableUser': 0 });
        }
        else if (event.target.name === 'blockUser') {
            if (event.target.checked)
                setUserData({ ...userData, 'blockUser': 1 });
            else
                setUserData({ ...userData, 'blockUser': 0 });
        }
        else if (event.target.name === 'changePassword') {            
            if (event.target.checked) {
                setUserData({ ...userData, 'changePassword': 0 });
            } else {
                setUserData({ ...userData, 'changePassword': 1 });
            }
        }
        else if (event.target.name === 'employeeId') {
            if (event.target.value > 0) {
                setUserData({
                    ...userData, 'employeeId': event.target.value, 'fullName': employeeList[event.target.selectedIndex - 1].fullName
                    , 'locationId': employeeList[event.target.selectedIndex - 1].workLocation.id
                    , 'loginName': employeeList[event.target.selectedIndex - 1].phone
                });
                setReadOnly(true);
            } else {
                setUserData({ ...userData, 'employeeId': event.target.value, 'fullName': '', 'locationId': 0 });
                setReadOnly(false);
            }
        }
        else {
            setUserData({ ...userData, [event.target.name]: event.target.value });
        }
        console.log(userData);
    };

    const viewUser = (user) => {
        if (getLoggedInUser().roleId == 1 || getLoggedInUser().roleId == 6) {
            getuserById(user.userId).then(res => {
                setIsLoading(false);
                console.log(res);
                setactiveTab(1);
                if (res.status === true) {
                    setUserData({
                        ...userData, 'fullName': res.data.fullName
                        , 'locationId': res.data.locationId, 'roleId': res.data.roleId, 'disableUser': res.data.disableUser, 'blockUser': res.data.blockUser
                        , 'changePassword': res.data.changePassword, 'loginName': res.data.loginName, 'userId': res.data.userId, 'employeeId': res.data.employeeId
                    });
                    editCanvasEnd();
                } else {
                    alert(res.message);
                }                
            }).catch((error) => {
                console.log(error);
                alert("Error while fetching user information");
            });            
        }
    }

    const headerLink = props => {
        return (
            <button onClick={() => viewUser(props)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.fullName}
            </button>
        )
    }
        
    const addUser = () => {
        console.log(userData);

        let formErrors = {};

        if (!userData.fullName) {
            formErrors.fullName = 'User full name is required';
        }

        if (!userData.locationId) {
            formErrors.locationId = 'User location is required';
        }

        if (!userData.roleId) {
            formErrors.roleId = 'User role is required';
        }

        if (!userData.loginName) {
            formErrors.loginName = 'Login name is required';
        }

        if (!userData.newPassword) {
            formErrors.newPassword = 'New Password is required';
        }

        if (!userData.confPassword) {
            formErrors.confPassword = 'Confirm Password is required';
        }

        if (userData.newPassword && userData.confPassword) {
            if (userData.newPassword != userData.confPassword) {
                formErrors.confPassword = 'New Password and Confirm Password is not matching';
            }
        }

        setErrors(formErrors);

        addUserDetail(userData).then(res => {
            console.log(res);
            if (res.status === true) {
                alert("Added Successfully");
                fetchUsers();
                setUserData({
                    userId: 0, fullName: '', loginName: '', roleId: '', disableUser: 0, blockUser: 0, changePassword: 0, locationId: '',
                    employeeId: '', newPassword: '', confPassword: ''
                });

                setIsLoading(false);
                toggleCanvasEnd();
            } else {
                alert(res.message);
            }            
        }).catch((error) => {
            alert(error);
            console.log(error);
        });
    }

    const updateUser = () => {
        console.log(userData);

        let formErrors = {};
        if (!userData.blockUser) {
            formErrors.name = 'Department Type is required';
        }
        if (!userData.disableUser) {
            formErrors.description = 'Description is required';
        }

        setErrors(formErrors);

        updateUserDetail(userData).then(res => {
            console.log(res);
            if (res.status === true) {
                fetchUsers();
                setUserData({
                    userId: 0, fullName: '', loginName: '', roleId: '', disableUser: 0, blockUser: 0, changePassword: 0, locationId: '',
                    employeeId: '', newPassword: '', confPassword: ''
                });
                alert("Updated Successfully")

                setIsLoading(false);
                editCanvasEnd();
            } else {
                alert(res.message);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const updatePassword = () => {
        console.log(userData);
        
        let formErrors = {};
        if (!userData.newPassword) {
            formErrors.newPassword = 'New Password is required';
        }
        if (!userData.confPassword) {
            formErrors.confPassword = 'Confirm Password is required';
        }

        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            updateUserCredential(userData).then(res => {
                console.log(res);
                if (res.status === true) {
                    fetchUsers();
                    setUserData({
                        userId: 0, fullName: '', loginName: '', roleId: '', disableUser: 0, blockUser: 0, changePassword: 0, locationId: '',
                        employeeId: '', newPassword: '', confPassword: ''
                    });
                    alert("Updated Successfully")
                    setIsLoading(false);
                    editCanvasEnd();
                } else {
                    alert(res.message);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                fetchUsers();
                break;
        }
    }

    document.title = "Users";

    return (

        <Fragment>
            <div className="m-2">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Users" enableAction={getLoggedInUser().roleId != 1 ? false : true} actionName="New User" onClick={toggleCanvasEnd} ></Breadcrumbs>

                    <Card className='overflow-hidden'>

                        <GridComponent dataSource={userList} ref={grid => gridInstance = grid} allowPaging={true} allowFiltering={true} showColumnMenu={true}
                            allowResizing={true}
                            toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} statelessTemplates={['directiveTemplates']} allowExcelExport={true}
                            allowPdfExport={true} pageSettings={{ pageCount: 25, pageSizes: true }} filterSettings={{ type: 'Excel' }}>
                            <ColumnsDirective>
                                <ColumnDirective field="fullName" headerText='Fullname' template={headerLink} width='150'></ColumnDirective>                                
                                <ColumnDirective field="role.name" headerText='User Role' width='100' />
                                <ColumnDirective field="location" headerText='Location' width='100' />
                                <ColumnDirective field="lastLogin" type="date" format="dd-MM-yyyy HH:mm:ss" headerText='Last Login' width='100' />
                                <ColumnDirective field="userStatus" headerText='Status' width='100' />
                                <ColumnDirective field="updatedOn" type="date" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='100' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
                        </GridComponent>

                    </Card>

                    <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                        <OffcanvasHeader toggle={toggleCanvasEnd}>
                            <Row>
                                <Col sm='12'>
                                    <b>Invite Employee/New User</b>
                                </Col>
                            </Row>
                        </OffcanvasHeader>
                        <hr />
                        <OffcanvasBody>                                                        
                            <Row className="mb-2">
                                <Label>Employee</Label>
                                <Col sm='12' md='12' xl='12'>
                                    <Input type="select" className="form-select" name='employeeId' onChange={handleChange} value={userData?.employeeId}>
                                        <option value="0" key="0">Select Employee</option>
                                        {employeeList &&
                                            employeeList.map((emp) => {
                                                return <option value={emp.id} key={emp.id}>{emp.fullName}</option>
                                            })}
                                    </Input>   
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Label>Full Name</Label>
                                <Col sm='12' md='12' xl='12'>
                                    <Input type="text" value={userData?.fullName} onChange={handleChange} disabled={isReadOnly} name='fullName' placeholder="Full Name"></Input>
                                    <p style={{ color: "Red" }}>{errors.fullName && <span>{errors.fullName}</span>}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Label>Location</Label>
                                <Col sm='12' md='12' xl='12'>
                                    <Input type="select" className="form-select" disabled={isReadOnly}
                                         name='locationId' onChange={handleChange} value={userData?.locationId}>
                                        <option value="0" key="0">Select Location</option>
                                        {locations &&
                                            locations.map((res) => {
                                                return <option value={res.id} key={res.id}>{res.locationName}</option>
                                            })}
                                    </Input>
                                    <p style={{ color: "Red" }}>{errors.locationId && <span>{errors.locationId}</span>}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Label>User Role</Label>
                                <Col sm='12' md='12' xl='12'>
                                   <Input type="select" className="form-select" name='roleId' onChange={handleChange} value={userData?.roleId}>
                                        <option value="0" key="0">Select Role</option>
                                        {roles &&
                                            roles.map((res) => {
                                                return <option value={res.id} key={res.id}>{res.name}</option>
                                            })}
                                    </Input>
                                    <p style={{ color: "Red" }}>{errors.roleId && <span>{errors.roleId}</span>}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <label>
                                    Login/User Name
                                </label>
                                <Col sm='12' md='12' xl='12'>
                                    <Input type="text" name='loginName' placeholder="Login name" onChange={handleChange} value={userData?.loginName}></Input>
                                    <p style={{ color: "Red" }}>{errors.loginName && <span>{errors.loginName}</span>}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <label>
                                    New Password
                                </label>
                                <Col sm='12' md='12' xl='12'>
                                    <Input type="password" name='newPassword' placeholder="New Password" onChange={handleChange} value={userData?.newPassword}></Input>
                                    <p style={{ color: "Red" }}>{errors.newPassword && <span>{errors.newPassword}</span>}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <label>
                                    Confirm Password
                                </label>
                                <Col sm='12' md='12' xl='12'>
                                    <Input type="password" name='confPassword' placeholder="Confirm Password" onChange={handleChange} value={userData?.confPassword}></Input>
                                    <p style={{ color: "Red" }}>{errors.confPassword && <span>{errors.confPassword}</span>}</p>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row className="mb-2">
                                <Col sm='8' md='8' xl='8'>Change Password on next Login</Col>
                                <Col sm='4' md='4' xl='4'>
                                    <Input type="checkbox" className="form-check" 
                                        name='changePassword' onChange={handleChange}>
                                    </Input>
                                </Col>
                            </Row>
                            <div className="offcanvas-footer">
                                {isLoading ? <LoadingSpinner /> : <ButtonComponent color="primary"
                                    className="btn btn-primary" disabled={isLoading} onClick={addUser}
                                    type="submit">Submit</ButtonComponent>}&nbsp;&nbsp;&nbsp;
                                <button onClick={toggleCanvasEnd} className="btn btn-dark">Close</button>
                            </div>
                        </OffcanvasBody>
                    </Offcanvas>

                    <Offcanvas backdrop="static" className='w-30' direction="end" isOpen={editcanvasOpen} toggle={editCanvasEnd}>
                        <OffcanvasHeader toggle={editCanvasEnd}>
                            <Row>
                                <Col sm='12'>
                                    <b>User Details</b>
                                </Col>
                            </Row>
                        </OffcanvasHeader>
                        <hr />

                        <OffcanvasBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>General</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>Password</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem style={{ display:'none' }}>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            setactiveTab(3);
                                        }}>
                                        <span>Location</span>
                                    </NavLink>
                                </NavItem>

                            </Nav>
                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <div style={{ paddingTop: '10px' }}>
                                     <Row className="mb-2">
                                        <Label>Full Name</Label>
                                            <Col sm='12' md='12' xl='12'>
                                                <Input type="text" value={userData?.fullName} disabled
                                                    name='fullName' readOnly placeholder="Full Name"></Input>
                                        </Col>
                                    </Row>                                    
                                    <Row className="mb-2">
                                        <Label>Primary Location</Label>
                                            <Col sm='12' md='12' xl='12'>
                                                <Input type="select" className="form-select" disabled
                                                    name='locationId' onChange={handleChange} value={userData?.locationId}>                                                    
                                                <option value="0" key="0">Select Location</option>
                                                {locations &&
                                                        locations.map((res) => {
                                                        return <option value={res.id} key={res.id}>{res.locationName}</option>
                                                    })}
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Label>Primary Role</Label>
                                            <Col sm='12' md='12' xl='12'>
                                                <Input type="select" className="form-select" name='roleId' onChange={handleChange} value={userData?.roleId}>
                                                <option value="0" key="0">Select Role</option>
                                                {roles &&
                                                    roles.map((res) => {
                                                        return <option value={res.id} key={res.id}>{res.name}</option>
                                                    })}
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingTop: "10px" }}></Row>
                                    <Row className="mb-2">
                                        <Col sm='8' md='8' xl='8'>
                                            Disable User
                                        </Col>
                                            <Col sm='4' md='4' xl='4'>
                                                <Input type="checkbox" className="form-check" name='disableUser' defaultChecked={userData.disableUser} onChange={handleChange}></Input>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='8' md='8' xl='8'>
                                            Block User from Sign-In
                                        </Col>
                                            <Col sm='4' md='4' xl='4'>
                                                <Input type="checkbox" className="form-check" defaultChecked={userData.blockUser} name='blockUser' onChange={handleChange}>
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col sm='8' md='8' xl='8'>Change Password</Col>
                                            <Col sm='4' md='4' xl='4'>
                                                <Input type="checkbox" className="form-check" name='changePassword' defaultChecked={userData.changePassword} onChange={handleChange}>
                                            </Input>
                                        </Col>
                                    </Row>
                                    <div className="offcanvas-footer">
                                        {isLoading ? <LoadingSpinner /> : <ButtonComponent color="primary"
                                            className="btn btn-primary" disabled={isLoading} onClick={updateUser}
                                                type="submit">Update</ButtonComponent>}&nbsp;&nbsp;&nbsp;
                                        <button onClick={editCanvasEnd} className="btn btn-dark">Close</button>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <div style={{ paddingTop: '10px' }}>
                                        <Row className="mb-2">
                                            <Label>Login Name</Label>
                                            <Col sm='12' md='12' xl='12'>
                                                <Input type="text" name='loginName' value={userData?.loginName} onChange={handleChange}>
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <label>
                                                New Password
                                            </label>
                                            <Col sm='12' md='12' xl='12'>
                                                <Input type="text" name='newPassword' placeholder="New Password" onChange={handleChange}></Input>
                                                <p style={{ color: "Red" }}>{errors.newPassword && <span>{errors.newPassword}</span>}</p>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <label>
                                                Confirm Password
                                            </label>
                                            <Col sm='12' md='12' xl='12'>
                                                <Input type="text" name='confPassword' placeholder="Confirm Password" onChange={handleChange}></Input>
                                                <p style={{ color: "Red" }}>{errors.confPassword && <span>{errors.confPassword}</span>}</p>
                                            </Col>
                                        </Row>
                                        <div className="offcanvas-footer">
                                            
                                            <ButtonComponent color="primary"
                                                className="btn btn-primary" disabled={isLoading} onClick={updatePassword}
                                                type="submit">Update Password</ButtonComponent>&nbsp;&nbsp;
                                            <button onClick={editCanvasEnd} className="btn btn-dark">Close</button>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId={3}>
                                    <div style={{ paddingTop: '10px' }}>
                                        <Row className="mb-2">
                                            <Label>Location</Label>
                                            <Col sm='12' md='12' xl='12'>
                                                <Input type="select" name='loginName' value={userData?.loginName}>
                                                    <option value="0" key="0">Select Location</option>
                                                    {locations &&
                                                        locations.map((res) => {
                                                            return <option value={res.id} key={res.id}>{res.locationName}</option>
                                                        })}
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <label>Role</label>
                                            <Col sm='12' md='12' xl='12'>
                                                <Input type="select" name='newPassword' placeholder="New Password">
                                                    <option value="0" key="0">Select Role</option>
                                                    {roles &&
                                                        roles.map((res) => {
                                                            return <option value={res.id} key={res.id}>{res.name}</option>
                                                        })}
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <label>
                                                Notes
                                            </label>
                                            <Col sm='12' md='12' xl='12'>
                                                <Input type="textarea" name='locationNotes' placeholder="Notes"></Input>
                                            </Col>
                                        </Row>
                                        <div className="offcanvas-footer">
                                              <button onClick={editCanvasEnd} className="btn btn-dark">Close</button>
                                        </div>
                                    </div>
                                </TabPane>                               
                            </TabContent>
                        </OffcanvasBody>
                    </Offcanvas>

                </Container>
            </div>
        </Fragment>


    );
}

export default SettingsUsers;