import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";

const TDSConfig = () => {

    const submitBankDetails = (event) => {
        event.preventDefault();

    }

    const timeout = () => {
        setTimeout(() => {
            // setDataSuccess('')
        }, 3000);
    }

    return (
        <>
            <Card>

                <CardBody>
                    <Row className="mb-2">
                        <Col lg='3' sm='3' md='3'></Col>
                        <Col lg='2' sm='2' md='2'>
                            TDS Calculation
                        </Col>
                        <Col lg='4' sm='3' md='3'>
                            <Input type="radio" value={1} readOnly name="weeklyOff">Yes</Input>&nbsp;Monthly TDS
                            <br />
                            <Input type="radio" value={2} readOnly name="weeklyOff">No</Input>&nbsp;Yearly TDS
                            <br />
                            <Input type="radio" value={3} checked readOnly='true' name="weeklyOff">No</Input>&nbsp;Estimated TDS

                        </Col>
                    </Row>
                    <br />

                </CardBody>
            </Card>
        </>
    )
}

export default TDSConfig;