import React, { useState } from "react";
import { CardBody, NavItem, TabContent, TabPane, NavLink, UncontrolledTooltip, Container, Row, Col } from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import './index.css'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page } from "@syncfusion/ej2-react-grids";

const ConsultantSalaryDeposit = () => {
    const [activeTab, setactiveTab] = useState(1);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab);
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardBody>
                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }} >
                                        <div className="step-icon" data-bs-toggle="tooltip" id="SellerDetails">
                                            <p>1</p>
                                            <UncontrolledTooltip placement="top" target="SellerDetails">
                                                Compensation & Expenses
                                            </UncontrolledTooltip>
                                        </div>
                                        <br />
                                        <h6 className="card-title-desc">Select Pay Period & Batch</h6>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <div className="step-icon" data-bs-toggle="tooltip" id="CompanyDocument" >
                                            <p>2</p>
                                            <UncontrolledTooltip placement="top" target="CompanyDocument">
                                                Review
                                            </UncontrolledTooltip>
                                        </div>
                                        <br />
                                        <h6 className="card-title-desc">Review</h6>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            setactiveTab(3);
                                        }}>
                                        <div className="step-icon" data-bs-toggle="tooltip" id="BankDetails" >
                                            <p>3</p>
                                            <UncontrolledTooltip placement="top" target="BankDetails">
                                                Confirm
                                            </UncontrolledTooltip>
                                        </div>
                                        <br />
                                        <h6 className="card-title-desc">Confirm</h6>
                                    </NavLink>
                                </NavItem>
                            </ul>

                            <TabContent className="twitter-bs-wizard-tab-content" activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Row>
                                        <Col sm='4' md='4' lg='4'>
                                            Select Pay Period & Batch
                                            <hr />
                                            Select the pay period & payroll batches for
                                            which you want to run the Direct Deposit.
                                            <br /><br />
                                            <Row>
                                                <Col sm='3' md='3' lg='3'>
                                                    Pay period
                                                </Col>
                                                <Col sm='9' md='9' lg='9'>
                                                    <select className="form-select">
                                                        <option>Select Pay Period</option>
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='3' md='3' lg='3'>
                                                    Batch
                                                </Col>
                                                <Col sm='9' md='9' lg='9'>
                                                    <select className="form-select">
                                                        <option>Select Batch</option>
                                                    </select>
                                                </Col>
                                            </Row>

                                            <div className="text-end mt-2">
                                                <ButtonComponent className="e-info">Apply</ButtonComponent>
                                            </div>

                                        </Col>
                                        <Col sm='4' md='4' lg='4'>
                                            Overview
                                            <hr />
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Employees to be paid
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    0
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Employees Net Amount
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Payment Charges
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Payment Charges GST(18%)
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Transfer Charges ?
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Transfer Charges GST(18%)
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    <b>Total amount to be transferred</b>
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm='4' md='4' lg='4'>
                                            Key Contact Information
                                            <hr />
                                            This contact information will be used in the event of any issues while processing Direct Deposit or to confirm the transaction.

                                            <Row>
                                                <Col sm='4' md='4' lg='4'>
                                                    Name
                                                </Col>
                                                <Col sm='8' md='8' lg='8'>
                                                    <input type='text' className="form-control" placeholder="Name"></input>
                                                </Col>
                                                <br /><br />
                                                <Col sm='4' md='4' lg='4'>
                                                    Contact Email
                                                </Col>
                                                <Col sm='8' md='8' lg='8'>
                                                    <input type='text' className="form-control" placeholder="Contact Email"></input>
                                                </Col>
                                                <br /><br />
                                                <Col sm='4' md='4' lg='4'>
                                                    Contact Phone
                                                </Col>
                                                <Col sm='8' md='8' lg='8'>
                                                    <input type='text' className="form-control" placeholder="Contact Phone"></input>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </TabPane>
                                <TabPane tabId={2}>
                                    <Row>
                                        <Col sm='4' md='4' lg='4'>
                                            <b>Direct Deposit Details</b>
                                            <hr />
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Employees to be paid
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    0
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Employees Net Amount
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Payment Charges
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Payment Charges GST(18%)
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Transfer Charges ?
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    Transfer Charges GST(18%)
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='9' md='9' lg='9'>
                                                    <b>Total amount to be transferred</b>
                                                </Col>
                                                <Col sm='3' md='3' lg='3'>
                                                    ₹0.00
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm='4' md='4' lg='4'>
                                            <b>Contact Information</b>
                                            <hr />
                                            <Row className="mt-2">
                                                <Col sm='8' md='8' lg='8'>
                                                    Name
                                                </Col>
                                                <Col sm='4' md='4' lg='4'>
                                                    Name
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='7' md='7' lg='7'>
                                                    Contact Email
                                                </Col>
                                                <Col sm='5' md='5' lg='5'>
                                                    gjprasad@gmail.com
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='8' md='8' lg='8'>
                                                    Contact Phone
                                                </Col>
                                                <Col sm='4' md='4' lg='4'>
                                                    77777-77777
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col sm='4' md='4' lg='4'>
                                            <b>Virtual Account Information</b>
                                            <hr />
                                            <Row className="mt-2">
                                                <Col sm='8' md='8' lg='8'>
                                                    Account Number
                                                </Col>
                                                <Col sm='4' md='4' lg='4'>
                                                    808080807566003848
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='8' md='8' lg='8'>
                                                    IFSC
                                                </Col>
                                                <Col sm='4' md='4' lg='4'>
                                                    YESB0CMSNOC
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col sm='8' md='8' lg='8'>
                                                    Account Holder Name
                                                </Col>
                                                <Col sm='4' md='4' lg='4'>
                                                    F2C ONLINE SERVICES
                                                </Col>
                                            </Row>
                                            <ul>
                                                <li>
                                                    Please save the following bank account information
                                                </li>
                                                <li>
                                                    Please transfer the amount only after submitting this form.
                                                </li>
                                            </ul>


                                        </Col>
                                    </Row>
                                    <br /><br /><br />
                                    <GridComponent allowPaging={true} >
                                        <ColumnsDirective>
                                            <ColumnDirective field="bankName" headerText='EMPLOYEE NAME' width='150'></ColumnDirective>
                                            <ColumnDirective field="bankIFSC" headerText='BANK NAME' width='150' />
                                            <ColumnDirective field="branchName" headerText='BANK ACCOUNT' width='150' />
                                            <ColumnDirective field="accountName" headerText='IFSC' width='150' />
                                            <ColumnDirective field="accountNumber" headerText='BATCH' width='150' />
                                            <ColumnDirective field="branchName" headerText='NET AMOUNT' width='150' />
                                            <ColumnDirective field="accountNumber" headerText='CONTACT DETAILS' width='150' />
                                            <ColumnDirective field="accountName" headerText='ADDRESS INFO' width='150' />
                                            <ColumnDirective field="accountName" headerText='ACTION' width='150' />
                                        </ColumnsDirective>
                                        <Inject services={[Page]} />
                                    </GridComponent>
                                </TabPane>

                                <TabPane tabId={3}>
                                    <div>
                                        <div className="text-center mb-4">
                                            <h6>Confirm</h6>
                                            {/* <p className="card-title-desc">Fill all information below</p> */}
                                        </div>
                                    </div>
                                </TabPane>

                            </TabContent>
                            <ul className="pager wizard twitter-bs-wizard-pager-link mt-4">
                                {activeTab === 1 ? activeTab === 1 && <></> : <li className={activeTab === 1 ? "previous disabled" : "previous"}>
                                    <Link to="#" className={activeTab === 1 ? "btn btn-primary disabled" : "btn btn-primary"}
                                        onClick={() => {
                                            toggleTab(activeTab - 1);
                                        }}>
                                        <i className="bx bx-chevron-left me-1"></i> Previous
                                    </Link>
                                </li>}

                                {activeTab === 3 ? activeTab === 3 && <></> : <li className={activeTab === 3 ? "next disabled" : "next"}>
                                    <Link to="#" className="btn btn-primary"
                                        onClick={() => {
                                            toggleTab(activeTab + 1);
                                        }}>
                                        Next <i className="bx bx-chevron-right ms-1"></i>
                                    </Link>
                                </li>}

                                {activeTab != 3 ? activeTab != 3 && <></> :  <li className={activeTab === 3 ? "next disabled" : "next"}>
                                    <Link to="#" className="btn btn-primary"
                                        onClick={() => {
                                            toggleTab(activeTab + 1);
                                        }}>
                                        Submit <i className="bx bx-chevron-right ms-1"></i>
                                    </Link>
                                </li>}
                            </ul>

                        </div>
                    </CardBody>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ConsultantSalaryDeposit;
