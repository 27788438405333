import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, CardHeader, CardTitle, Col, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, } from "reactstrap";
import { addEmployeeDependent, getEmployeeDependents } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import Spinner from "../../shared/spinner";
import './index.css'

const EmployeeDependent = (props) => {
    const [canvasPlacement, setCanvasPlacement] = useState("start");
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [dependentData, setDependentData] = useState({
        employeeId: 0, name: "", relationship: "", age: ""
    });
    const [dependentList, setDependentList] = useState();

    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const params = useParams();

    useEffect(() => {
        setDependentData({ ...dependentData, 'employeeId': props.employeeId });

        getEmployeeDependents(props.employeeId).then(res => {
            console.log(res.data);
            if (res.data) {
                setDependentList(res.data)
            }
        });

    }, [submitted]);

    const handleChange = event => {
        setDependentData({ ...dependentData, [event.target.name]: event.target.value });
    };

    const submitDependentDetails = (event) => {
        event.preventDefault();
        let formErrors = {};
        if (!dependentData.name) {
            formErrors.name = 'Name is required';
        }
        if (!dependentData.relationship) {
            formErrors.relationship = 'Relationship is required';
        }
        if (!dependentData.age) {
            formErrors.age = 'Age is required';
        }

        setErrors(formErrors);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            console.log(dependentData);
            addEmployeeDependent(dependentData).then(res => {
                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    setDependentData({
                        name: "", relationship: "", age: ""
                    });
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }

    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const toggleDependent = () => {
        setCanvasPlacement("end");
        setCanvasOpen(!canvasOpen);
    };

    const toolbarTemplate = () => {
        return (
            <Row className="m-2">
                <Col xl="9" className="mt-2" md="9" sm="9">
                    <CardTitle tag="h4">Dependent Information</CardTitle>
                </Col>
                <Col xl="3" md="3" sm="4">
                    <ButtonComponent onClick={toggleDependent} className='e-btn e-outline e-primary'>
                        <i className="bx bx-plus font-size-16 align-middle"></i>{" "}Add Details</ButtonComponent>
                </Col>
            </Row>
        )
    }

    const toolbarOptions = [
        { template: toolbarTemplate }
    ];


    return (
        <>
            <Row className="m-2">
                <Col xl="9" className="mt-2" md="9" sm="9">
                    <CardTitle tag="h4">Dependent Information</CardTitle>
                </Col>
                <Col xl="3" md="3" sm="4">
                    <button type="button" onClick={toggleDependent} className="btn btn-success waves-effect waves-light">
                        <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                        Add
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button type="button" className="btn btn-warning waves-effect waves-light">
                        <i className="bx bx-revision font-size-16 align-middle me-2"></i>{" "}
                        Refresh
                    </button>
                </Col>
            </Row>

            {!dependentList ?
                <div className='text-center mt-4'>
                    <Spinner />
                </div>
                :
                <GridComponent dataSource={dependentList} allowPaging={true} pageSettings={{ pageCount: 5 }} statelessTemplates={['directiveTemplates']}>
                    <ColumnsDirective>
                        <ColumnDirective field="name" headerText="Name" width="150" />
                        <ColumnDirective field="relationship" headerText="Relationship" width="150" />
                        <ColumnDirective field="age" headerText="Age" width="150"></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Page]} />
                </GridComponent>
            }

            <Offcanvas backdrop="static" className="w-30" direction={canvasPlacement} isOpen={canvasOpen}
                toggle={toggleDependent}>
                <OffcanvasHeader toggle={toggleDependent}>
                    <Row>
                        <Col sm="12">
                            <b>Dependent Details</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />

                <OffcanvasBody>
                    <FormGroup>
                        <Row>
                            <Col xl="12" md="12" sm="12">
                                <Label className="form-label" for="InputHelp">
                                    Name
                                </Label>
                                <Input type="text" className="form-control" name="name"
                                    value={dependentData.name} onChange={handleChange} placeholder="Enter Name"></Input>
                                <p style={{ color: "Red" }}>{errors.name && <span>{errors.name}</span>}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl="12" md="12" sm="12">
                                <Label className="form-label" for="InputHelp">
                                    Relationship
                                </Label>
                                <Input type="text" className="form-control" name="relationship"
                                    value={dependentData.relationship} onChange={handleChange} placeholder="Enter Relationship"></Input>
                                <p style={{ color: "Red" }}>{errors.relationship && <span>{errors.relationship}</span>}</p>
                            </Col>

                            <Col xl="12" md="12" sm="12">
                                <Label className="form-label" for="InputHelp">
                                    Age
                                </Label>
                                <Input type="text" className="form-control" name="age"
                                    value={dependentData.age} onChange={handleChange} placeholder="Enter Age"></Input>
                                <p style={{ color: "Red" }}>{errors.age && <span>{errors.age}</span>}</p>
                            </Col>

                        </Row>
                    </FormGroup>

                    <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
                        {dataSuccess === 'added' && <SuccessAlert />}
                        {dataSuccess === 'failed' && <FailureAlert />}
                    </div>

                    <div className="offcanvas-footer">
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            onClick={submitDependentDetails} className="btn btn-sm" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;
                        <button onClick={toggleDependent} className="btn btn-dark btn-sm">
                            Close
                        </button>
                    </div>
                </OffcanvasBody>
            </Offcanvas>

        </>
    );
};

export default EmployeeDependent;
