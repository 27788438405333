import React, { Component, useState, useEffect } from "react"
import ReactEcharts from "echarts-for-react"
import { getData } from "../../helpers/url-request"

const EmployeeChart = () => {

    const [districts, setDistricts] = useState([]);
    const [totalEmp, setTotalEmp] = useState([]);
    const [totalPer, setTotalPer] = useState([]);
    const [totalDep, setTotalDep] = useState([]);
    const [totalOth, setTotalOth] = useState([]);

    const option =  {        
        grid: {
            zlevel: 0,
            x: 80,
            x2: 50,
            y: 30,
            y2: 30,
            borderWidth: 0,
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            }
        },
        toolbox: {
            orient: 'center',
            left: 0,
            top: 20,
            feature: {              
                magicType: { type: ['line', 'bar'], title: { line: "For line chart", bar: "For bar chart" } },
                restore: { title: "restore" },
                saveAsImage: { title: "Download Image" }
            }
        },
        color: ['#2ab57d', '#5156be', '#fd625e', '#fdaa5e'],
        legend: {
            data: ['Employee', 'Permanent', 'Deputation', 'Others' ],
            textStyle: { color: '#858d98' }
        },
        xAxis: [
            {
                type: 'category',
                data: districts,
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: [                
            {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: '#858d98'
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(133, 141, 152, 0.1)"
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',                                       
                axisLine: {
                    lineStyle: {
                        color: '#858d98'
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(133, 141, 152, 0.1)"
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],
        series: [
            {
                name: 'Employee',
                type: 'bar',
                data: totalEmp
            },
            {
                name: 'Permanent',
                type: 'bar',
                data: totalPer
            },
            {
                name: 'Deputation',
                type: 'bar',
                data: totalDep
            },
            {
                name: 'Others',
                type: 'bar',
                data: totalOth
            }
        ]        
    }

    useEffect(() => {

        getData("/Employee/districtcount/0").then(response => {
            console.log(response.data);
            if (response.data) {
                //setChartData(response.data.Total);
                setDistricts(response.data.District);
                setTotalEmp(response.data.EmpCount);
                setTotalPer(response.data.PerCount);
                setTotalDep(response.data.DepCount);
                setTotalOth(response.data.OthCount);
            }
        });

    }, []);

    return (        
        <React.Fragment>
            Employee Chart
            <ReactEcharts style={{ height: "350px" }} option={option} />
        </React.Fragment>
    )    
}

export default EmployeeChart
