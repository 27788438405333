import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort }
    from "@syncfusion/ej2-react-grids";
import AddHRConsultant from "./add-consultant";
import { getConsultantList } from "../../../helpers/backend_helper";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import Spinner from "../../shared/spinner";
import { getLoggedInUser } from "../../../helpers/backend_helper";


const HRConsultant = () => {
    const [consultants, setConsultantData] = useState();
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [updateList, setUpdate] = useState('');

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    useEffect(() => {
        getConsultantList().then(res => {
            console.log(res.data);
            if (res.data) {
                setConsultantData(res.data)
            }
        });
    }, [updateList]);

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const formSubmitted = (formSuccess) => {
        console.log(formSuccess);
        setUpdate(formSuccess);
        console.log(updateList);
    }

    return (

        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="HR Management" breadcrumbItem="Consultant" enableAction={getLoggedInUser().roleId != 1 ? false : true} actionName="Add Consultant" onClick={toggleCanvasEnd}></Breadcrumbs>

                    {!consultants ?
                        <div className='text-center mt-4'>
                            <Spinner />
                        </div> : <GridComponent toolbar={toolbarOptions} dataSource={consultants} allowPaging={true} statelessTemplates={['directiveTemplates']}
                            allowSorting={true} showColumnMenu={true} pageSettings={{ pageCount: 5 }}>
                            <ColumnsDirective>
                                <ColumnDirective field="fullName" headerText='Full Name' width='150'></ColumnDirective>
                                <ColumnDirective field="companyName" headerText='CompanyName' width='150' />
                                <ColumnDirective field="emailId" headerText='EmailId' width='150' />
                                <ColumnDirective field="phoneNo" headerText='PhoneNo' width='150'></ColumnDirective>
                                <ColumnDirective field="city" headerText='City' width='150' />
                                <ColumnDirective field="bankName" headerText='BankName' width='150' />
                                <ColumnDirective field="branchName" headerText='BranchName' width='150' />
                                <ColumnDirective field="accountNumber" headerText='AccountNumber' width='150' />
                                <ColumnDirective field="bankIFSC" headerText='BankIFSC' width='150' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                        </GridComponent>}


                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-50' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Consultant Details</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />

                            <OffcanvasBody>
                                <AddHRConsultant onStateChange={formSubmitted} />
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>


    );
}

export default HRConsultant;