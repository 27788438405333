import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, } from "reactstrap";
import { getEmployeeIncrement, getIncrementTypes, getCompanyLocations } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import Spinner from "../../shared/spinner";
import ITSectionDetail from "./ITSectionDetail";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as url from '../../../helpers/url_helper';
import { getData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const ITDeclarationTypeView = (props) => {
  let gridInstance;
  const [canvasPlacement, setCanvasPlacement] = useState("start");
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [transferData, setTransferData] = useState({
      employeeId: 0, orderDate: "", effectiveDate: "", notes: "", basic: 0, incrementTypeId: 0
  });
  const [educationList, setEducationList] = useState();  
  const [transferTypeList, setTransferTypeList] = useState();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [submitted, setFormSubmit] = useState(false);
  const [dataSuccess, setDataSuccess] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const params = useParams();

    const loadData = () => {
        showLoading(true);
        getData("/payrollit/declarationtypes").then(res => {
            console.log(res.data);
            if (res.data) {
                setEducationList(res.data)
            }

            showLoading(false);
        });
    }

  useEffect(() => {     

      loadData();

  }, [submitted]);

    const handleChange = event => {
        if (event.target.name == "transferTypeId")
            setTransferData({ ...transferData, [event.target.name]: Number(event.target.value) });
        else
            setTransferData({ ...transferData, [event.target.name]: event.target.value });      
  };


    const submitTransferDetails = (event) => {
    event.preventDefault();
      let formErrors = {};
        if (!transferData.incrementTypeId) {
          formErrors.incrementTypeId = 'Select the Increment Type';
      }
      if (!transferData.orderDate) {
          formErrors.orderDate = 'Enter order date';
      }
      if (!transferData.effectiveDate) {
          formErrors.effectiveDate = 'Enter effective date';
      }

      setErrors(formErrors);
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0) {
        setIsLoading(true);

        console.log(transferData.transferTypeId);

        const formData = new FormData();

        formData.append("basic", transferData.basic);
        formData.append("effectiveDate", transferData.effectiveDate);
        formData.append("orderDate", transferData.orderDate);
        formData.append("employeeId", transferData.employeeId);        
        formData.append("notes", transferData.notes);
        formData.append("incrementTypeId", transferData.incrementTypeId);

        if (selectedFiles.length > 0) {
            selectedFiles.forEach((file, index) => {
                console.log(file);
                formData.append("documents", file);
            });
        } else {
            console.log("Inside Else");
            formData.append("documents", []);
        }

        const res = addEmployeeTransfer(formData);
        console.log("res after await");
        console.log(res);
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

  const addEmployeeTransfer = body => {
    let userdata = localStorage.getItem("authUser");
    userdata = JSON.parse(userdata);

    var token = userdata.token;
    // const boundary = "----WebKitFormBoundary7MA4YWxkTrZu0gW";
    let requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token,
            // 'Content-Type': `multipart/form-data; boundary=${boundary}`,
            apiKey: 'key123',
        },
        body,
    };
    return fetch(url.BASE_URL + '/Employee/addincrement', requestOptions)
        .then(response => {
            console.log({ response });
            response.json().then(res => {
                console.log(res);

                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    setTransferData({
                        employeeId: 0, orderDate: "", effectiveDate: "", notes: "", basic: 0, incrementTypeId: 0
                    });

                    console.log("Setting canvas to false");
                    setCanvasOpen(false);

                    getEmployeeIncrement(props.employeeId).then(resp => {
                        console.log(resp.data);
                        if (resp.data) {
                            setEducationList(resp.data)
                        }
                    });

                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();
            });
        })
        .catch(err => {
            console.log({ err });
        });
  };


  const addEducation = () => {
    setCanvasPlacement("end");
    setCanvasOpen(!canvasOpen);
    };

    const onClose = () => {
        addEducation();
        loadData();
    }


    const handleAcceptedFiles = (files) => {
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    const viewDocument = (docId, docName) => {
        console.log("view document: " + docId);
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        fetch(url.BASE_URL + '/Employee/downloadincrementdocument/' + docId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + userdata.token,
                'apikey': 'key123',
            },
        }).then((response) => response.blob())
            .then((blob) => {
                console.log(blob);
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',docName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const docsLink = props => {
        console.log("Inside docsLink");
        console.log(props.employeeIncrementDocuments);
        
        const links = [];
        
        props.employeeIncrementDocuments ? props.employeeIncrementDocuments.map((doc) => {
            links.push(
                <button onClick={() => viewDocument(doc.id, doc.fileName)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                    {doc.fileName}
                </button>);
            links.push(<br></br>);
        }) : links.push(<label>None</label>)
        
        return (
            <div>{links}</div>
        )
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const toolbarOptions = [
        'Search',
        { text: 'Add', prefixIcon: 'e-add', id: 'add' },
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Delete', prefixIcon: 'e-trash', id: 'delete' },
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'add':
                addEducation();
                break;
            case 'delete':
                deleteDeductions();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const statusTemplate = (props) => {
        return (<div>{props.status === 1 ?
            <div className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }

  return (
      <>
          <Row className="m-2">
              <Col xl="12" className="mt-2" md="12" sm="12">
                  <CardTitle tag="h4">Income tax Declaration Types, for employees to claim benefits under different sections</CardTitle>
              </Col>
          </Row>
          
          <GridComponent dataSource={educationList} allowTextWrap={true} allowPaging={true} pageSettings={{ pageCount: 25, pageSizes: true }} allowResizing={true}
              statelessTemplates={['directiveTemplates']} toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid}          >
              <ColumnsDirective>
                  <ColumnDirective type="checkbox" width="35" />
                  <ColumnDirective field="section.name" headerText="Section" width="150" />
                  <ColumnDirective field="name" headerText="Name" width="150" />
                  <ColumnDirective field="description" headerText="Description" width="150" />
                  <ColumnDirective field="status" headerText="Status" template={statusTemplate} width="150" />
                  <ColumnDirective field="updatedOn" type="date" format="dd-MM-yyyy h:mm a" headerText="Last Updated" width="150" />
              </ColumnsDirective>
              <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize]} />
          </GridComponent>
        
      

      <Offcanvas backdrop="static" className="w-30" direction={canvasPlacement} isOpen={canvasOpen}
        toggle={addEducation}>
        <OffcanvasHeader toggle={addEducation}>
          <Row>
            <Col sm="12">
              <b>Increment Details</b>
            </Col>
          </Row>
        </OffcanvasHeader>
        <hr />
              <OffcanvasBody>
                  <ITSectionDetail employeeId={props.employeeId} onClose={onClose} />
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default ITDeclarationTypeView;
