import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import {
    ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, FilterType, Inject, Sort, Edit,
    ColumnMenu, CommandColumn, Toolbar,
    ExcelExport, PdfExport, Group, Resize, Aggregate, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective
} from "@syncfusion/ej2-react-grids";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanyPayperiods, getUserLocations, getDepartments, getEmployeeList, getAllContractors, getAccountGroups
} from "../../helpers/backend_helper";
import { getData, postData } from "../../helpers/url-request";
import Select from "react-select"
import { showLoading } from 'react-global-loading';

const SalaryAbstractReport = () => {
    let gridInstance;    
    const [employees, setEmployees] = useState();
    const [departments, setDepartments] = useState();
    const [locations, setLocations] = useState();    
    const [payPeriods, setPayPeriods] = useState();
    const [earningColumns, setEarningColumns] = useState([]);
    const [deductionColumns, setDeductionColumns] = useState([]);
    const [formData, setFormData] = useState({
        "payPeriodId": 0, "accountGroupId": 0,
        "locationId": 0, "departmentId": 0,
        "contractorId": 0, "employeeId": 0
    });
    const [reportData, setReportData] = useState([]);
    

    useEffect(() => {

        getCompanyPayperiods().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "payPeriodTitle": "Select Period" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setPayPeriods(emps)
            }
        });

        getEmployeeList().then(res => {
            if (res.data) {
                let emps = [{ label: "ALL", value: 0 }];
                res.data.forEach(el => {
                    emps.push({ label: el.fullName, value: el.id });
                });
                setEmployees(emps)
            }
        });

        getDepartments().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setDepartments(emps)
            }
        });

        getAccountGroups().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setLocations(emps)
            }
        });


    }, []);


    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];


    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const handleChange = event => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    };

    const confirmHandler = (event) => {
        event.preventDefault();
        setReportData([]);
        console.log(gridInstance.columns);
        for (var i = gridInstance.columns.length; i >= 0; i--) {
            gridInstance.columns.pop();            
        }
        gridInstance.refreshColumns();
        var _this = gridInstance;        
        showLoading(true);
        postData("/payroll/salarydetailreport", formData).then(res => {
            console.log(res.data);

            var earningCols = [];
            var deductionCols = [];
            res.data.columns.map((columnName) => {
                var newColumn = { field: columnName.key, headerText: columnName.value, width: 120 };
                var _column = new String(columnName.key);
                if (_column.startsWith("A") || _column.startsWith("Gross")) {
                    if (_column != "AccountGroup")
                        earningCols.push(newColumn);
                } else if (_column.startsWith("D") || _column.startsWith("Deductions")) {
                    deductionCols.push(newColumn);
                }
            });

            setEarningColumns(earningCols);
            setDeductionColumns(deductionCols);

            var slNo = { headerText: "Sl-No", field: "Sl-No", width: 50 };
            var accountGroup = { headerText: "Account Group", field: "AccountGroup", width: 120 };
            var fullName = { headerText: "FullName", field: "FullName", width: 120 };
            var payrollMonth = { headerText: "PayrollMonth", field: "PayrollMonth", width: 120 };
            var earningColumn = { headerText: "Earning", columns: earningCols, width: 120 };
            var deductionColumn = { headerText: "Deduction", columns: deductionCols, width: 120 }
            var netColumn = { headerText: "Net Salary", field: "NetAmount", width: 120 }
            
            _this.columns.push(slNo);
            _this.columns.push(accountGroup);
            _this.columns.push(fullName);
            _this.columns.push(payrollMonth);
            _this.columns.push(earningColumn);
            _this.columns.push(deductionColumn);
            _this.columns.push(netColumn);

            _this.refreshColumns();

            console.log(res.data.records);
            setReportData(res.data.records);
            _this.dataSource = res.data.records;
            _this.refresh();

            showLoading(false);
        });
    }

    const resetReport = () => {
        setReportData([]);
        setFormData({
            "payPeriodId": 0,
            "locationId": 0, "departmentId": 0,
            "contractorId": 0, "employeeId": 0
        });
    }

    const earningHeader = () => {
        return (<div>
            <span>Ship Details</span>            
        </div>);
    }

    const deductionHeader = () => {
        return (<div>
            <span>Deductions</span>
        </div>);
    }

    const footerSum = (props) => {
        return (<span>Sum: {props.Sum}</span>);
    };

    return (
        <div className="page-content">
            <Breadcrumbs title="HR Management" breadcrumbItem="Employee's Salary Detail Report" enableAction={false}></Breadcrumbs>
            <CardBody>
                <Row>                    
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Select Period
                        <select className="form-select" name="payPeriodId" onChange={handleChange}>
                            {payPeriods &&
                                payPeriods.map((payperiod) => {
                                    return <option value={payperiod.id} key={payperiod.id}>{payperiod.payPeriodTitle}</option>
                                })}
                        </select>
                    </Col>                    
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Select Location
                        <select className="form-select" name="accountGroupId" onChange={handleChange}>
                            {locations &&
                                locations.map((emp) => {
                                    return <option value={emp.id} key={emp.id}>{emp.name}</option>
                                })}
                        </select>
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Employee
                        <Select type="select"
                            placeholder='Select Employee'
                            name="employeeId"
                            isMulti={false}
                            options={employees}
                            classNamePrefix="form-select"
                            isLoading={false}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        <br />
                        <Button color="primary" className="btn primary" type="submit" onClick={confirmHandler}>View Report</Button>&nbsp;&nbsp;&nbsp;
                        <Button color="primary" className="btn primary" type="reset" onClick={resetReport}>Clear</Button>
                    </Col>
                </Row>
            </CardBody>
        <hr />

            <Row>
                <Col sm='12' md='12' lg='12'>
                    <CardBody>                        
                        <GridComponent allowPaging={true} dataSource={reportData}
                            toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5, pageSizes: true }}
                            statelessTemplates={['directiveTemplates']} allowResizing={true} filterSettings={{ type: 'Excel' }} allowFiltering={true}
                            allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective headerText='Sl.No' width='50'></ColumnDirective>
                                <ColumnDirective field="employeeProfile.fullName" headerText='Employee' width='150'></ColumnDirective>
                                <ColumnDirective field="payrollDetail.payrollMonth" headerText='Pay Month' width='150'></ColumnDirective>
                                <ColumnDirective headerText='Earnings' columns={earningColumns} width='120' />
                                <ColumnDirective headerText='Deductions' columns={deductionColumns} width='120' />
                                <ColumnDirective field="netAmount" headerText='Net Amount' width='120' />
                            </ColumnsDirective>
                            <AggregatesDirective>
                              <AggregateDirective>
                                <AggregateColumnsDirective>
                                    <AggregateColumnDirective field='netAmount' type='Sum' footerTemplate={footerSum} />
                                </AggregateColumnsDirective>
                                </AggregateDirective>
                            </AggregatesDirective>
                            <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize, Aggregate]} />
                        </GridComponent>                        
                    </CardBody>
                </Col>

            </Row>

        </div>
    )
}

export default SalaryAbstractReport;