import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Resize } from "@syncfusion/ej2-react-grids";
import { useEffect, useState } from "react";
import {
    createTimeEntry, getCompanyLocations, getDepartments, getEmployeeList, getUserLocations,
    getManulAttendanceList, getVendorsPayments, getAllContractors
} from "../../../helpers/backend_helper";
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const AttendanceReport = () => {
    let gridInstance;
    const [employees, setEmployees] = useState();
    const [departments, setDepartments] = useState();
    const [locations, setLocations] = useState();
    const [vendors, setVendors] = useState();
    const [attendance, setAttendanceDetails] = useState();
    const [formData, setFormData] = useState({
        "fromDate": "", "toDate": "",
        "locationId": 1, "departmentId": 0,
        "contractorId": 0, "employeeId": 0
    })

    useEffect(() => {
        getEmployeeList().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "fullName": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setEmployees(emps)
            }
        });

        getDepartments().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setDepartments(emps)
            }
        });

        getUserLocations().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "locationName": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setLocations(emps)
            }
        });

        getAllContractors().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "name": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setVendors(emps)
            }
        });
    }, []);

    const handleChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        //if (event.target.name === 'hours') {
        //    setHrs(event.target.value)
        //} else if (event.target.name === 'mins') {
        //    setMin(event.target.value)
        //} else {
        //    setTimeEntry({ ...timeEntry, [event.target.name]: event.target.value });
        //}
    };

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const confirmHandler = (event) => {
        event.preventDefault();

        if (!formData.fromDate || !formData.toDate) {
            alert("Please select From and To Date");
            return;
        }

        showLoading(true);
        postData("/attendance/report", formData).then(res => {
            showLoading(false);
            setAttendanceDetails(res.data);
        });
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Attendance" breadcrumbItem="Attendance Report"></Breadcrumbs>
            </Container>
            <CardBody>
                <form onSubmit={confirmHandler}>
                    <Row>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            From Date
                            <Input type="date" name="fromDate" onChange={handleChange} className="form-control"></Input>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            To Date
                            <Input type="date" name="toDate" onChange={handleChange} className="form-control"></Input>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='3'>
                            Location
                            <select className="form-select" name="locationId" onChange={handleChange}>
                                {locations &&
                                    locations.map((emp) => {
                                        return <option value={emp.id} key={emp.id}>{emp.locationName}</option>
                                    })}
                            </select>
                        </Col>                                              
                        <Col className="mt-2" sm='3' md='3' lg='3'>
                            Employee Name
                            <select className="form-select" name="employeeId" onChange={handleChange} >
                                {employees &&
                                    employees.map((emp) => {
                                        return <option value={emp.id} key={emp.id}>{emp.fullName}</option>
                                    })}
                            </select>
                        </Col>
                        <Col className="mt-2" sm='3' md='3' lg='2'>
                            <br />
                            <Button color="primary" className="btn primary" type="submit">Run Report</Button>&nbsp;&nbsp;&nbsp;
                            <Button color="primary" className="btn primary" type="reset">Clear</Button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
            <hr />
            <GridComponent dataSource={attendance} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid}
                pageSettings={{ pageCount: 5, pageSizes: true }} allowExcelExport={true} showColumnMenu={true} allowPdfExport={true}
                allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}>
                <ColumnsDirective>
                    <ColumnDirective field="employee.fullName" headerText='Employee Name' width='150'></ColumnDirective>
                    <ColumnDirective field="employee.employeeType.name" headerText='Type' width='150' />
                    <ColumnDirective field="noOfDays" headerText='Days' width='150' />
                    <ColumnDirective field="holidays" headerText='Holidays' width='150'></ColumnDirective>
                    <ColumnDirective field="workingDays" headerText='Working Days' width='150' />
                    <ColumnDirective field="leaves" headerText='Leaves' width='150' />
                    <ColumnDirective field="lop" headerText='LOP' width='150' />
                    <ColumnDirective field="presentDays" headerText='Present' width='150' />
                </ColumnsDirective>
                <Inject services={[Toolbar, Page, Sort, ColumnMenu, Resize]} />
            </GridComponent>

        </div>
    )
}

export default AttendanceReport;