import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { ToolbarOptions } from "../../../components/Common/Toolbar";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, Resize, Filter, ExcelExport, PdfExport } from "@syncfusion/ej2-react-grids";
import AddLeaveRequest from "./add-leave-request/add-leave-request";
import LeaveDetail from './LeaveDetail';
import { getEmployeeLeaveRequest } from "../../../helpers/backend_helper";
import { showLoading } from 'react-global-loading';

const LeaveRequest = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [canvasDetailOpen, setCanvasDetailOpen] = useState(false);
    const [leaveRequestList, setLeaveRequestList] = useState();
    const [leaveId, setLeaveId] = useState();
    const [employeeId, setEmployeeId] = useState();
    

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        showLoading(true);
        getEmployeeLeaveRequest({}).then((res) => {
            if (res.data) {
                setLeaveRequestList(res.data);
            }
            showLoading(false);
        });
    }

    const afterUserAction = () => {        
        getEmployeeLeaveRequest({}).then((res) => {
            if (res.data) {
                setLeaveRequestList(res.data);
            }
        })
        toggleCanvasEnd();
    }

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const toggleCanvasDetailEnd = () => {
        setCanvasPlacement('end')
        setCanvasDetailOpen(!canvasDetailOpen)
    }


    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    const headerLink = props => {
        return (
            <button onClick={() => viewLeaveRequest(props.id, props.employeeProfile.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.leaveId}
            </button>
        )
    }

    const viewLeaveRequest = (leaveId, empId) => {        
        setLeaveId(leaveId);
        setEmployeeId(empId);
        toggleCanvasDetailEnd();
    }

    return (
        <Fragment>
            <div className="page-content">

                <Modal isOpen={canvasDetailOpen} toggle={toggleCanvasDetailEnd} fullscreen>
                    <ModalHeader toggle={toggleCanvasDetailEnd}>Leave Details</ModalHeader>
                    <ModalBody>
                        <LeaveDetail leaveId={leaveId} employeeId={employeeId} closeAction={toggleCanvasDetailEnd} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="btn primary" type="button" onClick={toggleCanvasDetailEnd}>Close</Button>
                    </ModalFooter>
                </Modal>                

                <Container fluid>
                    <Breadcrumbs title="Leave" breadcrumbItem="Leave Request" enableAction={true} actionName="New Leave Request" onClick={toggleCanvasEnd} ></Breadcrumbs>
                                            
                    <GridComponent dataSource={leaveRequestList} allowPaging={true} toolbar={ToolbarOptions} ref={grid => gridInstance = grid}
                        pageSettings={{ pageCount: 25, pageSizes: true }} statelessTemplates={['directiveTemplates']} allowResizing={true} allowFiltering={true}
                        allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} filterSettings={{ type: 'Excel' }} 
                        toolbarClick={toolbarClick.bind(this)}>
                        <ColumnsDirective>                            
                            <ColumnDirective field="leaveId" headerText='Request Id' template={headerLink} width='100'></ColumnDirective>
                            <ColumnDirective field="employeeProfile.fullName" headerText='Employee' width='150'></ColumnDirective>
                            <ColumnDirective field="location.locationName" headerText='Location' width='125'></ColumnDirective>                            
                            <ColumnDirective field="leaveType.name" headerText='Leave Type' width='150'></ColumnDirective>
                            <ColumnDirective field="leavePeriodType.name" headerText='Leave Period' width='150' />
                            <ColumnDirective field="fromDate" type="datetime" format="dd/MM/yyyy" headerText='From Date' width='125' />
                            <ColumnDirective field="toDate" type="datetime" format="dd/MM/yyyy" headerText='To Date' width='125' />                            
                            <ColumnDirective field="leaveStatus" headerText='Leave Status' width='125' />
                            <ColumnDirective field="leaveTaskDetail.assignedToUser.fullName" headerText='Assigned To' width='125' />
                            <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='150'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Sort, ColumnMenu, Filter, Resize, ExcelExport, PdfExport]} />
                    </GridComponent>

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-40' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Leave Request</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />
                            <OffcanvasBody>
                                <AddLeaveRequest closeAction={afterUserAction} />
                            </OffcanvasBody>
                        </Offcanvas>
                        
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

export default LeaveRequest;