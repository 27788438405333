import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";
import { showLoading } from 'react-global-loading';

import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Toolbar } from "@syncfusion/ej2-navigations";
import HRACCAScale from "./HRACCAScale";
import DAScale from "./DAScale";

const SettingsPayscale = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Settings" breadcrumbItem="Payscale Settings" enableAction={false}></Breadcrumbs>
                <Container fluid>
                    <CardBody>
                        <div id="basic-pills-wizard" className="twitter-bs-wizard">

                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>HRA CCA</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>DA</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <HRACCAScale />
                                </TabPane>
                                <TabPane tabId={2}>
                                    <DAScale />
                                </TabPane>                                
                            </TabContent>

                        </div>
                    </CardBody>
                </Container >
            </div >
        </React.Fragment >
    );
};

export default SettingsPayscale;