import React, { useState, useEffect } from "react";
import { CardBody, NavItem, TabContent, TabPane, NavLink, UncontrolledTooltip, Container } from "reactstrap";

import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";
import ExpensesandCompensation from "./compensation&expenses";
import TimeAttendance from "./time&attendance";
import ITDeclaration from "./ITDeclaration";
import Review from "./Review";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getData, postData } from "../../../helpers/url-request";

const PayrollWizard = (param) => {
    const [activeTab, setactiveTab] = useState(4);
    const [payrollData, setPayrollData] = useState({});
    const history = useHistory();
    const [formData, setFormData] = useState({
        "payPeriod": 1, "batchId": 1
    });
    const [payPeriodId, setPayPeriodId] = useState();

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
            }
        }
    }

    const handleNext = () => {
        if (activeTab == 4) {            
            var formData = {
                "payPeriodId": param.payPeriodId
            }

            postData("/payroll/payrollprocess/submit", formData).then(res => {                                
                //history.push('/payroll/summary');
                //navigate(0);
                history.go(0);
            });
        } else {
            toggleTab(activeTab + 1);
        }
    }

    const handleBack = () => {
        toggleTab(activeTab - 1);
    }

    useEffect(() => {

        if (param.payPeriodId > 0) {


            //var formData = {
            //    "payPeriodId": param.payPeriodId
            //}

            //postData("/payroll/payrollprocess/attendance", formData).then(res => {
            //    console.log(res.data);
            //    setPayrollData(res.data);
            //});

            //postData("/payroll/payrollprocess/earnings", formData).then(res => {
            //    console.log(res.data);
            //    //setPayrollData(res.data);
            //});

            //postData("/payroll/payrollprocess/deductions", formData).then(res => {
            //    console.log(res.data);
            //    //setPayrollData(res.data);
            //});
        }

    }, []);

    return (
        <React.Fragment>
            <div>
                <Container fluid>

                    <Breadcrumbs title="HR Management" breadcrumbItem="Attendance & Payroll Details" enableAction={false}></Breadcrumbs>

                    <CardBody>
                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified" style={{ display:'none' }}>
                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }} >
                                        <div className="step-icon" data-bs-toggle="tooltip" id="SellerDetails">
                                            <i className="bx bx-book-bookmark"></i>
                                            <UncontrolledTooltip placement="top" target="SellerDetails">
                                                Compensation & Expenses
                                            </UncontrolledTooltip>
                                        </div>
                                        <br />
                                        <h6>Attendance</h6>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <div className="step-icon" data-bs-toggle="tooltip" id="CompanyDocument" >
                                            <i className="bx bx-time-five"></i>
                                            <UncontrolledTooltip placement="top" target="CompanyDocument">
                                                Time & Attendance
                                            </UncontrolledTooltip>
                                        </div>
                                        <br />
                                        <h6>Earnings</h6>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            setactiveTab(3);
                                        }}>
                                        <div
                                            className="step-icon"
                                            data-bs-toggle="tooltip"
                                            id="BankDetails" >
                                            <i className="bx bxs-bank"></i>
                                            <UncontrolledTooltip placement="top" target="BankDetails">
                                                IT Declarations
                                            </UncontrolledTooltip>
                                        </div>
                                        <br />
                                        <h6>Deductions</h6>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: activeTab === 4 })} onClick={() => { setactiveTab(4); }}>
                                        <div
                                            className="step-icon"
                                            data-bs-toggle="tooltip"
                                            id="BankDetails" >
                                            <i className="bx bx-git-pull-request"></i>
                                            <UncontrolledTooltip placement="top" target="BankDetails">
                                                Final Review
                                            </UncontrolledTooltip>
                                        </div>
                                        <br />
                                        <h6>Review</h6>
                                    </NavLink>
                                </NavItem>
                            </ul>

                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <div>
                                        {/*<TimeAttendance payPeriodId={param.payPeriodId} />*/}
                                    </div>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <div>
                                        {/*<ExpensesandCompensation data={payrollData} />*/}
                                    </div>
                                </TabPane>
                                <TabPane tabId={3}>
                                    <div>
                                        {/*<ITDeclaration data={payrollData} />*/}
                                    </div>
                                </TabPane>
                                <TabPane tabId={4}>
                                    <div>
                                        {/*<Review data={payrollData} />*/}
                                        <TimeAttendance payPeriodId={param.payPeriodId} />
                                    </div>
                                </TabPane>
                            </TabContent>
                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                <li className={activeTab === 1 ? "previous hidden" : "previous"}>
                                    {/*<Link to="#" className={activeTab === 1 ? "btn btn-primary disabled" : "btn btn-primary"}*/}
                                    {/*    onClick={handleBack}>*/}
                                    {/*    <i className="bx bx-chevron-left me-1"></i> Previous*/}
                                    {/*</Link>*/}
                                </li>

                                <li className={activeTab === 4 ? "next disabled" : "next"}>
                                    {/*<Link to="#" className="btn btn-primary" onClick={handleNext}>*/}
                                    {/*    {activeTab === 4 ? "Submit" : "Next"}<i className="bx bx-chevron-right ms-1"></i>*/}
                                    {/*</Link>*/}
                                    <Link to="#" className="btn btn-primary" onClick={handleNext}>
                                        {"Submit"}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </CardBody>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PayrollWizard;
