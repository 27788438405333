import { React, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Button } from "reactstrap"
import github from "../../assets/images/brands/github.png";
import FeatherIcon from "feather-icons-react";

const Breadcrumb = props => {

    const [maxIcon, setMaxIcon] = useState("maximize");

  const expandClick = () => {      
      var _page = document.getElementsByClassName("page-content")
      console.log(_page.length);
      if (_page.length > 0) {
          _page[0].className = "fullScreenView";
          setMaxIcon("minimize");
      } else {
          var _page = document.getElementsByClassName("fullScreenView")
          _page[0].className = "page-content";
          setMaxIcon("maximize");
      }
  }

  return (
    <Row>
      <Col xs="12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
                  <div className="page-title-right">
                      {props.enableAction ?
                          <Button type="button" className='btn btn-primary waves-effect waves-light' tag={Link} to="#" onClick={props.onClick} color="primary">
                              <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                              {props.actionName}
                          </Button> : ""
                      }
                      <div style={{ paddingLeft: '25px', paddingRight: '10px', display: 'inline' }}>
                          <a style={{ cursor: 'pointer' }} onClick={expandClick}>
                              <FeatherIcon icon={maxIcon} />
                          </a>
                      </div>
                  </div>                  
            </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string,
    enableAction: PropTypes.bool,
    actionName: PropTypes.string
}

export default Breadcrumb
