import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, Sort, ColumnMenu, GridComponent, Page, Inject, Toolbar, ExcelExport, PdfExport }
    from "@syncfusion/ej2-react-grids";

import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import Spinner from "../../shared/spinner";
import { AddPolicy } from "./AddPolicy";

export const HRPolicies = () => {
    const [policies, setPolicyData] = useState();
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [updateList, setUpdate] = useState('');

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    useEffect(() => {
        setPolicyData([])
        // console.log("inside useEffect");
        // getAllContractors().then(res => {
        //     console.log(res.data);
        //     if (res.data) {
        //         setContractorsData(res.data)
        //     }
        // });
    }, [updateList]);


    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const formSubmitted = (formSuccess) => {
        console.log(formSuccess);
        setUpdate(formSuccess);
        console.log(updateList);
    }

    function toolbarClick(args) {
        console.log(args.item);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    return (

        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Policies" breadcrumbItem="Policies" enableAction={true} actionName="New Policy" onClick={toggleCanvasEnd} />
                    {!policies ?
                        <div className='text-center mt-4'>
                            <Spinner />
                        </div> : <GridComponent allowPaging={true} allowSorting={true} toolbar={toolbarOptions}
                            showColumnMenu={true} pageSettings={{ pageCount: 5 }} toolbarClick={toolbarClick.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective field="name" headerText='POLICY NAME' width='150'></ColumnDirective>
                                <ColumnDirective field="emailId" headerText='DESCRIPTION' width='150' />
                                <ColumnDirective field="phoneNo" headerText='DOWNLOAD' width='150'></ColumnDirective>
                                <ColumnDirective field="city" headerText='VIEW' width='150' />
                                <ColumnDirective field="bankName" headerText='ATTACHMENT' width='150' />
                                <ColumnDirective field="branchName" headerText='ACTION' width='150' />
                            </ColumnsDirective>
                            <Inject services={[Page, Toolbar, Sort, PdfExport, ColumnMenu]} />
                        </GridComponent>}

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" style={{'width':'550px'}} direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Create New Policy</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />

                            <OffcanvasBody>
                                <AddPolicy />
                                {/* <AddHRContractors onStateChange={formSubmitted} /> */}
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>


    );
}