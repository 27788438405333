import PropTypes from 'prop-types';
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Modal } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LightDark from "../CommonForBoth/Menus/LightDark";

// import images
import logoSvg from "../../assets/images/logo-sm.svg";
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

//i18n
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from "react-redux";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changelayoutMode
} from "../../store/actions";
import { HelpModal } from '../CommonForBoth/Modal/TopModal';

const Header = props => {
  const dispatch = useDispatch();
  const { showRightSidebar } = useSelector((state) => ({
    showRightSidebar: state.Layout.ShowRightSidebar
  }));
  const { onChangeLayoutMode } = props;
  const [search, setsearch] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [isClick, setClick] = useState(true);
  const [modal_longscroll, setmodal_longscroll] = useState(false);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute('data-sidebar-size', 'sm');
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute('data-sidebar-size', 'lg');
    }
  }

  function tog_longscroll() {
    //setmodal_longscroll(!modal_longscroll);
      //removeBodyCss();
      window.open("/help");
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const onModalClose = () => {
    setmodal_longscroll(false)
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" /> <span className="logo-txt">HRMS</span>
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" /> <span className="logo-txt">HRMS</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button" className="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars"></i>
            </button>


          </div>

          <div className="d-flex h4">ಕರ್ನಾಟಕ ಮಹರ್ಷಿ ವಾಲ್ಮೀಕಿ ಪರಿಶಿಷ್ಟ ಪಂಗಡಗಳ ಅಭಿವೃದ್ದಿ ನಿಗಮ ನಿಯಮಿತ</div>

          <div className="d-flex">
            
                <Dropdown
                    className="d-none d-lg-inline-block ms-1"
                    isOpen={socialDrp}
                    toggle={() => {
                        //setsocialDrp(!socialDrp);
                    }}>
                    <DropdownToggle
                        className="btn header-item noti-icon "
                        tag="button"
                    >
                        <FeatherIcon
                            icon="grid"
                            className="icon-lg"
                        />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                        <div className="p-2">
                            <Row className="g-0">
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={github} alt="Github" />
                                        <span>GitHub</span>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={bitbucket} alt="bitbucket" />
                                        <span>Bitbucket</span>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={dribbble} alt="dribbble" />
                                        <span>Dribbble</span>
                                    </Link>
                                </Col>
                            </Row>

                            <Row className="g-0">
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={dropbox} alt="dropbox" />
                                        <span>Dropbox</span>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={mail_chimp} alt="mail_chimp" />
                                        <span>Mail Chimp</span>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link className="dropdown-icon-item" to="#">
                                        <img src={slack} alt="slack" />
                                        <span>Slack</span>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </DropdownMenu>
                </Dropdown>

            <NotificationDropdown />
            <div
              onClick={() => {
                   //dispatch(showRightSidebarAction(!showRightSidebar));
              }}
              className="dropdown d-inline-block" >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle " >
                <FeatherIcon icon="settings" className="icon-lg" />
              </button>
            </div>
            <ProfileMenu />

          </div>
        </div>
      </header>

      <Modal id="exampleModalLongScrollable" isOpen={modal_longscroll}
        toggle={() => {
          tog_longscroll();
        }}>
        <HelpModal onCloseClick={onModalClose} />
      </Modal>

    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  changelayoutMode: PropTypes.func,
  layoutMode: PropTypes.any,
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    layoutMode
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, layoutMode };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  changelayoutMode,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
