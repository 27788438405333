import React, { useEffect, useState } from 'react';
import {
    Card, CardBody, CardHeader, Col, Row, FormGroup, Label, Input, Button, FormFeedback, Form
} from 'reactstrap';
import { addExpenseRequest, createExpense, getEmployeeList, getExpenseTypes, modifyExpenseData, getLoanTypes } from "../../../helpers/backend_helper";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { FailureAlert, SuccessAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/Loading';

const LoanBody = () => {
    const [expenseTypes, setExpenseTypes] = useState();
    const [employees, setEmployees] = useState();

    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("Choose File");
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        getLoanTypes().then((res) => {
            if (res.status === true) {
                let expenseTypes = [{ id: 0, name: "Select Loan Type" }]
                res.data.forEach(el => {
                    expenseTypes.push(el)
                });
                setExpenseTypes(expenseTypes);
            }
        })

        getEmployeeList().then((res) => {
            if (res.status === true) {
                let employeesList = [{ id: 0, fullName: "Select Employee" }]
                res.data.forEach(el => {
                    employeesList.push(el)
                });
                setEmployees(employeesList);
            }
        });
    }, []);

    const handleChange = event => {
        console.log(event.target.files[0], event.target.files[0].name);
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    }

    // console.log(expenseTypes[0]);

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            EmployeeId: '',
            ExpenseTypeId: '',
            ExpenseDate: '',
            Amount: '',
            // projName: '',
            Description: '',
            Documents: '',
        },
        validationSchema: Yup.object({
            EmployeeId: Yup.string().required("Please Select Employee"),
            ExpenseTypeId: Yup.string().required("Please Select Expense Type"),
            ExpenseDate: Yup.string().required("Please Enter Expense Incurred Date"),
            Amount: Yup.string().required("Please Enter Expense Amount"),
            // projName: Yup.string().required("Please Enter Project Name/Cost Center"),
            // Description: Yup.string().required("Please Enter Description"),
        }),
        onSubmit: async (values) => {
            console.log("values", values);

            const formData = new FormData();
            // formData.append("Documents", file);
            formData.append("employeeId", values.EmployeeId);
            formData.append("expenseTypeId", values.ExpenseTypeId);
            formData.append("expenseDate", values.ExpenseDate);
            formData.append("amount", values.Amount);
            formData.append("description", values.Description);
            // formData.append("documents", file);
            console.log(file, selectedFiles);
            if (selectedFiles.length > 0) {
                selectedFiles.forEach((file, index) => {
                    console.log(file);
                    formData.append(`documents`, file);
                });
            } else {
                console.log("Inside Else");
                formData.append(`documents`, []);
            }


            const response = await createExpense(formData);
            console.log(response);

            // addExpenseRequest(formData).then(res => {
            //     if (res.status === true) {
            //         console.log("Inside success");
            //         setDataSuccess("added");
            //         setIsLoading(false);
            //     } else {
            //         console.log("Inside failure");
            //         setDataSuccess("failed");
            //         setIsLoading(false);
            //     }
            //     setFormSubmit(!submitted);
            //     timeout();
            // }).catch((err) => {
            //     console.log(err);
            //     setDataSuccess("failed");
            //     setIsLoading(false);
            //     timeout();
            // })
        }
    });

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    return (
        <React.Fragment>
            <Col xl={12}>
                <CardBody>
                    <Form className="needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <Row>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">Employee</Label>

                                    <Input name="EmployeeId" type="select"
                                        className="form-control" id="validationCustom01"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.EmployeeId || ""}
                                        invalid={
                                            validation.touched.EmployeeId && validation.errors.EmployeeId ? true : false
                                        } >
                                        {employees &&
                                            employees.map((res) => {
                                                return <option value={res.id} key={res.id}>{res.fullName}</option>
                                            })}
                                    </Input>
                                    {validation.touched.EmployeeId && validation.errors.EmployeeId ? (
                                        <FormFeedback type="invalid">{validation.errors.EmployeeId}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom01">Loan Type</Label>

                                    <Input name="ExpenseTypeId" type="select"
                                        className="form-control" id="validationCustom01"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ExpenseTypeId || ""}
                                        invalid={
                                            validation.touched.ExpenseTypeId && validation.errors.ExpenseTypeId ? true : false
                                        } >
                                        {expenseTypes &&
                                            expenseTypes.map((res) => {
                                                return <option value={res.id} key={res.id}>{res.name}</option>
                                            })}
                                    </Input>
                                    {validation.touched.ExpenseTypeId && validation.errors.ExpenseTypeId ? (
                                        <FormFeedback type="invalid">{validation.errors.ExpenseTypeId}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Loan Date</Label>
                                    <Input name="ExpenseDate"
                                        type="date" className="form-control" id="validationCustom02" onChange={validation.handleChange}
                                        onBlur={validation.handleBlur} value={validation.values.ExpenseDate || ""}
                                        invalid={
                                            validation.touched.ExpenseDate && validation.errors.ExpenseDate ? true : false
                                        }
                                    />
                                    {validation.touched.ExpenseDate && validation.errors.ExpenseDate ? (
                                        <FormFeedback type="invalid">{validation.errors.ExpenseDate}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>                            
                            <Col sm='12' className='mb-1'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Loan Amount</Label>
                                    <Input
                                        name="Amount"
                                        type="number"
                                        placeholder='Expense Amount'
                                        className="form-control"
                                        id="validationCustom02"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Amount || ""}
                                        invalid={
                                            validation.touched.Amount && validation.errors.Amount ? true : false
                                        }
                                    />
                                    {validation.touched.Amount && validation.errors.Amount ? (
                                        <FormFeedback type="invalid">{validation.errors.Amount}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm='12' className='mb-1'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Installments</Label>
                                    <Input
                                        name="Amount"
                                        type="number"
                                        placeholder='Expense Amount'
                                        className="form-control"
                                        id="validationCustom02"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Amount || ""}
                                        invalid={
                                            validation.touched.Amount && validation.errors.Amount ? true : false
                                        }
                                    />
                                    {validation.touched.Amount && validation.errors.Amount ? (
                                        <FormFeedback type="invalid">{validation.errors.Amount}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">EMI Date</Label>
                                    <Input name="ExpenseDate"
                                        type="date" className="form-control" id="validationCustom02" onChange={validation.handleChange}
                                        onBlur={validation.handleBlur} value={validation.values.ExpenseDate || ""}
                                        invalid={
                                            validation.touched.ExpenseDate && validation.errors.ExpenseDate ? true : false
                                        }
                                    />
                                    {validation.touched.ExpenseDate && validation.errors.ExpenseDate ? (
                                        <FormFeedback type="invalid">{validation.errors.ExpenseDate}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm='12' className='mb-1'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Description</Label>
                                    <Input
                                        name="Description"
                                        type="textarea"
                                        placeholder='Description....'
                                        className="form-control"
                                        id="validationCustom02"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Description || ""}
                                        invalid={
                                            validation.touched.Description && validation.errors.Description ? true : false
                                        }
                                    />
                                    {validation.touched.Description && validation.errors.Description ? (
                                        <FormFeedback type="invalid">{validation.errors.Description}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>

                            <Col sm='12' md='12' lg='12'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCustom02">Documents</Label>
                                    <Input
                                        name="Description"
                                        type="file"
                                        className="form-control"
                                        id="validationCustom02"
                                        multiple
                                        onChange={handleFileUpload}
                                        // onChange={handleChange}
                                        // onBlur={validation.handleBlur}
                                        value={validation.values.Documents || ''}
                                    />

                                </FormGroup>
                            </Col>

                        </Row>
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            className="btn btn-sm" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;
                        <Button className='btn btn-sm m-2' color="secondary" type="button">
                            Close
                        </Button>

                        <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
                            {dataSuccess === 'added' && <SuccessAlert />}
                            {dataSuccess === 'failed' && <FailureAlert />}
                        </div>
                    </Form>
                </CardBody>
            </Col>
        </React.Fragment>
    );
};

export default LoanBody;