import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort } from "@syncfusion/ej2-react-grids";
import LeaveApproval from './LeaveApproval';
import { getEmployeeLeaveRequest } from "../../../helpers/backend_helper";
import { ToolbarOptions } from "../../../components/Common/Toolbar";
import LeaveDetail from '../LeaveRequest/LeaveDetail';

const PendingLeaveRequest = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [canvasDetailOpen, setCanvasDetailOpen] = useState(false);
    const [leaveRequestList, setLeaveRequestList] = useState();
    const [leaveId, setLeaveId] = useState();
    const [employeeId, setEmployeeId] = useState();

    useEffect(() => {
        var _data = {
            forApproval: true
        }
        getEmployeeLeaveRequest(_data).then((res) => {
            if (res.data) {
                setLeaveRequestList(res.data);
            }
        })
    }, [])

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const toggleCanvasDetailEnd = () => {
        setCanvasPlacement('end')
        setCanvasDetailOpen(!canvasDetailOpen)
    }

    const afterUserAction = () => {
        var _data = {
            forApproval: true
        }
        getEmployeeLeaveRequest(_data).then((res) => {
            if (res.data) {
                setLeaveRequestList(res.data);
            }
        })
        toggleCanvasDetailEnd();
    }

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const headerLink = props => {
        return (
            <button onClick={() => viewLeaveRequest(props.id, props.employeeProfile.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.leaveId}
            </button>
        )
    }

    const viewLeaveRequest = (leaveId, empId) => {
        setLeaveId(leaveId);
        setEmployeeId(empId);
        toggleCanvasDetailEnd();
    }

    return (
        <Fragment>
            <div className="page-content">

                <Modal isOpen={canvasDetailOpen} toggle={toggleCanvasDetailEnd} fullscreen>
                    <ModalHeader toggle={toggleCanvasDetailEnd}>Leave Details</ModalHeader>
                    <ModalBody>
                        <LeaveDetail leaveId={leaveId} employeeId={employeeId} closeAction={toggleCanvasDetailEnd} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="btn primary" type="button" onClick={toggleCanvasDetailEnd}>Close</Button>
                    </ModalFooter>
                </Modal>

                <Container fluid>
                    <Breadcrumbs title="Leave" breadcrumbItem="Leave Request for Approval" enableAction={false}></Breadcrumbs>
                                            
                    <GridComponent dataSource={leaveRequestList} allowPaging={true} toolbar={ToolbarOptions} ref={grid => gridInstance = grid}
                        pageSettings={{ pageCount: 5 }} statelessTemplates={['directiveTemplates']}
                        allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true}
                        toolbarClick={toolbarClick.bind(this)}>
                        <ColumnsDirective>                            
                            <ColumnDirective field="leaveId" headerText='Request Id' template={headerLink} width='150'></ColumnDirective>
                            <ColumnDirective field="employeeProfile.fullName" headerText='Employee' width='150'></ColumnDirective>
                            <ColumnDirective field="leaveType.name" headerText='Leave Type' width='150'></ColumnDirective>
                            <ColumnDirective field="leavePeriodType.name" headerText='Leave Period' width='150' />
                            <ColumnDirective field="fromDate" type="datetime" format="dd/MM/yyyy" headerText='From Date' width='150' />
                            <ColumnDirective field="toDate" type="datetime" format="dd/MM/yyyy" headerText='To Date' width='150' />                            
                            <ColumnDirective field="leaveStatus" headerText='Leave Status' width='150' />
                            <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy h:mm a" headerText='Last Updated' width='150'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                    </GridComponent>
                    
                </Container>
            </div>
        </Fragment>
    )
}

export default PendingLeaveRequest;