import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Input, Row, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React from "react";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort } from "@syncfusion/ej2-react-grids";
import { useEffect, useState, useRef } from "react";
import {
    createTimeEntry, getCompanyLocations, getCompanyPayperiods, getDepartments, getEmployeeList,
    getManulAttendanceList, getVendorsPayments, getAllContractors
} from "../../../helpers/backend_helper";
import { getData, postData } from "../../../helpers/url-request";
import TaskDetailView from "../../Tasks/MyTask/TaskDetailView";

const MonthlyAttendanceReportView = (props) => {
    let gridInstance;
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')
    const [locations, setLocations] = useState();
    const [payPeriods, setPayPeriods] = useState();
    const [payPeriod, setPayPeriod] = useState();
    const [location, setLocation] = useState();
    const [generatedOn, setGeneratedOn] = useState();
    const [generatedBy, setGeneratedBy] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [taskId, setTaskId] = useState();
    const [reportStatus, setReportStatus] = useState();
    const [attendance, setAttendanceDetails] = useState();
    
    useEffect(() => {

        console.log("props.reportId");
        console.log(props.reportId);

        getData("/attendance/monthlyreport/" + props.reportId).then(res => {
            console.log(res.data);
            setAttendanceDetails(res.data.monthlyAttendanceDetails);
            setLocation(res.data.location.locationName);
            setGeneratedOn(res.data.generatedOn);
            setGeneratedBy(res.data.generatedBy);
            setPayPeriod(res.data.payPeriod.payPeriodTitle);
            setFromDate(res.data.payPeriod.fromDate);
            setToDate(res.data.payPeriod.toDate);
            setReportStatus(res.data.reportStatus);
            setTaskId(res.data.taskId);
        });
       
    }, []);

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        console.log(args.item.text);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    return (
        <div>
            <Container fluid>
                <CardBody>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                     <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>Report</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>Approval</span>
                                    </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            className="twitter-bs-wizard-tab-content"
                            activeTab={activeTab}>
                            <TabPane tabId={1}>
                                <Row>
                                    <Col className="mt-2" sm='3' md='3' lg='3'>
                                        Period :  {payPeriod}
                                    </Col>
                                    <Col className="mt-2" sm='3' md='3' lg='3'>
                                        Location : {location}
                                    </Col>                       
                                    <Col className="mt-2" sm='3' md='3' lg='3'>
                                        Generated On : {generatedOn}
                                    </Col>
                                    <Col className="mt-2" sm='3' md='3' lg='3'>
                                        Generated By : {generatedBy}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-2" sm='3' md='3' lg='3'>
                                        From Date :  {fromDate}
                                    </Col>
                                    <Col className="mt-2" sm='3' md='3' lg='3'>
                                        To Date : {toDate}
                                    </Col>
                                    <Col className="mt-2" sm='3' md='3' lg='3'>
                                        Report Status : {reportStatus}
                                    </Col>
                                </Row>
            
                            <hr />
                            <div className="d-flex">
                                <GridComponent dataSource={attendance} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid} pageSettings={{ pageCount: 5 }}
                                allowExcelExport={true} showColumnMenu={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)}>
                                    <ColumnsDirective>
                                        <ColumnDirective field="employee.fullName" headerText='Employee Name' width='150'></ColumnDirective>
                                        <ColumnDirective field="employee.employeeType.name" headerText='Employee Type' width='100' />
                                        <ColumnDirective field="location.locationName" headerText='Location' width='150' />
                                        <ColumnDirective field="days" headerText='Days' width='150' />
                                        <ColumnDirective field="holidays" headerText='Holidays' width='150'></ColumnDirective>
                                        <ColumnDirective field="workingDays" headerText='Working Days' width='150' />
                                        <ColumnDirective field="leaves" headerText='Leaves' width='150' />
                                        <ColumnDirective field="lop" headerText='LOP' width='150' />
                                        <ColumnDirective field="presentDays" headerText='Present' width='150' />
                                    </ColumnsDirective>
                                    <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                                </GridComponent>                
                                </div>
                            </TabPane>
                            <TabPane tabId={2}>
                                {taskId > 0 ? <TaskDetailView taskId={taskId} /> : ""}
                            </TabPane>
                        </TabContent>
                    </div>
                </CardBody>
            </Container>
        </div>
    )
}

export default MonthlyAttendanceReportView;