import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { getExpenseTypes, createExpenseTypes, getEmployeeGrades, createEmployeeGrades, getEmployeeGroups } from "../../../helpers/backend_helper";

const SettingsGrades = () => {
    let gridInstance;
    const [gradeList, setGradeData] = useState([]);
    const [submitted, setFormSubmit] = useState(false);
    const [groups, setGroups] = useState();
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        payscale: '',
        description: '',
        status: 0,
        groupId: 0
    });
    const [errors, setErrors] = useState({});

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    useEffect(() => {
        console.log("inside useEffect");

        getEmployeeGroups().then(res => {
            console.log(res);
            if (res.data) {
                setGroups(res.data)
            }
        });

        getEmployeeGrades()
            .then(res => {
                console.log(res);
                if (res.data) {
                    setGradeData(res.data)
                }
            });
        // console.log(expenseList);
    }, [submitted]);

    const handleChange = event => {
        console.log(event.target.name);
        if (event.target.name === "status") {
            let statusSet = event.target.checked ? 1 : 0;
            setFormData({ ...formData, [event.target.name]: statusSet });
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }

    };

    const handleSubmit = event => {
        event.preventDefault();

        // Validate form inputs
        let formErrors = {};
        if (!formData.name) {
            formErrors.name = 'Grade Name is required';
        }
        if (!formData.payscale) {
            formErrors.payscale = 'Payscale is required';
        }
        // if (!formData.description) {
        //     formErrors.description = 'Description is required';
        // }

        setErrors(formErrors);

        // If there are no errors, submit the form
        if (Object.keys(formErrors).length === 0) {
            // console.log('Form submitted!', formData);
            //Submit the form here
            createEmployeeGrades(formData).then((res) => {
                console.log(res);
                setFormSubmit(!submitted);
            }).catch((err) => {
                console.log(err);
            })

        }
    };

    const toolbarOptions = [
        'Search',
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' },
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    const toolbarClick = (args) => {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    return (

        <Fragment>
            <div className="m-2">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Employee Groups" enableAction={true} actionName="Add Group" onClick={toggleCanvasEnd}></Breadcrumbs>

                    {!gradeList && <p>Loading Please wait.....</p>}

                    {gradeList && <GridComponent dataSource={gradeList} allowPaging={true} pageSettings={{ pageCount: 5, pageSizes: true }}
                        toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} ref={grid => gridInstance = grid} allowSorting={true}
                        statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true} allowResizing={true}>
                        <ColumnsDirective>                            
                            <ColumnDirective field="employeeGroup.name" headerText='Group Name' width='150'></ColumnDirective>
                            <ColumnDirective field="name" headerText='Grade Name' width='150'></ColumnDirective>
                            <ColumnDirective field="payscale" headerText='Pay Scale' width='150' />
                            <ColumnDirective headerText='Status' width='150' />
                            <ColumnDirective headerText='description' width='150'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize]} />
                    </GridComponent>}

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Group Details</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />
                            <OffcanvasBody>
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Name:
                                            </Label>
                                            <select className="form-select" name="groupId" onChange={handleChange}>
                                                {groups &&
                                                    groups.map((group) => {
                                                        return <option value={group.id} key={group.id}>{group.name}</option>
                                                    })}
                                            </select>
                                            <p style={{ color: "Red" }}>{errors.name && <span>{errors.name}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Name:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                onChange={handleChange}
                                                value={formData.name}
                                            />
                                            <p style={{ color: "Red" }}>{errors.name && <span>{errors.name}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Payscale:
                                            </Label>
                                            <Input
                                                type="text"
                                                name="payscale"
                                                placeholder="Payscale"
                                                onChange={handleChange}
                                                value={formData.payscale}
                                            />
                                            <p style={{ color: "Red" }}>{errors.payscale && <span>{errors.payscale}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Description:
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                placeholder="Description"
                                                onChange={handleChange}
                                                value={formData.description}
                                            />
                                            <p style={{ color: "Red" }}>{errors.description && <span>{errors.description}</span>}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <Label className='form-label' for='input-default'>
                                                Status:
                                            </Label>
                                            <Input
                                                type="checkbox"
                                                name="status"
                                                onChange={handleChange}
                                                value={formData.status}
                                            />
                                        </Col>
                                    </Row>

                                    <Button color="primary" type="submit">Submit</Button>
                                </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </Fragment>


    );
}

export default SettingsGrades;