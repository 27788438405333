// import Select from "react-select/dist/declarations/src/Select";
import { Button, Col, Input, Label, Row } from "reactstrap";

import Select from 'react-select'
import React, { useEffect, useState } from "react";
import { addEmployeeLeaveRequest, getEmployeeList, getLeaveTypes, getLocalStorage } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import { getData, postData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const AddLeaves = (props) => {
    const [leaveValue, setLeaveValue] = useState('');
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [leaveTypes, setLeaveTypes] = useState();
    const [employees, setEmployees] = useState();
    const [employeeList, setEmployeeList] = useState();
    const [role, setRole] = useState();

    const [formData, setFormData] = useState({
        employeeId: 0, leaveTypeId: 0, leaveDays: '', description: ''
    });
    const [errors, setErrors] = useState({});

    const confirmHandler = (event) => {
        event.preventDefault();
        let formErrors = {};

        if (!formData.leaveTypeId) {
            formErrors.leaveTypeId = 'Leave Type is required';
        }
       
        // if (!formData.description) {
        //     formErrors.description = 'Description is required';
        // }

        setErrors(formErrors);

        console.log(formData);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            postData("/Leave/manageleave", formData).then((res) => {
                // console.log(res);
                setIsLoading(false);
                setDataSuccess("added");
                timeout();
                if (props.onClose) {
                    props.onClose();
                }
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }
    }

    useEffect(() => {
        getLeaveTypes().then((res) => {
            //console.log(res);
            formData.leaveTypeId = res.data[0].id
            setLeaveTypes(res.data);
        });

        getEmployeeList().then((res) => {
            // console.log(res);
            formData.employeeId = res.data[0].id
            setEmployees(res.data);

            if (res.data) {
                let emps = [{ label: "Select Employee", value: 0 }];
                res.data.forEach(el => {
                    emps.push({ label: el.fullName, value: el.id });
                });
                setEmployeeList(emps)
            }
        });

        const storage = getLocalStorage();
        // console.log(storage);
        setRole(storage.roleName);

    }, [submitted]);

    // const onLeaveChange = e => {
    //     setLeaveValue(e.currentTarget.value);
    // }

    const handleChange = (event) => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    }

    const closeHandler = (event) => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    return (
        <>
            <form>
                <Row>
                    {role != 'Administrator' ? <></> :
                        <>
                            <Col className="mb-1" xl="4" md="4" sm="12">
                                Employee
                            </Col>
                            <Col className='mb-1' xl='8' md='8' sm='12'>
                                <Select type="select"
                                    placeholder='Select Employee'
                                    name="employeeId"
                                    isMulti={false}
                                    options={employeeList}
                                    classNamePrefix="form-select"
                                    isLoading={false}
                                    onChange={handleChange}
                                />
                                <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                            </Col>
                        </>
                    }

                    <Col className='mb-1' xl='4' md='4' sm='12'>
                        <span>Leave Type</span>
                    </Col>
                    <Col className='mb-1' xl='8' md='8' sm='12'>
                        <Input type="select" className="form-select" name="leaveTypeId"
                            onChange={handleChange} value={formData.leaveTypeId}>
                            {leaveTypes &&
                                leaveTypes.map((res) => {
                                    return <option value={res.id} key={res.id}>{res.name}</option>
                                })}</Input>
                        <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                    </Col>

                    <Col className='mb-1' xl='4' md='4' sm='12'>
                        <span>No. of Days</span>
                    </Col>
                    <Col className='mb-1' xl='8' md='8' sm='12'>
                        <Input type='number' name="leaveDays" onChange={handleChange} id='input-default' placeholder='No of Days' />
                    </Col>

                    <Col className='mb-1' xl='4' md='4' sm='12'>
                        <span>Description</span>
                    </Col>
                    <Col className='mb-1' xl='8' md='8' sm='12'>
                        <Input type='textarea' name="description" onChange={handleChange} id='input-default' placeholder='Description' />
                    </Col>

                    <div className="text-center mt-2">
                        {dataSuccess === 'added' && <SuccessAlert />}
                        {dataSuccess === 'failed' && <FailureAlert />}
                    </div>

                    <div className="offcanvas-footer">
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            onClick={confirmHandler} className="btn btn-sm" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;
                        <button type="reset" className="btn btn-dark btn-sm" onClick={closeHandler}>Close</button>
                    </div>
                </Row>
            </form>
        </>
    )
}

export default AddLeaves;