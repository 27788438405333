import { Fragment, useEffect } from "react"

import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";

import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import { useState } from "react";
import React from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Sort, Edit, ColumnMenu, CommandColumn, Toolbar, ExcelExport, PdfExport, Resize }
    from "@syncfusion/ej2-react-grids";

import AddHRContractors from "./add-vendors";
import { getAllContractors } from "../../../helpers/backend_helper";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import Spinner from "../../shared/spinner";
import { getLoggedInUser } from "../../../helpers/backend_helper";

const HRContractor = () => {

    let gridInstance;

    const [contractors, setContractorsData] = useState();
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [updateList, setUpdate] = useState('');

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    useEffect(() => {
        console.log("inside useEffect");
        console.log(updateList);
        getAllContractors().then(res => {
            console.log(res.data);
            if (res.data) {
                setContractorsData(res.data)
            }
        });
    }, [updateList]);

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' },
        // { text: 'Add Employee', prefixIcon: 'e-add', id: 'Add' },
        //{ template: toolbarTemplate }

    ];

    function toolbarClick(args) {
        console.log(args.item);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const formSubmitted = (formSuccess) => {
        console.log(formSuccess);
        setUpdate(formSuccess);
        console.log(updateList);
    }

    return (

        <React.Fragment>
            <div className="page-content">
                <div className='mt-2'>
                    <Breadcrumbs title="HR Management" breadcrumbItem="Vendors" enableAction={getLoggedInUser().roleId != 1 ? false : true} actionName="Add Vendor" onClick={toggleCanvasEnd}></Breadcrumbs>

                    {/* {!contractors && <p>Loading Please wait.....</p>} */}

                    {!contractors ?
                        <div className='text-center mt-4'>
                            <Spinner />
                        </div> : <GridComponent dataSource={contractors} ref={grid => gridInstance = grid} allowExcelExport={true} allowPdfExport={true}
                            allowSorting={true} toolbar={toolbarOptions} toolbarClick={toolbarClick.bind(this)} allowResizing={true}
                            allowPaging={true} showColumnMenu={true} pageSettings={{ pageCount: 5, pageSizes: true }}>
                        <ColumnsDirective>
                            <ColumnDirective field="name" headerText='Full Name' width='150'></ColumnDirective>
                            {/* <ColumnDirective field="companyName" headerText='CompanyName' width='150' /> */}
                            <ColumnDirective field="emailId" headerText='EmailId' width='150' />
                            <ColumnDirective field="phoneNo" headerText='PhoneNo' width='150'></ColumnDirective>
                            <ColumnDirective field="city" headerText='City' width='150' />
                            <ColumnDirective field="bankName" headerText='BankName' width='150' />
                            <ColumnDirective field="branchName" headerText='BranchName' width='150' />
                            <ColumnDirective field="accountNumber" headerText='AccountNumber' width='150' />
                            <ColumnDirective field="bankIFSC" headerText='BankIFSC' width='150' />
                        </ColumnsDirective>
                            <Inject services={[Toolbar, ExcelExport, Sort, Page, PdfExport, ColumnMenu, Resize]} />
                    </GridComponent>}

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop="static" className='w-50' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Vendor Details</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />

                            <OffcanvasBody>
                                <AddHRContractors onStateChange={formSubmitted} />
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </div>
            </div>
        </React.Fragment>


    );
}

export default HRContractor;