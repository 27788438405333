import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject } from "@syncfusion/ej2-react-grids";

const Forms = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="HR Management" breadcrumbItem="Forms" enableAction={false}></Breadcrumbs>

                    
                    <GridComponent allowPaging={true} pageSettings={{ pageCount: 5 }}>
                        <ColumnsDirective>
                            <ColumnDirective field="name" headerText='Form Type' width='150'></ColumnDirective>
                            <ColumnDirective field="description" headerText='Description' width='150' />
                            <ColumnDirective headerText='Status' width='150' />
                            <ColumnDirective headerText='Action' width='150'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page]} />
                    </GridComponent>
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Forms;