import { getData, postData, downloadFile, postRawData } from "../../../helpers/url-request";
import {
    ColumnDirective, ColumnsDirective, GridComponent, Page, Filter, FilterType, Inject, Sort, Edit,
    ColumnMenu, CommandColumn, Toolbar, Resize, ExcelExport, PdfExport, Group, Freeze } from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState, useRef } from "react";
import {
    Col, Container, Row, CardBody, Nav, NavItem, CardTitle, TabContent, TabPane, NavLink, Modal, ModalBody,
    ModalHeader, ModalFooter, Button, Offcanvas, OffcanvasBody, OffcanvasHeader, Input, Label, Card
} from "reactstrap";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import Select from 'react-select'
import classnames from "classnames";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import TaskDetailView from "../../Tasks/MyTask/TaskDetailView";
import PayslipDetail from "../Payslips/payslipdetail";
import { showLoading } from 'react-global-loading';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

const DeductionPaymentDetail = (payroll) => {
    let gridInstance;
    const [activeTab, setactiveTab] = useState(2);
    const [active, setActive] = useState('2')
    const [salaryslipList, setSalaryslipList] = useState();
    const [payrollDetail, setPayrollDetail] = useState();
    const [taskId, setTaskId] = useState();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [salaryDetail, setSalaryDetail] = useState();
    const [canvasDetailOpen, setCanvasDetailOpen] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [isBulkPayment, setIsBulkPayment] = useState(false);
    const [salaryIds, setSalaryIds] = useState([]);
    const [formData, setFormData] = useState({
        notes: '', paymentTypeId: 0, amount: 0, salaryIds : [], salaryId: 0, paymentDate: '', paymentRef: ''
    });
    const [errors, setErrors] = useState({});
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [pendingAmount, setPendingAmount] = useState(0);
    
    const loadPayslips = () => {

        showLoading(true);

        getData("/payroll/pendingdeduction/").then(res => {
            showLoading(false);
            console.log(res);
            if (res.data) {
                if (res.status == true)
                    setSalaryslipList(res.data);
                else
                    alert(res.message);
            }
        }).catch(error => {
            alert(error);
            showLoading(false);
        });
    }

    useEffect(() => {
        console.log(payroll.payrollId);

        showLoading(true);

        //getData("/payroll/pendingsalary").then(res => {
        //    console.log(res);
        //    if (res.data) {
        //        setPayrollDetail(res.data);
        //        setTaskId(res.data.taskId);
        //    }
        //}).catch(error => {
        //    alert(error);
        //    showLoading(false);
        //});

        loadPayslips();
        //setTaskId(payroll.taskId);

    }, []);

    const togglePaymentDetail = () => {        
        setCanvasDetailOpen(!canvasDetailOpen)
    }

    const submitApproval = () => {
        console.log("submitApproval");
    };

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' },
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' }
    ];

    const toolbarClick = (args) => {
        console.log(args.item);
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadPayslips();
                break;
        }
    }

    const viewSalarySlip = (id) => {

        showLoading(true);

        getData("/payroll/payslip/" + id).then(res => {
            console.log(res);
            if (res.data) {
                setSalaryDetail(res.data);
                setTimeout(() => {
                    showLoading(false);
                    toggle();
                }, 1000);
            } else {
                showLoading(false);
            }
        });
        
    }

    const handleAcceptedFiles = (files) => {
        console.log(files);
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setSelectedFiles(files);
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const onRowDoubleClick = (events) => {
        console.log(events.rowData);
        //setFormData({ ...formData, ["payscaleId"]: events.rowData.id });        
        //toggle();
        viewSalarySlip(events.rowData.id);
    };

    const linktemplate = props => {
        return (
            <button onClick={() => viewSalarySlip(props.salaryId)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                View
            </button>
        )
    }

    const updatetemplate = props => {
        return (
            <button onClick={() => updatePaymentDetail(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                Make/Update Payment
            </button>
        )
    }
       
    const updatePaymentDetail = (id) => {        
        setIsBulkPayment(false);
        showLoading(true);
        setFormData({ ...formData, "salaryId": id, "salaryIds": 0 });
        getData("/payroll/deduction/" + id).then(res => {
            console.log(res);
            if (res.data) {
                console.log("Salary Detail");
                console.log(res.data);
                setSalaryDetail(res.data);
                setSalaryIds([id]);
                setFormData({ ...formData, "amount": (res.data.netAmount - res.data.netPaid) });
                setPendingAmount(res.data.netAmount - res.data.netPaid);
                setTimeout(() => {
                    showLoading(false);
                    togglePaymentDetail();
                }, 1000);
            } else {
                showLoading(false);
            }
        });        
    }

    const confirmHandler = (event) => {
        event.preventDefault();
        let formErrors = {};
                
        if (!formData.amount) {
            formErrors.amount = 'Enter the amount/percentage';
        }
        
        if (!formData.paymentRef) {
            formErrors.paymentRef = 'Enter payment Reference';
        }

        setErrors(formErrors);

        console.log(formData);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {

            showLoading(true);           

            const _data = new FormData();

            _data.append("salaryIds", salaryIds);                
            _data.append("paymentDate", formData.paymentDate);
            _data.append("amount", formData.amount);
            _data.append("paymentTypeId", formData.paymentTypeId);
            _data.append("paymentRef", formData.paymentRef);
            _data.append("notes", formData.notes);

            if (selectedFiles.length > 0) {
                selectedFiles.forEach((file, index) => {
                    console.log(file);
                    _data.append("documents", file);
                });
            } else {
                console.log("Inside Else");
                _data.append("documents", []);
            }

            postRawData("/payment/makedeductionpayment", "POST", _data).then((res) => {
                showLoading(false);

                if (res.status == true) {
                    alert("Payment success");                    
                    togglePaymentDetail();
                } else {
                    alert(res.message);
                }
            }).catch((err) => {
                console.log(err);
                showLoading(false);
            })
        }
    }

    const handleChange = (event) => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });

            if (event.target.name == "correctionType") {
                setCorrectionType(event.target.value);
            }

        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    }

    const closeHandler = (event) => {
        
    }

    const doBulkPayment = (event) => {
        console.log("doBulkPayment");

        var selRows = gridInstance.getSelectedRecords();
        if (selRows.length > 0) {
            setSalaryDetail(null);
            setIsBulkPayment(true);

            setPendingAmount(0);

            var _data = [];
            selRows.map((_row, i) => {
                _data.push(_row.id);
            });

            setFormData({ ...formData, "salaryIds": _data });

            postData("/payroll/deductionpendingamount", _data).then(res => {
                showLoading(false);
                console.log(res); 
                if (res.status == true) {
                    setSalaryIds(_data);
                    setFormData({ ...formData, "amount": res.data });
                    setPendingAmount(res.data);
                    togglePaymentDetail();
                } else {
                    alert(res.message);
                }                
            }).catch(error => {
                alert(error);
                showLoading(false);
            });
            
        } else {
             alert("Please select the Deduction Details to make payment");
        }
    }

    return (        
        <div>

            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>Pay Slip Detail</ModalHeader>
                <ModalBody>
                    <PayslipDetail data={salaryDetail} />
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button color="secondary" className="btn primary" type="button" onClick={toggle}>Close</Button>
                    </div>
                </ModalFooter>
            </Modal>

            <Container fluid>

                <div style={{ paddingBottom: '20px' }}>
                    <Button color="primary" className="btn primary" onClick={doBulkPayment}>Make Payment</Button>
                </div>

                <div className="d-flex">
                    <GridComponent dataSource={salaryslipList} statelessTemplates={['directiveTemplates']} toolbar={toolbarOptions} ref={grid => gridInstance = grid}
                        allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowFiltering={true}
                        showColumnMenu={true} allowPaging={true} pageSettings={{ pageCount: 5, pageSizes: true }} filterSettings={{ type: 'Excel' }} allowResizing={true}>
                        <ColumnsDirective>
                            <ColumnDirective type='checkbox' width='50' />
                            <ColumnDirective field="employeeProfile.fullName" headerText='Employee Name' width='150'></ColumnDirective>
                            <ColumnDirective field="employeeProfile.workDepartment.name" headerText='Department' width='150'></ColumnDirective>                            
                            <ColumnDirective field="payrollDetail.payrollMonth" headerText='Pay Period' width='150'></ColumnDirective>                            
                            <ColumnDirective field="deduction" headerText='Deduction' width='150' />
                            <ColumnDirective field="amount" headerText='Amount' width='150' />
                            <ColumnDirective field="netDeducted" headerText='Net Paid' width='150' />                            
                            <ColumnDirective field="paymentDetails" headerText='Payment Detail' width='150' />
                            <ColumnDirective headerText='View' width='100' template={linktemplate} />
                            <ColumnDirective headerText='Action' width='150' template={updatetemplate} />
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Filter, ExcelExport, Sort, PdfExport, ColumnMenu, Resize]} />
                    </GridComponent>
                </div>

                <div className='demo-inline-spacing'>

                    <Offcanvas backdrop="static" className='w-40' direction="end" isOpen={canvasDetailOpen} toggle={togglePaymentDetail}>
                        <OffcanvasHeader toggle={togglePaymentDetail}>
                            <Row>
                                <Col sm='12'>
                                    <b>Payment Detail</b>
                                </Col>
                            </Row>
                        </OffcanvasHeader>
                        <hr />
                        <OffcanvasBody>
                            <form>
                                <Row>
                                    <Row hidden={isBulkPayment}>
                                        <Col sm="4" md="4">
                                            <Label>Employee : </Label>                                        
                                        </Col>

                                        <Col sm="8" md="8">
                                            <Label>{salaryDetail?.employeeProfile.fullName}</Label>
                                        </Col>

                                        <Col sm="4" md="4">
                                            <Label>Pay Month : </Label>                                        
                                        </Col>
                                        <Col sm="8" md="8">
                                            <Label>{salaryDetail?.payrollDetail.payrollMonth}</Label>
                                        </Col>

                                        <Col sm="4" md="4">
                                            <Label>Deduction : </Label>
                                        </Col>
                                        <Col sm="8" md="8">
                                            <Label>{salaryDetail?.deduction}</Label>
                                        </Col>

                                        <Col sm="4" md="4">
                                            <Label>Net Amount : </Label>                                        
                                        </Col>
                                        <Col sm="8" md="8">
                                            <Label>{salaryDetail?.amount}</Label>
                                        </Col>

                                        <Col sm="4" md="4">
                                            <Label>Net Paid : </Label>
                                        </Col>
                                        <Col sm="8" md="8">
                                            <Label>{salaryDetail?.netDeducted}</Label>
                                        </Col>
                                        <br /><br />
                                    </Row>

                                    <Col sm="12" md="12">
                                        <Label>Payment Date</Label>
                                        <Input type="date" onChange={handleChange} name='paymentDate'></Input>
                                        <p style={{ color: "Red" }}>{errors.paymentDate && <span>{errors.paymentDate}</span>}</p>
                                    </Col>

                                    <Col sm="12" md="12" className="mb-2">
                                        <Label>Select Payment Method</Label>
                                        <Input type="select" onChange={handleChange}
                                            name='paymentTypeId' placeholder="Select Payment Method">
                                            <option value='0'>Select Payment Method</option>                                            
                                            <option value='2'>Cheque</option>
                                            <option value='3'>Direct Transfer</option>
                                            <option value='4'>Direct Deposit</option>
                                        </Input>
                                        <p style={{ color: "Red" }}>{errors.paymentTypeId && <span>{errors.paymentTypeId}</span>}</p>
                                    </Col>

                                    <Col sm="12" md="12">
                                        <Label>Amount Paid</Label>
                                        <Input type="number" onChange={handleChange} name='amount' placeholder="Paid Amount" readOnly={isBulkPayment} defaultValue={pendingAmount}></Input>
                                        <p style={{ color: "Red" }}>{errors.amount && <span>{errors.amount}</span>}</p>
                                    </Col>

                                    <Col className='mb-1' xl='12' md='12' sm='12'>
                                        <Label>Payment Reference</Label>
                                        <Input type='textarea' name="paymentRef" rows="2" onChange={handleChange} id='input-default' placeholder='Payment Reference' />
                                    </Col>

                                    <Col className='mb-1' xl='12' md='12' sm='12'>
                                        <Label>Notes</Label>
                                        <Input type='textarea' name="notes" rows="4" onChange={handleChange} id='input-default' placeholder='Additional Notes' />
                                    </Col>
                                    
                                    <Col sm='12' className='mb-1'>
                                        <br />
                                        <Label>Payment Related Documents</Label>
                                        <Dropzone onDrop={(acceptedFiles) => {
                                            handleAcceptedFiles(acceptedFiles);
                                        }}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone" style={{ minHeight: '130px' }}>
                                                    <div className="dz-message needsclick mt-2" style={{ padding: '10px' }} {...getRootProps()} >
                                                        <input {...getInputProps()} />
                                                        <div className="mb-3">
                                                            <i className="display-4 text-muted bx bx-cloud-upload" />
                                                        </div>
                                                        <h4>Drop files here or click to upload.</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                            {selectedFiles.map((f, i) => {
                                                return (
                                                    <Card
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}>
                                                        <div className="p-2">
                                                            <Row className="align-items-center">
                                                                <Col className="col-auto">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        height="80"
                                                                        className="avatar-sm rounded bg-light"
                                                                        alt={f.name}
                                                                        src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUpJREFUOE+N0z1LHlEQhuHr1aDiRyEStTGK2PoTbO20sRESRAVtDWJhpYH8HwsbFQRRC02pgnaSIJgija3fMq9n4bDZDdlmd4cz9zwz85yG92cAt/iT/suvFrRjGVt4LA40MsAZBmsARYFrfMUenuJsDjjHMHoQFV/xgDv04xTTOMIMDsqAKyzhGz6mCof4jA4sYDPBXwq1ZQVDaEttPKO1oqU+/KgCXCaJE4jkG4yUABGPlr7XKfiE7gT4UAwqg0SsF8d1CmYxh58V0iN5FDGXjX8p6EwKcsZ98kHEYgYnVYDYwhcs4lfKLoYce59MLf3Gep2C8EFXmn5UzQ0XTgzzhE/CE03T5WsMBfNYwRh2MkAkh4I4s4u1KkCdlaNIAEJZfMeWKhXUAcJYU1hNgO10H/5q4QLjNZcpD4fN98stRDAA//vEtW8WewNXM0MR2YfR7AAAAABJRU5ErkJggg=="}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Link
                                                                        to="#"
                                                                        className="text-muted font-weight-bold"
                                                                    >
                                                                        {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                        <strong>{f.formattedSize}</strong>
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Card>
                                                );
                                            })}
                                        </div>
                                    </Col>
                                    

                                    <div className="text-center mt-2">
                                        {dataSuccess === 'added' && <SuccessAlert />}
                                        {dataSuccess === 'failed' && <FailureAlert />}
                                    </div>

                                    <div className="offcanvas-footer">
                                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                                            onClick={confirmHandler} className="btn" disabled={isLoading}
                                            type="submit">Update</Button>}&nbsp;&nbsp;&nbsp;
                                        <button type="reset" className="btn btn-dark" onClick={closeHandler}>Close</button>
                                    </div>
                                </Row>
                            </form>
                        </OffcanvasBody>
                    </Offcanvas>
                </div>

            </Container>
        </div>
    )
}

export default DeductionPaymentDetail;