import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";

import classnames from "classnames";
import Allowances from "./allowances";
import Deductions from "./deductions";
import PayPeriod from "./pay-period";
import TDSConfig from "./tdsconfig";
import PayslipSettings from "./payslip-settings";
import RoundingAmount from "./runding-amount";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const PayrollSettings = () => {
    const [activeTab, setactiveTab] = useState(1);
    const [active, setActive] = useState('1')

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab);
            }
        }
    }

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="HR Management" breadcrumbItem="Payroll Settings" enableAction={false}></Breadcrumbs>
                <Container fluid>
                    <CardBody>

                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1);
                                        }}>
                                        <span>Allowances</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            setactiveTab(2);
                                        }}>
                                        <span>Deductions</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            setactiveTab(3);
                                        }}>
                                        <span>Pay Period</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 4 })}
                                        onClick={() => {
                                            setactiveTab(4);
                                        }}>
                                        <span>TDS Config</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 5 })}
                                        onClick={() => {
                                            setactiveTab(5);
                                        }}>
                                        <span>Payslip Settings</span>
                                    </NavLink>
                                </NavItem>


                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 6 })}
                                        onClick={() => {
                                            setactiveTab(6);
                                        }}>
                                        <span>Rounding Amounts</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent
                                className="twitter-bs-wizard-tab-content"
                                activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Allowances />
                                </TabPane>
                                <TabPane tabId={2}>
                                   <Deductions />
                                </TabPane>

                                <TabPane tabId={3}>
                                    <PayPeriod />
                                </TabPane>

                                <TabPane tabId={4}>
                                   <TDSConfig />
                                </TabPane>

                                <TabPane tabId={5}>
                                    <PayslipSettings />
                                </TabPane>

                                <TabPane tabId={6}>
                                    <RoundingAmount />
                                </TabPane>
                            </TabContent>

                        </div>
                    </CardBody>
                </Container >
            </div >
        </React.Fragment >
    );
};

export default PayrollSettings;
