import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar } from "@syncfusion/ej2-react-grids";
import LoadingSpinner from "../../shared/Loading";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { addEmployeeAddress, addEmployeeBank, getAddressTypes, getEmployeeAddress, getEmployeeBanks } from "../../../helpers/backend_helper";
import { useParams } from "react-router-dom";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";


const EmployeeAddressDetails = (props) => {
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [address, setAddress] = useState({
        employeeId: 0, addressTypeId: 0, addressLine1: "", addressLine2: "",
        city: "", pincode: "", notes: ""
    });

    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [addressList, setAddressList] = useState();
    const [addressTypes, setAddressTypes] = useState();

    const params = useParams();

    const toggleAddress = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
        setErrors({});
    }

    useEffect(() => {
        setAddress({ ...address, 'employeeId': props.employeeId });

        getEmployeeAddress(props.employeeId).then(res => {
            // console.log(res.data);
            if (res.data) {
                setAddressList(res.data)
            }
        });

        getAddressTypes().then(res => {
            if (res.data) {
                address.addressTypeId = res.data[0].id;
                let addresses = [{ "id": 0, "name": "Select Address Type" }]
                res.data.forEach(element => {
                    addresses.push(element);
                });

                setAddressTypes(addresses);
            }
        });

    }, [submitted]);

    const handleChange = event => {
        setAddress({ ...address, [event.target.name]: event.target.value });
    };

    const submitAddressDetails = (event) => {
        event.preventDefault();
        console.log(address);
        let formErrors = {};
        if (!address.addressTypeId) {
            formErrors.addressTypeId = 'Address Type is required';
        }
        if (!address.addressLine1) {
            formErrors.addressLine1 = 'Address Line 1 is required';
        }
        if (!address.city) {
            formErrors.city = 'City is required';
        }
        // if (!address.bankIFSC) {
        //     formErrors.bankIFSC = 'Bank IFSC is required';
        // }

        setErrors(formErrors);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);

            console.log(address);
            addEmployeeAddress(address).then(res => {
                if (res.status === true) {
                    console.log("Inside success");
                    setDataSuccess("added");
                    setIsLoading(false);
                    setAddress({
                        addressTypeId: 0, addressLine1: "", addressLine2: "",
                        city: "", pincode: "", notes: ""
                    });
                } else {
                    console.log("Inside failure");
                    setDataSuccess("failed");
                    setIsLoading(false);
                }
                setFormSubmit(!submitted);
                timeout();
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }


    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const toolbarTemplate = () => {
        return (
            <Row className="m-2">
                <Col xl="9" className="mt-2" md="9" sm="9">
                    <CardTitle tag="h4">Address Information</CardTitle>
                </Col>
                <Col xl="3" md="3" sm="4">
                    <ButtonComponent onClick={toggleAddress}  className='e-btn e-outline e-primary'>
                        <i className="bx bx-plus font-size-16 align-middle"></i>{" "}Add Details</ButtonComponent>
                </Col>
            </Row>
        )
    }

    const toolbarOptions = [
        { template: toolbarTemplate }
    ];

    return (
        <>

            <Row className="m-2">
                <Col xl="9" className="mt-2" md="9" sm="9">
                    <CardTitle tag="h4">Address Information</CardTitle>
                </Col>
                <Col xl="3" md="3" sm="4">
                    <button type="button" onClick={toggleAddress} className="btn btn-success waves-effect waves-light">
                        <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                        Add
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button type="button" className="btn btn-warning waves-effect waves-light">
                        <i className="bx bx-revision font-size-16 align-middle me-2"></i>{" "}
                        Refresh
                    </button>
                </Col>
            </Row>                        

            <CardBody>
                <GridComponent dataSource={addressList} allowPaging={true} pageSettings={{ pageCount: 5 }} statelessTemplates={['directiveTemplates']}>
                    <ColumnsDirective>
                        <ColumnDirective field="addressLine1" headerText='Address Line 1' width='150'></ColumnDirective>
                        <ColumnDirective field="addressLine2" headerText='Address Line 2' width='150' />
                        <ColumnDirective field="city" headerText='City' width='150' />
                        <ColumnDirective field="pincode" headerText='Pin Code' width='150'></ColumnDirective>
                        <ColumnDirective field="createdOn" type="datetime" format='dd/MM/yyyy' headerText='Created On' width='150' />
                    </ColumnsDirective>
                    <Inject services={[Toolbar,Page]} />
                </GridComponent>
            </CardBody>


            <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleAddress}>
                <OffcanvasHeader toggle={toggleAddress}>
                    <Row>
                        <Col sm='12'>
                            <b>Address Details</b>
                        </Col>
                    </Row>
                </OffcanvasHeader>
                <hr />

                <OffcanvasBody>
                    <FormGroup onSubmit={submitAddressDetails}>
                        <Row >
                            <Col sm="12" md="12">
                                <Label for="addressTypeId">Address Type</Label>
                                <Input type="select" value={address.addressTypeId} onChange={handleChange}
                                    name='addressTypeId'>
                                    {addressTypes &&
                                        addressTypes.map((add) => {
                                            return <option value={add.id} key={add.id}>{add.name}</option>
                                        })}
                                </Input>
                                <p style={{ color: "Red" }}>{errors.addressTypeId && <span>{errors.addressTypeId}</span>}</p>
                            </Col>
                        </Row>

                        <Row >
                            <Col sm="12" md="12">
                                <Label for="addressLine1">Address Line 1</Label>
                                <Input type="textarea" value={address.addressLine1} onChange={handleChange}
                                    name="addressLine1" placeholder="Enter Address Line 1"></Input>
                                <p style={{ color: "Red" }}>{errors.addressLine1 && <span>{errors.addressLine1}</span>}</p>
                            </Col>
                        </Row>

                        <Row >
                            <Col sm="12" md="12">
                                <Label for="addressLine2">Address Line 2</Label>
                                <Input type="textarea" value={address.addressLine2} onChange={handleChange}
                                    name="addressLine2" placeholder="Enter Address Line 2"></Input>
                            </Col>
                        </Row>

                        <Row >
                            <Col sm="12" md="12">
                                <Label for="city">City</Label>
                                <Input type="text" value={address.city} onChange={handleChange}
                                    name="city" placeholder="Enter City Name"></Input>
                                <p style={{ color: "Red" }}>{errors.city && <span>{errors.city}</span>}</p>
                            </Col>
                        </Row>

                        <Row >
                            <Col sm="12" md="12">
                                <Label for="pincode">Pin Code</Label>
                                <Input type="text" value={address.pincode} onChange={handleChange}
                                    name="pincode" placeholder="Enter Pin Code"></Input>
                                <p style={{ color: "Red" }}>{errors.pincode && <span>{errors.pincode}</span>}</p>
                            </Col>
                        </Row>

                        <Row >
                            <Col sm="12" md="12">
                                <Label for="notes">Notes</Label>
                                <Input type="textarea" value={address.notes} onChange={handleChange}
                                    name="notes" placeholder="Enter Notes...."></Input>
                                <p style={{ color: "Red" }}>{errors.notes && <span>{errors.notes}</span>}</p>
                            </Col>
                        </Row>

                        <div className="text-center mt-2" style={{ marginBottom: '5rem' }}>
                            {dataSuccess === 'added' && <SuccessAlert />}
                            {dataSuccess === 'failed' && <FailureAlert />}
                        </div>

                        <div className="offcanvas-footer">
                            {isLoading ? <LoadingSpinner /> : <Button color="primary"
                                onClick={submitAddressDetails} className="btn btn-sm" disabled={isLoading}
                                type="submit">Submit</Button>}&nbsp;
                            <button onClick={toggleAddress} className="btn btn-dark btn-sm">Close</button>
                        </div>
                    </FormGroup>

                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default EmployeeAddressDetails;