import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input,
    Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row,
} from "reactstrap";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Resize } from "@syncfusion/ej2-react-grids";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCompanyPayperiods, getCompanyLocations, getDepartments, getEmployeeList, getAllContractors, getAllowancesTypes
} from "../../helpers/backend_helper";
import { getData, postData } from "../../helpers/url-request";
import Select from "react-select"
import { showLoading } from 'react-global-loading';

const BankDetailsReport = () => {
    let gridInstance;
    const [employees, setEmployees] = useState([]);
    const [departments, setDepartments] = useState();
    const [reportData, setReportData] = useState([]);
    const [formData, setFormData] = useState({
        "bankName": "", "branchName": "", "accountNumber": "",
        "bankIFSC": "", "employeeId": 0, "accountName": ""
    });

    useEffect(() => {
        getEmployeeList().then(res => {
            if (res.data) {
                let emps = [{ label: "ALL", value: 0 }];
                res.data.forEach(el => {
                    emps.push({ label: el.fullName, value: el.id });
                });
                setEmployees(emps)
            }
        });

    }, []);


    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {        
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    const handleChange = event => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    };

    const confirmHandler = (event) => {
        event.preventDefault();
        setReportData([]);
        showLoading(true);
        postData("/employee/searchbanks", formData).then(res => {
            console.log(res.data);
            setReportData(res.data);
            showLoading(false);
        });
    }

    const resetReport = () => {
        setReportData([]);
        setFormData({
            "bankName": 0, "branchName": 0,
            "bankIFSC": 0, "employeeId": 0
        });
    }

    return (
        <div className="page-content">
            <Breadcrumbs title="HR Management" breadcrumbItem="Bank Details Report" enableAction={false}></Breadcrumbs>
            <CardBody>
                <Row>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        Employee
                        <Select type="select"
                            placeholder='Select Employee'
                            name="employeeId"
                            isMulti={false}
                            options={employees}
                            classNamePrefix="form-select"
                            isLoading={false}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col className="mt-2" sm='3' md='3' lg='3'>
                        <br />
                        <Button color="primary" className="btn primary" type="submit" onClick={confirmHandler}>View Report</Button>&nbsp;&nbsp;&nbsp;
                        <Button color="primary" className="btn primary" type="reset" onClick={resetReport}>Clear</Button>
                    </Col>
                </Row>
            </CardBody>
            <hr />
            <CardBody>
                <GridComponent dataSource={reportData} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid}
                    pageSettings={{ pageCount: 5, pageSizes: true }} statelessTemplates={['directiveTemplates']} allowResizing={true}
                    allowExcelExport={true} allowPdfExport={true} allowSorting={true} toolbarClick={toolbarClick.bind(this)} >
                    <ColumnsDirective>
                        <ColumnDirective field="employee.fullName" headerText='Employee' width='150'></ColumnDirective>
                        <ColumnDirective field="employee.workTitle" headerText='Title' width='150'></ColumnDirective>
                        <ColumnDirective field="bankName" headerText='Bank Name' width='150'></ColumnDirective>
                        <ColumnDirective field="branchName" headerText='Branch' width='150'></ColumnDirective>
                        <ColumnDirective field="accountNumber" headerText="A/C Number" width='150' />
                        <ColumnDirective field="accountName" headerText="A/C Name" width='150' />
                        <ColumnDirective field="bankIFSC" headerText="IFSC" width='150' />
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Page, Resize]} />
                </GridComponent>
            </CardBody>
        </div>
    )
}

export default BankDetailsReport;