import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Input, Row, Button } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Filter, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { useEffect, useState } from "react";
import { getCompanyLocations, getUserLocations } from "../../helpers/backend_helper";
import { getData, postData } from "../../helpers/url-request";
import { showLoading } from 'react-global-loading';

const AttendanceGrid = () => {
    let gridInstance;
    const [attendance, setAttendanceDetails] = useState();
    const [locations, setLocations] = useState();
    const [formData, setFormData] = useState({
        "fromDate": "", "toDate": "",
        "locationId": 0, "departmentId": 0,
        "contractorId": 0, "employeeId": 0
    })

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
        }
    }

    useEffect(() => {

        getUserLocations().then(res => {
            if (res.data) {
                let emps = [{ "id": 0, "locationName": "ALL" }];
                res.data.forEach(el => {
                    emps.push(el)
                });
                setLocations(emps)
            }
        });
    }, []);

    const confirmHandler = (event) => {
        event.preventDefault();

        console.log(formData);

        if (formData.fromDate == '' || formData.toDate == '') {
            alert("Please select from date and to date");
            return;
        }

        showLoading(true);
        postData("/attendance/detailreport", formData).then(res => {
            showLoading(false);
            setAttendanceDetails(res.data);
        });
    }

    function statusTemplate(props) {

        return (<div>{props.attendanceStatus === "Present" ?
            <div>
                <span className="statustxt e-activecolor">Present</span>
            </div> :
            <div>
                <span className="statustxt e-inactivecolor">{props.attendanceStatus}</span>
            </div>}</div>);
    }

    const handleChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    function mapTemplate(props) {

        var _url = "https://www.google.com/maps?q=" + props.locationLat + "," + props.locationLon + "&z=17&hl=en";
        return (<div>{props.source === 'Mobile' ?
            <div><a href={_url} target="_blank" rel="noreferrer">View Location</a></div> : <div></div>}
        </div>);
    }

    return (
        <React.Fragment>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <form onSubmit={confirmHandler}>
                            <Row>
                                <Col className="mt-2" sm='3' md='3' lg='3'>
                                    From Date <Input type="date" name="fromDate" onChange={handleChange} className="form-control"></Input>
                                </Col>
                                <Col className="mt-2" sm='3' md='3' lg='3'>
                                    To Date <Input type="date" name="toDate" onChange={handleChange} className="form-control"></Input>
                                </Col>
                                <Col className="mt-2" sm='3' md='3' lg='3'>
                                    Location
                                    <select className="form-select" name="locationId" onChange={handleChange}>
                                        {locations &&
                                            locations.map((emp) => {
                                                return <option value={emp.id} key={emp.id}>{emp.locationName}</option>
                                            })}
                                    </select>
                                </Col>
                                <Col className="mt-2" sm='3' md='3' lg='3'>
                                    <br />
                                    <Button color="primary" className="btn primary" type="submit">View Attendance</Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                    <CardBody className="px-0 py-0">
                        <GridComponent dataSource={attendance} allowPaging={true} toolbar={toolbarOptions} ref={grid => gridInstance = grid}
                            pageSettings={{ pageCount: 5, pageSizes: true }} allowExcelExport={true} showColumnMenu={true} allowPdfExport={true}
                            allowSorting={true} toolbarClick={toolbarClick.bind(this)} allowResizing={true}
                            allowFiltering={true} filterSettings={{ type: 'Excel' }} statelessTemplates={['directiveTemplates']} >
                            <ColumnsDirective>
                                <ColumnDirective field="employeeName" headerText='Employee Name' width='200'></ColumnDirective>
                                <ColumnDirective field="employeeType" headerText='Employee Type' width='175'></ColumnDirective>
                                <ColumnDirective field="attendanceDate" headerText='Date' width='125' />
                                <ColumnDirective field="attendanceStatus" template={statusTemplate} headerText='Status' width='125' />
                                <ColumnDirective field="timeIn" headerText='Time In' width='125' />
                                <ColumnDirective field="timeOut" headerText='Time Out' width='125' />
                                <ColumnDirective field="source" headerText='Source' width='125' />
                                <ColumnDirective field="locationName" headerText='Office' width='175' />
                                <ColumnDirective headerText='View Location' template={mapTemplate} width='175' />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, Filter, ColumnMenu, ExcelExport, PdfExport, Resize]} />
                        </GridComponent>
                    </CardBody>

                </Card>

            </Col>
        </React.Fragment>
    );
}

export default AttendanceGrid;