import React from 'react';
import { Fragment, useEffect } from "react"
import { useForm } from "react-hook-form"
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort, ExcelExport, PdfExport, Resize } from "@syncfusion/ej2-react-grids";
import { Button, Card, CardHeader, CardTitle, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Form, FormGroup } from 'reactstrap'
import { useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useFormik, Field, FormikProvider } from "formik";
import Dropzone from "react-dropzone";
import LoadingSpinner from "../../shared/Loading";
import './style.css'
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { createLocation, getCompanyLocationById, getCompanyLocations, getMasterDistricts, getTaskTypeList, updateLocation } from "../../../helpers/backend_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import urlRequest from '../../../helpers/url-request';
import * as url from '../../../helpers/url_helper';
import { showLoading } from 'react-global-loading';

const TaskType = () => {
    let gridInstance;
    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [districts, setDistricts] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tasktypeList, setTasktypeList] = useState();
    const [errors, setErrors] = useState({});
    const [locData, setLocationDetails] = useState({
        id: 0, locationName: "", districtId: 0, city: "", lat: "", lon: "", radius: 0, description: ""
    });
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()

    const [selectedFiles, setselectedFiles] = useState([]);

    function handleAcceptedFiles(files) {
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    /**
  * Formats the size
  */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }


    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const handleChange = (event) => {
        console.log(event);
    }

    // const validationSchema = Yup.object().shape({
    //     locationName: Yup.string().required('locationName is required')
    // });

    // const { register, handleSubmit, reset, setValue, getValues, errors, formState } = useForm({
    //     resolver: yupResolver(validationSchema)
    // });

    const loadData = () => {
        showLoading(true);
        getTaskTypeList().then((res) => {
            showLoading(false);
            if (res.data) {
                setTasktypeList(res.data)
            }
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    // console.log(locationList);

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        //enableReinitialize: true,
        initialValues: {
            Name: '',
            Description: '',
            Documents: ''
        },
        onChange: (values, actions) => {
            console.log("On Change");
        },
        onSubmit: async (values) => {
            
            console.log("values", values);

            const formData = new FormData();
            formData.append("name", values.Name);
            formData.append("description", values.Description);            
            if (selectedFiles.length > 0) {
                selectedFiles.forEach((file, index) => {
                    console.log(file);
                    formData.append("documents", file);
                });
            } else {
                console.log("Inside Else");
                formData.append("documents", []);
            }

            const response = await createTaskType(formData);
            console.log(response);
            loadData();
            toggleCanvasEnd();
        }
    });

    const createTaskType = async body => {
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        var token = userdata.token;
        // const boundary = "----WebKitFormBoundary7MA4YWxkTrZu0gW";
        let requestOptions = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                // 'Content-Type': `multipart/form-data; boundary=${boundary}`,
                apiKey: 'key123',
            },
            body,
        };
        return fetch(url.BASE_URL + '/task/createtasktype', requestOptions)
            .then(response => {
                console.log({ response });
                return response;
            })
            .catch(err => {
                console.log({ err });
            });
    };

    const afterSubmit = (res) => {
        console.log("after Submit");
        if (res.status === true) {
            // console.log("Inside success");
            setDataSuccess("added");
            setIsLoading(false);
            //setLocationDetails({
            //    locationName: "", districtId: 0, city: "", lat: "", lon: "", radius: 0, description: ""
            //});
        } else {
            // console.log("Inside failure");
            setDataSuccess("failed");
            setIsLoading(false);
        }
        setFormSubmit(!submitted);
        timeout();
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    const toolbarOptions = [
        'Search',
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' },
        { text: 'Refresh', prefixIcon: 'e-refresh ', id: 'refresh' }
    ];


    function toolbarClick(args) {
        switch (args.item.id) {
            case 'pdf':
                gridInstance.pdfExport();
                break;
            case 'excel':
                gridInstance.excelExport();
                break;
            case 'csv':
                gridInstance.csvExport();
                break;
            case 'refresh':
                loadData();
                break;
        }
    }

    function statusTemplate(props) {
        return (<div>{props.status === 1 ?
            <div className="statustemp e-activecolor">
                <span className="statustxt e-activecolor">Active</span>
            </div> :
            <div className="statustemp e-inactivecolor">
                <span className="statustxt e-inactivecolor">Inactive</span>
            </div>}</div>);
    }


    const headerLink = props => {
        return (
            <button onClick={() => viewLocation(props.id)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                {props.locationName}
            </button>
        )
    }

    const viewLocation = (locationId) => {
        console.log(locationId);
        setIsEdit(true);
        // setLocationDetails({
        //     locationName: "aaaaa", districtId: 0, city: "", lat: "22", lon: "22", radius: 0, description: ""
        // });

        getCompanyLocationById(locationId).then((res) => {
            // console.log(res.data['locationName'], res.data['city'],
            //     res.data['lat'], res.data['lon']);
            setLocationDetails(locData => ({
                ...locData,
                id: res.data['id'], locationName: res.data['locationName'], districtId: 0, city: res.data['city'],
                lat: res.data['lat'], lon: res.data['lon'], radius: 0, description: res.data['description']
            }));
            // setTimeout(() => {
            //     console.log(locData);
            // }, 5000);
        })
        // const fields = ['locationName'];
        // fields.forEach(field => {
        //     console.log(field);
        //     console.log(locData);
        //     setValue(field, locData.locationName);
        // });
        toggleCanvasEnd();
        //history.push('/hr/employee/view/' + EmpId);
    }

    const addLocation = () => {
        setIsEdit(false);
        toggleCanvasEnd();
    }

    // setInterval(() => {
    //     console.log(locData);
    // }, 2000);

    const docsLink = props => {
        console.log("Inside docsLink");
        console.log(props.taskTypeFiles);

        const links = [];

        props.taskTypeFiles ? props.taskTypeFiles.map((doc) => {
            links.push(
                <button onClick={() => viewDocument(doc.id, doc.fileName)} color='primary' className='btn btn-link btn-sm waves-effect waves-light'>
                    {doc.fileName}
                </button>);
            links.push(<br></br>);
        }) : links.push(<label>None</label>)

        return (
            <div>{links}</div>
        )
    }

    const viewDocument = (docId, docName) => {
        console.log("view document: " + docId);
        let userdata = localStorage.getItem("authUser");
        userdata = JSON.parse(userdata);

        fetch(url.BASE_URL + '/task/downloadtasktypedocument/' + docId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + userdata.token,
                'apikey': 'key123',
            },
        }).then((response) => response.blob())
            .then((blob) => {
                console.log(blob);
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download', docName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Settings" breadcrumbItem="Task Types" enableAction={true} actionName="Add TaskType" onClick={addLocation} />

                    <GridComponent dataSource={tasktypeList} allowPaging={true} pageSettings={{ pageCount: 5, pageSizes: true }} ref={grid => gridInstance = grid}
                        toolbar={toolbarOptions} allowSorting={true} showColumnMenu={true} toolbarClick={toolbarClick.bind(this)}
                        statelessTemplates={['directiveTemplates']} allowExcelExport={true} allowPdfExport={true} allowResizing={true}>
                        <ColumnsDirective>
                            <ColumnDirective field="name" headerText='Task Type' width='150'></ColumnDirective>
                            <ColumnDirective field="description" headerText='Description' width='150' />                            
                            <ColumnDirective field="status" template={statusTemplate} headerText='Status' width='80' />
                            <ColumnDirective template={docsLink} headerText="Documents" allowTextWrap={true} width="150"></ColumnDirective>
                            <ColumnDirective field="updatedOn" type="datetime" format="dd/MM/yyyy" headerText='Last Modified' width='100' />                            
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Page, Sort, ColumnMenu, ExcelExport, PdfExport, Resize]} />
                    </GridComponent>

                    <div className='demo-inline-spacing'>

                        <Offcanvas backdrop={true} className='w-35' direction={canvasPlacement} isOpen={canvasOpen} toggle={toggleCanvasEnd}>
                            <OffcanvasHeader toggle={toggleCanvasEnd}>
                                <Row>
                                    <Col sm='12'>
                                        <b>Task Type</b>
                                    </Col>
                                </Row>
                            </OffcanvasHeader>
                            <hr />
                            <OffcanvasBody>                                
                                <form 
                                    onSubmit={validation.handleSubmit}>
                                    <Row>
                                        <Col sm='12' className='mb-1'>
                                            <FormGroup className="mb-3">
                                                <Label className='form-label' for='input-default'>
                                                    Name
                                                </Label>
                                                <input type='text' name="Name" placeholder='Task Type Name' value={validation.values.Name}
                                                    onChange={validation.handleChange}
                                                    className="form-control" />
                                                <p style={{ color: "Red" }}></p>
                                            </FormGroup>
                                        </Col>

                                        <Col sm='12' className='mb-4'>
                                            <Label className='form-label' for='description'>
                                                Description
                                            </Label>
                                            <Input type='textarea' className="form-control" value={validation.values.Description} name="Description"
                                                onChange={validation.handleChange} placeholder='Description' />
                                        </Col>

                                        <Col sm='12' className='mb-4'>
                                            <Dropzone onDrop={(acceptedFiles) => {
                                                    handleAcceptedFiles(acceptedFiles);
                                                }}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                        <div
                                                            className="dz-message needsclick mt-2"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                                            </div>
                                                            <h4>Drop files here or click to upload.</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                {selectedFiles.map((f, i) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={f.name}
                                                                            src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUpJREFUOE+N0z1LHlEQhuHr1aDiRyEStTGK2PoTbO20sRESRAVtDWJhpYH8HwsbFQRRC02pgnaSIJgija3fMq9n4bDZDdlmd4cz9zwz85yG92cAt/iT/suvFrRjGVt4LA40MsAZBmsARYFrfMUenuJsDjjHMHoQFV/xgDv04xTTOMIMDsqAKyzhGz6mCof4jA4sYDPBXwq1ZQVDaEttPKO1oqU+/KgCXCaJE4jkG4yUABGPlr7XKfiE7gT4UAwqg0SsF8d1CmYxh58V0iN5FDGXjX8p6EwKcsZ98kHEYgYnVYDYwhcs4lfKLoYce59MLf3Gep2C8EFXmn5UzQ0XTgzzhE/CE03T5WsMBfNYwRh2MkAkh4I4s4u1KkCdlaNIAEJZfMeWKhXUAcJYU1hNgO10H/5q4QLjNZcpD4fN98stRDAA//vEtW8WewNXM0MR2YfR7AAAAABJRU5ErkJggg=="}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    );
                                                })}
                                            </div>
                                        </Col>

                                        <div className="text-center mt-2">
                                            {dataSuccess === 'added' && <SuccessAlert message={"Updated"} />}
                                            {dataSuccess === 'failed' && <FailureAlert />}
                                        </div>

                                        <hr />

                                        <div>
                                            {isLoading ? <LoadingSpinner /> : <Button color="primary"
                                                className="btn btn-sm" disabled={isLoading}
                                                type="submit">Submit</Button>}&nbsp;
                                            <Button type="button" onClick={toggleCanvasEnd} className="btn btn-dark btn-sm">Close</Button>
                                        </div>

                                    </Row>
                                    </form>
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default TaskType;