import { Fragment, useEffect } from "react"

import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";

import React, { useState } from "react";
import { getPaymentTypes } from "../../../helpers/master_api_helpers";
import LoadingSpinner from "../../shared/Loading";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import { CreateVendors, getContractorsTypes } from "../../../helpers/backend_helper";


const AddHRContractors = (props) => {
    const [formData, setFormData] = useState({
        name: '',
        contractorTypeId: '',
        companyName: '',
        emailId: '',
        phoneNo: '',
        address: '',
        city: '',
        pincode: '',
        panNumber: '',
        gstin: '',
        taxPercentage: '',
        gstPercentage: '',
        paymentTypeId: '',
        bankName: '',
        branchName: '',
        accountNumber: '',
        accountName: '',
        bankIFSC: '',
        notes: '',
    });
    const [errors, setErrors] = useState({});
    const [formSuccess, setFormSuccess] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [contractorTypes, setContratorTypes] = useState([]);

    useEffect(() => {
        console.log("inside useEffect");
        getPaymentTypes().then((res) => {
            // console.log(res);
            if (res.data.length > 0) {
                setPaymentTypes(res.data);
                setFormData({ ...formData, "paymentTypeId": res.data[0].id });
            }
        }).catch((err) => {
            console.log(err);
        })

        getContractorsTypes().then((res) => {
            // console.log(res);
            if (res.data.length > 0) {
                let data = [{ 'id': 0, 'name': 'Select Contractor Type' }]
                res.data.forEach(el => {
                    data.push(el);
                });
                setContratorTypes(data);
                setFormData({ ...formData, "contractorTypeId": data[0].id });
            }
        }).catch((err) => {
            console.log(err);
        })

    }, []);

    const handleChange = event => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = event => {
        event.preventDefault();
        // props.onStateChange('updated');
        // Validate form inputs
        let formErrors = {};
        if (!formData.contractorTypeId) {
            formErrors.contractorTypeId = 'Full Name is required';
        }
        if (!formData.name) {
            formErrors.name = 'Full Name is required';
        }
        if (!formData.companyName) {
            formErrors.companyName = 'Company Name is required';
        }
        if (!formData.emailId) {
            formErrors.emailId = 'Email is required';
        }
        if (!formData.phoneNo) {
            formErrors.phoneNo = 'Phone No is required';
        }
        if (!formData.address) {
            formErrors.address = 'Address is required';
        }
        if (!formData.city) {
            formErrors.city = 'City is required';
        }
        if (!formData.pincode) {
            formErrors.pincode = 'Pincode is required';
        }
        if (!formData.gstPercentage) {
            formErrors.gstPercentage = 'GST % is required';
        }
        if (!formData.taxPercentage) {
            formErrors.taxPercentage = 'Tax % is required';
        }
        if (!formData.panNumber) {
            formErrors.panNumber = 'Pan Number is required';
        }
        if (!formData.bankName) {
            formErrors.bankName = 'BankName is required';
        }
        if (!formData.branchName) {
            formErrors.branchName = 'Branch Name is required';
        }
        if (!formData.accountNumber) {
            formErrors.accountNumber = 'Account Number is required';
        }
        if (!formData.accountName) {
            formErrors.accountName = 'Account Name is required';
        }
        if (!formData.bankIFSC) {
            formErrors.bankIFSC = 'BankIFSC is required';
        }
        if (!formData.notes) {
            formErrors.notes = 'Notes is required';
        }

        setErrors(formErrors);

        // If there are no errors, submit the form
        if (Object.keys(formErrors).length === 0) {
            setIsLoading(true);
            console.log(formData);
            CreateVendors(formData).then((res) => {
                console.log(res);
                if (res.data) {
                    props.onStateChange(res.status);
                    setFormSuccess('added');
                    setIsLoading(false);
                    resetForm();
                } else {
                    setFormSuccess('failed');
                    setIsLoading(false);
                }
                timeout();
            }).catch((err) => {
                console.log(err);
                setFormSuccess('failed');
                setIsLoading(false);
                timeout();
            })
        }
    };

    const resetForm = () => {
        setFormData({
            name: '', companyName: '', emailId: '', phoneNo: '', address: '', city: '', pincode: '',
            panNumber: '', gstin: '', taxPercentage: '', gstPercentage: '', paymentTypeId: '',
            bankName: '', branchName: '', accountNumber: '', accountName: '', bankIFSC: '', notes: ''
        })
    }

    const timeout = () => {
        setTimeout(() => {
            setFormSuccess('')
        }, 3000);
    }

    return (

        <Fragment>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Form className="needs-validation" onSubmit={handleSubmit}>
                            <Row>
                                <Col md="6">
                                    <label>
                                        Contractor Type:</label>
                                    <Input type="select" placeholder="Contractor Type" className="form-select" name="contractorTypeId"
                                        onChange={handleChange}
                                        value={formData.contractorTypeId} >
                                        {contractorTypes.map(item => {
                                            return (<option key={item.id} value={item.id}>{item.name}</option>);
                                        })}
                                    </Input>
                                    {errors.contractorTypeId && <span style={{ color: "Red" }}>{errors.contractorTypeId}</span>}
                                </Col>

                                <Col md="6" className="mb-2">
                                    <FormGroup className="mb-3">
                                        <label>
                                            Full Name: </label>
                                        <Input type="text" placeholder="Full Name" id="validationCustom01" className="form-control" name="name" onChange={handleChange}
                                            value={formData.name} />
                                        {errors.name && <span>{errors.name}</span>}

                                    </FormGroup>

                                </Col>

                                <Col md="6">
                                    <label>
                                        Company Name:
                                    </label>
                                    <Input type="text" placeholder="Company Name" className="form-control" name="companyName" onChange={handleChange}
                                        value={formData.companyName} />
                                    {errors.companyName && <span>{errors.companyName}</span>}
                                </Col>
                                {/* </Row>

                            <Row className="mb-3"> */}
                                <Col md="6" className="mb-2">
                                    <label>
                                        Email Id: </label>
                                    <Input type="email" placeholder="Enter Email address" className="form-control" name="emailId" onChange={handleChange}
                                        value={formData.emailId} />
                                    {errors.emailId && <span>{errors.emailId}</span>}
                                </Col>
                                <Col md="6">
                                    <label>
                                        Phone Number:</label>
                                    <Input type="text" placeholder="Enter Phone number" className="form-control" name="phoneNo" onChange={handleChange}
                                        value={formData.phoneNo} />
                                    {errors.phoneNo && <span>{errors.phoneNo}</span>}
                                </Col>
                                {/* </Row>

                            <Row className="mb-3"> */}
                                <Col md="6" className="mb-2">
                                    <label>
                                        Address: </label>
                                    <Input type="textarea" placeholder="Enter Address" className="form-control" name="address" onChange={handleChange}
                                        value={formData.address} />
                                    {errors.address && <span>{errors.address}</span>}
                                </Col>
                                <Col md="6">
                                    <label>
                                        City:</label>
                                    <Input type="text" placeholder="Enter City" className="form-control" name="city" onChange={handleChange}
                                        value={formData.city} />
                                    {errors.city && <span>{errors.city}</span>}
                                </Col>
                                {/* </Row>

                            <Row className="mb-3"> */}
                                <Col md="6" className="mb-2">
                                    <label>
                                        Pin Code: </label>
                                    <Input type="text" placeholder="Pin Code" className="form-control" name="pincode" onChange={handleChange}
                                        value={formData.pincode} />
                                    {errors.pincode && <span>{errors.pincode}</span>}
                                </Col>
                                <Col md="6">
                                    <label>
                                        PAN Number:</label>
                                    <Input type="text" placeholder="Enter PAN Number" className="form-control" name="panNumber" onChange={handleChange}
                                        value={formData.panNumber} />
                                    {errors.panNumber && <span>{errors.panNumber}</span>}

                                </Col>
                                {/* </Row>

                            <Row className="mb-3"> */}
                                <Col md="6" className="mb-2">
                                    <label>
                                        GSTIN:</label>
                                    <Input type="text" placeholder="Enter GSTIN" className="form-control" name="gstin" onChange={handleChange}
                                        value={formData.gstin} />
                                    {errors.gstin && <span>{errors.gstin}</span>}
                                </Col>
                                <Col md="6">
                                    <label>
                                        TaxPercentage: </label>
                                    <Input type="text" placeholder="Tax %" className="form-control" name="taxPercentage" onChange={handleChange}
                                        value={formData.taxPercentage} />
                                    {errors.taxPercentage && <span>{errors.taxPercentage}</span>}
                                </Col>
                                {/* </Row>

                            <Row className="mb-3"> */}
                                <Col md="6" className="mb-2">
                                    <label>
                                        GST Percentage:</label>
                                    <Input type="text" placeholder="GST %" className="form-control" name="gstPercentage" onChange={handleChange}
                                        value={formData.gstPercentage} />
                                    {errors.gstPercentage && <span>{errors.gstPercentage}</span>}
                                </Col>
                                <Col md="6">
                                    <label>
                                        Payment Type:</label>
                                    <Input type="select" placeholder="Payment Type" className="form-select" name="paymentTypeId"
                                        onChange={handleChange} value={formData.paymentTypeId} >
                                        {paymentTypes.map(item => {
                                            return (<option key={item.id} value={item.id}>{item.name}</option>);
                                        })}
                                    </Input>
                                    {errors.paymentTypeId && <span>{errors.paymentTypeId}</span>}
                                </Col>
                                {/* </Row>

                            <Row className="mb-3"> */}
                                <Col md="6" className="mb-2">
                                    <label>
                                        Bank Name:</label>
                                    <Input type="text" placeholder="Bank Name" className="form-control" name="bankName" onChange={handleChange}
                                        value={formData.bankName} />
                                    {errors.bankName && <span>{errors.bankName}</span>}
                                </Col>
                                <Col md="6">
                                    <label>
                                        BranchName:</label>
                                    <Input type="text" placeholder="Branch Name" className="form-control" name="branchName" onChange={handleChange}
                                        value={formData.branchName} />
                                    {errors.branchName && <span>{errors.branchName}</span>}
                                </Col>
                                {/* </Row>

                            <Row className="mb-3"> */}
                                <Col md="6" className="mb-2">
                                    <label>
                                        AccountNumber: </label>
                                    <Input type="text" placeholder="Enter Account Number" className="form-control" name="accountNumber" onChange={handleChange}
                                        value={formData.accountNumber} />
                                    {errors.accountNumber && <span>{errors.accountNumber}</span>}

                                </Col>
                                <Col md="6">
                                    <label>
                                        AccountName:</label>
                                    <Input type="text" placeholder="Enter Account Name" className="form-control" name="accountName" onChange={handleChange}
                                        value={formData.accountName} />
                                    {errors.accountName && <span>{errors.accountName}</span>}
                                </Col>
                                {/* </Row>

                            <Row className="mb-3"> */}
                                <Col md="6" className="mb-2">
                                    <label>
                                        BankIFSC:</label>
                                    <Input type="text" placeholder="Enter Bank IFSC Code " className="form-control" name="bankIFSC" onChange={handleChange}
                                        value={formData.bankIFSC} />
                                    {errors.bankIFSC && <span>{errors.bankIFSC}</span>}
                                </Col>
                                <Col md="6">

                                </Col>
                                {/* </Row>

                            <Row className="mb-3"> */}
                                <Col md="6">
                                    <label>
                                        Notes:</label>
                                    <Input type="textarea" placeholder="Enter Notes " className="form-control" name="notes" onChange={handleChange}
                                        value={formData.notes} />
                                    {errors.notes && <span>{errors.notes}</span>}
                                </Col>
                                <Col md="6">

                                </Col>
                            </Row>

                            <div className="text-center">
                                {formSuccess === 'added' && <SuccessAlert />}
                                {formSuccess === 'failed' && <FailureAlert />}
                            </div>
                            {isLoading ? <LoadingSpinner /> : <Button color="primary" disabled={isLoading} type="submit">Submit</Button>}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>


    );
}

export default AddHRContractors;