import { getData } from "./url-request";
import * as url from "./url_helper"

let userdata = localStorage.getItem("authUser");
userdata = JSON.parse(userdata);

const headers = new Headers();
headers.append('Content-Type', 'application/json');
if (userdata) {
    headers.append('Authorization', "Bearer " + userdata.token);
}
headers.append('ApiKey', 'key123');

// GET MASTER PAYMENT TYPES
const getPaymentTypes = async () => {
    return await getData(url.GET_PAYMENTTYPES);
}

export { getPaymentTypes }