import React, { useEffect, useState } from "react";
import { CardBody, Nav, NavItem, TabContent, TabPane, NavLink, Label, Input, Card, CardHeader, CardTitle, Col, Row, Container, Button } from "reactstrap";

import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject }
    from "@syncfusion/ej2-react-grids";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";

const ExpensePolicy = () => {

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Settings" breadcrumbItem="Expense Policy" enableAction={false}></Breadcrumbs>

                <Container fluid>
                    <GridComponent allowPaging={true} pageSettings={{ pageCount: 5 }}>
                        <ColumnsDirective>
                            <ColumnDirective field="name" headerText='Expense Type' width='150'></ColumnDirective>
                            <ColumnDirective field="description" headerText='Description' width='150' />
                            <ColumnDirective headerText='Status' width='150' />
                            <ColumnDirective headerText='Action' width='150'></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page]} />
                    </GridComponent>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ExpensePolicy;