import * as React from "react";
import { SymbolPaletteComponent, NodeConstraints, DiagramComponent, Inject, BpmnDiagrams, UndoRedo, DiagramContextMenu, DataBinding, } from "@syncfusion/ej2-react-diagrams";
import { SampleBase } from "./sample-base";
import './index.css';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container } from "reactstrap";

// import "./bpmn-icons.css";
let diagram;
let nodes = [
    {
        id: 'start', width: 40, height: 40, offsetX: 35, offsetY: 230, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'Start' }
        }
    },
    {
        id: 'subProcess', width: 520, height: 250, offsetX: 355, offsetY: 230,
        constraints: NodeConstraints.Default & ~NodeConstraints.Drag,
        shape: {
            shape: 'Activity', type: 'Bpmn',
            activity: {
                activity: 'SubProcess', subProcess: {
                    type: 'Transaction', collapsed: false,
                    processes: ['processesStart', 'service', 'compensation', 'processesTask',
                        'error', 'processesEnd', 'user', 'subProcessesEnd']
                }
            }
        }
    },
    {
        id: 'hazardEnd', width: 40, height: 40, offsetX: 305, offsetY: 420, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'End' },
        }, annotations: [{
            id: 'label2', content: 'Hazard',
            style: { fill: 'white', color: 'black' }, verticalAlignment: 'Top', margin: { top: 20 }
        }]
    },
    {
        id: 'cancelledEnd', width: 40, height: 40, offsetX: 545, offsetY: 420, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'End' },
        }, annotations: [{
            id: 'cancelledEndLabel2', content: 'Cancelled',
            style: { fill: 'white', color: 'black' }, verticalAlignment: 'Top', margin: { top: 20 }
        }]
    },
    {
        id: 'end', width: 40, height: 40, offsetX: 665, offsetY: 230, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'End' }
        },
    },
    {
        id: 'processesStart', width: 30, height: 30, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'Start' }
        }, margin: { left: 40, top: 80 }
    },
    {
        id: 'service', style: { fill: '#6FAAB0' }, width: 95, height: 70,
        shape: {
            type: 'Bpmn', shape: 'Activity', activity: {
                activity: 'Task', task: {
                    type: 'Service',
                    loop: 'ParallelMultiInstance',
                },
            },
        }, annotations: [{
            id: 'serviceLabel2', content: 'Applied', offset: { x: 0.50, y: 0.50 },
            style: { color: 'white', }
        }], margin: { left: 110, top: 20 },
    },
    {
        id: 'service', style: { fill: '#6FAAB0' }, width: 95, height: 70,
        shape: {
            type: 'Bpmn', shape: 'Activity', activity: {
                activity: 'Task', task: {
                    type: 'Service',
                    loop: 'ParallelMultiInstance',
                },
            },
        }, annotations: [{
            id: 'serviceLabel2', content: 'Applied 2', offset: { x: 0.50, y: 0.50 },
            style: { color: 'white', }
        }], margin: { left: 150, top: 50 },
    },
    {
        id: 'compensation', width: 30, height: 30,
        shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'Intermediate', trigger: 'Compensation' }
        }, margin: { left: 170, top: 100 }
    },
    {
        id: 'processesTask', style: { fill: '#F6B53F' }, width: 95, height: 70,
        shape: {
            type: 'Bpmn', shape: 'Activity', activity: {
                activity: 'Task', task: {
                    type: 'Service',
                },
            },
        }, annotations: [{
            id: 'serviceLabel2', content: 'Process', offset: { x: 0.50, y: 0.60 },
            style: { color: 'white' }
        }], margin: { left: 290, top: 20 },
    },
    {
        id: 'error', width: 30, height: 30,
        shape: {
            type: 'Bpmn', shape: 'Event',
            event: {
                event: 'Intermediate', trigger: 'Error'
            }
        }, margin: { left: 350, top: 100 }
    },
    {
        id: 'processesEnd', width: 30, height: 30, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'End' }
        }, margin: { left: 440, top: 80 }
    },
    {
        id: 'user', style: { fill: '#E94649' }, width: 90, height: 80,
        shape: {
            type: 'Bpmn', shape: 'Activity', activity: {
                activity: 'Task', task: {
                    type: 'User', compensation: true
                },
            },
        }, annotations: [{
            id: 'serviceLabel2', content: 'Withdraw', offset: { x: 0.50, y: 0.60 },
            style: { color: 'white' }
        }], margin: { left: 240, top: 160 },
    },
    {
        id: 'subProcessesEnd', width: 30, height: 30, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'End' }
        }, margin: { left: 440, top: 210 }
    },
];

let connectors = [
    { id: 'connector1', sourceID: 'start', targetID: 'subProcess' },
    { id: 'connector2', sourceID: 'subProcess', sourcePortID: 'success', targetID: 'end' },
    {
        id: 'connector3', sourceID: 'subProcess', sourcePortID: 'failure', targetID: 'hazardEnd', type: 'Orthogonal',
        segments: [{ type: 'Orthogonal', length: 50, direction: 'Bottom' }],
        annotations: [{
            id: 'connector3Label2', content: 'Failure', offset: 0.50,
            style: { fill: 'white' }
        }]
    },
    {
        id: 'connector4', sourceID: 'subProcess', sourcePortID: 'cancel', targetID: 'cancelledEnd', type: 'Orthogonal',
        segments: [{ type: 'Orthogonal', length: 50, direction: 'Bottom' }],
    },
    { id: 'connector5', sourceID: 'processesStart', targetID: 'service', type: 'Orthogonal', },
    { id: 'connector6', sourceID: 'service', targetID: 'processesTask' },
    { id: 'connector7', sourceID: 'processesTask', targetID: 'processesEnd', type: 'Orthogonal', },
    {
        id: 'connector8', sourceID: 'compensation', targetID: 'user', type: 'Orthogonal',
        shape: {
            type: 'Bpmn',
            flow: 'Association',
            association: 'Directional'
        }, style: {
            strokeDashArray: '2,2'
        },
        segments: [{ type: 'Orthogonal', length: 30, direction: 'Bottom' },
        { type: 'Orthogonal', length: 80, direction: 'Right' }]
    },
    {
        id: 'connector9', sourceID: 'error', targetID: 'subProcessesEnd', type: 'Orthogonal',
        annotations: [{
            id: 'connector9Label2', content: 'Process', offset: 0.50,
            style: { fill: 'white', color: 'black' }
        }],
        segments: [{ type: 'Orthogonal', length: 50, direction: 'Bottom' }]
    }
];


let diagramInstance;
export class SettingsWorkflow extends SampleBase {
    rendereComplete() {
        diagramInstance.fitToPage();
    }
    render() {
        return (
            <>
                {/* <div className="page-content">
                    <Breadcrumbs title="Settings" breadcrumbItem="Workflow" />
                </div> */}
                {/* <div className="control-pane">
                    <div className="control-section"> */}
                {/* <div className="sb-mobile-palette-bar">
                            <div id="palette-icon" style={{ float: "right" }} className="e-ddb-icons1 e-toggle-palette"></div>
                        </div> */}
                {/* <div id="palette-space" className="sb-mobile-palette">
                            <SymbolPaletteComponent id="symbolpalette" expandMode="Multiple" palettes={[
                                {
                                    id: "Bpmn",
                                    expanded: true,
                                    symbols: bpmnShapes,
                                    iconCss: "e-diagram-icons1 e-diagram-Bpmn",
                                    title: "Bpmn Shapes"
                                },
                                {
                                    id: "connectors",
                                    expanded: true,
                                    symbols: getConnectors(),
                                    iconCss: "e-diagram-icons1 e-diagram-connector",
                                    title: "Connectors"
                                }
                            ]} width={"100%"} height={"471px"} symbolHeight={60} symbolWidth={60} symbolMargin={{ left: 15, right: 15, top: 15, bottom: 15 }} getNodeDefaults={(symbol) => {
                                symbol.style.strokeColor = '#757575';
                            }} getSymbolInfo={(symbol) => {
                                return { fit: true };
                            }}><Inject services={[BpmnDiagrams, UndoRedo, DiagramContextMenu, DataBinding]} />
                            </SymbolPaletteComponent>
                        </div> */}


                <DiagramComponent id="diagram" ref={diagram => (diagramInstance = diagram)} width={"100%"}
                    height={"550px"} snapSettings={{ constraints: NodeConstraints.Default | NodeConstraints.AllowDrop }} nodes={nodes} connectors={connectors}
                //Sets the default values of a node
                //  dragEnter={(args) => {
                //     let obj = args.element;
                //     if (obj instanceof Node) {
                //         if (!obj.shape.activity.subProcess.collapsed) {
                //             obj.shape.activity.subProcess.transaction.cancel.visible = true;
                //             obj.shape.activity.subProcess.transaction.failure.visible = true;
                //             obj.shape.activity.subProcess.transaction.success.visible = true;
                //         }
                //         else {
                //             let oWidth = obj.width;
                //             let oHeight = obj.height;
                //             let ratio = 100 / obj.width;
                //             obj.width = 100;
                //             obj.height *= ratio;
                //             obj.offsetX += (obj.width - oWidth) / 2;
                //             obj.offsetY += (obj.height - oHeight) / 2;
                //         }
                //     }
                // }}
                ><Inject services={[BpmnDiagrams, UndoRedo, DataBinding]} />
                </DiagramComponent>

                {/* </div>
                </div> */}
            </>);
    }
}
function getConnectors() {
    let connectorSymbols = [
        {
            id: 'Link1', type: 'Orthogonal', sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 },
            targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } }, style: { strokeWidth: 2, strokeColor: '#757575' }
        },
        {
            id: 'Link2', type: 'Orthogonal', sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 },
            targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } }, style: { strokeWidth: 2, strokeDashArray: '4 4', strokeColor: '#757575' }
        },
        {
            id: 'Link3', type: 'Straight', sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 },
            targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } }, style: { strokeWidth: 2, strokeColor: '#757575' }
        },
        {
            id: 'link4', sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 }, type: 'Orthogonal',
            targetDecorator: { style: { strokeColor: '#757575', fill: '#757575' } },
            shape: {
                type: 'Bpmn',
                flow: 'Association',
                association: 'Directional'
            }, style: {
                strokeDashArray: '2,2', strokeColor: '#757575'
            },
        },
    ];
    return connectorSymbols;
}
let isMobile;
function addEvents() {
    isMobile = window.matchMedia('(max-width:550px)').matches;
    if (isMobile) {
        let paletteIcon = document.getElementById('palette-icon');
        if (paletteIcon) {
            paletteIcon.addEventListener('click', openPalette, false);
        }
    }
}
function openPalette() {
    let paletteSpace = document.getElementById('palette-space');
    isMobile = window.matchMedia('(max-width:550px)').matches;
    if (isMobile) {
        if (!paletteSpace.classList.contains('sb-mobile-palette-open')) {
            paletteSpace.classList.add('sb-mobile-palette-open');
        }
        else {
            paletteSpace.classList.remove('sb-mobile-palette-open');
        }
    }
}
function contextMenuClick(args) {
    diagram = diagramInstance;
    if (diagram.selectedItems.nodes.length > 0) {
        let bpmnShape = diagram.selectedItems.nodes[0].shape;
        if (args.item.iconCss.indexOf('e-adhocs') > -1) {
            bpmnShape.activity.subProcess.adhoc = args.item.id === 'AdhocNone' ? false : true;
        }
        if (args.item.iconCss.indexOf('e-event') > -1) {
            bpmnShape.event.event = args.item.id;
        }
        if (args.item.iconCss.indexOf('e-trigger') > -1) {
            bpmnShape.event.trigger = args.item.text;
        }
        if (args.item.iconCss.indexOf('e-loop') > -1) {
            let loop = (args.item.id === 'LoopNone') ? 'None' : args.item.id;
            if (bpmnShape.activity.activity === 'Task') {
                bpmnShape.activity.task.loop = loop;
            }
            if (bpmnShape.activity.activity === 'SubProcess') {
                bpmnShape.activity.subProcess.loop = loop;
            }
        }
        if (args.item.iconCss.indexOf('e-compensation') > -1) {
            let compensation = (args.item.id === 'CompensationNone') ? false : true;
            if (bpmnShape.activity.activity === 'Task') {
                bpmnShape.activity.task.compensation = compensation;
            }
            if (bpmnShape.activity.activity === 'SubProcess') {
                bpmnShape.activity.subProcess.compensation = compensation;
            }
        }
        if (args.item.iconCss.indexOf('e-call') > -1) {
            let compensation = (args.item.id === 'CallNone') ? false : true;
            if (bpmnShape.activity.activity === 'Task') {
                bpmnShape.activity.task.call = compensation;
            }
        }
        if (args.item.id === 'CollapsedSubProcess' || args.item.id === 'ExpandedSubProcess') {
            if (args.item.id === 'ExpandedSubProcess') {
                bpmnShape.activity.activity = 'SubProcess';
                bpmnShape.activity.subProcess.collapsed = false;
            }
            else {
                bpmnShape.activity.activity = 'SubProcess';
                bpmnShape.activity.subProcess.collapsed = true;
            }
        }
        if (args.item.iconCss.indexOf('e-boundry') > -1) {
            let call = args.item.id;
            if (args.item.id !== 'Default') {
                call = (args.item.id === 'BoundryEvent') ? 'Event' : 'Call';
            }
            bpmnShape.activity.subProcess.boundary = call;
        }
        if (args.item.iconCss.indexOf('e-data') > -1) {
            let call = args.item.id === 'DataObjectNone' ? 'None' : args.item.id;
            bpmnShape.dataObject.type = call;
        }
        if (args.item.iconCss.indexOf('e-collection') > -1) {
            let call = (args.item.id === 'Collectioncollection') ? true : false;
            bpmnShape.dataObject.collection = call;
        }
        if (args.item.iconCss.indexOf('e-task') > -1) {
            let task = args.item.id === 'TaskNone' ? 'None' : args.item.id;
            if (bpmnShape.activity.activity === 'Task') {
                bpmnShape.activity.task.type = task;
            }
        }
        if (args.item.iconCss.indexOf('e-gate') > -1) {
            let task = args.item.id.replace('Gateway', '');
            if (bpmnShape.shape === 'Gateway') {
                bpmnShape.gateway.type = task;
            }
        }
        diagram.dataBind();
    }
}
// tslint:disable-next-line:max-func-body-length
function contextMenuOpen(args) {
    diagram = diagramInstance;
    let hiddenId = [];
    if (args.element.className !== 'e-menu-parent e-ul ') {
        hiddenId = ['Adhoc', 'Loop', 'taskCompensation', 'Activity-Type', 'Boundry', 'DataObject',
            'collection', 'DeftCall', 'TriggerResult', 'EventType', 'TaskType', 'GateWay'];
    }
    if (diagram.selectedItems.nodes.length) {
        for (let item of args.items) {
            let bpmnShape = diagram.selectedItems.nodes[0].shape;
            if (bpmnShape.shape !== 'DataObject' && bpmnShape.shape !== 'Gateway') {
                if (item.text === 'Ad-Hoc') {
                    if (bpmnShape.activity.activity === 'SubProcess') {
                        hiddenId.splice(hiddenId.indexOf(item.id), 1);
                    }
                }
                if (item.text === 'Loop' || item.text === 'Compensation' || item.text === 'Activity-Type') {
                    if (bpmnShape.shape === 'Activity') {
                        hiddenId.splice(hiddenId.indexOf(item.id), 1);
                    }
                }
                if (item.text === 'Boundry') {
                    if (bpmnShape.activity.activity === 'SubProcess') {
                        hiddenId.splice(hiddenId.indexOf(item.id), 1);
                    }
                }
            }
            if (item.text === 'Data Object') {
                if (bpmnShape.shape === 'DataObject') {
                    hiddenId.splice(hiddenId.indexOf(item.id), 1);
                }
            }
            if (item.text === 'Collection') {
                if (bpmnShape.shape === 'DataObject') {
                    hiddenId.splice(hiddenId.indexOf(item.id), 1);
                }
            }
            if (item.text === 'Call') {
                if (bpmnShape.shape === 'Activity' && bpmnShape.activity.activity === 'Task') {
                    hiddenId.splice(hiddenId.indexOf(item.id), 1);
                }
            }
            if (item.text === 'Trigger Result') {
                if ((bpmnShape.shape === 'Event')) {
                    hiddenId.splice(hiddenId.indexOf(item.id), 1);
                }
            }
            if (item.text === 'Event Type') {
                if ((bpmnShape.shape === 'Event')) {
                    hiddenId.splice(hiddenId.indexOf(item.id), 1);
                }
            }
            if (item.text === 'Task Type') {
                if ((bpmnShape.shape === 'Activity')
                    && (bpmnShape.activity.activity === 'Task')) {
                    hiddenId.splice(hiddenId.indexOf(item.id), 1);
                }
            }
            if (item.text === 'GateWay') {
                if ((bpmnShape.shape === 'Gateway')) {
                    hiddenId.splice(hiddenId.indexOf(item.id), 1);
                }
            }
            if (args.parentItem && args.parentItem.id === 'TriggerResult' && bpmnShape.shape === 'Event') {
                if (item.text !== 'None' && (item.text === bpmnShape.event.event || item.text === bpmnShape.event.trigger)) {
                    hiddenId.push(item.id);
                }
                if (bpmnShape.event.event === 'Start') {
                    if (item.text === 'Cancel' || item.text === 'Terminate' || item.text === 'Link') {
                        hiddenId.push(item.id);
                    }
                }
                if (bpmnShape.event.event === 'NonInterruptingStart' || item.text === 'Link') {
                    if (item.text === 'Cancel' || item.text === 'Terminate' || item.text === 'Compensation' ||
                        item.text === 'Error' || item.text === 'None') {
                        hiddenId.push(item.id);
                    }
                }
                if (bpmnShape.event.event === 'Intermediate') {
                    if (item.text === 'Terminate') {
                        hiddenId.push(item.id);
                    }
                }
                if (bpmnShape.event.event === 'NonInterruptingIntermediate') {
                    if (item.text === 'Cancel' || item.text === 'Terminate' || item.text === 'Compensation' ||
                        item.text === 'Error' || item.text === 'None' || item.text === 'Link') {
                        hiddenId.push(item.id);
                    }
                }
                if (bpmnShape.event.event === 'ThrowingIntermediate') {
                    if (item.text === 'Cancel' || item.text === 'Terminate' || item.text === 'Timer' || item.text === 'Error' ||
                        item.text === 'None' || item.text === 'Pareller' || item.text === 'Conditional') {
                        hiddenId.push(item.id);
                    }
                }
                if (bpmnShape.event.event === 'End') {
                    if (item.text === 'Parallel' || item.text === 'Timer' || item.text === 'Conditional' || item.text === 'Link') {
                        hiddenId.push(item.id);
                    }
                }
            }
            if (args.parentItem && args.parentItem.id === 'EventType' && bpmnShape.shape === 'Event') {
                if (item.text === bpmnShape.event.event) {
                    hiddenId.push(item.id);
                }
            }
        }
    }
    args.hiddenItems = hiddenId;
}

export default SettingsWorkflow;