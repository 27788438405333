// import Select from "react-select/dist/declarations/src/Select";
import { Button, Col, Input, Label, Row, Card } from "reactstrap";

import Select from 'react-select'
import React, { useEffect, useState } from "react";
import { addEmployeeLeaveRequest, getCompanyPayperiods, getEmployeeList, getLocalStorage, getAllowancesTypes, getDeductionTypes } from "../../../helpers/backend_helper";
import { FailureAlert, SuccessAlert } from "../../shared/Alert";
import LoadingSpinner from "../../shared/Loading";
import { getData, postData, postRawData } from "../../../helpers/url-request";
import { showLoading } from 'react-global-loading';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as url from '../../../helpers/url_helper';

const SalaryCorrectionDetail = (props) => {
    
    const [submitted, setFormSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [allowanceTypes, setAllowanceTypes] = useState();
    const [deductionTypes, setDeductionTypes] = useState();
    const [payPeriods, setPayPeriods] = useState();
    const [employeeList, setEmployeeList] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [correctionType, setCorrectionType] = useState(1);
    const [role, setRole] = useState();

    const [formData, setFormData] = useState({
        employeeId: 0, payPeriodId: 0, allowanceTypeId: 0, deductionTypeId: 0, description: '', amountType: 2, amount: 0
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getAllowancesTypes().then((res) => {
            let allowanceType = [{ "id": 0, "name": "Select Allowance Type" }];
            res.data.forEach(el => {
                allowanceType.push(el)
            });
            setAllowanceTypes(allowanceType);            
        });

        getDeductionTypes().then((res) => {
            let deductionType = [{ "id": 0, "name": "Select Deduction Type" }];
            res.data.forEach(el => {
                deductionType.push(el)
            });
            setDeductionTypes(deductionType);
        });

        getCompanyPayperiods().then((res) => {
            let payperiod = [{ "id": 0, "payPeriodTitle": "Select Pay Period" }];
            res.data.forEach(el => {
                payperiod.push(el)
            });
            setPayPeriods(payperiod);
        });

        getEmployeeList().then((res) => {
            // console.log(res);
            formData.employeeId = res.data[0].id
            
            if (res.data) {
                let emps = [{ label: "Select Employee", value: 0 }];
                res.data.forEach(el => {
                    emps.push({ label: el.fullName, value: el.id });
                });
                setEmployeeList(emps)
            }
        });

        const storage = getLocalStorage();        
        setRole(storage.roleName);

    }, [submitted]);

    const confirmHandler = (event) => {
        event.preventDefault();
        let formErrors = {};

        if (!formData.payPeriodId) {
            formErrors.payPeriodId = 'Pay Period is required';
        }

        if (!formData.amount) {
            formErrors.amount = 'Enter the amount/percentage';
        }
       
        if (!formData.description) {
             formErrors.description = 'Description is required';
        }

        setErrors(formErrors);
        
        console.log(formData);
        console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {

            setIsLoading(true);

            const _data = new FormData();

            _data.append("employeeId", formData.employeeId);
            _data.append("payPeriodId", formData.payPeriodId);
            _data.append("rorrectionType", formData.correctionType);
            _data.append("allowanceTypeId", formData.allowanceTypeId);
            _data.append("deductionTypeId", formData.deductionTypeId);
            _data.append("amountType", formData.amountType);
            _data.append("amount", formData.amount);
            _data.append("description", formData.description);

            if (selectedFiles.length > 0) {
                selectedFiles.forEach((file, index) => {
                    console.log(file);
                    _data.append("documents", file);
                });
            } else {
                console.log("Inside Else");
                _data.append("documents", []);
            }

            postRawData("/PayrollMisc/addsalarycorrection", "POST", _data).then((res) => {
                setIsLoading(false);

                if (res.status == true) {
                    setDataSuccess("added");
                    timeout();
                    if (props.onClose) {
                        props.onClose();
                    }
                } else {
                    setDataSuccess("failed");
                    alert(res.message);
                }                
            }).catch((err) => {
                console.log(err);
                setDataSuccess("failed");
                setIsLoading(false);
                timeout();
            })
        }
    }

   

    // const onLeaveChange = e => {
    //     setLeaveValue(e.currentTarget.value);
    // }

    const handleChange = (event) => {
        if (event.target) {
            setFormData({ ...formData, [event.target.name]: event.target.value });

            if (event.target.name == "correctionType") {
                setCorrectionType(event.target.value);
            }

        } else {
            setFormData({ ...formData, "employeeId": event.value });
        }
    }

    const handleAcceptedFiles = (files) => {
        console.log(files);
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setSelectedFiles(files);
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const closeHandler = (event) => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const timeout = () => {
        setTimeout(() => {
            setDataSuccess('')
        }, 3000);
    }

    return (
        <>
            <form>
                <Row>
                    <Col className="mb-1" xl="12" md="12" sm="12">
                        Employee
                    </Col>
                    <Col className='mb-1' xl='12' md='12' sm='12'>
                        <Select type="select"
                            placeholder='Select Employee'
                            name="employeeId"
                            isMulti={false}
                            options={employeeList}
                            classNamePrefix="form-select"
                            isLoading={false}
                            onChange={handleChange}
                        />
                        <p style={{ color: "Red" }}>{errors.employeeId && <span>{errors.employeeId}</span>}</p>
                    </Col>
                    <Col className='mb-1' xl='12' md='12' sm='12'>
                        <Label>Pay Period</Label>
                        <Input type="select" className="form-select" name="payPeriodId"
                            onChange={handleChange} value={formData.payPeriodId}>
                            {payPeriods &&
                                payPeriods.map((res) => {
                                    return <option value={res.id} key={res.id}>{res.payPeriodTitle}</option>
                                })}</Input>
                        <p style={{ color: "Red" }}>{errors.payPeriodId && <span>{errors.payPeriodId}</span>}</p>
                    </Col>

                    <Col className='mb-1' sm="12" md="12">
                        <input id="allowanceCorrection" name="correctionType" onChange={handleChange} type="radio" value="1" defaultChecked /> Allowance&nbsp;
                        <input id="deductionCorrection" name="correctionType" onChange={handleChange} type="radio" value="2" /> Deduction
                    </Col>

                    <Col className='mb-1' xl='12' md='12' sm='12' hidden={(correctionType == 2)}>
                        <Label>Allowance Type</Label>
                        <Input type="select" className="form-select" name="allowanceTypeId"
                            onChange={handleChange} value={formData.allowanceTypeId}>
                            {allowanceTypes &&
                                allowanceTypes.map((res) => {
                                    return <option value={res.id} key={res.id}>{res.name}</option>
                                })}</Input>
                        <p style={{ color: "Red" }}>{errors.allowanceTypeId && <span>{errors.allowanceTypeId}</span>}</p>
                    </Col>

                    <Col className='mb-1' xl='12' md='12' sm='12' hidden={(correctionType == 1)}>
                        <Label>Deduction Type</Label>
                        <Input type="select" className="form-select" name="deductionTypeId"
                            onChange={handleChange} value={formData.deductionTypeId}>
                            {deductionTypes &&
                                deductionTypes.map((res) => {
                                    return <option value={res.id} key={res.id}>{res.name}</option>
                                })}</Input>
                        <p style={{ color: "Red" }}>{errors.deductionTypeId && <span>{errors.deductionTypeId}</span>}</p>
                    </Col>

                    <Col className='mb-1' sm="12" md="12">
                        <input id="percentage" name="amountType" onChange={handleChange} type="radio" value="1" /> Percentage&nbsp;
                        <input id="fixedAmount" name="amountType" onChange={handleChange} type="radio" value="2" defaultChecked /> Fixed Amount
                    </Col>

                    <Col sm="12" md="12">
                        <Input type="number" onChange={handleChange}
                            name='amount' placeholder="Enter Percentage/Amount">
                        </Input>
                        <p style={{ color: "Red" }}>{errors.amount && <span>{errors.amount}</span>}</p>
                    </Col>

                    <Col className='mb-1' xl='12' md='12' sm='12'>
                        <Label>Description</Label>
                        <Input type='textarea' name="description" rows="6" onChange={handleChange} id='input-default' placeholder='Description' />
                    </Col>

                    <Col sm='12' className='mb-1'>
                        <br />
                        <Label>Correction Related Documents</Label>
                        <Dropzone onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles);
                        }}>
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone" style={{ minHeight: '130px' }}>
                                    <div className="dz-message needsclick mt-2" style={{ padding: '10px' }} {...getRootProps()} >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                            <i className="display-4 text-muted bx bx-cloud-upload" />
                                        </div>
                                        <h4>Drop files here or click to upload.</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedFiles.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}>
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUpJREFUOE+N0z1LHlEQhuHr1aDiRyEStTGK2PoTbO20sRESRAVtDWJhpYH8HwsbFQRRC02pgnaSIJgija3fMq9n4bDZDdlmd4cz9zwz85yG92cAt/iT/suvFrRjGVt4LA40MsAZBmsARYFrfMUenuJsDjjHMHoQFV/xgDv04xTTOMIMDsqAKyzhGz6mCof4jA4sYDPBXwq1ZQVDaEttPKO1oqU+/KgCXCaJE4jkG4yUABGPlr7XKfiE7gT4UAwqg0SsF8d1CmYxh58V0iN5FDGXjX8p6EwKcsZ98kHEYgYnVYDYwhcs4lfKLoYce59MLf3Gep2C8EFXmn5UzQ0XTgzzhE/CE03T5WsMBfNYwRh2MkAkh4I4s4u1KkCdlaNIAEJZfMeWKhXUAcJYU1hNgO10H/5q4QLjNZcpD4fN98stRDAA//vEtW8WewNXM0MR2YfR7AAAAABJRU5ErkJggg=="}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                );
                            })}
                        </div>
                    </Col>
                    
                    <div className="text-center mt-2">
                        {dataSuccess === 'added' && <SuccessAlert />}
                        {dataSuccess === 'failed' && <FailureAlert />}
                    </div>

                    <div className="offcanvas-footer">
                        {isLoading ? <LoadingSpinner /> : <Button color="primary"
                            onClick={confirmHandler} className="btn" disabled={isLoading}
                            type="submit">Submit</Button>}&nbsp;&nbsp;&nbsp;
                        <button type="reset" className="btn btn-dark" onClick={closeHandler}>Close</button>
                    </div>
                </Row>
            </form>
        </>
    )
}

export default SalaryCorrectionDetail;