import React, { useEffect } from 'react';

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
    Button, Card, CardHeader, CardTitle, Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Container
} from "reactstrap";

import { useState } from 'react';
import ExpenseBody from './ExpenseBody';
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Inject, Toolbar, ColumnMenu, Sort } from "@syncfusion/ej2-react-grids";
import { getEmployeeExpenseRequest } from '../../../helpers/backend_helper';

const AddExpense = () => {

    const [canvasPlacement, setCanvasPlacement] = useState('start')
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [expenseList, setExpenseList] = useState();

    useEffect(() => {
        getEmployeeExpenseRequest().then((res) => {
            if (res.data) {
                setExpenseList(res.data);
            }
        })
    }, [])

    const toggleCanvasEnd = () => {
        setCanvasPlacement('end')
        setCanvasOpen(!canvasOpen)
    }

    const toolbarOptions = [
        { text: 'Excel Export', prefixIcon: 'e-excelexport', id: 'excel' },
        { text: 'Pdf Export', prefixIcon: 'e-pdfexport ', id: 'pdf' },
        { text: 'Csv Export', prefixIcon: 'e-csvexport ', id: 'csv' }
    ];

    document.title = "Expense | HRMS";
    return (
        <React.Fragment>
            <div className="page-content">
                
                    <Breadcrumbs title="Expense" breadcrumbItem="Expenses" enableAction={true} actionName="Add Expense" onClick={toggleCanvasEnd} />

                    <div className='mt-2'>
                    <GridComponent dataSource={expenseList} allowPaging={true} allowSorting={true} showColumnMenu={true} pageSettings={{ pageCount: 5 }}>
                        <ColumnsDirective>
                                <ColumnDirective field='employeeProfile.fullName' headerText='Employee' ></ColumnDirective>
                                <ColumnDirective field='expenseType.name' headerText='Expense Type' width='175' ></ColumnDirective>
                                <ColumnDirective field='expenseDate' type='datetime' format='dd/MM/yyyy' headerText='Expense Date' width='175'></ColumnDirective>
                                <ColumnDirective field='amount' headerText='Amount' width='150'></ColumnDirective>
                                <ColumnDirective field='description' headerText='Description' width='250' />
                                <ColumnDirective field='expenseStatus'  headerText='Status' width='150' />                                
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Page, Sort, ColumnMenu]} />
                        </GridComponent>
                    </div>
                    
                    <Offcanvas backdrop="static" className='w-30' direction={canvasPlacement} isOpen={canvasOpen}
                        toggle={toggleCanvasEnd}>
                        <OffcanvasHeader toggle={toggleCanvasEnd}>
                            <Row>
                                <Col sm='12'>
                                    <b>Add Expenses</b>
                                </Col>
                            </Row>
                        </OffcanvasHeader>
                        <hr />

                        <OffcanvasBody>
                            <ExpenseBody />
                        </OffcanvasBody>
                </Offcanvas>
                
            </div>
        </React.Fragment>
    );
};

export default AddExpense;