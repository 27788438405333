import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = ""

const generatedToken = localStorage.getItem("accessToken")

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: { 'Authorization': generatedToken }
})

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + generatedToken,
  'ApiKey': 'key123'
};

const headers = new Headers(defaultHeaders);

// axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.defaults.headers.common["ApiKey"] = "key123"

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

// const instance = axios.create({
//   headers: { 'Authorization': 'Bearer YOUR_ACCESS_TOKEN' }
// });

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  console.log("Inside Post " + url);
  console.log({ ...data }, { ...config })
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
      console.log(response);
      response.data
    })

  // let res = fetch(url, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     "ApiKey": "key123"
  //   },
  //   body: JSON.stringify(data)
  // })
  //   .then(response => {
  //     return response.json()
  //   })
  //   .then(data => console.log(data))
  //   .catch(error => console.error(error));

  // console.log(res);
}

export async function checkLogin(url, data, config) {
  console.log("checkLogin", url);
  console.log(data);

  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ApiKey': 'key123'
    },
    body: JSON.stringify(data),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('HTTP error, status = ' + response.status);
      }
      return response.json();
    })
    .then(data => {
      console.log(data);
      if(data.status){
        localStorage.setItem("accessToken", JSON.stringify(data.data.token));
      }
      
      // do something with the data
      return data;
    })
    .catch(error => {
      console.log(error);
    });

  // console.log(res);
}

export async function postLogin(url, data, config = {}) {
  console.log("Inside postLogin", url);
  console.log(data, "Inside Post login data");

  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
      console.log(response);
      response.data
    }).catch((err) => {
      console.log(err);
    })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data).catch((err) => {
      console.log(err);
    })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function getMasterEmployeeTypes(url) {
  console.log("Inside getMasterEmployeeTypes: " + url);

  let userdata = localStorage.getItem("authUser");
  userdata = JSON.parse(userdata);
  //console.log(userdata,userdata.token);

  const headers = new Headers();
  let token = generatedToken;
  token = token.replace('"', '');
  //console.log(token);
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', "Bearer " + userdata.token);
  headers.append('ApiKey', 'key123');

  return await fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error(error))

}

